import React from 'react';
//import PropTypes from 'prop-types';
import classNames from 'classnames';

const Checkbox = ({ className, name, schema, value, onChange, valid }) => {
  return (
    <div className={classNames('form-group form-check', className)}>
      <input
        type="checkbox"
        className="form-check-input"
        id={name}
        name={name}
        checked={value}
        onChange={({ target: { name, checked } }) => onChange(name, checked)}
      />
      <label className="form-check-label" htmlFor={name}>
        {schema.title}
      </label>
    </div>
  );
};

Checkbox.propTypes = {};

export default Checkbox;
