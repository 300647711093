import React, { PureComponent } from 'react';
// import { ScoreBadge } from "../../components";
// import CommentModal from "../../components/Modal/CommentModal";
import QuestionModal from '../../components/Modal/QuestionModal';
// import EmptyComment from "./EmptyComment";

class Questions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { rating: 0, errors: { comment: false, rating: false } };
    this.comment = React.createRef();
  }

  componentDidMount() {}

  handleRating = (value) => {
    this.setState({ rating: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.props.user.id) {
      this.props.loginRequired(true);
      return;
    }

    const commentText = this.comment.current.value;
    const errors = { comment: false, rating: false };

    errors.comment = !commentText.length;
    errors.rating = !this.state.rating;

    this.setState({ errors }, () => {
      if (!errors.comment) {
        const comment = {
          customerId: this.props.user.id,
          name: `${this.props.user.firstname} ${this.props.user.lastname}`,
          date: new Date(),
          email: this.props.user.email,
          // star: this.state.rating * 10,
          text: commentText,
          productId: this.props.product.id,
          repliedTo: 0,
        };

        this.props.onSubmit(comment);
      }
    });
  };

  render() {
    const comments = [];
    const replies = [];

    this.props.comments.forEach((comment) => {
      comment.repliedTo ? replies.push(comment) : comments.push(comment);
    });

    return (
      <>
        <div className="questions-container">
          <div className="send-question">
            <p className="fs-14">
              Ürün hakkında aklına gelen her soruyu sorabilirsin
            </p>
            <button
              className="btn comment-button"
              onClick={this.props.onToggle}
            >
              Sorumu Gönder
            </button>
          </div>
          {this.props.comments.filter((c) => c.tur === 2).length > 0 && (
            <div className="px-3 px-sm-0">
              <h6>Soru-Cevap</h6>
              <ul className="list-group list-group-flush full-w">
                {comments
                  .filter((c) => c.tur === 2)
                  .map((comment) => (
                    <>
                      <li
                        className="list-group-item comment-item main-comment main-question"
                        key={comment.id}
                      >
                        <div className="d-flex align-items-center">
                          <figure className="figure mr-4">
                            <div className="default-avatar">
                              {comment.avatar ? (
                                <img
                                  src={comment.avatar.path}
                                  width="48"
                                  height="48"
                                  alt=""
                                />
                              ) : comment.name ? (
                                comment.name.charAt(0).toUpperCase() +
                                comment.name
                                  .split(' ')
                                  .slice(-1)[0]
                                  .charAt(0)
                                  .toUpperCase()
                              ) : (
                                'DK'
                              )}
                            </div>
                          </figure>

                          <div className="comment-box">
                            <span className="comment-user-name mt-0">
                              {comment.name}
                            </span>
                            <br />

                            <p className="comment">{comment.text}</p>
                          </div>
                        </div>
                        {replies
                          .filter((reply) => reply.repliedTo === comment.id)
                          .map((reply) => (
                            <ul>
                              <li
                                className="list-group-item main-question comment-item border-none p-0"
                                key={reply.id}
                              >
                                <div className="d-flex align-items-center">
                                  <figure className="figure mr-4 float-left">
                                    <div className="default-avatar">
                                      {reply.avatar ? (
                                        <img
                                          src={reply.avatar.path}
                                          width="48"
                                          height="48"
                                          alt=""
                                        />
                                      ) : reply.name ? (
                                        reply.name.charAt(0).toUpperCase() +
                                        reply.name
                                          .split(' ')
                                          .slice(-1)[0]
                                          .charAt(0)
                                          .toUpperCase()
                                      ) : (
                                        'DK'
                                      )}
                                    </div>
                                  </figure>
                                  <div className="comment-box">
                                    <span className="comment-user-name mt-0">
                                      {reply.name}
                                    </span>
                                    <br />

                                    <p className="comment">{reply.text}</p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          ))}
                      </li>
                    </>
                  ))}
              </ul>
            </div>
          )}
        </div>

        <QuestionModal
          className={this.props.show ? 'd-block' : 'd-none'}
          show={this.props.show}
          handleSubmit={this.handleSubmit}
          initialRating={this.state.rating}
          handleRating={this.handleRating}
          errors={this.state.errors}
          comment={this.comment}
          onToggle={this.props.onToggle}
          product={this.props.product}
        />
      </>
    );
  }
}

export default Questions;
