import React, { Component } from 'react';
//import PropTypes from 'prop-types';

class Refund extends Component {
  render() {
    return (
      <>
        <h3 className="mt-2">Cayma Hakkı ve İade Koşulları</h3>
        <div className="card">
          <div className="card-header"></div>
          <div className="card-body pt-0 fs-14">
            <h6>GENEL:</h6>

            <p>
              1.Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda
              sipariş verdiğiniz takdirde, size sunulan ön bilgilendirme formunu
              ve mesafeli satış sözleşmesini kabul etmiş sayılırsınız.
            </p>
            <p>
              2.ALICI, satın aldıkları ürünün satış ve teslimi ile ilgili olarak
              6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli
              Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile
              yürürlükteki diğer yasalara tabidir.
            </p>
            <p>
              3.Doğru Kaynak&apos;ın özellikle kargo ücretini üstlendiğini
              belirttiği durumlar dışında, ürün sevkiyat masrafı olan kargo
              ücretleri alıcılar tarafından ödenecektir.
            </p>
            <p>
              4.Satın alınan her bir ürün, 30 günlük yasal süreyi aşmamak kaydı
              ile alıcının gösterdiği adresteki kişi ve/veya kuruluşa teslim
              edilir. Bu süre içinde ürün teslim edilmez ise, ALICI sözleşmeyi
              sona erdirebilir.
            </p>
            <p>
              5.Satın alınan ürün, eksiksiz ve siparişte belirtilen niteliklere
              uygun ve varsa garanti belgesi, kullanım kılavuzu gibi belgelerle
              teslim edilmek zorundadır.
            </p>
            <p>
              6.Satın alınan ürünün satılmasının imkansızlaşması durumunda,
              SATICI bu durumu öğrendiğinden itibaren 3 gün içinde yazılı olarak
              alıcıya bu durumu bildirmek zorundadır. 14 gün içinde de toplam
              bedel ALICI&apos;ya iade edilmek zorundadır.
            </p>

            <h6>SATIN ALINAN ÜRÜN BEDELİ ÖDENMEZ İSE:</h6>

            <p>
              7.ALICI, satın aldığı ürün bedelini ödemez veya banka kayıtlarında
              iptal ederse, SATICI&apos;nın ürünü teslim yükümlülüğü sona erer.
            </p>

            <h6>KREDİ KARTININ YETKİSİZ KULLANIMI İLE YAPILAN ALIŞVERİŞLER:</h6>

            <p>
              8.Ürün teslim edildikten sonra, ALICI&apos;nın ödeme yaptığı kredi
              kartının yetkisiz kişiler tarafından haksız olarak kullanıldığı
              tespit edilirse ve satılan ürün bedeli ilgili banka veya finans
              kuruluşu tarafından SATICI'ya ödenmez ise, ALICI, sözleşme konusu
              ürünü 3 gün içerisinde nakliye gideri SATICI&apos;ya ait olacak
              şekilde SATICI&apos;ya iade etmek zorundadır.
            </p>

            <h6>
              ÖNGÖRÜLEMEYEN SEBEPLERLE ÜRÜN SÜRESİNDE TESLİM EDİLEMEZ İSE:
            </h6>

            <p>
              9.SATICI&apos;nın öngöremeyeceği mücbir sebepler oluşursa ve ürün
              süresinde teslim edilemez ise, durum ALICI&apos;ya bildirilir.
              ALICI, siparişin iptalini, ürünün benzeri ile değiştirilmesini
              veya engel ortadan kalkana dek teslimatın ertelenmesini talep
              edebilir. ALICI siparişi iptal ederse; ödemeyi nakit ile yapmış
              ise iptalinden itibaren 14 gün içinde kendisine nakden bu ücret
              ödenir. ALICI, ödemeyi kredi kartı ile yapmış ise ve iptal ederse,
              bu iptalden itibaren yine 14 gün içinde ürün bedeli bankaya iade
              edilir, ancak bankanın alıcının hesabına 2-3 hafta içerisinde
              aktarması olasıdır.
            </p>

            <h6>ALICININ ÜRÜNÜ KONTROL ETME YÜKÜMLÜLÜĞÜ:</h6>

            <p>
              10.ALICI, sözleşme konusu mal/hizmeti teslim almadan önce muayene
              edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı
              mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim alınan
              mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir. ALICI,
              Teslimden sonra mal/hizmeti özenle korunmak zorundadır. Cayma
              hakkı kullanılacaksa mal/hizmet kullanılmamalıdır. Ürünle birlikte
              Fatura da iade edilmelidir.
            </p>

            <h6>CAYMA HAKKI:</h6>

            <p>
              11.ALICI; satın aldığı ürünün kendisine veya gösterdiği adresteki
              kişi/kuruluşa teslim tarihinden itibaren 14 (on dört) gün
              içerisinde, SATICI&apos;ya aşağıdaki iletişim bilgileri üzerinden
              bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk
              üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı reddederek
              sözleşmeden cayma hakkını kullanabilir.
            </p>

            <h6>
              SATICININ CAYMA HAKKI BİLDİRİMİ YAPILACAK İLETİŞİM BİLGİLERİ:
            </h6>

            <p>12.ŞİRKET</p>

            <table width="100%" style={{ marginBottom: '1rem' }}>
              <tbody>
                <tr>
                  <td>ADI/UNVANI:</td>
                  <td>Doğru Kaynak Dağıtım Teknoloji A.Ş.</td>
                </tr>
                <tr>
                  <td>ADRES:</td>
                  <td>
                    Yeşilce Mah. Göktürk Cad. No:20 K.2 Kağıthane/İstanbul
                  </td>
                </tr>
                <tr>
                  <td>E-POSTA:</td>
                  <td>
                    <a href="mailto:destek@dogrukaynak.com">
                      destek@dogrukaynak.com
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>TEL:</td>
                  <td>0 212 963 3535</td>
                </tr>
              </tbody>
            </table>

            <h6>CAYMA HAKKININ SÜRESİ:</h6>

            <p>
              13.ALICI, satın aldığı eğer bir hizmet ise bu 14 günlük süre
              sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakkı
              süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına
              başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz.
            </p>

            <p>
              14.Cayma hakkının kullanımından kaynaklanan masraflar SATICI&apos;
              ya aittir.
            </p>

            <p>
              15.Cayma hakkının kullanılması için 14 (ondört) günlük süre içinde
              SATICI' ya iadeli taahhütlü posta, faks veya eposta ile yazılı
              bildirimde bulunulması ve ürünün işbu sözleşmede düzenlenen "Cayma
              Hakkı Kullanılamayacak Ürünler" hükümleri çerçevesinde
              kullanılmamış olması şarttır.
            </p>

            <h6>CAYMA HAKKININ KULLANIMI:</h6>

            <p>
              16. 3. kişiye veya ALICI&apos; ya teslim edilen ürünün faturası,
              (İade edilmek istenen ürünün faturası kurumsal ise, iade ederken
              kurumun düzenlemiş olduğu iade faturası ile birlikte gönderilmesi
              gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri
              İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.)
            </p>

            <p>
              17.İade formu, İade edilecek ürünlerin kutusu, ambalajı, varsa
              standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak
              teslim edilmesi gerekmektedir.
            </p>

            <h6>İADE KOŞULLARI:</h6>

            <p>
              18.SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en
              geç 14 günlük süre içerisinde toplam bedeli ve ALICI&apos;yı borç
              altına sokan belgeleri ALICI&apos; ya iade etmek ve 20 günlük süre
              içerisinde malı iade almakla yükümlüdür.
            </p>

            <p>
              19.ALICI&apos; nın kusurundan kaynaklanan bir nedenle malın
              değerinde bir azalma olursa veya iade imkânsızlaşırsa ALICI kusuru
              oranında SATICI&apos;nın zararlarını tazmin etmekle yükümlüdür.
              Ancak cayma hakkı süresi içinde malın veya ürünün usulüne uygun
              kullanılması sebebiyle meydana gelen değişiklik ve bozulmalardan
              ALICI sorumlu değildir.
            </p>

            <p>
              20.Cayma hakkının kullanılması nedeniyle SATICI tarafından
              düzenlenen kampanya limit tutarının altına düşülmesi halinde
              kampanya kapsamında faydalanılan indirim miktarı iptal edilir.
            </p>

            <h6>CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER:</h6>

            <p>
              21.ALICI&apos;nın isteği veya açıkça kişisel ihtiyaçları
              doğrultusunda hazırlanan ve geri gönderilmeye müsait olmayan, iç
              giyim alt parçaları, mayo ve bikini altları, makyaj malzemeleri,
              tek kullanımlık ürünler, çabuk bozulma tehlikesi olan veya son
              kullanma tarihi geçme ihtimali olan mallar, ALICI&apos;ya teslim
              edilmesinin ardından ALICI tarafından ambalajı açıldığı takdirde
              iade edilmesi sağlık ve hijyen açısından uygun olmayan ürünler,
              teslim edildikten sonra başka ürünlerle karışan ve doğası gereği
              ayrıştırılması mümkün olmayan ürünler, Abonelik sözleşmesi
              kapsamında sağlananlar dışında, gazete ve dergi gibi süreli
              yayınlara ilişkin mallar,{' '}
              <strong>
                Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye
                anında teslim edilen gayrimaddi mallar, ile ses veya görüntü
                kayıtlarının, kitap, dijital içerik, yazılım programlarının,
                veri kaydedebilme ve veri depolama cihazlarının, bilgisayar sarf
                malzemelerinin, ambalajının ALICI tarafından açılmış olması
                halinde iadesi Yönetmelik gereği mümkün değildir.
              </strong>{' '}
              Ayrıca Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile
              ifasına başlanan hizmetlere ilişkin cayma hakkının kullanılması da
              Yönetmelik gereği mümkün değildir.
            </p>

            <p>
              22.Kozmetik ve kişisel bakım ürünleri, iç giyim ürünleri, mayo,
              bikini, kitap, kopyalanabilir yazılım ve programlar, DVD, VCD, CD
              ve kasetler ile kırtasiye sarf malzemeleri (toner, kartuş, şerit
              vb.) iade edilebilmesi için ambalajlarının açılmamış, denenmemiş,
              bozulmamış ve kullanılmamış olmaları gerekir.
            </p>

            <h6>TEMERRÜT HALİ VE HUKUKİ SONUÇLARI</h6>

            <p>
              23.ALICI, ödeme işlemlerini kredi kartı ile yaptığı durumda
              temerrüde düştüğü takdirde, kart sahibi banka ile arasındaki kredi
              kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı
              sorumlu olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili
              banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet
              ücretini ALICI&apos;dan talep edebilir ve her koşulda
              ALICI&apos;nın borcundan dolayı temerrüde düşmesi halinde, ALICI,
              borcun gecikmeli ifasından dolayı SATICI&apos;nın uğradığı zarar
              ve ziyanını ödeyeceğini kabul eder.
            </p>

            <h6>ÖDEME VE TESLİMAT</h6>

            <p>
              24.Ödemelerinizi kredi kartı, banka havalesi, kapıda ödeme, BKM
              Express ile gerçekeştirebilirsiniz. Banka Havalesi veya EFT
              (Elektronik Fon Transferi) yaparak, TR62 0006 4000 0011 1071 0257
              51 ya da IBAN 1107-1025751 bankası hesaplarımızdan (TL) herhangi
              birine yapabilirsiniz.
            </p>

            <p>
              25.Sitemiz üzerinden kredi kartlarınız ile, Her türlü kredi
              kartınıza online tek ödeme ya da online taksit imkânlarından
              yararlanabilirsiniz. Online ödemelerinizde siparişiniz sonunda
              kredi kartınızdan tutar çekim işlemi gerçekleşecektir.
            </p>

            <p>
              26.Doğru Kaynak gerek duyduğunda daha hızlı gönderimde bulunmak
              amacıyla kargo firması değişikliği yapabilir. Doğru Kaynak'ta
              müşteri memnuniyeti nedeniyle yapılan değişiklik için ek bir ücret
              talep edilmez.
            </p>

            <p>
              27.Ortalama teslim süreleri: Özel Kargo Şirketi'yle tahmini 3-7
              gün ve her hâlükârda 30 günlük yasal süre içerisinde teslimat
              yapılmaktadır. Türkiye dışına gönderim durumunda bu süreler,
              ülkeye göre değişiklik göstermektedir.
            </p>

            <p>
              28.Türkiye içi gönderilerde seçtiğiniz teslimatçı firma, sipariş
              sürecinde kargo firması ve ücret seçenekleri adres aşamasından
              sonra kargo sayfasında bildirilmektedir. Şu kadar ki, satış anında
              yürüttüğümüz ve/veya www.dogrukaynak.com'da şartlarını ilan
              ettiğimiz kampanyaların sonucuna bağlı olarak söz konusu kargo
              ücretinin tamamını ya da bir kısmını tarafınıza yansıtmayabiliriz.
            </p>
          </div>
        </div>
      </>
    );
  }
}

Refund.propTypes = {};

export default Refund;
