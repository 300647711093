import React, { Component } from 'react';
//import PropTypes from 'prop-types';

class MembershipAgreement extends Component {
  render() {
    return (
      <>
        <h3 className="mt-2">Üyelik Sözleşmesi</h3>
        <div className="card">
          <div className="card-header">{/* <h5>Üyelik Sözleşmesi</h5> */}</div>
          <div className="card-body pt-0 fs-14">
            <p>
              İş bu sözleşme Yüksek Başarı Yayın Dağıtım ve Paz. A.Ş. ye ait
              dogrukaynak.com adlı internet sitesinin hizmet şartlarını
              belirler. Siteye üye olunması, üyelik sözleşmesinin üye tarafından
              okunduğunu ve kabul edildiğini gösterir.
            </p>
            <p>
              ÜYE'NİN HAK VE YÜKÜMLÜLÜKLERİ 1- Üye, işbu sözleşmenin tamamını
              okuduğunu, içeriğini tamamen anladığını ve sözleşmede belirtilen
              tüm hususları kayıtsız ve şartsız olarak kabul ettiğini ve
              onayladığını beyan ve taahhüt etmiştir. 2- Üye tarafından
              oluşturulan hesap sadece kendisine ait olup başkasına
              devredilemez. Bilgilerin yahut şifrenin başka kişiler tarafından
              kullanılmasından doğacak sorumluluk üyeye ait olup bu sebeple
              ödemek zorunda kalınacak her türlü para cezası ve/veya tazminat
              için üyeye rücu edilebilecektir. 3- Üye, belirlediği şifrenin
              güvenliğini sağlamanın kendi sorumluluğu altında olduğunu ve bu
              doğrultuda gereken önlemleri almayı, diğer üyelerin verilerine
              izinsiz olarak ulaşmamayı ve kendilerine ait şifreleri
              başkalarıyla paylaşmamayı, aksi halde bundan doğacak hukuki ve
              cezai sorumlukların kendisine ait olacağını; hesabının yetkisi
              olmayanlar kişilerce kullanıldığını veya şifresinin bir başkası
              tarafından ele geçirildiğini öğrendiğinde derhal Yüksek Başarı’ya
              bildirmeyi; hesabın üçüncü şahıslar tarafından ele geçirilmesinden
              sorumlu tutulamayacağını kabul ve taahhüt eder. 4- Üye, kanunen
              yasak bilgiler içeren mesajlar, yazılım virüsü gibi üçüncü
              şahıslara zarar verebilecek içerikleri dağıtmayacağını; diğer
              kullanıcıların bilgisayarındaki bilgilere ya da yazılıma zarar
              verecek program ve/ya bilgiler göndermemeyi, siteyi
              kullanmalarından ötürü üçüncü şahısların maruz kalabilecekleri her
              türlü zarar ve ziyanı tazmin edeceğini kabul ve taahhüt eder. 5-
              Üye, sağlanan hizmetten faydalandığı sürece Fikir ve Sanat
              Eserleri Yasası, Sınai Mülkiyet Kanunu, Türk Ceza Kanunu, Borçlar
              Kanunu, Ticaret Kanunu ve diğer mevzuat hükümleri ile Yüksek
              Başarı tarafından yayınlanan bildirimlere riayet etmeyi ve bunları
              ihlal etmemeyi kabul ve taahhüt eder. Aksi taktirde ödemek zorunda
              kalınan her türlü tazminat ve/veya idari/adli para cezaları için
              kullanıcı ve/veya üyeye aynen rücu edilir. 6- dogrukaynak.com
              sitesinin ismi, logosu, içeriği ve yazılımı, her türlü fikri ve
              mali hakkı Yüksek Başarı’ya aittir. Üye, site ismini, logosunu,
              içeriğini ve yazılımını kopyalamak, çoğaltmak, değiştirmek
              amacıyla ya da izin ve yetki verilmeyen başka amaçlarla
              kullanamaz. 7- Üye, vereceği bilgilerin doğru olduğunu,
              yayınlayacağı tüm görüş, düşünce, ifade ve resimlerin sadece
              kendisini bağlayacağını bunlardan ötürü dogrukaynak.com bir
              sorumluluğunun bulunmadığını beyan, kabul ve garanti eder. Buna
              rağmen …… tarafından ödenmek zorunda kalınan adli/idari para
              cezaları ve/veya tazminatlar için üyeye aynen rücu edilir.
            </p>

            <p>
              dogrukaynak.com HAK VE YÜKÜMLÜLÜKLERİ 1- dogrukaynak.com her zaman
              için tek taraflı olarak kullanıcıya ve/veya üyeye verilen hizmeti
              sürekli veya geçici durdurabileceği gibi herhangi bir sayfasındaki
              ya da hizmetindeki üye erişimini neden belirtmeksizin ve
              uyarmaksızın, erteleme, sınırlama ve yok etme hakkına sahiptir. 2-
              dogrukaynak.com işbu üyelik sözleşmesi ile sair kullanım
              koşullarına uygun olmadığını tespit ettiği üyelerin hesaplarını
              hiçbir açıklama yapmaksınızın silme hakkına sahiptir. Ayrıca,
              belirli zaman dilimi boyunca güncellenmediği tespit edilen
              üyelerin hesapları da hiçbir açıklama yapmaksınızın
              silinebilecektir. 3- dogrukaynak.com alan adlı internet sitesinin
              kesintisiz veya hatasız olacağını ya da sitenin veya içeriğinin
              kullanılmasıyla ya da siteye bağlantı yapılmasıyla belirli
              sonuçların elde edileceğini garanti etmemektedir. Doğrudan,
              dolaylı veya arızi zararlar, netice kabilinden doğan zararlar ve
              cezai tazminatlar da dahil olmak üzere, dogrukaynak.com alan adlı
              internet sitesinin kullanılmasından kaynaklanabilecek zararlar
              için sorumlu tutulamaz. 4- dogrukaynak.com internet sitesinin
              birçok yerinden kendi markası olan ve/ya olmayan diğer sitelere
              link verebilir. Link verdiği, tavsiye ettiği sitelerin bilgi
              kullanımı, gizlilik ilkeleri ve içeriğinden sorumlu değildir. 5-
              dogrukaynak.com teknik nedenlerden kaynaklanan fiyat, ürün ve her
              türlü güncelleme hatalarından sorumlu değildir. İlan edilen
              fiyatları dilediği zaman değiştirebilir. 6- dogrukaynak.com üye
              tarafından beyan edilen her türlü bilgiyi, Gizlilik Politikası ile
              açıklanan kullanımlar, işbu sözleşme ile belirlenen
              yükümlülüklerini ve internet sitesinin işletilmesi için gereken
              uygulamaların yürütülmesini ifa, kendisi tarafından belirlenen
              istatistiki değerlendirmeler ve Yüksek Başarı’ya ait diğer
              sitelerde reklam, tanıtım, anket, kampanya ve doğrudan pazarlama
              amacıyla gereken sürelerde kullanabilir ve saklayabilir. Üye
              bilgilerinin bu şekilde kullanımına ve saklanmasına rıza
              gösterdiğini kabul ve beyan eder. Yürürlükteki mevzuat uyarınca
              yetkili makamlardan usulüne uygun olarak talep gelmesi halinde
              kullanıcının ve/veya üyenin kendisinde bulunan IP bilgisi dahil
              tüm bilgilerini ilgili yetkili makamlarla paylaşılacaktır. 7-
              dogrukaynak.com iş bu üyelik sözleşmesi uyarınca, üyelerinin
              kendisinde kayıtlı elektronik posta adreslerine bilgilendirme
              mailleri ve cep telefonlarına bilgilendirme SMS'leri gönderme
              yetkisine sahiptir. Üye işbu üyelik sözleşmesini onaylamasıyla
              beraber bilgilendirme maillerinin elektronik posta adresine ve
              bilgilendirme SMS'lerinin cep telefonuna gönderilmesini kabul
              etmiş sayılacaktır. Üyenin talebi halinde bu listeden çıkışını
              sağlayacaktır.
            </p>
            <p>
              FESİH VE DEĞİŞİKLİKLER 1- dogrukaynak.com ve üye bu üyelik
              sözleşmesini dilediği zaman sona erdirebilir. 2- dogrukaynak.com
              bu üyelik sözleşmesinin koşullarında ve internet sitesinde
              dilediği zaman ve yasal düzenlemeler gereği de tek taraflı olarak
              değişiklik yapma hakkına sahiptir. Bu değişiklikler, yayınlandığı
              andan itibaren geçerlilik kazanmış sayılır. Kullanıcılar internet
              sitesine her yeni girişlerinde,
              güncellenmiş/değiştirilmiş/ayarlanmış maddeleri kabul etmiş
              sayılmaktadırlar. 3- Üyenin işbu sözleşme sözleşmede belirtilen
              hak ve yükümlülüklere aykırı hareketleri ve/veya edim ve
              taahhütlerini yerine getirmemesi nedeniyle, dogrukaynak.com
              ve/veya 3. kişilerin uğrayacağı her türlü zarar ve ziyanı derhal
              tazmin etmeyi ve üyeliğinin tek taraflı olarak feshedebileceğini
              kabul ve taahhüt eder.
            </p>
            <p>
              İHTİLAF İşbu sözleşme kanunlar ihtilâfı kurallarına bakılmaksızın
              Türkiye Cumhuriyeti kanunlarına tâbi olup, bu kanunlara göre
              çözümlenecek ve İstanbul Mahkemeleri görev ve yer olarak yetkili
              olacaktır.
            </p>
            <p>
              TEBLİGAT ADRESLERİ Üyenin bildirdiği adres, bu sözleşme ile ilgili
              olarak yapılacak her türlü bildirim için yasal adres kabul edilir.
              Taraflar, mevcut adreslerindeki değişiklikleri yazılı olarak diğer
              tarafa 3 (üç) gün içinde bildirmedikçe, eski adreslere yapılacak
              bildirimlerin geçerli olacağını ve kendilerine yapılmış
              sayılacağını kabul ederler. İşbu sözleşme nedeniyle
              dogrukaynak.com’un üyenin kayıtlı e-posta adresini kullanarak
              yapacağı her türlü bildirim aynen geçerli olup, e-postanın
              yollanmasından 1 (bir) gün sonra üyeye ulaştığı kabul edilecektir.
            </p>
            <p>
              YÜRÜRLÜK Üye, bu üyelik sözleşmesinde yer alan maddelerin tümünü
              okuduğunu, anladığını, kabul ettiğini ve kendisiyle ilgili olarak
              verdiği bilgilerin doğruluğunu onayladığını beyan, kabul ve
              taahhüt eder. İşbu üyelik sözleşmesi üyenin üye olması anında
              akdedilmiş ve karşılıklı ve süresiz olarak yürürlüğe girmiştir.
              Sözleşme üyeliğin sona ermesi ile veya işbu sözleşmede sayılan
              fesih hallerinden herhangi birinin gerçekleşmesi ile hiçbir ihtara
              gerek kalmaksızın kendiliğinden hükümsüz kalacaktır.
            </p>
          </div>
        </div>
      </>
    );
  }
}

MembershipAgreement.propTypes = {};

export default MembershipAgreement;
