import React, { Component } from 'react';
import { connect } from 'react-redux';
import _remove from 'lodash/remove';
import { Link } from 'react-router-dom';
import 'react-popper-tooltip/dist/styles.css';
import { Breadcrumb } from '../../components';
import * as actions from '../../routes/actions/Content';
import Pagination from '../../components/Pagination/Pagination';
import { footerIcons } from '../../services/constants';
import {
  formatDate as dateFormatter,
  setPrerenderTimeout,
} from '../../helpers';

const breadcrumbs = [
  {
    title: 'Anasayfa',
    url: '/',
  },
  {
    title: 'Blog Yazıları',
    active: true,
  },
];

class List extends Component {
  state = {
    mode: 'live',
    show: false,
    showGallery: false,
    formData: {},
    itemType: '',
    index: -1,
    modified: false,
    schema: '',
    uiSchema: '',

    isTest: false,

    search: [],
    pageOfItems: [],
  };
  onChangePage = (pageOfItems) => {
    this.setState({ pageOfItems: pageOfItems });
  };
  componentDidMount() {
    // this.props.getMainPage("anasayfa", 1);

    if (!this.props.pages.length) {
      this.props.getPages();
    }
  }
  componentDidUpdate() {
    setPrerenderTimeout();
  }

  formatDate(date) {
    return dateFormatter.dateLong(new Date(date));
  }

  getBlogData = (data, id) => {
    if (id === undefined) return data;
    return data.filter(
      (item) => item.blogOptions.category.toLowerCase() === id,
    );
  };

  render() {
    const { pages } = this.props;
    const data = pages.filter(
      (item) =>
        item.isBlog === true && item.active === 1 && item.published === 1,
    );
    const { id } = this.props.match.params;
    const blogs = this.getBlogData(data, id);

    let sideMenu = blogs
      .sort((a, b) => (a.views < b.views ? 1 : -1))
      .slice(0, 6);

    let blogSearch;
    if (this.state.search.length) {
      const searchPattern = new RegExp(
        this.state.search.map((term) => `(?=.*${term})`).join(''),
        'i',
      );
      blogSearch = blogs.filter(
        (item) =>
          (item.blogOptions.title &&
            item.blogOptions.title.match(searchPattern)) ||
          (item.blogOptions.category &&
            item.blogOptions.category.match(searchPattern)),
      );
    } else {
      blogSearch = blogs;
    }

    return (
      <>
        <div className="row">
          <div className="row mb-4">
            <div className="col-12 brand-list">
              <Breadcrumb items={breadcrumbs} />
            </div>
            <div className="col-12">
              <h1>Blog Yazıları</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-8">
            <div className="row">
              {blogSearch
                .filter((item) => item.blogOptions.isFeatured)
                .map((item, i) => (
                  <>
                    {i === 0 ? (
                      <>
                        {/* ilk blog */}
                        <div className="card mb-4" key={i}>
                          <img
                            className="card-img-top"
                            src={item.blogOptions.coverImage}
                            alt="Card image cap"
                          />

                          <div className="card-body">
                            <h2 className="card-title">
                              {' '}
                              {item.blogOptions.title}
                            </h2>
                            <p className="card-text">
                              {item.name.length < 200 ? (
                                <>{item.blogOptions.metaDescription}</>
                              ) : (
                                <>
                                  {item.blogOptions.metaDescription.substr(
                                    0,
                                    200,
                                  )}
                                </>
                              )}
                            </p>
                            <Link
                              to={`/sayfa/${item.slug}/${item.id}`}
                              className="btn btn-primary"
                            >
                              Devamı →
                            </Link>
                          </div>
                          <div className="card-footer text-muted">
                            <a href="#"> {item.blogOptions.category}</a>{' '}
                            {this.formatDate(item.createDate)}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ))}

              {this.state.pageOfItems
                .filter((item) => !item.blogOptions.isFeatured)
                .map((item, i) => (
                  <div className="col-md-6" key={i}>
                    <div className="card mb-4">
                      <img
                        className="card-img-top"
                        src={item.blogOptions.coverImage}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <h5 className="card-title">{item.blogOptions.title}</h5>
                        <p className="card-text">
                          {item.name.length < 200 ? (
                            <>{item.blogOptions.metaDescription} ...</>
                          ) : (
                            <>
                              {item.blogOptions.metaDescription.substr(0, 200) +
                                '...'}
                            </>
                          )}
                        </p>
                        <Link
                          to={`/sayfa/${item.slug}/${item.id}`}
                          className="btn btn-outline-dark btn-sm"
                        >
                          Devamı
                        </Link>
                      </div>
                      <div className="card-footer text-muted">
                        <a href="#"> {item.blogOptions.category}</a>{' '}
                        {this.formatDate(item.createDate)}
                      </div>
                    </div>
                  </div>
                ))}
              <Pagination
                items={blogSearch}
                storesCount={blogSearch.length}
                onChangePage={this.onChangePage}
                key={blogSearch.length}
              />
            </div>
          </div>

          <div className="col-12 col-xl-4">
            <div className="card">
              <div className="card-body">
                <div className="input-group">
                  <h5 style={{ padding: 4 }}>Takip Edin: </h5> {'   '}
                  {footerIcons &&
                    footerIcons.map((footerIcon, i) => {
                      return (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn-link text-left color-484848 fs-14"
                          href={footerIcon.link && footerIcon.link}
                          key={i}
                        >
                          <img
                            style={{ width: '30px' }}
                            src={footerIcon.icon}
                            alt={footerIcon.name}
                          />
                        </a>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="card my-4">
              <div className="card-body">
                <img
                  className="card-img-top"
                  src="https://2.bp.blogspot.com/-vvG5hMTFOro/W6RaoxdAikI/AAAAAAAAK1k/jezYdP7fvfYvt15Jv8a0agrGQE2lMU8YgCKgBGAs/s1600/MASAI-2.jpg"
                  alt="Card image cap"
                />
              </div>
            </div>

            <div className="card my-4">
              <h5 className="card-header">Arama</h5>
              <div className="card-body">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      this.setState({ search: e.target.value.split(' ') })
                    }
                    placeholder="Arama terimi yazınız..."
                  />
                  {/* <span className="input-group-btn">
                    <button className="btn btn-secondary" type="button">
                      Ara!
                    </button>
                  </span> */}
                </div>

                {/* <ul>
                  {blogs.map((item, i) => (
                    <li key={item + i}>{item.name}</li>
                  ))}
                </ul> */}
              </div>
            </div>

            <div className="card my-4">
              <h5 className="card-header">EN ÇOK OKUNANLAR</h5>
              {sideMenu.map((item, i) => (
                <div className="well" key={i}>
                  <div className="media" key={i}>
                    {' '}
                    <div className="media-left" style={{ padding: 7 }}>
                      {' '}
                      <a href="#">
                        {' '}
                        <img
                          className="media-object"
                          alt="64x64"
                          style={{ width: '64px', height: '40px' }}
                          src="https://3.bp.blogspot.com/--C1wpaf_S4M/W7V__10nRoI/AAAAAAAAK24/1NSfapuYSIY0f0wzXY9NgoH0FjQLT07YACKgBGAs/s1600/maxresdefault.jpg"
                          data-holder-rendered="true"
                        />{' '}
                      </a>{' '}
                    </div>{' '}
                    <Link
                      to={`/sayfa/${item.slug}/${item.id}`}
                      style={{ padding: 10 }}
                    >
                      {item.blogOptions.title}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ AccountReducer, ContentReducer }) => ({
  user: AccountReducer.user,
  mainPage: ContentReducer.mainPage,
  pages: ContentReducer.pages,
});

const mapDispatchToProps = {
  getMainPage: actions.getMainPage,

  getPages: actions.getPages,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
