import React, { Component } from 'react';
//import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';
import { customStyles } from '../../helpers';
import * as actions from '../../routes/actions/Product';
import { customFilterOption } from './Utility';

const options = [{ value: '', label: 'Seçiniz...' }];

class BlogCategory extends Component {
  render() {
    const { name, onChange, valid, value } = this.props;

    const items = [
      {
        value: 'Dersler',
        label: 'Dersler',
      },
      {
        value: 'Sınavlar',
        label: 'Sınavlar',
      },
      {
        value: 'Kitaplar',
        label: 'Kitaplar',
      },
      {
        value: 'Rehberlik',
        label: 'Rehberlik',
      },
    ];
    const selected = items.find((p) => p.value === value);
    return (
      <Select
        id={name}
        isSearchable={false}
        placeholder="Seçiniz..."
        options={items}
        className={classNames(valid === false ? 'is-invalid' : 'valid')}
        styles={customStyles}
        onChange={onChange}
        value={selected}
        filterOption={customFilterOption}
      />
    );
  }
}

BlogCategory.propTypes = {};

const mapStateToProps = ({ ProductReducer }) => ({
  taxonomies: ProductReducer.taxonomies,
});

const mapDispatchToProps = {
  getTaxonomies: actions.getTaxonomies,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogCategory);
