const FormSchemas = {
  account: {
    schema: {
      title: 'Hesap Bilgilerim',
      type: 'object',
      required: ['firstname', 'lastname', 'email', 'birthdate', 'classId'],
      properties: {
        email: {
          title: 'E-Posta Adresi',
          type: 'string',
          format: 'email',
        },
        firstname: {
          title: 'Ad',
          type: 'string',
          minLength: 1,
        },
        lastname: {
          title: 'Soyad',
          type: 'string',
          minLength: 1,
        },
        classId: {
          title: 'Sınıf',
          type: 'integer',
        },
        city: {
          title: 'İl',
          type: 'object',
        },
        town: {
          title: 'İlçe',
          type: 'object',
        },
        mobile: {
          title: 'Cep Telefonu',
          type: 'string',
        },
        gender: {
          title: 'Cinsiyet',
          type: 'string',
        },
        birthdate: {
          title: 'Doğum Tarihiniz',
          type: 'string',
          format: 'customdate',
        },
        tckn: {
          title: 'TC Kimlik No',
          type: 'string',
        },
        // schoolId: {
        //   title: "Okul",
        //   type: "integer",
        // },
      },
    },
    uiSchema: {
      'ui:options': {
        horizontal: true,
        hideTitle: true,
      },
      firstname: {
        classNames: 'col-md-6',
      },
      lastname: {
        classNames: 'col-md-6',
      },
      tckn: {
        'ui:widget': 'mask',
        classNames: 'col-md-6',
        'ui:options': {
          mask: '99999999999',
          maskChar: null,
        },
      },
      mobile: {
        'ui:field': 'phone',
        classNames: 'col-md-6',
        'ui:options': {
          mask: '0a99 999 99 99',
          maskChar: '_',
          formatChars: {
            9: '[0-9]',
            a: '[1-9]',
          },
        },
      },
      gender: {
        'ui:widget': 'gender',
        classNames: 'col-md-6',
      },
      city: {
        'ui:widget': 'city',
        classNames: 'col-md-6',
        'ui:options': {
          setter: {
            town: undefined,
            schoolId: undefined,
          },
          isCity: true,
        },
      },
      town: {
        'ui:widget': 'town',
        classNames: 'col-md-6',
        'ui:options': {
          dependencies: ['city'],
          enableAddRow: false,
        },
      },
      birthdate: {
        'ui:widget': 'date',
        classNames: 'col-md-6',
      },
      email: {
        'ui:field': 'email',
        'ui:readonly': true,
        classNames: 'col-md-6',
      },
      // schoolId: {
      //   "ui:field": "school",
      //   classNames: "col-md-6",
      //   "ui:placeholder": "Okul Seçiniz",
      //   "ui:options": {
      //     dependencies: ["city"],
      //   },
      // },
      classId: {
        'ui:widget': 'classroom',
        classNames: 'col-md-6',
        'ui:placeholder': 'Sınıf Seçiniz',
        selectDefault: true,
      },
    },
  },
  demoAccount: {
    schema: {
      title: 'Hesap Bilgilerim',
      type: 'object',
      required: ['firstname', 'lastname', 'email', 'birthdate', 'classId'],
      properties: {
        firstname: {
          title: 'Ad',
          type: 'string',
          minLength: 1,
        },
        lastname: {
          title: 'Soyad',
          type: 'string',
          minLength: 1,
        },
        email: {
          title: 'E-Posta Adresi',
          type: 'string',
          format: 'email',
        },
        mobile: {
          title: 'Cep Telefonu',
          type: 'string',
        },
        tckn: {
          title: 'TC Kimlik No',
          type: 'string',
        },
        birthdate: {
          title: 'Doğum Tarihiniz',
          type: 'string',
          format: 'customdate',
        },
        city: {
          title: 'İl',
          type: 'object',
        },
        town: {
          title: 'İlçe',
          type: 'object',
        },
        gender: {
          title: 'Cinsiyet',
          type: 'string',
        },
        schoolId: {
          title: 'Okul',
          type: 'integer',
        },
        classId: {
          title: 'Sınıf',
          type: 'integer',
        },
      },
    },
    uiSchema: {
      'ui:options': {
        horizontal: true,
        hideTitle: true,
      },
      firstname: {
        classNames: 'col-md-6',
        'ui:readonly': true,
      },
      lastname: {
        classNames: 'col-md-6',
        'ui:readonly': true,
      },
      tckn: {
        'ui:widget': 'mask',
        classNames: 'col-md-6',
        'ui:options': {
          mask: '99999999999',
          maskChar: null,
        },
        'ui:readonly': true,
      },
      mobile: {
        'ui:field': 'phone',
        classNames: 'col-md-6',
        'ui:options': {
          mask: '0a99 999 99 99',
          maskChar: '_',
          formatChars: {
            9: '[0-9]',
            a: '[1-9]',
          },
        },
        'ui:readonly': true,
      },
      gender: {
        'ui:widget': 'gender',
        classNames: 'col-md-6',
        'ui:readonly': true,
      },
      city: {
        'ui:widget': 'city',
        classNames: 'col-md-6',
        'ui:options': {
          setter: {
            town: undefined,
            schoolId: undefined,
          },
        },
        'ui:readonly': true,
      },
      town: {
        'ui:widget': 'town',
        classNames: 'col-md-6',
        'ui:options': {
          dependencies: ['city'],
        },
        'ui:readonly': true,
      },
      birthdate: {
        'ui:widget': 'date',
        classNames: 'col-md-6',
        'ui:readonly': true,
      },
      email: {
        'ui:field': 'email',
        'ui:readonly': true,
        classNames: 'col-md-6',
      },
      schoolId: {
        'ui:field': 'school',
        classNames: 'col-md-6',
        'ui:placeholder': 'Okul Seçiniz',
        'ui:options': {
          dependencies: ['city'],
        },
        'ui:readonly': true,
      },
      classId: {
        'ui:widget': 'classroom',
        classNames: 'col-md-6',
        'ui:placeholder': 'Sınıf Seçiniz',
        'ui:readonly': true,
      },
    },
  },
  avatar: {
    schema: {
      title: 'Avatar',
      type: 'object',
      required: ['birthdate', 'classId'],
      properties: {
        birthdate: {
          title: 'Doğum Tarihiniz',
          type: 'string',
          format: 'customdate',
        },
        classId: {
          title: 'Sınıf',
          type: 'integer',
        },
      },
    },
    uiSchema: {
      'ui:options': {
        horizontal: true,
        hideTitle: true,
      },
      birthdate: {
        'ui:widget': 'date',
        classNames: 'col-md-6',
      },
      classId: {
        'ui:widget': 'classroom',
        classNames: 'col-md-6',
        'ui:placeholder': 'Sınıf Seçiniz',
      },
    },
  },
  address: {
    schema: {
      type: 'object',
      required: [
        'firstname',
        'lastname',
        'mobile',
        'city',
        'town',
        'lines',
        'title',
      ],
      properties: {
        firstname: {
          title: 'Ad',
          type: 'string',
        },
        lastname: {
          title: 'Soyad',
          type: 'string',
        },
        mobile: {
          title: 'Cep Telefonu',
          type: 'string',
        },
        city: {
          title: 'İl',
          type: 'object',
        },
        town: {
          title: 'İlçe',
          type: 'object',
        },
        lines: {
          title: 'Adres',
          type: 'string',
          format: 'address-area',
        },
        title: {
          title: 'Adres Başlığı',
          type: 'string',
          maxLength: 50,
        },
      },
    },

    uiSchema: {
      firstname: {
        classNames: 'col-sm-12',
      },
      lastname: {
        classNames: 'col-sm-12',
      },
      mobile: {
        'ui:widget': 'mask',
        classNames: 'col-sm-12',
        'ui:options': {
          mask: '0a99 999 99 99',
          maskChar: '_',
          formatChars: {
            9: '[0-9]',
            a: '[1-9]',
          },
          minLength: 14,
        },
      },
      city: {
        'ui:widget': 'city',
        classNames: 'inputSmall inputSmallSelect inputSelectCity',
        'ui:options': {
          setter: {
            town: undefined,
          },
        },
      },
      town: {
        'ui:widget': 'town',
        classNames: 'inputSmall inputSmallSelect inputSelectCity',
        'ui:options': {
          dependencies: ['city'],
          enableAddRow: false,
        },
      },
      lines: {
        'ui:widget': 'textarea',
        'ui:options': {
          rows: 3,
          maxLength: 250,
        },
        classNames: 'col-sm-12',
      },
      title: {
        classNames: 'col-sm-12',
      },
    },
  },
  demoAddress: {
    schema: {
      type: 'object',
      required: [
        'firstname',
        'lastname',
        'mobile',
        'city',
        'town',
        'lines',
        'title',
      ],
      properties: {
        firstname: {
          title: 'Ad',
          type: 'string',
        },
        lastname: {
          title: 'Soyad',
          type: 'string',
        },
        mobile: {
          title: 'Cep Telefonu',
          type: 'string',
        },
        city: {
          title: 'İl',
          type: 'object',
        },
        town: {
          title: 'İlçe',
          type: 'object',
        },
        lines: {
          title: 'Adres',
          type: 'string',
          format: 'address-area',
        },
        title: {
          title: 'Adres Başlığı',
          type: 'string',
          maxLength: 50,
        },
      },
    },

    uiSchema: {
      firstname: {
        classNames: 'col-sm-12',
        'ui:readonly': true,
      },
      lastname: {
        classNames: 'col-sm-12',
        'ui:readonly': true,
      },
      mobile: {
        'ui:widget': 'mask',
        classNames: 'col-sm-12',
        'ui:options': {
          mask: '0a99 999 99 99',
          maskChar: '_',
          formatChars: {
            9: '[0-9]',
            a: '[1-9]',
          },
          minLength: 14,
        },
        'ui:readonly': true,
      },
      city: {
        'ui:widget': 'city',
        classNames: 'inputSmall inputSmallSelect inputSelectCity',
        'ui:options': {
          setter: {
            town: undefined,
          },
        },
        'ui:readonly': true,
      },
      town: {
        'ui:widget': 'town',
        classNames: 'inputSmall inputSmallSelect inputSelectCity',
        'ui:options': {
          dependencies: ['city'],
        },
        'ui:readonly': true,
      },
      lines: {
        'ui:widget': 'textarea',
        'ui:options': {
          rows: 3,
        },
        classNames: 'col-sm-12',
        'ui:readonly': true,
      },
      title: {
        classNames: 'col-sm-12',
        'ui:readonly': true,
      },
    },
  },
  businessAddress: {
    schema: {
      type: 'object',
      required: [
        'firstname',
        'taxNumber',
        'taxOffice',
        'mobile',
        'city',
        'town',
        'lines',
        'title',
      ],
      properties: {
        firstname: {
          title: 'Fatura Adı',
          type: 'string',
        },
        taxNumber: {
          title: 'Vergi Numarası',
          type: 'string',
          maxLength: 10,
        },
        taxOffice: {
          title: 'Vergi Dairesi',
          type: 'string',
        },
        mobile: {
          title: 'Cep Telefonu',
          type: 'string',
        },
        city: {
          title: 'İl',
          type: 'object',
        },
        town: {
          title: 'İlçe',
          type: 'object',
        },
        lines: {
          title: 'Adres',
          type: 'string',
          format: 'address-area',
        },
        title: {
          title: 'Adres Başlığı',
          type: 'string',
          maxLength: 50,
        },
      },
    },

    uiSchema: {
      firstname: {
        classNames: 'col-sm-12',
      },
      taxNumber: {
        classNames: 'col-sm-12',
      },
      taxOffice: {
        classNames: 'col-sm-12',
      },
      mobile: {
        'ui:widget': 'mask',
        classNames: 'col-sm-12',
        'ui:options': {
          mask: '0a99 999 99 99',
          maskChar: '_',
          formatChars: {
            9: '[0-9]',
            a: '[1-9]',
          },
          minLength: 14,
        },
      },
      city: {
        'ui:widget': 'city',
        classNames: 'inputSmall inputSmallSelect inputSelectCity',
        'ui:options': {
          setter: {
            town: undefined,
          },
        },
      },
      town: {
        'ui:widget': 'town',
        classNames: 'inputSmall inputSmallSelect inputSelectCity',
        'ui:options': {
          dependencies: ['city'],
          enableAddRow: false,
        },
      },
      lines: {
        'ui:widget': 'textarea',
        'ui:options': {
          rows: 3,
        },
        classNames: 'col-sm-12',
      },
      title: {
        classNames: 'col-sm-12',
      },
    },
  },
  demoBusinessAddress: {
    schema: {
      type: 'object',
      required: [
        'firstname',
        'taxNumber',
        'taxOffice',
        'mobile',
        'city',
        'town',
        'lines',
        'title',
      ],
      properties: {
        firstname: {
          title: 'Fatura Adı',
          type: 'string',
        },
        taxNumber: {
          title: 'Vergi Numarası',
          type: 'string',
          maxLength: 10,
        },
        taxOffice: {
          title: 'Vergi Dairesi',
          type: 'string',
        },
        mobile: {
          title: 'Cep Telefonu',
          type: 'string',
        },
        city: {
          title: 'İl',
          type: 'object',
        },
        town: {
          title: 'İlçe',
          type: 'object',
        },
        lines: {
          title: 'Adres',
          type: 'string',
          format: 'address-area',
        },
        title: {
          title: 'Adres Başlığı',
          type: 'string',
          maxLength: 50,
        },
      },
    },

    uiSchema: {
      firstname: {
        classNames: 'col-sm-12',
        'ui:readonly': true,
      },
      taxNumber: {
        classNames: 'col-sm-12',
        'ui:readonly': true,
      },
      taxOffice: {
        classNames: 'col-sm-12',
        'ui:readonly': true,
      },
      mobile: {
        'ui:widget': 'mask',
        classNames: 'col-sm-12',
        'ui:options': {
          mask: '0a99 999 99 99',
          maskChar: '_',
          formatChars: {
            9: '[0-9]',
            a: '[1-9]',
          },
          minLength: 14,
        },
        'ui:readonly': true,
      },
      city: {
        'ui:widget': 'city',
        classNames: 'inputSmall inputSmallSelect inputSelectCity',
        'ui:options': {
          setter: {
            town: undefined,
          },
        },
        'ui:readonly': true,
      },
      town: {
        'ui:widget': 'town',
        classNames: 'inputSmall inputSmallSelect inputSelectCity',
        'ui:options': {
          dependencies: ['city'],
        },
        'ui:readonly': true,
      },
      lines: {
        'ui:widget': 'textarea',
        'ui:options': {
          rows: 3,
        },
        classNames: 'col-sm-12',
        'ui:readonly': true,
      },
      title: {
        classNames: 'col-sm-12',
        'ui:readonly': true,
      },
    },
  },
  changePassword: {
    schema: {
      type: 'object',
      title: 'Şifre Değiştir',
      required: ['oldPassword', 'newPassword', 'confirmPassword'],
      properties: {
        oldPassword: {
          title: 'Mevcut Şifre',
          type: 'string',
        },
        newPassword: {
          title: 'Yeni Şifre',
          type: 'string',
          minLength: 8,
          passwordPolicy: true,
        },
        confirmPassword: {
          title: 'Yeni Şifre Tekrar',
          type: 'string',
          minLength: 8,
          compare: true,
        },
      },
    },
    uiSchema: {
      'ui:options': {
        horizontal: true,
      },
      oldPassword: {
        'ui:widget': 'password',
        'ui:options': { autoComplete: 'off' },
        classNames: 'col-md-6',
      },
      newPassword: {
        'ui:widget': 'password',
        'ui:options': { autoComplete: 'off' },
        classNames: 'col-md-6',
      },
      confirmPassword: {
        'ui:widget': 'password',
        'ui:options': { autoComplete: 'off' },
        classNames: 'col-md-6',
      },
    },
  },
  demoChangePassword: {
    schema: {
      type: 'object',
      title: 'Şifre Değiştir',
      required: ['oldPassword', 'newPassword', 'confirmPassword'],
      properties: {
        oldPassword: {
          title: 'Mevcut Şifre',
          type: 'string',
        },
        newPassword: {
          title: 'Yeni Şifre',
          type: 'string',
          minLength: 8,
          passwordPolicy: true,
        },
        confirmPassword: {
          title: 'Yeni Şifre Tekrar',
          type: 'string',
          minLength: 8,
          compare: true,
        },
      },
    },
    uiSchema: {
      'ui:options': {
        horizontal: true,
      },
      oldPassword: {
        // "ui:widget": "password",
        'ui:options': { autoComplete: 'off' },
        'ui:readonly': true,
        classNames: 'col-md-6',
      },
      newPassword: {
        'ui:widget': 'password',
        'ui:options': { autoComplete: 'off' },
        'ui:readonly': true,
        classNames: 'col-md-6',
      },
      confirmPassword: {
        'ui:widget': 'password',
        'ui:options': { autoComplete: 'off' },
        'ui:readonly': true,
        classNames: 'col-md-6',
      },
    },
  },
  resetPassword: {
    schema: {
      type: 'object',
      title: 'Şifre Değiştir',
      required: ['newPassword', 'confirmPassword'],
      properties: {
        newPassword: {
          title: 'Şifre',
          type: 'string',
          minLength: 8,
          passwordPolicy: true,
        },
        confirmPassword: {
          title: 'Şifre Tekrar',
          type: 'string',
          minLength: 8,
          compare: true,
        },
      },
    },
    uiSchema: {
      'ui:options': {
        horizontal: true,
      },
      oldPassword: {
        'ui:widget': 'password',
        'ui:options': { autoComplete: 'off' },
        classNames: 'col-md-6',
      },
      newPassword: {
        'ui:widget': 'password',
        'ui:options': { autoComplete: 'off' },
        classNames: 'col-md-6',
      },
      confirmPassword: {
        'ui:widget': 'password',
        'ui:options': { autoComplete: 'off' },
        classNames: 'col-md-6',
      },
    },
  },
  login: {
    schema: {
      type: 'object',
      properties: {
        username: {
          title: 'E-posta adresi',
          type: 'string',
          format: 'email',
        },
        password: {
          title: 'Şifre',
          type: 'string',
        },
        // remember: {
        //   title: "Daha sonra beni hatırla",
        //   type: "boolean",
        // },
      },
      required: ['username', 'password'],
    },
    uiSchema: {
      email: {
        classNames: '',
      },
      password: {
        'ui:widget': 'password',
        classNames: '',
      },
      // remember: {
      //   "ui:field": "checkbox",
      //   classNames: "",
      // },
    },
  },
  register: {
    schema: {
      type: 'object',
      required: ['email', 'password', 'classId'],
      properties: {
        email: {
          title: 'E-posta adresi',
          type: 'string',
          format: 'email',
        },
        password: {
          title: 'Şifre',
          type: 'string',
          minLength: 8,
          passwordPolicy: true,
        },
        classId: {
          title: 'Sınıf',
          type: 'integer',
        },
      },
    },
    uiSchema: {
      email: {
        classNames: 'col-md-12',
      },
      password: {
        'ui:widget': 'password',
        classNames: 'col-md-12',
        'ui:options': { autoComplete: 'off' },
      },
      classId: {
        'ui:widget': 'classroom',
        classNames: 'col-md-12',
        'ui:placeholder': 'Sınıf Seçiniz',
        selectDefault: true,
      },
    },
  },
  updateClass: {
    schema: {
      type: 'object',
      required: ['classId'],
      properties: {
        classId: {
          title: 'Sınıf',
          type: 'integer',
          minimum: 0,
          maximum: 12,
        },
      },
    },
    uiSchema: {
      classId: {
        'ui:widget': 'classroom',
        classNames: 'col-md-12',
        'ui:placeholder': 'Sınıfını Seç',
        'ui:additionalProps': {
          defaultItems: [
            {
              value: -1,
              label: 'Sınıfını Seç',
            },
            {
              value: 0,
              label: 'Sınıf Güncellemek İstemiyorum',
            },
          ],
          selectDefault: false,
        },
      },
    },
  },
  forgotPassword: {
    schema: {
      type: 'object',
      properties: {
        email: {
          title: 'E-posta adresi',
          type: 'string',
          format: 'email',
        },
      },
      required: ['email'],
    },
    uiSchema: {
      email: {
        classNames: 'col-md-12',
      },
    },
  },
  verification: {
    schema: {
      type: 'object',
      properties: {
        code: {
          title: 'Doğrulama Kodunuz',
          type: 'string',
        },
      },
    },
    uiSchema: {
      code: {
        classNames: 'col-md-12',
      },
    },
  },
  emailVerification: {
    schema: {
      type: 'object',
      properties: {
        code: {
          title: 'Kodu Gir',
          type: 'string',
        },
      },
    },
    uiSchema: {
      code: {
        classNames: 'col-md-12',
      },
    },
  },
  highlight: {
    schema: {
      type: 'object',
      properties: {
        title: {
          title: 'Başlık',
          type: 'string',
        },
        text: {
          title: 'Metin',
          type: 'object',
        },
        src: {
          title: 'Resim(492x320)',
          type: 'string',
        },
        label: {
          title: 'Etiket',
          type: 'string',
        },
        url: {
          title: 'Bağlantı',
          type: 'string',
          format: 'url',
        },
        orientation: {
          title: 'Görsel Pozisyonu',
          type: 'string',
        },
      },
    },
    uiSchema: {
      text: {
        'ui:widget': 'editor',
      },
      src: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
        },
      },
      orientation: {
        'ui:widget': 'imageOrientation',
        classNames: 'col-md-6',
        'ui:placeholder': 'Seçiniz',
      },
    },
  },
  textView: {
    schema: {
      type: 'object',
      properties: {
        text: {
          title: 'Metin',
          type: 'object',
        },
      },
    },
    uiSchema: {
      text: {
        'ui:widget': 'editor',
      },
    },
  },
  playerView: {
    schema: {
      type: 'object',
      properties: {
        url: {
          title: 'Video',
          type: 'string',
        },
      },
    },
    uiSchema: {
      url: {
        'ui:widget': 'url',
      },
    },
  },
  imageView: {
    schema: {
      type: 'object',
      properties: {
        url: {
          title: 'Resim',
          type: 'string',
        },
      },
    },
    uiSchema: {
      url: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
        },
      },
    },
  },
  linkItem_735: {
    schema: {
      type: 'object',
      properties: {
        src: {
          title: 'Resim(735x250)',
          type: 'string',
        },
        target: {
          title: 'Bağlantı',
          type: 'string',
        },
      },
    },
    uiSchema: {
      src: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          width: 735,
          height: 250,
        },
      },
      target: {
        'ui:widget': 'url',
      },
    },
  },
  linkItem_359: {
    schema: {
      type: 'object',
      properties: {
        src: {
          title: 'Resim(359x250 ya da 359x516)',
          type: 'string',
        },
        target: {
          title: 'Bağlantı',
          type: 'string',
        },
      },
    },
    uiSchema: {
      src: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          width: 359,
          height: [250, 516],
        },
      },
      target: {
        'ui:widget': 'url',
      },
    },
  },
  slider: {
    schema: {
      type: 'object',
      required: ['thumbnail', 'mobile', 'web'],
      properties: {
        thumbnail: {
          title: 'Küçük Resim (70x43)',
          type: 'string',
        },
        mobile: {
          title: 'Mobil (520x370)',
          type: 'string',
        },
        web: {
          title: 'Web (1200x280)',
          type: 'string',
        },
        target: {
          title: 'Bağlantı',
          type: 'string',
        },
      },
    },
    uiSchema: {
      thumbnail: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          imgPreview: true,
          width: 70,
          height: 43,
        },
      },
      mobile: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          imgPreview: true,
          width: 520,
          height: 370,
        },
      },
      web: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          imgPreview: true,
          width: 1200,
          height: 280,
        },
      },
      target: {
        'ui:widget': 'url',
      },
    },
  },
  mainBanner: {
    schema: {
      type: 'object',
      required: ['mobile', 'web'],
      properties: {
        mobile: {
          title: 'Mobil (520x64)',
          type: 'string',
        },
        web: {
          title: 'Web (1200x133)',
          type: 'string',
        },
        target: {
          title: 'Bağlantı',
          type: 'string',
        },
      },
    },
    uiSchema: {
      mobile: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          width: 520,
          height: 64,
        },
      },
      web: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          width: 1200,
          height: 133,
        },
      },
      target: {
        'ui:widget': 'url',
      },
    },
  },
  mainBannerFooter: {
    schema: {
      type: 'object',
      required: ['mobile', 'web'],
      properties: {
        mobile: {
          title: 'Mobil (300x150)',
          type: 'string',
        },
        web: {
          title: 'Web (1200x133)',
          type: 'string',
        },
        target: {
          title: 'Bağlantı',
          type: 'string',
        },
      },
    },
    uiSchema: {
      mobile: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          width: 300,
          height: 150,
        },
      },
      web: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          width: 1200,
          height: 133,
        },
      },
      target: {
        'ui:widget': 'url',
      },
    },
  },
  headBanner: {
    schema: {
      type: 'object',
      required: ['mobile', 'web'],
      properties: {
        mobile: {
          title: 'Mobil (520x64)',
          type: 'string',
        },
        web: {
          title: 'Web (1440x76)',
          type: 'string',
        },
        target: {
          title: 'Bağlantı',
          type: 'string',
        },
      },
    },
    uiSchema: {
      mobile: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          width: 520,
          height: 64,
        },
      },
      web: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          width: 1440,
          height: 76,
        },
      },
      target: {
        'ui:widget': 'url',
      },
    },
  },
  cardBanner: {
    schema: {
      type: 'object',
      required: ['title', 'image'],
      properties: {
        title: {
          title: 'Başlık',
          type: 'string',
        },
        image: {
          title: 'Image (330x312)',
          type: 'string',
        },
        target: {
          title: 'Bağlantı',
          type: 'string',
        },
      },
    },
    uiSchema: {
      title: {
        'ui:widget': 'text',
      },
      image: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          width: 330,
          height: 312,
        },
      },
      target: {
        'ui:widget': 'url',
      },
    },
  },
  sliderImage: {
    schema: {
      type: 'object',
      required: ['image'],
      properties: {
        image: {
          title: 'image (204x327)',
          type: 'string',
        },
      },
    },
    uiSchema: {
      image: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          width: 204,
          height: 327,
        },
      },
    },
  },
  brandLinkItem: {
    schema: {
      type: 'object',
      properties: {
        src: {
          title: 'Resim(855x305)',
          type: 'string',
        },
        target: {
          title: 'Bağlantı',
          type: 'string',
        },
      },
    },
    uiSchema: {
      src: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          width: 855,
          height: 305,
        },
      },
      target: {
        'ui:widget': 'url',
      },
    },
  },
  crediCard: {
    schema: {
      type: 'object',
      properties: {
        creditCardNumber: {
          title: 'Kart Numarası',
          type: 'string',
        },
        expireDate: {
          title: 'Son Kullanma Tarihi',
          type: 'string',
        },
        CVC: {
          title: 'CVV',
          type: 'string',
          tooltip: true,
        },
      },
    },
    uiSchema: {
      creditCardNumber: {
        'ui:widget': 'mask',
        'ui:placeholder': '•••• •••• •••• ••••',
        'ui:options': {
          mask: '9999 9999 9999 9999',
          maskChar: null,
        },
      },
      expireDate: {
        'ui:widget': 'expireDate',
        classNames: 'col-8',
      },
      CVC: {
        'ui:widget': 'mask',
        classNames: 'col-4 icon',
        'ui:placeholder': '•••',
        'ui:options': {
          mask: '999',
          maskChar: null,
          enableAddRow: false,
        },
      },
    },
  },
  otp: {
    schema: {
      title: 'SMS Doğrulama',
      type: 'object',
      required: ['validationCode'],
      properties: {
        validationCode: {
          title: 'Doğrulama Kodu',
          type: 'string',
          minLength: 6,
          maxLength: 6,
        },
        referenceNo: {
          title: 'referenceNo',
          type: 'string',
        },
        sendSms: {
          title: 'sendSms',
          type: 'string',
        },
        sendSmsLanguage: {
          title: 'sendSmsLanguage',
          type: 'string',
        },
        pinType: {
          title: 'pinType',
          type: 'string',
        },
      },
    },
    uiSchema: {
      'ui:options': {
        horizontal: true,
        hideTitle: true,
      },
      validationCode: {
        classNames: 'col-md-6',
      },
      referenceNo: {},
      sendSms: {},
      sendSmsLanguage: {},
      pinType: {},
    },
  },
  mpRegistration: {
    schema: {
      title: 'Kart Kayıt',
      type: 'object',
      required: ['cardAliasName'],
      properties: {
        cardAliasName: {
          title: 'Kart Adı',
          type: 'string',
        },
        msisdn: {
          title: 'msisdn',
          type: 'string',
        },
        token: {
          title: 'token',
          type: 'string',
        },
        referenceNo: {
          title: 'referenceNo',
          type: 'string',
        },
        sendSms: {
          title: 'sendSms',
          type: 'string',
        },
        sendSmsLanguage: {
          title: 'sendSmsLanguage',
          type: 'string',
        },
      },
    },
    uiSchema: {
      'ui:options': {
        horizontal: true,
        hideTitle: true,
      },
      cardAliasName: {
        classNames: 'col-md-6',
        'ui:readonly': true,
      },
      msisdn: {},
      token: {},
      referenceNo: {},
      sendSms: {},
      sendSmsLanguage: {},
    },
  },
  blogOptions: {
    schema: {
      type: 'object',
      title: 'Blog Detayları',
      required: ['title', 'category', 'metaDescription', 'coverImage'],
      properties: {
        title: {
          title: 'Blog yazısı başlığı',
          type: 'string',
          minLength: 4,
        },
        metaDescription: {
          title: 'Blog yazısı SEO açıklaması ',
          type: 'string',
          minLength: 4,
        },
        coverImage: {
          title: 'Blog yazısı görseli (1280 x 800)',
          type: 'string',
          minLength: 4,
        },
        category: {
          title: 'Blog yazısı kategorisi',
          type: 'string',
          minLength: 4,
        },
        isFeatured: {
          title: 'Öne Çıkanlarda Göster',
          type: 'boolean',
        },
      },
    },
    uiSchema: {
      'ui:options': {
        horizontal: true,
      },
      coverImage: {
        'ui:widget': 'file',
        'ui:options': {
          accept: 'image/x-png,image/gif,image/jpeg',
          validateSize: true,
          width: 1280,
          height: 800,
        },
      },
      category: {
        'ui:widget': 'blogCategory',
        classNames: 'col-md-6',
        'ui:placeholder': 'Kategori Seçiniz',
      },
      isFeatured: {
        'ui:field': 'checkbox',
        classNames: '',
      },
    },
  },
};

export default FormSchemas;
