import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logos } from '../../variables/General';
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import _pullAt from 'lodash/pullAt';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import CloseButton from '../../components/Button/Close';
import {
  EditableItem,
  EditableToolbar,
  Breadcrumb,
  FormModal,
  Modal,
} from '../../components';
import * as actions from '../../routes/actions/Content';
import RelatedProducts from './RelatedProducts';
import Highlight from './Highlight';
import TextView from './TextView';
import Placeholder from './Placeholder';
import ImageView from './ImageView';
import PlayerView from './PlayerView';
import FormSchemas from '../../variables/FormSchemas';
import EditMenu from './EditMenu';
import { formatDate, setPrerenderTimeout } from '../../helpers';

import { BaseForm } from '../../components';

import { Helmet } from 'react-helmet';
const menuItems = [
  {
    name: 'edit',
    icon: faPencilAlt,
  },
  {
    name: 'remove',
    icon: faTrash,
    className: 'text-danger',
  },
];

const placeholderPage = {
  products: [],
  rows: [{ columns: [{ id: 1, size: 12 }] }],
};

const BrandHeader = ({ show }) =>
  show && (
    <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
      <div className="col-auto d-lg-block ml-2">
        <figure>
          <img src={logos[2]} alt="..." />
        </figure>
      </div>
      <div className="col p-4 d-flex flex-column">
        <h3 className="mb-0">Tonguç Yayınları</h3>
        <p>Buraya markanın kısa açıklaması gelecek...</p>
      </div>
    </div>
  );

const RenderWidget = ({ component, ...props }) => {
  switch (component) {
    case 'BrandHeader':
      return <BrandHeader {...props} />;
    case 'Highlight':
      return <Highlight {...props} />;
    case 'TextView':
      return <TextView {...props} />;
    case 'PlayerView':
      return <PlayerView {...props} />;
    case 'ImageView':
      return <ImageView {...props} />;
    default:
      return <Placeholder {...props} />;
  }
};

class Page extends Component {
  state = {
    mode: 'live',
    show: false,
    row: 0,
    col: 0,
    schema: {},
    uiSchema: {},
    formData: {},
    modified: false,
    showPopup: false,
    showHistoryPopup: false,
    invalid: false,
    sendForApprovaled: false,
    value: '',
    history: [
      {
        Date: '08.11.2019 16:32',
        User: 'Sevilcan Taşçı',
        Operation: 'Onaya Gönderildi',
        Cause: 'Lorem ipsum…',
      },
      {
        Date: '08.11.2019 16:40',
        User: 'Zübeyir Tercan',
        Operation: 'İade Edildi',
        Cause:
          'Lorem Ipsum, dizgi ve baskı endüstrisinde kullanılan mıgır metinlerdir. Lorem Ipsum, adı bilinmeyen bir matbaacının bir hurufat numune',
      },
    ],
  };

  setIsChecked = (isChecked) => {
    const { page } = this.props;
    page.content.isBlog = isChecked;
    this.props.changePage(page);
    this.setState({ modified: true });
  };

  saveBlogDetail = (formatData) => {
    const { page } = this.props;
    page.content.blog = formatData;
    this.props.changePage(page);
    this.setState({ modified: true });
    toast.success('Blog ayarları kaydedildi. Sayfayı kaydetmeyi unutma.');
  };

  async componentDidMount() {
    const { success, error } = await this.props.getByName(
      this.props.match.params.code,
      1,
    );
    if (success === false && error.status === 404) {
      this.props.changePage({});
      this.props.history.push('/sayfa-bulunamadi/404');
    } else {
      await this.getPageHistory();
    }
  }

  componentDidUpdate() {
    setPrerenderTimeout();
  }

  getPageHistory = async () => {
    await this.props.getHistoryByName(this.props.match.params.code);
    const status =
      this.props.pageHistory && this.props.pageHistory.length > 0
        ? this.props.pageHistory[this.props.pageHistory.length - 1].pageStatus
        : -1;
    this.setState({
      pageStatus: status,
    });
  };

  handleHistoryClick = async () => {
    this.getPageHistory();
    this.setState({
      showHistoryPopup: true,
    });
  };

  change = (page) => {
    this.setState({ modified: true });
    this.props.changePage(page);
  };
  handleChange = (event) => {
    this.setState({ value: event.target.value, invalid: !event.target.value });
  };

  handleReturnClick = async () => {
    const { value } = this.state;
    const { match, page } = this.props;
    if (value) {
      const result = await this.props.returnPage(page.id, value);
      if (result.success) {
        toast.success('Sayfa geri gönderildi.');
        this.props.getByName(match.params.code, 1);
        this.setState({ showPopup: false });
      } else {
        toast.success('Hata oluştu');
      }
    } else {
      this.setState({ invalid: true });
    }
  };

  addRow = (count) => {
    const { page } = this.props;
    const tempPage = page.content || {
      products: [],
      rows: [{ columns: [] }],
    };
    const { rows } = tempPage;
    const column = { id: 1, size: count === 1 ? 12 : 6 };
    if (rows) {
      column.id = rows[0].columns.length + 1;
      rows[0].columns.push(column);
      if (count > 1) {
        rows[0].columns.push({ id: column.id + 1, size: 6 });
      }
    } else {
      tempPage.rows = [{ columns: [] }];
      tempPage.rows[0].columns.push(column);
      if (count > 1) {
        tempPage.rows[0].columns.push({ id: column.id + 1, size: 6 });
      }
    }
    page.content = tempPage;
    this.change(page);
  };

  addWidgetClick = (id, name) => {
    const { page } = this.props;
    const tempPage = page.content || placeholderPage;

    const { rows } = tempPage;
    if (rows) {
      rows[0].columns.find((p) => p.id === id).component = name;
    } else {
      tempPage.rows = [{ columns: [{ id: 1, size: 12, component: name }] }];
    }
    page.content = tempPage;
    this.change(page);
  };

  createForm = (item) => {
    switch (item.component) {
      case 'Highlight':
        return FormSchemas.highlight;
      case 'TextView':
        return FormSchemas.textView;
      case 'PlayerView':
        return FormSchemas.playerView;
      case 'ImageView':
        return FormSchemas.imageView;
      default:
        return { schema: null, uiSchema: null };
    }
  };

  handleItemClick = (item, row, col) => {
    const { page } = this.props;
    const { rows } = page.content;
    switch (item.name) {
      case menuItems[0].name:
        const column = rows[row].columns[col];
        const { schema, uiSchema } = this.createForm(column);
        this.setState({
          show: true,
          row,
          col,
          schema,
          uiSchema,
          formData: column.props,
        });
        break;
      case menuItems[1].name:
        _pullAt(rows[row].columns, [col]);
        page.rows = rows;
        this.change(page);
        break;
      default:
        break;
    }
  };

  getPageStatus = (op) => {
    let res = '';
    switch (op) {
      case 0:
        res = 'Yeni';
        break;
      case 1:
        res = 'Güncellendi';
        break;
      case 2:
        res = 'Onaya Gönderildi';
        break;
      case 3:
        res = 'Onaylandı';
        break;
      case 4:
        res = 'İade Edildi';
        break;
      case 5:
        res = 'Yayınlandı';
        break;

      default:
        break;
    }

    return res;
  };

  addOrRemoveProduct = async (id) => {
    let page = this.props.page;

    let { content } = page;
    if (!content.products) {
      content = Object.assign({}, content, { products: [] });
    }
    let { products } = content;
    if (products.some((x) => x === id)) {
      products = products.splice(
        products.findIndex((x) => x === id),
        1,
      );
    } else {
      products.push(id);
    }
    page.content = content;
    await this.change(page);
  };

  handleSubmit = (formData) => {
    const { page } = this.props;
    const { rows } = page.content;
    const { row, col } = this.state;
    rows[row].columns[col].props = formData;
    page.content.rows = rows;
    this.change(page);
    this.setState({ show: false });
  };

  handleChangeMode = async (mode) => {
    const { match, page, savePage } = this.props;
    switch (mode) {
      case 'live':
        if (page.content.isBlog === false) {
          page.content.blog = {};
        }
        const { success } = await savePage(match.params.id, page);
        if (success) {
          toast.success('Kayıt edildi.');
          this.setState({ modified: false, sendForApprovaled: true }, () =>
            this.props.getByName(match.params.code, 0),
          );
        }
        break;
      case 'cancel':
        this.setState({ mode: 'live' }, () =>
          this.props.getByName(match.params.code, 1),
        );
        break;
      case 'draft':
        this.setState({ mode }, () =>
          this.props.getByName(match.params.code, 0),
        );
        break;
      case 'publish':
        const result = await this.props.publishPage(page.id);
        if (result.success) {
          toast.success('Son yapılan değişikler yayına alındı.');
          this.props.getByName(match.params.code, 1);
          this.props.getPages();
        }
        break;
      case 'sendForApproval':
        const resultApproval = await this.props.sendForApproval(page.id);
        if (resultApproval.success) {
          this.setState({ sendForApprovaled: false });
          toast.success('Sayfa onaya gönderildi.');
          this.props.getByName(match.params.code, 1);
        }
        break;
      case 'confirm':
        const resultConfirm = await this.props.confirmPage(page.id);
        if (resultConfirm.success) {
          toast.success('Sayfa onaylandı.');
          this.props.getByName(match.params.code, 1);
        }
        break;
      case 'return':
        this.setState({ showPopup: true });
        break;
      case 'history':
        // this.setState({ showHistoryPopup: true });
        this.handleHistoryClick();
        break;
      default:
        this.setState({ mode: 'live' });
        break;
    }
  };

  render() {
    const { page, brands, user } = this.props;
    const breadcrumbs = [
      { title: 'Anasayfa', url: '/' },
      { title: page.name, active: true },
    ];
    if (page.brandId) {
      const brand = brands.find((p) => p.id === page.brandId);
      if (brand) {
        breadcrumbs.splice(1, 0, {
          title: brand.name,
          url: `/markalar/detay/${brand.code}?b=${brand.id}`,
        });
      }
    }
    const isBrand = page.brandId > 0;
    const currentPage = page.content || placeholderPage;
    const editable =
      user.editor === true && (!user.brandId || user.brandId === page.brandId);
    const markaAdmin = user.editor && user.brandId;
    const admin = user.editor && !user.brandId;
    return (
      <>
        <Helmet>
          <title>
            {page && page.isBlog
              ? `${page.blogOptions.title} ${page.name} ${page.blogOptions.category}`
              : 'Tonguç Mağaza'}
          </title>
          <link rel="canonical" href={window.location.href.split('?')[0]} />
          <meta
            name="description"
            content={
              page && page.isBlog
                ? `${page.blogOptions.metaDescription}`
                : "Tonguç'la çalış, Başarıya alış!"
            }
          />
        </Helmet>

        <div className="row">
          <div className="container">
            <div className="col-12">
              <Breadcrumb items={breadcrumbs} />
            </div>
          </div>
        </div>

        {editable && (
          <div className="row">
            <div className="container">
              {this.state.mode === 'draft' && (
                <div className="col-12">
                  <div className="form-group row">
                    <div className="col-12">
                      <div className="custom-control custom-checkbox custom-control-inline">
                        <input
                          name="checkbox"
                          id="checkbox_0"
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked={currentPage.isBlog}
                          onClick={() => this.setIsChecked(!currentPage.isBlog)}
                        />
                        <label
                          htmlFor="checkbox_0"
                          className="custom-control-label"
                        >
                          Blog'a eklensin mi?
                        </label>
                      </div>
                    </div>
                  </div>
                  {currentPage.isBlog && (
                    <BaseForm
                      schema={FormSchemas.blogOptions.schema}
                      uiSchema={FormSchemas.blogOptions.uiSchema}
                      formData={
                        currentPage.blog || {
                          title: '',
                          category: '',
                          metaDescription: '',
                          coverImage: '',
                          isFeatured: false,
                        }
                      }
                      onSubmit={this.saveBlogDetail}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary px-5 py-2 my-3 fs-14 font-weight-500 text-capitalize border-radius-8"
                      >
                        Blog Bilgilerini Kaydet
                      </button>
                    </BaseForm>
                  )}
                </div>
              )}
              <div className="col-12">
                <EditableToolbar
                  mode={this.state.mode}
                  modified={this.state.modified}
                  onChange={this.handleChangeMode}
                  published={page.published}
                  pageStatus={page.pageStatus}
                  publishVisible={markaAdmin}
                  historyVisible={true}
                  sendForApprovalVisible={markaAdmin}
                  sendForApprovaled={!this.state.sendForApprovaled}
                  confirmedVisible={admin}
                  returnVisible={admin}
                  saveVisible={markaAdmin}
                >
                  {/* {markaAdmin && */}
                  <EditMenu onAddClick={this.addRow} />
                  {/* } */}
                </EditableToolbar>
              </div>
            </div>
          </div>
        )}
        {(currentPage.rows || placeholderPage.rows).map((row, i) => (
          <div className="row" key={i}>
            <div className="container">
              {row.columns.map((col, ii) => (
                <div className={`mb-4 col-12 col-lg-${col.size}`} key={ii}>
                  <EditableItem
                    items={menuItems}
                    mode={this.state.mode}
                    onItemClick={(item) => this.handleItemClick(item, i, ii)}
                    enable={col.component ? true : false}
                  >
                    <RenderWidget
                      onAddClick={this.addWidgetClick}
                      component={col.component}
                      show={isBrand}
                      componentId={col.id}
                      mode={this.state.mode}
                      size={col.size}
                      {...col.props}
                    />
                  </EditableItem>
                </div>
              ))}
            </div>
          </div>
        ))}
        <RelatedProducts
          values={currentPage.products || []}
          editable={editable && this.state.mode !== 'live'}
          addOrRemoveProduct={this.addOrRemoveProduct}
          page={this.props.page}
        />
        <FormModal
          show={this.state.show}
          onClose={() => this.setState({ show: false })}
          onSubmit={this.handleSubmit}
          schema={this.state.schema}
          uiSchema={this.state.uiSchema}
          formData={this.state.formData}
        />
        <Modal
          show={this.state.showPopup}
          onCloseClick={() => this.setState({ showPopup: false })}
          className="modal-container modal-md non-modal-height"
        >
          <div className="modal-header bt-dedede:50">
            <CloseButton onClick={() => this.setState({ showPopup: false })} />
          </div>
          <div className="modal-body">
            <input
              type="text"
              className={classNames(
                'form-control',
                this.state.invalid === true ? 'is-invalid' : 'valid',
              )}
              placeholder="İade nedeni"
              onChange={this.handleChange}
              value={this.state.value}
            />
            {this.state.invalid && (
              <div className="invalid-feedback">İade nedeni gereklidir</div>
            )}
          </div>

          <div className="modal-footer fs-14 d-flex flex-column flex-sm-row justify-content-between w-100 text-left">
            <button
              type="button"
              className="btn btn-primary text-center text-capitalize mt-3 mt-sm-0 d-block d-sm-inline-block w-100"
              onClick={this.handleReturnClick}
            >
              İade Et
            </button>
          </div>
        </Modal>

        <Modal
          dialogClassName="modal-lg"
          show={this.state.showHistoryPopup}
          onCloseClick={() => this.setState({ showHistoryPopup: false })}
          className="modal-container modal-md non-modal-height"
        >
          <div className="modal-header bt-dedede:50">
            <CloseButton
              onClick={() => this.setState({ showHistoryPopup: false })}
            />
          </div>
          <div className="modal-body">
            <div className="col-12 col-md-12">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Tarih</th>
                    <th scope="col">Yetkili</th>
                    <th scope="col">İşlem</th>
                    <th scope="col">Detay</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.pageHistory &&
                    this.props.pageHistory.map((history, i) => (
                      <tr key={i}>
                        <td>{formatDate.date(history.createDate)}</td>
                        <td>{history.createdBy}</td>
                        <td>{this.getPageStatus(history.pageStatus)}</td>
                        <td>{history.detail}</td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {/* <ul className="list-group activity-max2-height">
                {this.state.history && this.state.history.map((history, i) => (
                  <li className="list-group-item border-0" key={i}>
                    <div className="fs-16 color-484848 font-weight-500">
                    </div>

                    <div className="d-flex justify-content-between align-items-center flex-wrap mt-2">

                      <span className="color-9b9b9b mr-1">
                        {history.Date}
                      </span>
                      <span className="color-9b9b9b mr-1">
                        {history.User}
                      </span>
                      <span className="color-9b9b9b mr-1">
                        {history.Operation}
                      </span>

                    </div>
                    <span className="color-9b9b9b mr-1">
                      {history.Cause}
                    </span>
                  </li>
                ))}
              </ul> */}
              {/* {this.state.history && this.state.history.length > 0 &&
                < div className=" d-flex flex-row justify-content-center ml-2">
                  <Link
                    className="fs-16 text-blue font-weight-200 "
                    onClick={this.showAllActivity}>
                    {!this.state.isShowAll ? "Tümünü Gör" : "Gizle"}
                    <FontAwesomeIcon
                      icon={!this.state.isShowAll ? faChevronDown : faChevronUp}
                      className="ml-2"
                    />
                  </Link>
                </div>
              } */}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({
  AccountReducer,
  ContentReducer,
  ProductReducer,
}) => ({
  user: AccountReducer.user,
  page: ContentReducer.page,
  brands: ProductReducer.brands,
  pageHistory: ContentReducer.pageHistory,
});

const mapDispatchToProps = {
  getByName: actions.getByName,
  getHistoryByName: actions.getHistoryByName,
  changePage: actions.changePage,
  savePage: actions.savePage,
  publishPage: actions.publishPage,
  sendForApproval: actions.sendForApproval,
  confirmPage: actions.confirmPage,
  returnPage: actions.returnPage,
  getPages: actions.getPages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
