import React from 'react';
//import PropTypes from 'prop-types';
import classNames from 'classnames';

const Selection = ({ title, value, logos, selectedValue, onSelect }) => (
  <button
    className={classNames('btn d-flex flex-column', {
      active: selectedValue === value,
    })}
    onClick={() => onSelect(value)}
  >
    <span className="d-flex w-100 justify-content-around">
      {logos &&
        logos.map((logo, index) => {
          let width = 28;
          let height = 20;
          let imgStyle = {};

          if (logo === '/static/media/bkm-express.df439778.svg') {
            width = 50;
            height = 20;
          } else if (logo === '/static/media/kapidaode.7155d2d0.png') {
            width = 40;
            height = 20;
            imgStyle = { objectFit: 'cover' };
          }

          return (
            <img
              key={index}
              src={logo}
              alt={title}
              width={width}
              height={height}
              style={imgStyle}
            />
          );
        })}
    </span>

    <span>{title}</span>
  </button>
);

Selection.propTypes = {};

export default Selection;
