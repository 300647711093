import React, { Component } from 'react';
import Select from 'react-select';
import { customStylesAccount } from '../../helpers/index';

class DateSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      seperator: props.seperator,
    };
  }

  handleInputChange = (name, value) => {
    const { seperator, onChange, readOnly } = this.props;
    const date = (this.props.value || seperator + seperator).split(seperator);
    switch (name) {
      case 'day':
        date[0] = value;
        break;
      case 'month':
        date[1] = value;
        break;
      case 'year':
        date[2] = value;
        break;
      default:
        break;
    }
    if (onChange) {
      onChange({
        target: { name: this.props.name, value: date.join(seperator) },
      });
    }
  };

  createDays = () => {
    let days = [{ label: 'GÜN' }];
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let lastDay = new Date(y, m + 1, 0).getDate();
    for (let i = 1; i <= lastDay; i++) {
      days.push({ value: i, label: i });
    }
    return days;
  };

  createMonths = () => {
    let months = [{ label: 'AY' }];
    for (let i = 1; i < 13; i++) {
      //Create the parent and add the children
      months.push({ value: i, label: i });
    }
    return months;
  };

  createYears = () => {
    let years = [{ label: 'YIL' }];
    let year = new Date().getFullYear();
    for (let i = 0; i < 100; i++) {
      let value = year - i;
      years.push({ value: value, label: value });
    }
    return years;
  };

  render() {
    const { seperator, value, readOnly } = this.props;
    let date = value && value.split(seperator).map((v) => parseInt(v));
    const days = this.createDays();
    const months = this.createMonths();
    const years = this.createYears();
    let selectedDay = null;
    let selectedMonth = null;
    let selectedYear = null;
    if (date) {
      selectedDay = days.find((p) => p.value === date[0]);
      selectedMonth = months.find((p) => p.value === date[1]);
      selectedYear = years.find((p) => p.value === date[2]);
    }

    return (
      <div className="input-group d-flex">
        <Select
          isSearchable={false}
          name="day"
          className="flex-fill"
          placeholder="Gün"
          options={days}
          styles={customStylesAccount}
          value={selectedDay}
          onChange={(selected) => this.handleInputChange('day', selected.value)}
          isDisabled={readOnly}
        />
        <Select
          isSearchable={false}
          name="month"
          className="flex-fill ml-3"
          placeholder="AY"
          options={months}
          styles={customStylesAccount}
          value={selectedMonth}
          onChange={(selected) =>
            this.handleInputChange('month', selected.value)
          }
          isDisabled={readOnly}
        />
        <Select
          isSearchable={false}
          name="year"
          className="flex-fill ml-3"
          placeholder="YIL"
          options={years}
          styles={customStylesAccount}
          value={selectedYear}
          onChange={(selected) =>
            this.handleInputChange('year', selected.value)
          }
          isDisabled={readOnly}
        />
      </div>
    );
  }
}

DateSelect.defaultProps = {
  seperator: '/',
};

export default DateSelect;
