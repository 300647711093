import React, { useEffect } from 'react';
import scriptLoader from 'react-async-script-loader';
import { videoInteractionSetting } from '../../services/constants';

function InteractionWrapper(props) {
  useEffect(() => {
    if (props.isScriptLoaded && props.isScriptLoadSucceed) {
      if (
        window.SynapsisInteractions &&
        props.userId > 0 &&
        props.contentId > 0
      ) {
        window.SynapsisInteractions.init({
          api: videoInteractionSetting.ApiUrl,
          querySelector: '.synapsis-vote',
          platformId: window.SynapsisInteractions.platforms.TongucMagaza,
        });
      }
    }
  }, [props.isScriptLoadSucceed]);

  useEffect(() => {
    if (props.userId > 0 && props.contentId > 0) {
      var timeOut = setTimeout(() => {
        markAsWatched();
      }, videoInteractionSetting.SecondsForMarkAsViewed * 1000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, []);

  const markAsWatched = () => {
    if (props.dontMarkAsWatched) return;
    window.SynapsisInteractions && window.SynapsisInteractions.markAsWatched();
  };

  return (
    <>
      <div
        className="synapsis-vote"
        data-content-type={props.contentType}
        data-content-id={props.contentId}
        data-user-id={props.userId}
        data-provider={props.videoProvider}
        style={{ width: '100%' }}
      >
        {props.children(markAsWatched)}
      </div>
    </>
  );
}

export default scriptLoader([videoInteractionSetting.ScriptUrl])(
  InteractionWrapper,
);
