import React from 'react';
import CloseButton from '../../components/Button/Close';
import { Modal } from '../../components';

export default function AvatarModal(props) {
  return (
    <Modal
      show={props.show}
      dialogClassName={props.dialogClassName}
      user={props.user}
      avatars={props.avatars}
      className="modal-container tats-avatar-modal non-modal-height overflow-scroll"
    >
      <div className="modal-header">
        <div className="modal-title fs-20 font-weight-600 color-484848">
          Avatar
        </div>
        <CloseButton onClick={props.onCloseClick} />
      </div>
      <div className="modal-body d-flex flex-wrap">
        {props.avatars.map((avatar, i) => (
          <label
            key={avatar.id}
            className={`radio-container img-radio mb-0 fs-10 ${
              props.user.avatar.path === avatar.path ? 'selected' : ''
            }`}
            onClick={(e) => props.updateAvatar(e, avatar)}
          >
            <div className="font-weight-500 ">
              <img src={avatar.path} alt="" />
            </div>
            <input
              type="radio"
              checked={props.user.avatar.path === avatar.path ? true : false}
              name={`avatar_${avatar.id}`}
            />
            <span className="radio-checkmark" />
          </label>
        ))}
      </div>
    </Modal>
  );
}
