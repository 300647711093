import actionTypes from './types';
import { tatsApiCall } from '../../../../services/api';
import { HTTP } from '../../../../services/constants';

export const getTestsQuestions =
  (testId, isVirtual = false) =>
  async (dispatch, getState) => {
    try {
      const {
        AccountReducer: { user },
        AnswerReducer,
        StudyReducer,
      } = getState();

      let result = {};
      if (AnswerReducer.testId === testId) {
        result = AnswerReducer.testsQuestions;
      } else if (StudyReducer.testId === testId) {
        result = StudyReducer.testsQuestions;
      }

      if (result.status) {
        dispatch({
          type: actionTypes.GET_TESTS_QUESTION,
          payload: {
            questions: result,
            testId: !user.id ? 0 : testId,
            testsQuestions: result,
          },
        });

        return { success: result.status, error: result.errorMessage };
      }

      const model = isVirtual
        ? {
            sanalTestNo: testId,
            isSoruImage: true,
          }
        : {
            testId,
            isSoruImage: true,
          };

      if (isVirtual) {
        const { data } = await tatsApiCall(
          '/studentsigns/getrandomtest',
          model,
          null,
          HTTP.POST,
          null,
          true,
        );

        result = data;

        dispatch({
          type: actionTypes.GET_TESTS_QUESTION,
          payload: {
            questions: result,
            testId: !user.id ? 0 : testId,
            testsQuestions: result,
          },
        });
        return { success: result.status, error: result.errorMessage };
      } else {
        if (!user.id || user.demo) {
          const { data } = await tatsApiCall(
            '/BookOperations/qrQuestionsNoName', //testsQuestions
            { qr: `http://tonguc.video/t/${testId}` },
            null,
            HTTP.POST,
            null,
            true,
          );

          result = data;
        } else {
          const { data } = await tatsApiCall(
            '/BookOperations/testsQuestions', //testsQuestions
            model,
            null,
            HTTP.POST,
            null,
            true,
          );

          result = data;
        }

        dispatch({
          type: actionTypes.GET_TESTS_QUESTION,
          payload: {
            questions: result,
            testId: !user.id ? 0 : testId,
            testsQuestions: result,
          },
        });

        return { success: result.status, error: result.errorMessage };
      }
    } catch (e) {
      return { success: false, error: e };
    }
  };
export const saveTestsQuestionsAnswer = (answer) => async (dispatch) => {
  try {
    const { data } = await tatsApiCall(
      `/StudentSigns/addingBulkTestSigns`,
      answer,
      null,
      HTTP.POST,
    );
    if (data.status === false) {
      return { success: false, error: data.errorMessage };
    }
    dispatch({
      type: actionTypes.SAVE_TEST_QUESTION_ANSWER,
      payload: data,
    });
    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};
