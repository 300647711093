import React, { Component } from 'react';
//import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { IconButton, BrandSelect, BrandsSelect } from '../../components';
import Pagination from 'react-js-pagination';
import * as actions from '../../routes/actions/Content';
import Icon from '../../components/Icon/Index';
import queryString from 'query-string';
require('../../components/Pagination/pagination-style.css');
const tablePad = {
  padding: '0.15rem',
};

class Pages extends Component {
  state = {
    value: '',
    brand: null,
    invalid: false,
    invalidPublishLocation: false,
    sortBy: '',
    orderByAsc: true,
    isTest: false,
    filter: {},
    filterBrandId: [],
    filterTitle: '',
    filterStartDate: '',
    filterEndDate: '',
    totalCount: 0,
  };

  componentDidMount() {
    const search = queryString.parse(this.props.location.search);
    const isTest = search.isTest ? true : false;
    const filter = this.state.filter;

    this.setState({ isTest: isTest });
    if (!this.props.pages.length) {
      this.props.getMyPages(1, isTest, filter);
    }
  }

  createPage = async (page) => {
    const { success, error } = await this.props.createPage(page);

    this.setState({ value: '', valuePublishLocation: '' }, () => {
      if (success) {
        toast.success(`${page.name} eklendi`);
      } else {
        toast.error(error.message);
      }
    });
  };

  deletePage = async (page) => {
    const confirmed = window.confirm(
      `(${page.name}) sayfa silinecek onaylıyor musunuz?`,
    );

    if (confirmed) {
      const { success, error } = await this.props.deletePage(page);

      this.setState({ address: {}, show: false, title: '' }, () => {
        if (success) {
          toast.success(`${page.name} silindi`);
        } else {
          toast.error(error.message);
        }
      });
    }
  };
  x;
  handleClick = () => {
    const { brand, value, valuePublishLocation } = this.state;
    if (value && (!this.state.isTest || valuePublishLocation)) {
      this.createPage({
        name: value,
        publishLocation: valuePublishLocation,
        content: {},
        brandId: brand ? brand.value : this.props.user.brandId,
      });
    } else {
      const invalid = value || true;
      const invalidPublishLocation = valuePublishLocation || true;
      this.setState({ invalid, invalidPublishLocation });
    }
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value, invalid: !event.target.value });
  };
  handleChangePublishLocation = (event) => {
    this.setState({
      valuePublishLocation: event.target.value,
      invalidPublishLocation: !event.target.value,
    });
  };

  handleBrandChange = (selected) => {
    this.setState({ brand: selected });
  };

  handleBrandsChange = (selected) => {
    let ids = [];
    selected.forEach((e) => {
      ids.push(e.value);
    });
    this.setState({ filterBrandId: ids });
  };
  handleTitleChange = (event) => {
    this.setState({ filterTitle: event.target.value });
  };
  handleStartDateChange = (event) => {
    this.setState({ filterStartDate: event.target.value });
  };

  handleEndDateChange = (event) => {
    this.setState({ filterEndDate: event.target.value });
  };
  submitPageFilter = () => {
    this.setState({
      filter: {
        Id: this.state.filterBrandId,
        Title: this.state.filterTitle,
        StartDate: this.state.filterStartDate,
        EndDate: this.state.filterEndDate,
      },
    });

    var page = 1;
    var isTest = this.state.isTest;
    var filter = {
      Id: this.state.filterBrandId,
      Title: this.state.filterTitle,
      StartDate: this.state.filterStartDate,
      EndDate: this.state.filterEndDate,
    };
    this.props.getMyPages(page, isTest, filter);
  };
  handleSortClick(sortBy) {
    let orderByAsc = this.state.orderByAsc;
    orderByAsc = !orderByAsc;

    this.setState({
      sortBy,
      orderByAsc,
    });
  }

  compareValues(key, orderByAsc = true) {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return orderByAsc ? comparison : comparison * -1;
    };
  }

  getPageStatus = (op) => {
    let res = '';
    switch (op) {
      case 0:
        res = 'Yeni';
        break;
      case 1:
        res = 'Güncellendi';
        break;
      case 2:
        res = 'Onaya Gönderildi';
        break;
      case 3:
        res = 'Onaylandı';
        break;
      case 4:
        res = 'İade Edildi';
        break;
      case 5:
        res = 'Yayınlandı';
        break;

      default:
        break;
    }

    return res;
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var page = pageNumber;
    var isTest = this.state.isTest;
    var filter = this.state.filter;
    this.props.getMyPages(page, isTest, filter);
  }
  _onFocus(e) {
    e.currentTarget.type = 'date';
  }
  _onBlurStart(e) {
    e.currentTarget.type = 'text';
    e.currentTarget.placeholder = 'Başlangıç Tarihi';
  }
  _onBlurEnd(e) {
    e.currentTarget.type = 'text';
    e.currentTarget.placeholder = 'Bitiş Tarihi';
  }

  render() {
    const { pages, headers } = this.props;
    let totalPageNumber = parseInt(headers['x-pagination']);

    return (
      <>
        <h3 className="mb-4 fs-18 color-484848 font-weight-600">Sayfalarım</h3>
        <div className="container-fluid">
          <div className="row my-4">
            <div className="col-12 col-md-6 col-lg-3 mb-2 mb-lg-0 pl-0 pr-1">
              <BrandSelect onChange={this.handleBrandChange} />
            </div>
            <div
              className={classNames(
                'col-12 col-md-6 mb-2 mb-lg-0',
                this.state.isTest ? 'col-lg-4 pl-1 pr-1' : 'col-lg-6',
              )}
            >
              <input
                type="text"
                className={classNames(
                  'form-control',
                  this.state.invalid === true ? 'is-invalid' : 'valid',
                )}
                placeholder="Sayfa Adı"
                onChange={this.handleChange}
                value={this.state.value}
              />
              {this.state.invalid && (
                <div className="invalid-feedback">Sayfa adı gereklidir</div>
              )}
            </div>
            {this.state.isTest && (
              <div className="col-12 col-md-6 col-lg-3 mb-2 mb-lg-0 pl-1 pr-1">
                <input
                  type="text"
                  className={classNames(
                    'form-control',
                    this.state.invalidPublishLocation === true
                      ? 'is-invalid'
                      : 'valid',
                  )}
                  placeholder="Yayın Yeri"
                  onChange={this.handleChangePublishLocation}
                  value={this.state.valuePublishLocation}
                />
                {this.state.invalidPublishLocation && (
                  <div className="invalid-feedback">Yayın Yeri gereklidir</div>
                )}
              </div>
            )}
            <div
              className={classNames(
                'col-12 col-md-6 pr-0 pl-1',
                this.state.isTest ? 'col-lg-2' : 'col-lg-3',
              )}
            >
              <button
                type="button"
                className="btn btn-primary btn-block py-2 fs-13 border-radius-8"
                onClick={this.handleClick}
              >
                Yeni Ekle
              </button>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-12 col-md-6 col-lg-3 mb-2 mb-lg-0 pl-0 pr-1">
              <BrandsSelect onChange={this.handleBrandsChange} />
            </div>
            <div className="col-12 col-md-6 mb-2 mb-lg-0 col-lg-3">
              <input
                type="text"
                className="form-control"
                placeholder="Sayfa Adı"
                onChange={this.handleTitleChange}
                value={this.state.filterTitle}
              />
            </div>
            <div className="col-12 col-md-6 mb-2 mb-lg-0 col-lg-2">
              <input
                type="text"
                placeholder="Başlangıç Tarihi"
                className="form-control"
                onFocus={this._onFocus}
                onBlur={this._onBlurStart}
                onChange={this.handleStartDateChange}
                value={this.state.filterStartDate}
              />
            </div>
            <div className="col-12 col-md-6 mb-2 mb-lg-0 col-lg-2">
              <input
                type="text"
                placeholder="Bitiş Tarihi"
                className="form-control"
                onFocus={this._onFocus}
                onBlur={this._onBlurEnd}
                onChange={this.handleEndDateChange}
                value={this.state.filterEndDate}
              />
            </div>
            <div className="col-12 col-md-6 pr-0 pl-1 col-lg-2">
              <button
                type="button"
                className="btn btn-primary btn-block py-2 fs-13 border-radius-8"
                onClick={this.submitPageFilter}
              >
                Filtrele
              </button>
            </div>
          </div>
        </div>

        {/* <h4 className="mb-4 fs-16 color-484848 font-weight-600">Marka Adı</h4> */}
        <div className="card">
          <div className="card-body">
            {this.state.isTest && (
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      onClick={() => this.handleSortClick('name')}
                      style={tablePad}
                    >
                      <div class="d-flex flex-row">
                        <div class="">Marka Adı &nbsp;</div>
                        <div class="">
                          {this.state.sortBy === 'name' &&
                            (this.state.orderByAsc ? (
                              <Icon icon="iChevronDown" />
                            ) : (
                              <Icon icon="iChevronUp" />
                            ))}
                        </div>
                      </div>

                      {/* 
                    Marka Adı &nbsp;
                    {
                      this.state.sortBy === 'name' &&
                      (
                        this.state.orderByAsc
                          ? <Icon icon="iChevronDown" />
                          : <Icon icon="iChevronUp" />
                      )
                    } */}
                    </th>
                    <th
                      scope="col"
                      cstyle={{ width: 100 }}
                      onClick={() => this.handleSortClick('pageStatus')}
                      style={tablePad}
                    >
                      <div class="d-flex flex-row">
                        <div class="">Durum &nbsp;</div>
                        <div class="">
                          {this.state.sortBy === 'pageStatus' &&
                            (this.state.orderByAsc ? (
                              <Icon icon="iChevronDown" />
                            ) : (
                              <Icon icon="iChevronUp" />
                            ))}
                        </div>
                      </div>
                      {/* <div> Durum &nbsp;</div>
                    <div>
                      {
                        this.state.sortBy === 'colDurum' &&
                        (
                          this.state.orderByAsc
                            ? <Icon icon="iChevronDown" />
                            : <Icon icon="iChevronUp" />
                        )
                      }
                    </div> */}
                    </th>
                    <th
                      scope="col"
                      onClick={() => this.handleSortClick('publishLocation')}
                      style={tablePad}
                    >
                      <div class="d-flex flex-row">
                        <div class="">Yayın Yeri &nbsp;</div>
                        <div class="">
                          {this.state.sortBy === 'publishLocation' &&
                            (this.state.orderByAsc ? (
                              <Icon icon="iChevronDown" />
                            ) : (
                              <Icon icon="iChevronUp" />
                            ))}
                        </div>
                      </div>

                      {/* <span> Yayın Yeri &nbsp;</span>
                    {
                      this.state.sortBy === 'colYayinYeri' &&
                      (
                        this.state.orderByAsc
                          ? <Icon icon="iChevronDown" />
                          : <Icon icon="iChevronUp" />
                      )
                    } */}
                    </th>
                    <th
                      scope="col"
                      onClick={() => this.handleSortClick('isBlog')}
                      style={tablePad}
                    >
                      <div class="d-flex flex-row">
                        <div class="">Blog &nbsp;</div>
                        <div class="">
                          {this.state.sortBy === 'isBlog' &&
                            (this.state.orderByAsc ? (
                              <Icon icon="iChevronDown" />
                            ) : (
                              <Icon icon="iChevronUp" />
                            ))}
                        </div>
                      </div>
                    </th>
                    <th scope="col" style={tablePad}></th>
                  </tr>
                </thead>
                <tbody>
                  {pages
                    .filter((item) => item.embedded === 0 && item.active === 1)
                    .sort(
                      this.compareValues(
                        this.state.sortBy,
                        this.state.orderByAsc,
                      ),
                    )
                    .map((item, i) => (
                      <tr key={i}>
                        <td style={tablePad}>
                          <Link to={`/sayfa/${item.slug}/${item.id}`}>
                            {item.name}
                          </Link>
                        </td>
                        <td style={tablePad}>
                          <span>
                            {
                              this.getPageStatus(item.pageStatus)
                              // item.published === 1
                              //   ? 'Onaylandı'
                              //   : 'Onay Bekliyor'
                            }
                          </span>
                        </td>
                        <td style={tablePad}>
                          <span>{item.publishLocation}</span>
                        </td>
                        <td>
                          <span>{item.isBlog === true ? '✔' : ''}</span>
                        </td>
                        <td className="text-right" style={tablePad}>
                          <IconButton
                            icon={faTrashAlt}
                            onClick={() => this.deletePage(item)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            {!this.state.isTest && (
              <ul className="list-group card-list-group">
                {pages
                  .filter(
                    (item) =>
                      item.embedded === 0 &&
                      item.active === 1 &&
                      item.published === 1,
                  )
                  .map((item, i) => (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      key={i}
                    >
                      <Link to={`/sayfa/${item.slug}/${item.id}`}>
                        {item.name}
                      </Link>
                      <span>
                        {item.isBlog === true ? 'bu bir blog sayfasıdır' : ''}
                      </span>
                      <IconButton
                        icon={faTrashAlt}
                        onClick={() => this.deletePage(item)}
                      />
                    </li>
                  ))}
              </ul>
            )}
            {/* <ul className="list-group card-list-group">
              {pages
                .filter(item => item.embedded === 0 && item.active === 1 && item.published === 1)
                .map((item, i) => (
                  <li
                    className="list-group-item d-flex justify-content-between align-items-center"
                    key={i}
                  >
                    <Link to={`/sayfa/${item.slug}/${item.id}`}>
                      {item.name}
                    </Link>
                    <IconButton
                      icon={faTrashAlt}
                      onClick={() => this.deletePage(item)}
                    />
                  </li>
                ))}
            </ul> */}
          </div>
        </div>
        <br />
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalPageNumber}
          pageRangeDisplayed={10}
          onChange={this.handlePageChange.bind(this)}
        />
      </>
    );
  }
}

Pages.propTypes = {};

const mapStateToProps = ({ AccountReducer, ContentReducer }) => ({
  user: AccountReducer.user,
  pages: ContentReducer.pages,
  headers: ContentReducer.headers,
});

const mapDispatchToProps = {
  getMyPages: actions.getMyPages,
  createPage: actions.createPage,
  deletePage: actions.deletePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
