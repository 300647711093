import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../routes/actions/Account';
//import PropTypes from "prop-types";
import classNames from 'classnames';
import EmailWidget from '../Widgets/Email';
import { ScoreBadge } from '../..';
import { toast } from 'react-toastify';
import storage from '../../../services/storage';
import { keys } from '../../../services/constants';

class EmailField extends Component {
  sendVerifyEmail = async () => {
    var source = '';
    var sourceUrl = storage.getItem(keys.MailVerificationRedirectLocation);
    if (sourceUrl == '/dijitalkitap/sinav-modu') {
      source = 'sinav-modu';
    }
    const { success, error } = await this.props.sendVerifyEmail(
      this.props.value,
      source,
    );

    if (success) {
      toast.success(
        'E-posta adresinize gönderilen linki tıklayıp doğrulama işlemini tamamlayınız.',
      );
    } else {
      if (error && error.length) {
        toast.error(error);
      }
    }
  };

  render() {
    const {
      className,
      name,
      schema,
      uiSchema,
      value,
      onChange,
      readOnly,
      errors,
      accountSettings,
    } = this.props;

    const valid = !(errors && errors.length > 0);
    const widgetProps = {
      className: classNames(
        'form-control',
        valid === false ? 'is-invalid' : 'valid',
      ),
      name,
      schema,
      uiSchema,
      value,
      readOnly,
      onChange,
      onClick: this.sendVerifyEmail,
      valid,
      verified: this.props.user.isMailVerified,
      isDemoUser: this.props.user.demo,
      activityTypes: this.props.activityTypes,
      accountSettings,
    };

    var activity = { Score: 0, Coins: 0 };
    if (this.props.activityTypes && this.props.activityTypes.length > 0) {
      activity = this.props.activityTypes.find(
        (x) => x.Name === 'verify-email',
      );
    }

    return (
      <div className={classNames('form-group col-12', className)}>
        <div className="d-flex justify-content-between">
          <label className="mt-2" htmlFor={name}>
            {schema.title}
          </label>
        </div>
        {!widgetProps.verified ? (
          <ScoreBadge
            icon="point"
            value={activity.Score}
            className="badge-score mb-1"
          />
        ) : null}
        <EmailWidget {...widgetProps} />
        <small id={`${name}_help`} className="form-text text-muted col-sm-12">
          {uiSchema && uiSchema['ui:description']}
        </small>
        {(errors || []).map((error) => (
          <div className="invalid-feedback">{error}</div>
        ))}
      </div>
    );
  }
}

EmailField.propTypes = {};

const mapStateToProps = ({ AccountReducer, ProductReducer }) => ({
  user: AccountReducer.user,
  activityTypes: ProductReducer.activityTypes,
});

const mapDispatchToProps = {
  sendVerifyEmail: actions.sendVerifyEmail,
  verify: actions.verify,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailField);
