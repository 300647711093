import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ScoreBadge from '../../../components/Badge/Score';

const EmailWidget = ({
  name,
  schema,
  uiSchema,
  value,
  valid,
  verified,
  onClick,
  readOnly,
  isDemoUser,
  ...props
}) => {
  var activity = { Score: 0, Coins: 0 };
  if (props.activityTypes && props.activityTypes.length > 0) {
    activity = props.activityTypes.find((x) => x.Name === 'verify-email');
  }

  return (
    <>
      <div className="input-group mb-3">
        <input
          type={schema.format || 'text'}
          id={name}
          name={name}
          defaultValue={value}
          {...props}
          disabled={readOnly}
        />
        {!verified ? (
          <div className="input-group-append">
            <button
              type="button"
              className="btn btn-outline-dark text-capitalize"
              disabled={isDemoUser}
              onClick={onClick}
            >
              Doğrula
            </button>
            <ScoreBadge
              icon="point"
              value={activity.Score}
              className="badge-score-custom mb-1"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default EmailWidget;
