import React, { useState, useEffect } from 'react';
import Modal from './Index';
import CloseButton from '../Button/Close';
import FormSchemas from '../../variables/FormSchemas';
import { BaseForm } from '..';
import apiCall from '../../services/api';
import * as actions from '../../routes/actions/Content';
import { connect } from 'react-redux';

const EditableBanner = (props) => {
  const [formData, setFormData] = useState({});
  const [page, setPage] = useState({});
  const [mode, setMode] = useState('');
  const [schema, setSchema] = useState();
  const [uiSchema, setUiSchema] = useState();

  useEffect(() => {
    if (props.editableBannerName && props.show) {
      apiCall(`/contents/content/${props.editableBannerName}/1`).then((res) => {
        setPage(res.data);
      });
    }
  }, [props.editableBannerName, props.show]);
  useEffect(() => {
    if (page && props.show) {
      setFormData(page.content);
    }
  }, [page, props.show]);

  const handleSubmit = async (formData) => {
    if (props.bannerType === 'cardBanner') {
      setPage({
        ...page,
        content: {
          ...page.content,
          title: formData.title,
          image: formData.image,
          target: formData.target,
        },
      });
    } else {
      setPage({
        ...page,
        content: {
          ...page.content,
          mobile: formData.mobile,
          web: formData.web,
          target: formData.target,
        },
      });
    }
    setMode('changed');
  };

  const savePage = async () => {
    await props.savePage(page.id, page);
    setMode('saved');
  };

  const publishPage = () => {
    props.publishPage(page.id);
    props.onClose();
    props.onPublish(page);
  };
  useEffect(() => {
    if (props.bannerType) {
      setSchema(FormSchemas[props.bannerType].schema);
      setUiSchema(FormSchemas[props.bannerType].uiSchema);
    }
  }, [props.bannerType, props.show]);

  return (
    <Modal
      className="editableBannerModal"
      show={props.show}
      onCloseClick={props.onClose}
    >
      <CloseButton onClick={props.onClose} />
      <div className="modal-body">
        {schema && uiSchema && (
          <BaseForm
            schema={schema}
            uiSchema={uiSchema}
            formData={formData}
            onSubmit={handleSubmit}
          >
            <button
              type="submit"
              className="btn btn-primary px-5 py-2 my-3 mr-1 fs-14 font-weight-500 text-capitalize border-radius-8"
            >
              Güncelle
            </button>
            <button
              type="button"
              disabled={mode !== 'changed'}
              onClick={savePage}
              className="btn btn-primary px-5 py-2 my-3 mr-1 fs-14 font-weight-500 text-capitalize border-radius-8"
            >
              Kaydet
            </button>
            <button
              type="button"
              disabled={mode !== 'saved'}
              onClick={publishPage}
              className="btn btn-primary px-5 py-2 my-3 fs-14 font-weight-500 text-capitalize border-radius-8"
            >
              Yayınla
            </button>
          </BaseForm>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = {
  savePage: actions.savePage,
  publishPage: actions.publishPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditableBanner);
