import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { uploadFile } from '../../../routes/actions/Content';

class File extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      inputFileLabel: props.schema.title,
      options: props.uiSchema && props.uiSchema['ui:options'],
      valid: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.schema?.title !== this.props.schema?.title) {
      this.setState({
        inputFileLabel: this.props.schema.title,
      });
    }

    try {
      if (
        JSON.stringify(prevProps.uiSchema) !==
        JSON.stringify(this.props.uiSchema)
      ) {
        this.setState({
          options: this.props.uiSchema['ui:options'],
        });
      }
    } catch (ex) {}
  }

  upload = async (target, file) => {
    const { success } = await this.props.uploadFile(
      target.name,
      'content',
      file,
    );
    if (success) {
      this.props.onChange(target.name, this.props.uploaded.url);
    }
  };

  handleChange = (event) => {
    const { options } = this.state;
    const target = event.target;

    var file = this.inputRef.current.files[0];
    const validate = (valid) =>
      this.setState({ valid }, () => valid && this.upload(target, file));

    if (options.validateSize === true) {
      if (file) {
        var img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = function () {
          let width = img.naturalWidth;
          let height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);
          let valid = false;

          if (typeof options.height === 'number')
            valid = width === options.width && height === options.height;

          if (typeof options.height === 'object')
            options.height.forEach((e) => {
              if (width === options.width && height === e) valid = true;
            });

          validate(valid);
        };
      }
    } else this.upload(target, file);
  };

  render() {
    const {
      args,
      className,
      name,
      schema,
      uiSchema,
      onChange,
      value,
      valid,
      uploadFile,
      ...rest
    } = this.props;
    const options = uiSchema && uiSchema['ui:options'];
    return (
      <>
        {options.imgPreview ? (
          <>
            <ul className="list-unstyled gallery-list mb-0">
              <li className="media product-show-media d-flex align-items-center">
                <figure className="mr-3">
                  <img src={value} style={{ maxWidth: 120 }} alt="" />
                </figure>
                <div className="media-body position-relative">
                  <input
                    type="file"
                    className={classNames(className, 'custom-file-input')}
                    id={name}
                    name={name}
                    onChange={this.handleChange}
                    maxLength={schema.maxLength}
                    ref={this.inputRef}
                    {...options}
                    {...rest}
                  />
                  <label
                    className="custom-file-label img-gallery-list"
                    htmlFor={name}
                  >
                    {value || this.state.inputFileLabel}
                  </label>
                </div>
              </li>
            </ul>
            {this.state.valid === false && (
              <div className="invalid-feedback">
                Resim boyutları istenilen şartlara uygun değildir.
              </div>
            )}
          </>
        ) : (
          <>
            <input
              type="file"
              className={classNames(className, 'custom-file-input')}
              id={name}
              name={name}
              onChange={this.handleChange}
              maxLength={schema.maxLength}
              ref={this.inputRef}
              {...options}
              {...rest}
            />
            <label className="custom-file-label" htmlFor={name}>
              {value || this.state.inputFileLabel}
            </label>
            {this.state.valid === false && (
              <div className="invalid-feedback">
                Resim boyutları istenilen şartlara uygun değildir.
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

File.propTypes = {
  name: PropTypes.string,
};

const mapStateToProps = ({ AccountReducer, ContentReducer }) => ({
  user: AccountReducer.user,
  uploaded: ContentReducer.uploaded,
});

const mapDispatchToProps = {
  uploadFile: uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(File);
