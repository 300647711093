import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { customStyles } from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Icon from '../../components/Icon/Index';

//import PropTypes from 'prop-types';

const DeliveryAddress = ({
  onAddClick,
  onEditClick,
  options,
  address,
  onChange,
  title,
  deleteAddress,
}) => {
  let value = options.find((x) => x.value === address.id);

  const [active, setActive] = useState(false);

  function changeAddress(address) {
    onChange(address);
    setActive(false);
  }

  useEffect(() => {}, [address]);
  return (
    <>
      <div className="">
        <div className="">
          <div className="form-group mb-0">
            <div className="d-flex align-items-center mb-2">
              <label
                htmlFor="deliveryAddress"
                className="fs-13 font-weight-600 m-0 color-202124"
              >
                {title}
              </label>
              <div
                className="add-address d-flex ml-auto p-0 align-items-center justify-content-end fs-13 font-weight-600 color-6e87ff bg-transparent text-capitalize"
                type="button"
                onClick={onAddClick}
              >
                <FontAwesomeIcon className="mr-2" icon={faPlus} />
                Yeni Adres Ekle
              </div>
            </div>
            {/* <Select
              isSearchable={false}
              placeholder="Adres Seçiniz"
              options={options}
              styles={customStyles}
              onChange={onChange}
              value={value || ""} 
            /> */}

            {address && address.id > 1 && (
              <div className="row">
                <div className="col-12 p-custom-md-0">
                  <div className="d-flex align-items-center border-radius-12 bg-f4f4f4 address-container">
                    <div className="d-flex flex-column">
                      <h5 className="fs-12 font-weight-600 color-484848">
                        {address.title}
                      </h5>
                      <p className="font-weight-500 fs-13 m-0 color-202124">
                        {`${address.firstname} ${address.lastname || ''}`}{' '}
                        <br />
                        {`${address.lines}`} <br />
                        {`${address.town.name} / ${address.city.name}`} <br />
                        <span>{`${address.mobile}`}</span>
                      </p>
                    </div>
                    {/* <button
                      className="btn fs-13 color-484848 text-capitalize outline-none box-shadow-none p-0"
                      onClick={() => onEditClick(address)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                      <span className="pl-2">Düzenle</span>
                    </button> */}
                    <div
                      className="d-flex flex-column ml-auto align-items-center fs-13 color-6e87ff cursor-pointer"
                      onClick={() => setActive((active) => !active)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                      Değiştir
                      <span className="fs-12 font-weight-500 color-202124">
                        (+{options.length} adres)
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className={`col-12 delivery-address ${active ? 'show' : ''}`}
                >
                  <div className="delivery-address-box">
                    <div className="position-relative">
                      <h4>Teslimat Adresi Seçiniz</h4>
                      <button
                        className="close-btn btn"
                        onClick={() => setActive((active) => !active)}
                      >
                        <Icon icon="iClose" />
                      </button>
                    </div>
                    <span className="header-bar" />

                    {options.map((item) => (
                      <div
                        className={`alert border-radius-8 b-dedede fs-13 color-484848 ${
                          item.id === address.id ? 'active' : ''
                        } `}
                      >
                        <div className="d-flex flex-column">
                          <h5 className="fs-12 font-weight-600 color-484848">
                            {item.title}
                          </h5>
                          <p className="font-weight-500 fs-13 m-0 color-202124">
                            {`${item.firstname} ${item.lastname || ''}`} <br />
                            {`${item.lines}`} <br />
                            {`${item.town.name} / ${item.city.name}`} <br />
                            <span>{`${item.mobile}`}</span>
                          </p>
                        </div>
                        <div className="d-flex">
                          <button
                            className="btn fs-13 color-484848 d-flex align-items-center text-capitalize outline-none box-shadow-none p-0 pr-1"
                            onClick={() => deleteAddress(item)}
                          >
                            <Icon icon="iDelete" />
                            <span className="pl-1">Sil</span>
                          </button>
                          <button
                            className="btn fs-13 color-484848 d-flex align-items-center text-capitalize outline-none box-shadow-none p-0"
                            onClick={() => onEditClick(item)}
                          >
                            <Icon icon="iEdit" />
                            <span className="pl-1">Düzenle</span>
                          </button>
                        </div>
                        <div className="radio--container">
                          <div
                            className={`radio m-0 ${
                              item.id === address.id ? 'active' : ''
                            }`}
                            onClick={() => changeAddress(item)}
                          />
                        </div>
                      </div>
                    ))}
                    <button
                      className="btn btn-default d-flex align-items-center justify-content-end fs-13 font-weight-600 color-484848 bg-transparent text-capitalize"
                      type="button"
                      onClick={onAddClick}
                    >
                      <FontAwesomeIcon className="mr-2" icon={faPlus} />
                      Yeni Adres Ekle
                    </button>
                  </div>
                </div>
                {/* <div
                  className={`modal-backdrop ${active ? "active" : ""}`}
                  style={{ opacity: "0.4" }}
                /> */}
              </div>
            )}

            <div
              isSearchable={false}
              placeholder="Adres Seçiniz"
              options={options}
              styles={customStyles}
              onChange={onChange}
              value={value || ''}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

DeliveryAddress.propTypes = {};

export default DeliveryAddress;
