import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { Modal } from '..';
import { toast } from 'react-toastify';
import CloseButton from '../Button/Close';

class Payment extends Component {
  constructor(props) {
    super(props);
    this.frame = React.createRef();
  }

  async componentDidMount() {
    const { innerHtml, orderId, productType } = this.props;
    if (innerHtml === true) {
      this.frame.current.removeAttribute('src');
    } else
      this.frame.current.setAttribute(
        'src',
        `/pay3d/${orderId}/${productType}`,
      );
    window.addEventListener('message', this.handleFrameMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleFrameMessage);
  }

  handleFrameMessage = ({ data }) => {
    if (data.source === 'payment-frame-load') {
      this.postMessage();
      return;
    } else if (data.source === 'payment-frame-result') {
      if (data.payload.status === 'True') {
        this.props.onSuccess();
      } else {
        toast.error(data.payload.message);
        this.props.onCloseClick();
      }
    }

    this.setState({ show: false, paying: false });
  };

  postMessage = () => {
    const { innerHtml, payment } = this.props;
    this.frame.current.contentWindow.postMessage(
      {
        type: 'LOAD_PAYMENT',
        innerHtml: innerHtml === true,
        payload: payment,
      },
      window.location.origin,
    );
  };

  render() {
    const { show, onCloseClick } = this.props;
    return (
      <Modal
        className="modal-container secure3d-modal non-modal-height"
        show={show}
      >
        <div className="modal-header bb-0">
          <CloseButton onClick={onCloseClick} />
        </div>
        <div className="modal-body text-center">
          <iframe title="3D Secure" className="w-100" ref={this.frame} />
        </div>
      </Modal>
    );
  }
}

Payment.propTypes = {};

export default Payment;
