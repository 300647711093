import svgTruck from './truck.svg';
import svgClose from './close.svg';
import svgProductClose from './icon-product-close.svg';
import svgAvatar from './avatar.jpg';
import svgAvatarLg from './avatar@2x.jpg';
import svgAvatarXl from './avatar@3x.jpg';
import svgQuestion from './question.svg';
import svgHearth from './hearth.svg';
import svgHearthOuter from './hearth-o.svg';
import svgBell from './bell.svg';

export const iconAvatar = svgAvatar;
export const iconAvatarLg = svgAvatarLg;
export const iconAvatarXl = svgAvatarXl;
export const iconBell = svgBell;
export const iconClose = svgClose;
export const iconProductClose = svgProductClose;
export const iconHearth = svgHearth;
export const iconHearthOuter = svgHearthOuter;
export const iconQuestion = svgQuestion;
export const iconTruck = svgTruck;
export const imgCircleCheck = require('./circle-check.svg');
export const imgTruck = require('./truck2.svg');
export const garantiPayLogo = require('./garanti-pay.png');

export { ReactComponent as iChevronDown } from './icon-chevron-blue.svg';
export { ReactComponent as iChevronUp } from './chevron-up.svg';
export { ReactComponent as iClose } from './close.svg';
export { ReactComponent as iBell } from './bell.svg';
export { ReactComponent as iCart } from './account-icons/sepet.svg';
export { ReactComponent as iCartHover } from './account-icons/sepet-hover.svg';
export { ReactComponent as iAccount } from './account-icons/icon-account.svg';
export { ReactComponent as iDoc } from './account-icons/doc.svg';
export { ReactComponent as iWallet } from './account-icons/icon-product-jeton.svg';
export { ReactComponent as iShipping } from './account-icons/icon-shipping.svg';
export { ReactComponent as iShippingHover } from './account-icons/shipping-hover.svg';
export { ReactComponent as iReservation } from './account-icons/rezervation.svg';
export { ReactComponent as iLocation } from './account-icons/icon-adreslerim.svg';
export { ReactComponent as iNotification } from './account-icons/notification.svg';
export { ReactComponent as iNotificationHover } from './account-icons/notification-hover.svg';
export { ReactComponent as iFavorites } from './account-icons/icon-favorilerim.svg';
export { ReactComponent as iPassword } from './account-icons/icon-password.svg';
export { ReactComponent as iQuestion } from './account-icons/icon-helper.svg';
export { ReactComponent as iExit } from './account-icons/icon-exit.svg';
export { ReactComponent as iUser } from './account-icons/account-default.svg';
export { ReactComponent as iChevronRight } from './chevron-right.svg';
export { ReactComponent as iPreferences } from './account-icons/icon-bildirimlerim.svg';
export { ReactComponent as iHamburger } from './hamburger-menu.svg';
export { ReactComponent as iBasket } from './icon-basket.svg';
export { ReactComponent as iSearch } from './icon-search-black.svg';
export { ReactComponent as iCloseMenu } from './icon-close.svg';
export { ReactComponent as iCloseFilter } from './icon-close-filter.svg';
export { ReactComponent as iMobileFilter } from '../filter/icon-filter.svg';
export { ReactComponent as iMobileSort } from '../filter/icon-sort.svg';
export { ReactComponent as iMobileFilterBack } from '../filter/icon-back-2.svg';
export { ReactComponent as iDigitalBook } from './e-kitap.svg';
export { ReactComponent as iBasketWhite } from './icon-sepet-white.svg';
export { ReactComponent as iTruck } from './icon-truck.svg';
export { ReactComponent as iSuccess } from './icon-success.svg';
export { ReactComponent as iInfo } from '../payment/icon-info.svg';
export { ReactComponent as iCVV } from '../payment/icon-cvv.svg';
export { ReactComponent as iSave } from './icon-save-secili.svg';
export { ReactComponent as iBasket3 } from './icon-sepet-3.svg';
export { ReactComponent as iDelete } from '../payment/icon-sil.svg';
export { ReactComponent as iEdit } from '../payment/icon-edit.svg';
export { ReactComponent as iOrder } from './account-icons/icon-siparis.svg';
export { ReactComponent as iReturn } from './account-icons/icon-return.svg';
export { ReactComponent as iWallet2 } from './account-icons/icon-wallet.svg';
export { ReactComponent as iPlusWhite } from './icon-plus-white.svg';
export { ReactComponent as iPlusBlack } from './icon-plus-black.svg';
export { ReactComponent as iMinusBlack } from './icon-minus-black.svg';
export { ReactComponent as iChevronRightNew } from './icon-arrow-chevron-right.svg';
export { ReactComponent as iChevronLeftNew } from './icon-arrow-chevron-left.svg';
export { ReactComponent as iChevronBottomNew } from './icon-arrow-chevron-bottom.svg';
export { ReactComponent as iFilterDelete } from './icon-sil.svg';
export { ReactComponent as iEyeMask } from './icon-password-mask.svg';
export { ReactComponent as iEyeUnmask } from './icon-password-mask-2.svg';
export { ReactComponent as iProductType } from './icon-product-type.svg';
export { ReactComponent as iPageCount } from './icon-pages.svg';
export { ReactComponent as iQuestionCount } from './icon-question-count.svg';
export { ReactComponent as iBarcode } from './icon-barcode.svg';
export { ReactComponent as iJeton } from './icon-product-jeton.svg';
export { ReactComponent as iSMS } from './account-icons/icons-sms.svg';
export { ReactComponent as iPhone } from './account-icons/icon-telefon.svg';
export { ReactComponent as iLock } from './icon-lock.svg';
export { ReactComponent as iBackArrow } from './icon-back-arrow.svg';
export { ReactComponent as iLike } from './icon-like.svg';
export { ReactComponent as iDislike } from './icon-dislike.svg';
export { ReactComponent as iArrowDownOrange } from './icon-arrow-chevron-orange.svg';
export { ReactComponent as iStats } from './icon-stats.svg';
export { ReactComponent as iVideo } from './icon-video.svg';
export { ReactComponent as iUp } from './icon-up.svg';
export { ReactComponent as iDown } from './icon-down.svg';
export { ReactComponent as iEditable } from './icon-edit-2.svg';
export { ReactComponent as iEyeOff } from './icon-eye-off.svg';
export { ReactComponent as iEyeOn } from './icon-eye-on.svg';
export { ReactComponent as iBkm } from './bkm-express.svg';
export { ReactComponent as iMailWhite } from './icon-mail-white.svg';
export { ReactComponent as iPdfSvg } from './pdf-svgrepo-com.svg';
export { ReactComponent as iPdfBookSvg } from './pdf-book.svg';
export { ReactComponent as iPdfAdditionalBookSvg } from './pdf-additional-book.svg';
