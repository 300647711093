import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, NumericInput } from '../../components';
import CloseButton from '../../components/Button/Close';
import _includes from 'lodash/includes';
import _remove from 'lodash/remove';
import _findIndex from 'lodash/findIndex';

class StatsErrorBox extends Component {
  state = {
    items: [],
    checkedSubjects: [],
    konular: [],
    // uniteler: [],
    quest: {},
    branslar: [],
    tests: [],
    soruSayisi: 1,
    unite: null,
  };

  constructor(props) {
    super(props);

    this.soru = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show && !this.props.show) {
      this.setState({
        quest: {},
        checkedSubjects: [],
        items: [],
        unite: null,
        // uniteler: [],
        bransId: null,
      });

      // this.soru.current.value = '';
    } else if (this.props.show && !prevProps.show) {
      if (this.props.bransId) {
        if (this.state.items.findIndex((p) => p == prevProps.bransId) > 0)
          this.toogle(prevProps.bransId);

        if (this.state.items.findIndex((p) => p == this.props.bransId) < 0)
          this.toogle(this.props.bransId, true);
      }

      this.setState({}, () => this.calculateQuestions());
    }
  }

  toogle = (item, checkSubjects = false) => {
    if (!item) {
      return;
    }
    const { items, checkedSubjects } = this.state;

    if (_includes(items, item)) {
      _remove(items, (p) => p === item);
      _remove(checkedSubjects, (p) => p.bransId === item);
    } else {
      if (
        this.props.branslar
          .filter((p) => p.tests.some((t) => t.toplamYanlis > 0))
          .some((p) => p.bransId === item)
      ) {
        items.push(item);
      }
    }

    const branslar = this.props.branslar
      ? this.props.branslar
          .filter((p) => items.includes(p.bransId))
          .filter((p) => p.tests.some((t) => t.toplamYanlis > 0))
      : [];

    // const uniteler = this.props.unites ? this.props.unites.filter(p => p.toplamYanlis > 0 && items.includes(p.bransId)) : [];

    const konular = branslar.reduce(function (konular, brans) {
      let cndKonular = brans.subjects
        .map((p) => {
          const { testKonu, testKonuAd, toplamYanlis } = p;
          let s = {
            id: testKonu,
            title: testKonuAd,
            bransId: brans.bransId,
            toplamYanlis,
          };
          return s;
        })
        .filter((p) => p.toplamYanlis > 0);

      konular = konular.concat(cndKonular);

      return konular;
    }, []);

    if (checkSubjects) {
      konular
        .filter(
          (p) =>
            p.bransId === item &&
            (!this.props.unite ||
              this.props.unite.subjects.some((s) => s.id === p.id)),
        )
        .map((p) => {
          if (checkedSubjects.findIndex((s) => s.id === p.id) < 0)
            checkedSubjects.push(p);
        });

      // this.setState({
      //     checkedSubjects: subjects
      // },
      //     () =>
      //         this.calculateQuestions()
      // );
    }

    this.setState(
      {
        items,
        konular,
        checkedSubjects,
        // uniteler
      },
      () => this.calculateQuestions(),
    );
  };

  handleChange = (soruSayisi) => {
    this.setState(
      {
        soruSayisi,
      },
      () => this.calculateQuestions(),
    );
  };

  calculateQuestions() {
    // const soru = +this.soru.current.value;
    const { soruSayisi } = this.state;
    const { items, checkedSubjects } = this.state;
    let quest = {};
    let bransQuestions = this.splitQuestions(soruSayisi, items.length); // 13,2 -> [6,1] = 7 + 6

    items.map((p, i) => {
      let unites = checkedSubjects.filter((u) => u.bransId == p);

      const total = bransQuestions[i];
      const uniteQuestions = this.splitQuestions(total, unites.length);

      quest[p] = {
        total: uniteQuestions.reduce((a, b) => a + b, 0),
        error: 1,
      };

      unites.map((unt, uix) => {
        const soruCount = uniteQuestions[uix];
        quest[p][unt.id] = soruCount;
      });
    });

    this.setState({ quest });
  }

  splitQuestions(total, target) {
    if (target > total || target === 0 || total === 0) {
      return new Array(target).fill(0);
    }

    let mod = total % target;
    let each = (total - mod) / target;

    let res = new Array(target).fill(each);
    var i;
    for (i = 0; i < mod; i++) {
      res[i] += 1;
    }

    return res;
  }
  handeTestOlusturClick = (e) => {
    // e.preventDefault()

    const soru = this.state.soruSayisi;
    let req = {
      secilenSoruSayisi: soru,
      secilenBranslar: this.state.items,
      seciliKonular: [
        0, // TODO
      ],
    };

    this.props.onOkClick(req);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // const soru = this.soru.current.value;
    const soru = this.state.soruSayisi;
    let req = {
      secilenSoruSayisi: soru,
      secilenBranslar: this.state.items,
      seciliKonular: [],
    };

    this.props.onOkClick(req);

    // TODO this.props.onOkClick();
  };

  handleChecked = (subject) => {
    if (!subject) {
      return;
    }
    const { checkedSubjects } = this.state;

    if (
      checkedSubjects.findIndex(
        (p) => p.id === subject.id && p.bransId == subject.bransId,
      ) > -1
    ) {
      _remove(
        checkedSubjects,
        (p) => p.id === subject.id && p.bransId == subject.bransId,
      );
    } else {
      checkedSubjects.push(subject);
    }

    this.setState(
      {
        checkedSubjects,
      },
      () => this.calculateQuestions(),
    );
  };

  render() {
    return (
      <Modal
        {...this.props}
        dialogClassName="modal-lg error-box-modal non-modal-height"
        className="modal-container"
      >
        <CloseButton onClick={this.props.onCloseClick} />
        <div className="modal-header mx-4 pl-0">
          <h5 className="modal-title" id="exampleModalLabel">
            Hata Kutusu Test Oluşturma
          </h5>
        </div>
        <div className="modal-body p-4">
          {/* <form onSubmit={this.handleSubmit}> */}

          <div>
            <p className="fs-16 font-weight-600 color-484848">
              Kaç soru çözmek istersin?
            </p>
            <NumericInput
              className="form-control"
              // onChange={(e) => { this.handleChange(e) }}
              maxValue={this.props.falseAndEmptyCount}
              onChange={(value) => this.handleChange(value)}
              defaultValue={this.state.soruSayisi}
              id="inputCount"
              ref={this.soru}
              style={{ width: 256 }}
              statsErrorBox
            />
            {/* <input
              className="form-control"
              // onChange={(e) => { this.handleChange(e) }}
              maxValue={40}
              onChange={(value) => this.handleChange(value)}
              defaultValue={this.state.soruSayisi}
              id="inputCount"
              ref={this.soru}
              style={{ width: 256 }}
            /> */}
          </div>

          <div className="mt-3" style={{ marginBottom: 24 }}>
            <p className="fs-16 color-484848 font-weight-500">Hangi Dersler?</p>
            {this.props.branslar &&
              this.props.branslar
                .filter(
                  (p) => p.tests && p.tests.some((t) => t.toplamYanlis > 0),
                )
                .map((brans, i) => (
                  <button
                    className={classNames(
                      'btn',
                      _includes(this.state.items, brans.bransId)
                        ? 'btn-blue'
                        : 'btn-default',
                      'error-box-lessons mb-2',
                    )}
                    onClick={() => this.toogle(brans.bransId)}
                  >
                    {brans.bransAd}{' '}
                    {this.props.errors &&
                      this.props.errors.find(
                        (e) => e.bransKod === brans.bransId,
                      ) &&
                      this.props.errors.find(
                        (e) => e.bransKod === brans.bransId,
                      ).yanlisSoru}
                    {/* {this.state.quest[brans.bransId] ? `(${this.state.quest[brans.bransId].total})` : ''} */}
                  </button>
                ))}
          </div>
          <div className="d-flex justify-content-between stats-error-container flex-sm-row flex-column">
            <div className="stats-error-box">
              {this.state.konular.map((item, i) => (
                <div
                  className="form-group form-check brand-checkbox"
                  key={item.id + '.' + i}
                >
                  <input
                    type="checkbox"
                    id={i}
                    checked={this.state.checkedSubjects.find(
                      (p) => p.id === item.id && p.bransId == item.bransId,
                    )}
                    onClick={() => this.handleChecked(item)}
                  />
                  <label htmlFor={i} className="m-0">
                    <h3 className="checkbox-label fs-16">
                      {item.title}(
                      {this.state.quest[item.bransId] &&
                      this.state.quest[item.bransId][item.id]
                        ? this.state.quest[item.bransId][item.id]
                        : '0'}
                      )
                    </h3>
                  </label>
                </div>
              ))}

              {/* {this.state.konular.map((konu, i) => (
                            <div className="form-group form-check brand-checkbox">
                                <input type="checkbox" id={konu.testKonu} />
                                <label htmlFor={konu.testKonu} className="m-0">
                                    <h3 className="checkbox-label fs-16">{konu.testKonuAd} (0)</h3>
                                </label>
                            </div>
                        ))} */}
            </div>
            <div className="text-right d-flex flex-column-reverse">
              <button
                type="button"
                className="btn btn-primary text-capitalize border-radius-8 fs-14 font-weight-500 px-4 py-2 m-3"
                onClick={this.handeTestOlusturClick}
              >
                Test Oluştur
              </button>
            </div>
          </div>
          {/* </form> */}
        </div>
      </Modal>
    );
  }
}

StatsErrorBox.propTypes = {};
export default StatsErrorBox;

// const mapStateToProps = ({ ChaptersReducer }) => ({
//     tests: ChaptersReducer.tests

// });

// const mapDispatchToProps = {
//     getBransTest: actions.getBransTest,
// };

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(StatsErrorBox);
