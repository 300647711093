import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { paths } from '../../variables/MapPaths';
import { Modal, CitySelect, TownSelect, RatingSelect } from '../../components';
import CloseButton from '../../components/Button/Close';
import * as actions from '../../routes/actions/Store';
import { getTowns, loginRequired } from '../../routes/actions/Account';
import { getStoreReviews } from '../../routes/actions/Product';
import ReviewForm from './ReviewForm';
import Pagination from '../../components/Pagination/Pagination';
import { formatDate as dateFormatter, customStyles } from '../../helpers';
import Select from 'react-select';

const AdreseTeslimOptions = [
  {
    value: -1,
    label: 'Hepsi',
  },
  {
    value: 1,
    label: 'Evet',
  },
  {
    value: 0,
    label: 'Hayır',
  },
];

const ModalContent = ({ addReview, storeId, onCloseClick, ...props }) => (
  <Modal {...props} className="stores-comment-modal modal-container">
    <div className="modal-header">
      <h5>Yorum Yap</h5>
      <CloseButton onClick={onCloseClick} />
    </div>
    <div className="modal-body mt-3">
      <ReviewForm
        addReview={addReview}
        storeId={storeId}
        onCloseClick={onCloseClick}
      />
    </div>
  </Modal>
);

class Stores extends Component {
  constructor() {
    super();

    this.state = {
      selected: 34,
      selectedTown: null,
      show: false,
      commentSuccess: false,
      storeId: null,
      activeStore: {},
      showStoreComments: false,
      pageOfItems: [],
      AdreseTeslim: -1,
    };
  }

  componentDidMount() {
    this.props.getStoresByCityId(this.state.selected);
  }

  componentWillMount() {}

  showCommentsModal = async (event, store) => {
    event.preventDefault();

    if (store.commentsCount) {
      await this.props.getStoreReviews(store.id);
      this.setState({ showStoreComments: true, activeStore: store });
    }
  };

  closeCommentsModal = () => {
    this.setState({ showStoreComments: false });
  };

  formatDate(date) {
    return date ? dateFormatter.dateLong(new Date(date)) : null;
  }

  handleClick = ({ target }) => {
    if (target.tagName === 'path') {
      const value = parseInt(target.parentNode.getAttribute('data-code'));
      this.handleChange({ value });
    }
  };

  handleChange = async (city) => {
    await this.props.getStoresByCityId(city.value);
    this.props.getTowns(city.value);

    this.setState({ selected: city.value });
  };

  onChangePage = (pageOfItems) => {
    this.setState({ pageOfItems: pageOfItems });
  };

  onAdreseTeslimChange = (option) => {
    this.setState({ AdreseTeslim: option.value });
  };

  handleTownChange = async (town) => {
    await this.props.getStoresByTownId(town.value);
    this.setState({ selectedTown: town.value });
  };

  showReviewForm = async (storeId) => {
    if (!this.props.user.id) {
      this.props.loginRequired(true);

      return;
    }
    const { success, error } = await this.props.checkReview(storeId);
    if (success) {
      toast.error(error);
      return;
    }

    this.setState({ show: true, storeId });
  };

  addReview = async (review) => {
    const { success, error } = await this.props.addReview(review);

    if (success) {
      toast.success(
        'Yorumun alındı. Kontrollerimiz sonrasında sitemizde gösterilip Puan ve Jeton hesabına yüklenecektir. Yorumun için teşekkürler.',
      );
    } else {
      if (error && error.length) {
        toast.error(error);
      }
    }
  };

  render() {
    const code = `0${this.state.selected}`.slice(-2);
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12 p-xs-0">
              <h1 className="fs-20 font-weight-500 mt-2 color-484848">
                Satış Noktalarımız
              </h1>
            </div>
          </div>
          <div className="card p-2 p-md-4 mt-4">
            <div className="row">
              <div className="col-12 hidden-md">
                <svg
                  version="1.1"
                  viewBox="0 120 1050 500"
                  style={{ cursor: 'pointer', fill: '#D8D8D8' }}
                  onMouseOver={this.handleMouseOver}
                  onClick={this.handleClick}
                >
                  <g id="turkiye">
                    {paths.map((path) => (
                      <g
                        key={path.name}
                        data-code={path.county}
                        data-name={path.name}
                      >
                        <path
                          d={path.path}
                          style={{
                            fill: path.county === code ? '#FFA500' : '#D8D8D8',
                          }}
                        />
                        {path.county === code && (
                          <text
                            x={path.textX || 500}
                            y={path.textY || 140}
                            fontSize="20"
                            fontWeight="500"
                            fill="#000000"
                            textAnchor="middle"
                          >
                            {path.name}
                          </text>
                        )}
                      </g>
                    ))}
                  </g>
                </svg>
                <hr />
              </div>
              <div className="col-12 p-xs-0">
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputCity">İl</label>
                      <CitySelect
                        value={this.state.selected}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputState">İlçe</label>
                      <TownSelect
                        value={this.state.selectedTown}
                        onChange={this.handleTownChange}
                        cityId={this.state.selected}
                      />
                    </div>
                    {/* <div className="form-group col-md-4">
                      <label htmlFor="inputState">Eve Teslim Hizmeti</label>
                      <Select
                        isSearchable={false}
                        options={AdreseTeslimOptions}
                        styles={customStyles}
                        onChange={this.onAdreseTeslimChange}
                        defaultValue={AdreseTeslimOptions[0]}
                        className="w-200"
                      />
                    </div> */}
                  </div>
                </form>
              </div>
              <div className="col-12 p-xs-0">
                <div className="b-dedede border-radius-8 p-0 p-md-3">
                  <div>
                    {this.props.stores.length === 0 && !this.props.isLoaded && (
                      <p className="text-center">Yükleniyor...</p>
                    )}

                    {this.props.stores.filter(
                      (p) =>
                        this.state.AdreseTeslim == -1 ||
                        (this.state.AdreseTeslim == 1
                          ? p.adreseTeslimHizmetiVar == true
                          : p.adreseTeslimHizmetiVar == false),
                    ).length === 0 && this.props.isLoaded ? (
                      <p className="text-center">
                        Üzgünüz aradığınız yerde henüz satış noktamız yok.
                      </p>
                    ) : (
                      <>
                        {this.state.pageOfItems
                          .filter(
                            (p) =>
                              this.state.AdreseTeslim == -1 ||
                              (this.state.AdreseTeslim == 1
                                ? p.adreseTeslimHizmetiVar == true
                                : p.adreseTeslimHizmetiVar == false),
                          )
                          .map((store, i) => {
                            return (
                              <div
                                className="fs-14 font-weight-500 stores-item"
                                key={i}
                              >
                                <p className="mb-2">{store.name}</p>
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                                  <p className="mb-1 color-919191 flex-1">
                                    {store.address}
                                  </p>
                                  <div className="text-md-right d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between">
                                    <a href={`tel:+9${store.phone}`}>
                                      {store.phone}
                                    </a>
                                    <RatingSelect
                                      className="ml-0 ml-sm-2 mt-1 mt-sm-0"
                                      readonly={true}
                                      initialRating={store.rating / 10}
                                    />
                                    <span
                                      className="text-nowrap ml-0 ml-sm-2 mt-1 mt-sm-0 cursor-pointer"
                                      onClick={(event) =>
                                        this.showCommentsModal(event, store)
                                      }
                                    >
                                      {store.commentsCount} yorum
                                    </span>
                                    <button
                                      key={i}
                                      onClick={() =>
                                        this.showReviewForm(store.id)
                                      }
                                      className="btn btn-primary stores-comment-btn ml-0 ml-sm-2 mt-1 mt-sm-0"
                                    >
                                      Yorum Yap
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        <Pagination
                          items={this.props.stores.filter(
                            (p) =>
                              this.state.AdreseTeslim == -1 ||
                              (this.state.AdreseTeslim == 1
                                ? p.adreseTeslimHizmetiVar == true
                                : p.adreseTeslimHizmetiVar == false),
                          )}
                          storesCount={
                            this.props.stores.filter(
                              (p) =>
                                this.state.AdreseTeslim == -1 ||
                                (this.state.AdreseTeslim == 1
                                  ? p.adreseTeslimHizmetiVar == true
                                  : p.adreseTeslimHizmetiVar == false),
                            ).length
                          }
                          onChangePage={this.onChangePage}
                          key={
                            this.props.stores.length +
                            '' +
                            this.state.AdreseTeslim
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
                <ModalContent
                  show={this.state.show}
                  storeId={this.state.storeId}
                  addReview={this.addReview}
                  onCloseClick={() => {
                    this.setState({ show: false, storeId: null });
                  }}
                  dialogClassName="modal-lg"
                />
                <Modal
                  show={this.state.showStoreComments}
                  onCloseClick={this.closeCommentsModal}
                  dialogClassName="modal-lg"
                >
                  <div className="modal-container">
                    <div className="modal-header">
                      <CloseButton onClick={this.closeCommentsModal} />
                      <h5>
                        {this.state.activeStore.name}{' '}
                        <span className="text-muted">
                          ({this.state.activeStore.commentsCount} yorum)
                        </span>
                      </h5>
                    </div>
                    <div className="modal-body">
                      <ul className="list-group list-group-flush comment-list">
                        {this.props.storeReviews.map((comment) => (
                          <>
                            <li
                              className="list-group-item comment-item border-none p-0 mt-2"
                              key={comment.id}
                            >
                              <div>
                                <figure className="figure mr-2 float-left">
                                  <div className="default-avatar">
                                    {comment.name
                                      ? comment.name.charAt(0).toUpperCase() +
                                        comment.name
                                          .split(' ')
                                          .slice(-1)[0]
                                          .charAt(0)
                                          .toUpperCase()
                                      : 'DK'}
                                  </div>
                                </figure>
                                <span>
                                  <span className="comment-user-name mt-0">
                                    {comment.name}
                                  </span>
                                  <br />
                                  <span className="time">
                                    {this.formatDate(comment.insertDate)}
                                  </span>
                                </span>
                              </div>
                              <div>
                                <h2 className="rating-component mb-0">
                                  <RatingSelect
                                    initialRating={Math.round(
                                      (comment.proximityReview +
                                        comment.findingProductReview +
                                        comment.genialityReview) /
                                        10 /
                                        3,
                                    )}
                                    readonly
                                  />
                                </h2>
                                <p className="comment">{comment.comment}</p>
                              </div>
                            </li>
                          </>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Stores.propTypes = {};

const mapStateToProps = ({ AccountReducer, ProductReducer, StoreReducer }) => ({
  user: AccountReducer.user,
  stores: StoreReducer.stores,
  storesCount: StoreReducer.storeCount,
  isLoaded: StoreReducer.isLoaded,
  storeReviews: ProductReducer.storeReviews,
});

const mapDispatchToProps = {
  getStoresByCityId: actions.getStoresByCityId,
  getStoresByTownId: actions.getStoresByTownId,
  getTowns: getTowns,
  loginRequired: loginRequired,
  addReview: actions.addReview,
  checkReview: actions.checkReview,
  getStoreReviews,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stores);
