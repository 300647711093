import React from 'react';
import Icon from '../../components/Icon/Index';
import { deleteAllItemInBasket } from '../../routes/actions/Product';

export default function RemoveModal({
  show,
  closeRemoveModal,
  deleteProductToBasket,
  selectedProduct,
  selectedProductName,
  addBuyLaterProduct,
}) {
  function deleteItem(selectedProduct) {
    deleteProductToBasket(selectedProduct);
    closeRemoveModal();
  }

  return (
    <div className={`remove-modal ${show ? 'd-flex' : 'd-none'}`}>
      <div className="modal-box">
        <button className="close-btn btn" onClick={() => closeRemoveModal()}>
          <Icon icon="iClose" />
        </button>
        <span />
        <div className="icon-container">
          <div className="sub-icon">
            <Icon icon="iSave" />
          </div>
          <Icon icon="iBasket3" />
        </div>
        <h2>Kaldır ama Listeme Ekle</h2>
        <div className="modal-text">
          <strong>{selectedProductName}</strong> adlı ürünü sepetinden
          çıkardıktan sonra “Daha Sonra Alacağım” listene eklemek ister misin?
        </div>
        <div className="d-flex w-100">
          <button
            className="btn btn-item d-none d-sm-block "
            style={{ marginRight: 16 }}
            onClick={() => closeRemoveModal()}
          >
            Vazgeç
          </button>
          <button
            className="btn btn-item"
            onClick={() => deleteItem(selectedProduct)}
          >
            Sil
          </button>
          <button
            className="btn btn-remove"
            onClick={() => addBuyLaterProduct(selectedProduct)}
          >
            Kaldır ama Listeme Ekle
          </button>
        </div>
      </div>
      <div
        className="modal-backdrop"
        style={{ opacity: 0.4 }}
        onClick={() => closeRemoveModal()}
      ></div>
    </div>
  );
}
