import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb } from '../../components';
import classNames from 'classnames';
import * as actions from '../../routes/actions/Desk/Chapters';
import { connect } from 'react-redux';
import Icon from '../../components/Icon/Index';
import queryString from 'query-string';
import 'react-popper-tooltip/dist/styles.css';
import { Tooltip } from '../../components';

class Chapters extends Component {
  state = {
    accordionSelected: 0,
    selectedSubject: {
      title: '',
    },
    title: '',
    showOnlyNotFinished: false,
    selectedTests: null,
    breadcrumbs: [],
    unites: [],
    showAll: false,
  };

  async componentDidMount() {
    const search = queryString.parse(this.props.location.search);
    const uniteId = search.uniteId || null;
    const showAll = search.showAll || false;

    var bransKod = this.props.match.params.bransKod;

    await this.props.getBooksBransTest(bransKod);

    let breadcrumbs = [
      {
        title: 'Anasayfa',
        url: '/dijitalkitap',
      },
      {
        title: this.props.bransAd,
        url: '/dijitalkitap/profile/lesson/' + bransKod,
      },
      {
        title: 'Testler',
        active: true,
      },
    ];

    if (this.props.match) {
      const unites =
        uniteId && !showAll
          ? this.props.unites.filter((p) => p.id == uniteId)
          : this.props.unites;

      const selectedTests = uniteId
        ? this.props.tests.filter((p) => p.testUnite == uniteId)
        : this.props.tests;

      var accordionSelected = uniteId
        ? unites.findIndex((p) => p.id == uniteId)
        : 0;
      var title = uniteId ? unites[accordionSelected].title : '';

      this.setState({
        selectedTests,
        breadcrumbs,
        uniteId,
        showAll,
        unites,
        accordionSelected,
        title,
      });
    }
  }

  handleChecked = () => {
    this.setState({
      showOnlyNotFinished: !this.state.showOnlyNotFinished,
    });
  };

  handleSubjectClick = (subject) => {
    let subjTests = this.props.tests.filter((t) => t.testKonu == subject.id);
    this.setState({
      selectedSubject: subject,
      title: subject.title,
      selectedTests: subjTests,
    });
  };

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <Breadcrumb items={this.state.breadcrumbs} />
        </div>
        <div className="col-12 col-lg-4 col-md-4 chapter-branch col-sm-12 mb-3 p-xs-0">
          <h1 className="mb-3 color-484848"> {this.props.bransAd}</h1>

          <div
            className="card accordion accordion-container chapter-acordion"
            id="accordionSubjects"
          >
            {this.state.unites.map((unite, index) => (
              <div
                className="card"
                style={{ borderBottom: '1px solid #616161' }}
                key={unite.id}
              >
                <div
                  className="card-header bg-transparent"
                  id={`heading${index}`}
                >
                  <h2 className="mb-0 w-100 d-flex justify-content-between">
                    <button
                      className={classNames(
                        'btn btn-link w-100 fs-16 font-weight-600 color-484848 d-flex align-items-center',
                        { collapsed: this.state.accordionSelected !== index },
                      )}
                      type="button"
                      onClick={() => {
                        this.setState({ accordionSelected: index });
                      }}
                      data-toggle="collapse"
                      data-target={`#collapse${index}`}
                      aria-expanded="true"
                      aria-controls={`#collapse${index}`}
                    >
                      {unite.title}
                      <Icon
                        className="ml-2"
                        icon="iChevronBottomNew"
                        style={{
                          transform:
                            this.state.accordionSelected === index &&
                            'rotate(180deg)',
                        }}
                      />
                    </button>
                    <span className="float-right py-2 fs-18 font-weight-600 color-919191">
                      %
                      {unite.toplam > 0
                        ? Math.round(
                            ((unite.toplamDogru +
                              unite.toplamYanlis +
                              unite.toplamBos) /
                              unite.testSoruSayisi) *
                              100,
                          )
                        : 0}
                    </span>
                  </h2>
                </div>

                <div
                  id={`collapse${index}`}
                  className={classNames('collapse', {
                    show: this.state.accordionSelected === index,
                  })}
                  aria-labelledby={`heading${index}`}
                  data-parent="#accordionSubjects"
                >
                  <div className="card-body p-0">
                    <ul className="list-group list-group-flush">
                      {unite.subjects.map((subSubject, subIndex) => (
                        <li
                          key={subIndex}
                          className={classNames(
                            'list-group-item',
                            'pl-4',
                            'fs-14',
                            'font-weight-600',
                            'pr-0',
                            {
                              'li-selected':
                                this.state.selectedSubject.id === subSubject.id,
                            },
                          )}
                          // className="list-group-item pl-4 fs-14"
                          onClick={() => this.handleSubjectClick(subSubject)}
                          key={subSubject.id}
                        >
                          {/* <div style={{ fontWeight: this.state.selectedSubject.id === subSubject.id ? 'bold' : 'normal' }}> </div> */}
                          {subSubject.title}
                          <span className="float-right font-weight-500 color-919191">
                            %
                            {subSubject.toplam > 0
                              ? Math.round(
                                  ((subSubject.toplamDogru +
                                    subSubject.toplamYanlis +
                                    subSubject.toplamBos) /
                                    subSubject.testSoruSayisi) *
                                    100,
                                )
                              : 0}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 col-lg-8 col-md-8 col-sm-12 p-xs-0">
          <div className="d-flex align-items-center mb-3 chapter-list-header">
            <div className="d-flex justify-content-start flex-1">
              <h1 className="color-484848 font-weight-600">
                {this.state.title}
              </h1>
            </div>
            <div className="d-flex justify-content-end flex-1">
              <div className="form-group form-check float-right brand-checkbox chapter-checkbox">
                <input
                  type="checkbox"
                  id="taxon-101"
                  name="YGS"
                  value="101"
                  onClick={this.handleChecked}
                />
                <label
                  htmlFor="taxon-101"
                  className="d-flex flex-column ml-2 mb-0"
                >
                  <h5 className="checkbox-label color-919191 font-weight-600 fs-12">
                    Sadece Bitirmediklerimi Göster
                  </h5>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            {!this.state.selectedTests ? (
              <div>{/* Konu seçiniz.. */}</div>
            ) : (
              this.state.selectedTests.map(
                (test, index) =>
                  (!this.state.showOnlyNotFinished || !test.testCozum) && (
                    <div
                      className="col-6 col-lg-4 col-md-4 col-sm-6 p-xs-5"
                      key={index}
                    >
                      <div className="card chapter-test-item">
                        <Link
                          to={
                            test.testCozum
                              ? `/dijitalkitap/book/lesson/subject/quiz/summary/${test.testID}`
                              : `/dijitalkitap/book/lesson/subject/quiz/${test.testID}`
                          }
                        >
                          <img
                            src={require('../../assets/images/cevap-anahtari.jpg')}
                            className="card-img-top chapter-test-image"
                            alt="..."
                          />

                          <img
                            className="book_image"
                            src={test.kitapKapak}
                            alt=""
                          />

                          <div className="card-body">
                            <h5 className="card-title mt-3 mb-4">
                              {test.yayinAd}
                            </h5>

                            <div className="d-flex flex-row justify-content-between">
                              <div className="flex-1 w-50">
                                <Tooltip
                                  placement="top"
                                  trigger="hover"
                                  tooltip={test.testAdi}
                                >
                                  <p className="m-0 text-truncate fs-14">
                                    {test.testAdi}
                                  </p>
                                </Tooltip>
                                <p className="card-text">
                                  <span className="page-range fs-14">{`Sayfa ${test.baslangicSayfa}-${test.bitisSayfa}`}</span>
                                </p>
                              </div>
                              <div className="flex-1">
                                <img
                                  className="icon float-right"
                                  src={
                                    !test.testCozum
                                      ? require('../../assets/images/question.svg')
                                      : test.net / test.testSoruSayisi > 0.7
                                        ? require('../../assets/images/emoji_1.svg')
                                        : require('../../assets/images/emoji_2.svg')
                                  }
                                  alt="..."
                                />
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ),
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

Chapters.propTypes = {};

const mapStateToProps = ({ ChaptersReducer }) => ({
  unites: ChaptersReducer.unites,
  tests: ChaptersReducer.tests,
  bransAd: ChaptersReducer.bransAd,
});

const mapDispatchToProps = {
  getBooksBransTest: actions.getBooksBransTest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapters);
