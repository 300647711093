import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { Modal } from '..';
import { toast } from 'react-toastify';
import CloseButton from '../Button/Close';

class Masterpass3D extends Component {
  constructor(props) {
    super(props);
    this.frame = React.createRef();
  }

  async componentDidMount() {
    this.frame.current.setAttribute('src', this.props.URL3D);
    window.addEventListener('message', this.handleFrameMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleFrameMessage);
  }

  handleFrameMessage = ({ data }) => {
    if (data.source === 'payment-frame-load') {
      return;
    } else if (data.source === 'payment-frame-result') {
      if (data.payload.result === 'True') {
        this.props.onSuccess(data.payload.token, !!data.payload.save);
        this.props.closeImplicitly();
      } else {
        toast.error('3D doğrulaması yapılamadı.');
        this.props.closeExplicitly();
      }
    }
    this.setState({ show: false, paying: false });
  };

  render() {
    const { show, closeExplicitly } = this.props;
    return (
      <Modal
        className="modal-container secure3d-modal non-modal-height"
        show={show}
      >
        <div className="modal-header bb-0">
          <CloseButton onClick={closeExplicitly} />
        </div>
        <div className="modal-body text-center">
          <iframe title="3D Secure" className="w-100" ref={this.frame}></iframe>
        </div>
      </Modal>
    );
  }
}

Masterpass3D.propTypes = {};

export default Masterpass3D;
