import React from 'react';
//import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';

const placeholder = 'Buraya metin ekleyin...';
const TextView = ({ text }) => {
  const content = { __html: text ? draftToHtml(text) : placeholder };
  return (
    <div className="card w-100" style={{ background: '#e6eded' }}>
      <div className="card-body banner4" dangerouslySetInnerHTML={content} />
    </div>
  );
};

TextView.propTypes = {};

export default TextView;
