import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Breadcrumb, ScoreBadge } from '../../components';
import Countdown from 'react-countdown-now';
import {
  //GoogleMapsImage,
  GoogleMapsSearch,
} from '../../services/constants';
import {
  cancelReservation,
  getActivityTypes,
  getNotifications,
} from '../../routes/actions/Product';

const Completionist = () => <span>Rezervasyon zaman aşımına uğradı.</span>;

const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <span>
        {hours} Saat {minutes} Dakika {seconds} Saniye
      </span>
    );
  }
};

const StepNumber = ({ value }) => (
  <svg
    className="bd-placeholder-img rounded-circle mr-3"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid slice"
    focusable="false"
    role="img"
    style={{ minWidth: 48 }}
  >
    <title>Completely round image</title>
    <rect width="100%" height="100%" fill="#868e96" />
    <text x="45%" y="50%" fill="#dee2e6" dy=".3em">
      {value}
    </text>
  </svg>
);

const breadcrumbs = [
  {
    title: 'Anasayfa',
    url: '/',
  },
  {
    title: 'Rezervasyon Onay',
    active: true,
  },
];

class Reservation extends Component {
  constructor(props) {
    super(props);

    if (!this.props.reservation.id) {
      this.props.history.push('/');
    }
  }

  componentDidMount() {
    this.props.getNotifications();
    this.props.getActivityTypes();
  }

  handleCancelClick = () => {
    this.props.history.push('/hesabim/rezervasyonlarim');
    toast.success('Reservasyon iptal edilmiştir.');
  };

  cancelReservation = async (e) => {
    e.preventDefault();

    const confirmed = window.confirm(
      `${this.props.reservation.storeName} - ${this.props.reservation.id} numaralı rezervasyonunuz iptal edilecek onaylıyor musunuz?`,
    );

    if (confirmed) {
      const { success, error } = await this.props.cancelReservation(
        this.props.reservation,
      );

      if (success) {
        toast.success('Reservasyon iptal edilmiştir.');
        this.props.history.push('/hesabim/rezervasyonlarim');
      } else {
        toast.error(error);
      }
    }
  };

  render() {
    const reservation = this.props.reservation;
    const totalPrice = reservation.products.reduce(
      (p, c) => p + c.price * c.quantity,
      0,
    );
    var activity = { Score: 0, Coins: 0 };
    if (this.props.activityTypes && this.props.activityTypes.length > 0) {
      activity = this.props.activityTypes.find(
        (x) => x.Name === 'complete-reservation',
      );
    }
    const coins = ((totalPrice * activity.Coins) / 100).toFixed(0);
    return (
      <div className="row">
        <div className="col-12">
          <Breadcrumb items={breadcrumbs} />
        </div>
        <div className="col-xl-12 d-flex flex-wrap justify-content-between mb-2">
          <h1>Rezervasyonunuz alınmıştır.</h1>
        </div>

        <div className="col-12">
          <div className="card card-list-group reservation-list mb-2 p-3">
            <div className="list-group-item border-0 bt-dedede:50">
              <div className="d-flex flex-row">
                <StepNumber value="1" />
                <div className="flex-grow-1">
                  <h5 className="fs-15 font-weight-600 mt-1 color-484848">
                    Kitapçıya Git
                  </h5>
                  <p className="mt-4 p-0 fs-14 font-weight-bold color-484848">
                    {reservation.storeName}
                  </p>
                  <p className="fs-13 p-0 mt-2 mb-0 color-919191">
                    {`${reservation.storeAddress.lines} ${reservation.storeAddress.town.name}/${reservation.storeAddress.city.name}`}
                  </p>
                  <p className="fs-13 p-0 m-0 color-919191">
                    {reservation.storeAddress.mobile}
                  </p>
                </div>

                <a
                  href={GoogleMapsSearch(reservation.storeAddress.lines)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <img
                    className="card-img"
                    src={GoogleMapsImage(
                      reservation.storeAddress.lines,
                      "240x180",
                      15
                    )}
                    alt="..."
                    style={{ maxWidth: 240 }}
                  /> */}
                </a>
              </div>
            </div>

            <div className="list-group-item border-0 bt-dedede:50">
              <div className="d-flex flex-row">
                <StepNumber value="2" />
                <div className="flex-grow-1">
                  <h5 className="fs-15 font-weight-600 mt-1 color-484848">
                    #{reservation.id} Rezervasyon numarasını kitapçıya göster.
                  </h5>
                </div>
              </div>
            </div>

            <div className="list-group-item border-0 bt-dedede:50">
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <StepNumber value="3" />
                  <h5 className="fs-15 font-weight-600 mt-1 color-484848">
                    Kitapları Al
                  </h5>
                </div>
                <div className="flex-grow-1 pl-0 pl-md-5">
                  <table className="table table-borderless fs-13 color-919191 w-sm-100 w-md-75">
                    <tbody>
                      {reservation.products.map((product) => (
                        <tr key={product.id}>
                          <td>{product.name}</td>
                          <td>{product.quantity} Adet</td>
                          <td className="text-right">{product.price} ₺</td>
                        </tr>
                      ))}
                      <tr style={{ borderTop: '1px solid #dedede' }}>
                        <td />
                        <td />
                        <td className="text-right text-primary">
                          Toplam : {totalPrice.toFixed(2)}₺
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="list-group-item border-0 bt-dedede:50">
              <div className="d-flex flex-row">
                <StepNumber value="4" />
                <div className="flex-grow-1">
                  <h5 className="fs-15 font-weight-600 mt-1 color-484848">
                    Kitap Aktivasyonunu Yap
                  </h5>
                  <p className="fs-14 color-919191 font-weight-500">
                    Aktivasyonu yaptığında puan ve jeton kazanacaksınız.
                  </p>

                  <div className="d-flex flex-column flex-sm-row">
                    <h3 title="Kitap aktivasyonu yaptığında +1000PUAN ve +3JETON kazanacaksınız.">
                      <ScoreBadge
                        value={activity.Score}
                        icon="point"
                        title={`+${activity.Score} Puan`}
                        className="badge-score mr-2"
                      />
                      <ScoreBadge
                        value={coins}
                        icon="coin"
                        title={`+${coins} Jeton`}
                        className="badge-coin"
                      />
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="list-group-item border-0 d-flex align-items-center justify-content-between">
              <button
                type="button"
                className="btn btn-danger text-capitalize fs-14 border-radius-8 px-5 py-2"
                onClick={this.cancelReservation}
                style={{ minWidth: 170 }}
              >
                İptal Et
              </button>

              <div
                className="alert float-right fs-18 mb-0 font-weight-600"
                title="Rezervasyonun birmesine 8 saat 25 dakika kaldı."
              >
                <span className="color-919191">Kalan Süre:</span>{' '}
                <Countdown
                  date={reservation.expirationDate}
                  renderer={countdownRenderer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Reservation.propTypes = {};

const mapStateToProps = ({ AccountReducer, ProductReducer }) => ({
  activityTypes: ProductReducer.activityTypes,
  user: AccountReducer.user,
  reservation: ProductReducer.reservation,
});

const mapDispatchToProps = {
  cancelReservation,
  getNotifications,
  getActivityTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservation);
