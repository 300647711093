import React from 'react';
//import PropTypes from "prop-types";
import Select from 'react-select';
import { customStyles } from '../../helpers';
import { creditCartMonths, creditCartYears } from '../../services/constants';

const ExpireDate = ({ onChange, value }) => {
  const seperator = '/';
  const values = value.split(seperator);
  return (
    <div className="d-flex expire-date">
      <Select
        isSearchable={false}
        placeholder="Ay"
        options={creditCartMonths}
        styles={customStyles}
        value={creditCartMonths.find((x) => x.value === values[0]) || ''}
        className="w-50 mr-1 date-item"
        onChange={(e) => onChange(`${e.value}${seperator}${values[1]}`)}
      />
      <Select
        isSearchable={false}
        placeholder="Yıl"
        options={creditCartYears}
        value={creditCartYears.find((x) => x.value === values[1]) || ''}
        styles={customStyles}
        className="w-50 date-item"
        onChange={(e) => onChange(`${values[0]}${seperator}${e.value}`)}
      />
    </div>
  );
};

ExpireDate.propTypes = {};

export default ExpireDate;
