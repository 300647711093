import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../routes/actions/Study';
import { Modal, RadialChart, ProgressCard } from '../../components';
import { ScoreBadge, InformationModal } from '../../components';
import lessonIcons from '../../assets/images/dersler';
import CloseButton from '../../components/Button/Close';
import FormSchemas from '../../variables/FormSchemas';
import { BaseForm, Tooltip, SuccessModal } from '../../components';
import * as accountActions from '../../routes/actions/Account';
// import TatsBanner from "./TatsBanner";
import AvatarModal from './AvatarModal';
import Icon from '../../components/Icon/Index';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

const calculateAge = (birthday) => {
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      showModal: false,
      class: { value: '0', label: 'Tüm Sınıflar', classId: 0 },
      bookName: '',
      showSuccess: false,
      aktivasyonAy: null,
      avatars: [],
      showAvatarSelect: false,
      showBirthSelect: false,
      showFullBannerDetail: false,
    };

    const { groupId } = this.props.match.params;

    this.groupId = groupId;

    this.activationKey = React.createRef();
  }

  componentDidMount() {
    if (this.groupId) {
      this.props.getGroupBooks(this.groupId);
    } else {
      this.props.getPurchasedBooks();
    }
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  classFilter = (option) => {
    this.setState({ class: option });
  };

  closeSuccessModal = () => {
    this.setState({ showSuccess: false });
  };

  success = () => {
    this.setState({ showSuccess: true });
  };

  componentDidMount() {
    this.props.getAvatars();

    this.props.fetchDesk();
    if (
      this.props.user &&
      this.props.user.id &&
      (!this.props.userBadges || this.props.userBadges.length === 0)
    )
      this.props.getUserBadges();
    //this.props.getErrorBox();
    // this.props.getUserBranches(); //bu data getscores datasının içerisinde birleştirilmiş olarak geliyor.
  }

  selectAvatar = (e) => {
    e.preventDefault();

    const birthdate = this.props.user.birthdate;
    const classId = this.props.user.classId;
    let ageFilter = [];
    let avatars = [];

    if (birthdate) {
      const dates = birthdate.split('/');
      const age = calculateAge(new Date(dates[2], dates[1] - 1, dates[0]));

      if (age <= 10) {
        //8-10 yaş
        ageFilter = [8, 9, 10];
      } else if (age >= 11 && age <= 14) {
        //11-14 yaş
        ageFilter = [11, 12, 13, 14];
      } else if (age >= 15) {
        //15-20 yaş
        ageFilter = [15, 16, 17, 18, 19, 20];
      }
    } else if (classId) {
      const classes = [
        { value: 3, classId: 3 },
        { value: 4, classId: 4 },
        { value: 5, classId: 5 },
        { value: 6, classId: 6 },
        { value: 7, classId: 7 },
        { value: 8, classId: 8 },
        { value: 9, classId: 9 },
        { value: 10, classId: 10 },
        { value: 11, classId: 11 },
        { value: 12, classId: 12 },
      ];

      const userClass = classes.find((c) => c.classId === classId);

      if (userClass && userClass.value <= 5) {
        ageFilter = [8, 9, 10];
      } else if (userClass && userClass.value >= 6 && userClass.value <= 8) {
        ageFilter = [11, 12, 13, 14];
      } else if (userClass && userClass.value >= 9) {
        ageFilter = [15, 16, 17, 18, 19, 20];
      }
    } else {
      return this.setState({
        avatars: [],
        showBirthSelect: true,
        showAvatarSelect: false,
      });
    }

    avatars = ageFilter.length
      ? this.props.avatars.filter((avatar) =>
          avatar.ages.every((a) => ageFilter.includes(a)),
        )
      : this.props.avatars;
    const userGender = this.props.user.gender;
    const genderFilter = userGender === 'K' || userGender === 'E';

    this.setState({
      avatars: genderFilter
        ? avatars.filter((avatar) => avatar.categories.includes(userGender))
        : avatars,
      showBirthSelect: false,
      showAvatarSelect: true,
    });
  };

  updateAvatar = async (e, a) => {
    e.preventDefault();

    await this.saveUser({ avatar: a });
    this.setState({
      showAvatarSelect: false,
    });
  };

  updateUserProfile = async (data) => {
    await this.saveUser(data);
    this.selectAvatar();
  };

  login = () => {
    this.props.loginRequired(true);
  };

  saveUser = async (data) => {
    let user = { ...this.props.user };
    user.tckn = user.tckn === '0' ? '' : user.tckn;
    user = Object.assign({}, user, { ...data });
    await this.props.updateUserProfile(user);
  };

  getLessonLevels = () => {
    const { scores, userBadges } = this.props;
    const lessonLevels = [];
    if (scores) {
      for (let i = 0; i < scores.length; i++) {
        const score = scores[i];
        if (score.Branch) {
          let lessonLevel = {};
          lessonLevel.title = score.Branch.Name;
          lessonLevel.prefix = lessonLevel.title.charAt(0).toUpperCase();
          lessonLevel.score = score.Score.CurrentScore;
          lessonLevel.level = score.Score.CurrentLevel;
          lessonLevel.totalScore =
            score.Score.CurrentScore + score.Score.DueScore;
          lessonLevel.badgeCount =
            userBadges && Array.isArray(userBadges)
              ? userBadges.filter((bdg) => bdg.Branch.Id == score.Branch.Id)
                  .length
              : 0;
          lessonLevel.icon =
            lessonIcons[lessonLevel.title.toLowerCase().charAt(0)] ||
            lessonIcons.d;
          lessonLevel.id = score.Branch.Id;
          lessonLevels.push(lessonLevel);
        }
      }
    }
    return lessonLevels;
  };

  globalActivate = (e) => {
    e.preventDefault();
    const key = this.activationKey.current.value;
    this.activateBook(key);
  };

  activateBook = async (key) => {
    if (this.props.user.demo) {
      toast.error(
        'Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor.',
      );
      this.props.loginRequired(true);
      return;
    }
    if (key.length < 6) {
      toast.error('Aktivasyon kodu en az 6 haneli olmalıdır.');
      return;
    }

    this.props
      .activateBook(key)
      .then(async (response) => {
        if (response.data.status === false) {
          toast.error(response.data.errorMessage);
        } else {
          await this.props.getPurchasedBooks();
          this.setState({
            showSuccess: true,
            bookName: response.data.bookName,
            aktivasyonAy: response.data.aktivasyonAy,
          });
          if (this.state.target) {
            this.state.target.value = '';
          }
        }
      })
      .catch((err) => alert(err));
  };

  render() {
    const userClass = this.props.user.classId
      ? { name: `${this.props.user.classId}. Sınıf` }
      : {};
    const mainLevel = (this.props.scores &&
      this.props.scores.find((s) => s.Branch === null)) || {
      Score: { CurrentLevel: 0, CurrentScore: 0, DueScore: 0, TotalScore: 0 },
    };
    const lessonLevels = this.getLessonLevels();
    const badges = this.props.userBadges || [];

    const isProd =
      window.location.origin.indexOf('//tongucmagaza.com') > -1 ||
      window.location.origin.indexOf('//www.tongucmagaza') > -1 ||
      window.location.origin.indexOf('//store.tongucakademi.com') > -1 ||
      window.location.origin.indexOf('//v2.tongucmagaza.com') > -1;

    const BirthAndClass = (props) => (
      <Modal {...props} className="modal-container">
        <div className="modal-header">
          <div className="modal-title fs-20 font-weight-600 color-484848">
            Avatar
          </div>
          <CloseButton onClick={props.onCloseClick} />
        </div>
        <div className="modal-body d-flex flex-wrap">
          <div className="col-12">
            <BaseForm
              schema={FormSchemas.avatar.schema}
              uiSchema={FormSchemas.avatar.uiSchema}
              formData={{}}
              onSubmit={this.updateUserProfile}
              showHeader={false}
            />
          </div>
        </div>
      </Modal>
    );

    const part0 =
      'Tanıtım kullanıcı girişiyle, kendi seviyeni görebileceğin, kitap ';
    const part1 = ' aktivasyonu yapabileceğin, puan, ';
    const part2 = ' rozet ve jeton kazanımları gibi faydalar ';
    const part3 =
      ' sağlayabileceğin ‘’çalışma modu’’nda gezinip fikir sahibi olabilirsin.';
    return (
      <>
        <Helmet>
          <title>Tonguç'la çalış, Başarıya alış!</title>
        </Helmet>
        {this.props.user.demo && (
          <div className="row m-0 p-4 card demo-banner">
            <img
              className="demo-banner-pattern"
              src={require('../../assets/images/images/demo_banner_pattern.png')}
              alt=""
            />
            <div className="col-12">
              <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 d-flex flex-column align-items-xl-baseline align-items-lg-baseline align-items-md-baseline align-items-sm-center">
                  <h1 className="text-center pb-1">
                    Tonguç Mağaza Çalışma Moduna Hoş Geldin.
                  </h1>

                  {/* <p className="demo-banner-p mb-xl-0 mb-lg-0 ml-0 mx-xl-0 mx-lg-0 mx-md-0 mx-sm-5 text-justify text-xl-left text-lg-left">
                                        Tanıtım kullanıcı girişiyle, kendi seviyeni görebileceğin, kitap aktivasyonu yapabileceğin, puan, rozet ve jeton kazanımları gibi faydalar sağlayabileceğin ‘’çalışma modu’’nda gezinip fikir sahibi olabilirsin.
                                        {
                                            (!this.state.showFullBannerDetail) &&
                                            <span className="font-weight-600" onClick={() => this.setState({ showFullBannerDetail: true })}> Devamını oku..</span>
                                        }
                                    </p> */}

                  <p className="demo-banner-p mb-xl-0 mb-lg-0 ml-0 mx-xl-0 mx-lg-0 mx-md-0 mx-sm-5 text-justify text-xl-left text-lg-left">
                    {part0}
                    {(window.innerWidth > 340 ||
                      this.state.showFullBannerDetail) &&
                      part1}
                    {(window.innerWidth > 568 ||
                      this.state.showFullBannerDetail) &&
                      part2}
                    {(window.innerWidth > 768 ||
                      this.state.showFullBannerDetail) &&
                      part3}
                    {window.innerWidth <= 768 &&
                      !this.state.showFullBannerDetail && (
                        <span
                          className="font-weight-600"
                          onClick={() =>
                            this.setState({ showFullBannerDetail: true })
                          }
                        >
                          {' '}
                          Devamını oku..
                        </span>
                      )}
                  </p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex align-items-center justify-content-center">
                  <button
                    onClick={this.login}
                    className="btn btn-link btn-primary text-white text-capitalize demo-banner-btn"
                  >
                    Üye Girişi Yap
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="d-flex align-items-center mt-3">
          <form
            className="input-container bg-f4f4f4 w-sm-100"
            onSubmit={this.globalActivate}
          >
            <input
              type="text"
              className="form-control fs-14"
              placeholder="Aktivasyon Kodunu Gir"
              name="general_activation"
              ref={this.activationKey}
            />
            <button type="submit" className="btn btn-blue w-30 border-radius-8">
              Aktif Et
            </button>
          </form>

          <InformationModal
            show={this.state.showModal}
            onCloseClick={() => this.setState({ showModal: false })}
            list={[
              'kitapaktivasyonu_ADIM1.jpg',
              'kitapaktivasyonu_ADIM2.jpg',
              'kitapaktivasyonu_ADIM3.jpg',
              'kitapaktivasyonu_ADIM4.jpg',
            ]}
          />

          <SuccessModal
            //show={this.state.showSuccess}
            aktivasyonAy={this.state.aktivasyonAy}
            bookName={this.state.bookName}
            show={this.state.showSuccess}
            onCloseClick={this.closeSuccessModal}
          />

          <FontAwesomeIcon
            icon={faQuestionCircle}
            onClick={() => {
              this.setState({ showModal: true });
            }}
            className="ml-2 color-919191"
          />
        </div>

        <div className="row">
          {/* <div className="col-12 p-xs-0">
            <TatsBanner />
          </div> */}
          <div className="col-12 col-lg-6 p-xs-0 mt-3">
            <div className="card h-100 bg-fffbef">
              <div className="card-body tats-profile pt-2 pt-lg-4 pl-0 pr-0 px-0 px-sm-1 px-sm-3 ">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-6 col-sm-6 text-left tats-profile-info">
                      <Link to="/dijitalkitap/profile">
                        <img
                          className="desk-profile-img mt-3"
                          src={
                            this.props.user.avatar
                              ? this.props.user.avatar.path
                              : 'https://cdn.dogrukaynak.com/avatars/default/default.svg'
                          }
                          alt=""
                        />

                        <div className="fs-20 font-weight-600 color-484848 mt-2">
                          {this.props.user.firstname || 'T'}{' '}
                          {this.props.user.lastname || 'M'}
                        </div>
                        <div className="fs-16 color-919191 font-weight-600">
                          {userClass.name}
                        </div>
                      </Link>
                      <button
                        className="btn btn-clear mt-4 p-1 pl-0 box-shadow-none text-blue fs-13 font-weight-600"
                        onClick={this.selectAvatar}
                      >
                        <img
                          src={require('../../assets/images/icons/pencil.svg')}
                          className="mr-2"
                          style={{ height: 16 }}
                          alt=""
                        />
                        DEĞİŞTİR
                      </button>
                    </div>
                    <div className="col-6 col-sm-6  text-center">
                      <Link to="/dijitalkitap/profile">
                        <div>
                          <RadialChart
                            key={mainLevel.Score.CurrentLevel}
                            current={mainLevel.Score.CurrentScore}
                            max={
                              mainLevel.Score.CurrentScore +
                              mainLevel.Score.DueScore
                            }
                            labels={['Tecrübe']}
                            label={mainLevel.Score.CurrentLevel}
                            chartHeight={window.innerWidth > 568 ? 215 : 150}
                          />
                        </div>

                        <div className="fs-16 color-9b9b9b position-relative mb-3">
                          <Tooltip
                            placement="top"
                            trigger="hover"
                            tooltip={`${mainLevel.Score.CurrentLevel}. Seviyede toplam ${mainLevel.Score.CurrentScore} puan kazandın`}
                          >
                            {mainLevel.Score.CurrentScore}
                          </Tooltip>{' '}
                          /{' '}
                          <Tooltip
                            placement="top"
                            trigger="hover"
                            tooltip={`Bir sonraki seviye için ${
                              mainLevel.Score.CurrentScore +
                              mainLevel.Score.DueScore
                            } puana erişmelisin`}
                          >
                            {mainLevel.Score.CurrentScore +
                              mainLevel.Score.DueScore}
                          </Tooltip>
                        </div>

                        <div>
                          <ScoreBadge
                            removePrefix={true}
                            icon="point"
                            className="badge-score-custom"
                            value={mainLevel.Score.TotalScore}
                          />
                        </div>

                        <div>
                          <ScoreBadge
                            title={`${badges.length || 0} Rozet`}
                            icon="badge"
                            className="badge-rosette"
                            value={badges.length || 0}
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 p-xs-0 mt-3">
            <div className="card h-100 overflow-hidden">
              <Link to="/dijitalkitap/books" className="d-block h-100">
                <h5
                  className="text-white mt-4 ml-4 font-weight-600 position-absolute"
                  style={{ fontSize: 26 }}
                >
                  Kütüphanem
                  <br />
                  <span className="fs-14">
                    *dijital kitaplar ve video çözümleri
                  </span>
                </h5>

                <img
                  src={require('../../assets/images/images/Kutuphanem.png')}
                  className="h-100 w-100"
                  alt="..."
                />
              </Link>
            </div>
          </div>
          {/* <div className="col-12 col-lg-6 mt-3">
            <div className="card h-100 overflow-hidden">
              <Link to="/dijitalkitap/sinav-modu" className="d-block h-100">
                <h5
                  className="text-white mt-4 ml-4 font-weight-600 position-absolute"
                  style={{ fontSize: 26 }}
                >
                  Sınav Modu
                </h5>
                <img
                  src={require("../../assets/images/images/sinav_modu.jpg")}
                  className="h-100 w-100"
                  alt="..."
                />
              </Link>
            </div>
          </div> */}
        </div>

        <div className="row mt-5 d-flex justify-content-center">
          {lessonLevels &&
            lessonLevels.map((lesson, i) =>
              lesson.level && lesson.level > 0 ? (
                <div
                  className="col-6 col-lg-3 mb-3 p-xs-5"
                  key={lesson.id + '-' + lesson.level}
                >
                  <ProgressCard
                    series={[lesson.level]}
                    labels={['Seviye']}
                    cardText={lesson.title}
                    lesson={lesson}
                    key={lesson.id + '-' + lesson.level}
                  />
                </div>
              ) : null,
            )}

          {/* Buradan yorum halindeki html silindi */}
        </div>
        <div className="row">
          <div className="col-12 p-sm-0">
            <Link to="/" class="btn btn-link p-2 nav-link d-flex">
              <Icon icon="iChevronLeftNew" />
              <strong>Mağaza Moduna Dön</strong>
            </Link>
          </div>
        </div>
        {this.state.showAvatarSelect ? (
          <AvatarModal
            user={this.props.user}
            avatars={this.state.avatars}
            updateAvatar={this.updateAvatar}
            show={this.state.showAvatarSelect}
            onCloseClick={() => this.setState({ showAvatarSelect: false })}
            dialogClassName="modal-lg"
          />
        ) : null}

        {this.state.showBirthSelect ? (
          <BirthAndClass
            show={this.state.showBirthSelect}
            onCloseClick={() => this.setState({ showBirthSelect: false })}
            dialogClassName="modal-lg"
          />
        ) : null}
      </>
    );
  }
}

Index.propTypes = {};

const mapStateToProps = ({ AccountReducer, StudyReducer }) => ({
  user: AccountReducer.user,
  taxonomies: StudyReducer.taxonomies,
  scores: StudyReducer.scores,
  activityTypes: StudyReducer.activityTypes,
  avatars: StudyReducer.avatars,
  userBadges: StudyReducer.userBadges,
  badges: StudyReducer.badges,
  userBranches: StudyReducer.userBranches,
});

const mapDispatchToProps = {
  getPurchasedBooks: actions.getPurchasedBooks,
  fetchProfile: actions.fetchProfile,
  fetchDesk: actions.fetchDesk,
  getErrorBox: actions.getErrorBox,
  getActivityTypes: actions.getActivityTypes,
  getBadges: actions.getBadges,
  getUserBadges: actions.getUserBadges,
  getScores: actions.getScores,
  updateUserProfile: actions.updateUserProfile,
  getUserBranches: actions.getUserBranches,
  loginRequired: accountActions.loginRequired,
  getAvatars: actions.getAvatars,
  activateBook: actions.activateBook,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
