import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const Index = ({ className, listClassName, items }) => {
  //const smallDevices = window.matchMedia("(max-width: 767.98px)");
  //const link = items[items.length - 2];
  return (
    <nav className={className} aria-label="breadcrumb">
      <ol className={classNames('breadcrumb', listClassName)}>
        {items.map((item, i) => (
          <li
            className={classNames('breadcrumb-item', { active: item.active })}
            key={i}
          >
            {item.active ? item.title : <Link to={item.url}>{item.title}</Link>}
          </li>
        ))}
      </ol>
    </nav>
  );
};

Index.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Index;
