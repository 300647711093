import React from 'react';
import Slider from 'react-slick';

const MainSlider = ({ slides }) => {
  // const images = [];

  // for (let i = 0; i < 10; i++) {
  //   images.push({
  //     original: 'http://lorempixel.com/1000/600/nature/1/',
  //     thumbnail: 'http://lorempixel.com/250/150/nature/1/',

  //     originalClass: 'image-gallery-originial',
  //     thumbnailClass: 'image-gallery-thumbnail'
  //   });
  // }
  const windowWidth = window.screen.width;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: true,
    customPaging: (i) => (
      <a href="#item" className="d-inline-block px-4 px-sm-0  customPagination">
        <img src={slides[i].thumbnail} className="d-none d-md-flex" alt="" />
      </a>
    ),
  };

  return (
    <div id="mainSlider">
      <Slider {...settings}>
        {slides.map((item) => (
          <div key={item.id}>
            <a href={item.target || '/'} target="_blank">
              <img
                id="imgmobile"
                src={`${windowWidth > 768 ? item.web : item.mobile}`}
                sizes={`(max-width: 768px) 768w, 100%`}
                className="img-fluid w-100 carousel-img"
                style={{ pointerEvents: 'none' }}
                alt="Tonguç'la çalış, Başarıya alış!"
              />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MainSlider;
