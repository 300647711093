import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../routes/actions/Account';
import storage from '../../services/storage';

function LoginWithToken(props) {
  const [loading, setLoading] = useState(false);

  // read token, target from query string
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const target = urlParams.get('target') || '/';
  const shouldClearLocalStorage = urlParams.get('clear') || 'false';

  useEffect(() => {
    async function handleLogin() {
      if (shouldClearLocalStorage === 'true') {
        storage.removeAuth();

        var targetStartsWithDesk = target.startsWith('/dijitalkitap');
        if (targetStartsWithDesk) {
          props.history.push('/');
          props.loginRequired(true);
          return;
        }

        props.history.push(target);
        props.history.go();
        return;
      }

      // redirect to target if user is already logged in
      if (props.user && props.user.id) {
        props.history.push(target);
        return;
      }

      // redirect to login page if token is not provided
      if (!token) {
        props.history.push('/');
        var targetStartsWithDesk = target.startsWith('/dijitalkitap');
        if (targetStartsWithDesk) {
          props.loginRequired(true);
        }
      } else {
        setLoading(true);
        const { success, error, inActive } = await props.loginExchange(token);
        setLoading(false);
        if (success) {
          props.history.push(target);
        }
      }
    }
    handleLogin();
  }, [token, props.history]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      {loading ? (
        <>
          <h1>Yönlendiriliyorsunuz...</h1>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Yönlendiriliyorsunuz...</span>
          </div>
        </>
      ) : null}
    </div>
  );
}

const mapStateToProps = ({ AccountReducer }) => ({
  user: AccountReducer.user,
  token: AccountReducer.token,
});

const mapDispatchToProps = {
  loginExchange: actions.loginExchange,
  loginRequired: actions.loginRequired,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LoginWithToken));
