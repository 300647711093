import React, { useState } from 'react';
import Modal from '../Modal/Index';
import QrReader from 'react-qr-scanner';
import CloseButton from '../Button/Close';
import { connect } from 'react-redux';
import { handleShowQrScanner } from '../../routes/actions/Account';
import { history } from '../../configureStore';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Component } from 'react';

const rules = [
  {
    validpatterns: [
      new RegExp('^https://dogrukaynak.com'),
      new RegExp('^https://www.dogrukaynak.com'),
      new RegExp('^https://test.dogrukaynak.com'),
      new RegExp('^http://localhost:3000'),
    ],
    handleQr: (qrCode, validPatterns) => {
      var regexPattern = validPatterns.find((pattern) => pattern.test(qrCode));
      if (regexPattern) {
        var path = qrCode.split(regexPattern).pop();
        history.push(path);
      }
    },
  },
  {
    validpatterns: [new RegExp('^http://tonguc.video/t/')],
    handleQr: (qrCode, validPatterns) => {
      var regexPattern = validPatterns.find((pattern) => pattern.test(qrCode));
      if (regexPattern) {
        var quizId = qrCode.split(regexPattern).pop();
        history.push(
          `/dijitalkitap/book/lesson/subject/quiz/summary/${quizId}`,
        );
      }
    },
  },
];

class QrScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qr: '',
      delay: 300,
      devices: [],
      selectedDevice: null,
      message: {
        message: '',
        type: 'info',
      },
      ready: false,
    };
  }

  componentDidMount() {
    let enumerateDevices;
    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        toast.success('Kamera başlatılamadı. Başka tarayıcı ile deneyiniz.');
        return;
      }
      enumerateDevices = navigator.mediaDevices.enumerateDevices();
      enumerateDevices.then((devices) => {
        // Filter out non-videoinputs
        const videoDevices = devices.filter(
          (device) => device.kind == 'videoinput',
        );

        if (videoDevices.length < 1) {
          this.setState({
            devices: [],
            ready: true,
          });
        } else if (videoDevices.length == 1) {
          this.setState({
            devices: videoDevices,
            selectedDevice: videoDevices[0].deviceId,
            ready: true,
          });
        } else {
          var facingMode = 'rear';
          const pattern =
            facingMode == 'rear'
              ? /rear|back|arka|environment/gi
              : /front|user|face/gi;

          // Filter out video devices without the pattern
          var filteredDevices = videoDevices.filter(({ label }) =>
            pattern.test(label.toLowerCase()),
          );

          if (filteredDevices.length == 0) {
            filteredDevices = videoDevices;
          }

          this.setState({
            devices: filteredDevices,
            selectedDevice:
              filteredDevices.length > 0
                ? filteredDevices[filteredDevices.length - 1].deviceId
                : null,
            ready: true,
          });
        }
      });
    } catch (err) {
      toast.success(err + '');
    }
  }

  // const[qr, setQr] = useState("");
  // const[delay, setDelay] = useState(299);
  // const[devices, setDevices] = useState([]);
  // const[selectedDevice, setSelectedDevice] = useState("init_device");
  // const[message, setMessage] = useState({
  //   message: "",
  //   type: "info",
  // });

  getRule = (qrCode) =>
    rules.find((r) => r.validpatterns.some((pattern) => pattern.test(qrCode)));

  handleScan = async (data) => {
    // setQr(data);
    if (data) {
      if (this.state.qr == data) return;
      this.setState(
        {
          qr: data,
        },
        () => {
          const rule = this.getRule(data);
          if (rule) {
            // if (this.state.qr === data) return;
            // this.onCloseClick();
            const message = { ...this.state.message, message: '' };
            this.setState(
              {
                qr: '',
                message: message,
                // selectedDevice: null
              },
              () => {},
            );
            setTimeout(() => this.props.handleShowQrScanner(false), 100);

            rule.handleQr(data, rule.validpatterns);
          } else {
            // setMessage({
            //   ...message,
            //   message:
            //     "QR okuyucumuz ile sadece Doğru Kaynak sitelerine ait karekodları okuyabilirsin",
            // });
            const message = {
              ...this.state.message,
              message:
                'QR okuyucumuz ile sadece Doğru Kaynak sitelerine ait karekodları okuyabilirsin',
            };
            this.setState({
              message: message,
            });
          }
        },
      );
    }
  };

  handleError = (err) => {
    toast.success('Kamera başlatılamadı');
    console.error(err);
  };

  onCloseClick = () => {
    // setQr("");
    // setMessage({ ...message, message: "" });

    // setSelectedDevice("init_device");
    const message = { ...this.state.message, message: '' };
    this.setState(
      {
        qr: '',
        message: message,
        // selectedDevice: null
      },
      () => {
        this.props.handleShowQrScanner(false);
      },
    );
  };

  // const chooseDeviceId = (e) => {
  //   console.log("device id plz")
  //   return 4;
  // }

  chooseDeviceId(filteredDevices, videoDevices) {
    if (
      (!filteredDevices || filteredDevices.length == 0) &&
      videoDevices &&
      videoDevices.length > 0
    ) {
      var pattern = /rear|back|arka|Arka|environment/gi;
      filteredDevices = videoDevices.filter(({ label }) =>
        pattern.test(label.toLowerCase()),
      );
    }
    var deviceId = this.state.selectedDevice
      ? this.state.selectedDevice
      : filteredDevices.length > 0
        ? filteredDevices[filteredDevices.length - 1].deviceId
        : videoDevices.length > 0
          ? videoDevices[videoDevices.length - 1].deviceId
          : null;

    // setSelectedDevice(deviceId);
    // setDevices(videoDevices);
    this.setState({
      selectedDevice: deviceId,
      // devices: videoDevices // filteredDevices.length > 0 ? filteredDevices : videoDevices
    });

    return deviceId;
  }

  changeDevice = (option) => {
    this.setState({
      selectedDevice: option.value,
    });
    // setDelay(delay == 299 ? 300 : 299);
    // setSelectedDevice(option.value)
  };

  previewStyle = {
    width: '100%',
  };

  render() {
    return this.props.showQrModal ? (
      <Modal show={true} className="non-modal-height">
        <div className="modal-header bt-dedede:50">
          {/* <h5
              className="modal-title font-weight-600 color-484848"
              style={{ fontSize: 20 }}
            >
              {qr && qr.length && qr}
            </h5> */}
          {this.state.devices && this.state.devices.length > 1 && (
            <Select
              isSearchable={false}
              options={this.state.devices.map((p, i) => {
                return { value: p.deviceId, label: `Kamera ${i}` || p.label };
              })}
              placeholder="Kamera seçiniz"
              onChange={this.changeDevice}
              className="w-100 mt-2 mt-sm-0"
              defaultValue={this.state.selectedDevice}
            />
          )}
          <CloseButton onClick={this.onCloseClick} />
        </div>

        <div className="modal-body">
          {this.state.ready && (
            <div className="d-center justify-content-center align-items-center">
              <QrReader
                key={this.state.selectedDevice}
                facingMode={'rear'}
                delay={this.state.delay}
                onError={(e) => this.handleError(e)}
                onScan={(d) => this.handleScan(d)}
                chooseDeviceId={(p, q) => this.chooseDeviceId(p, q)}
                style={this.previewStyle}
                className={'d-center justify-content-center align-items-center'}
              />
            </div>
          )}
        </div>
        {this.state.message.message && this.state.message.message.length && (
          <div
            className={`alert alert-${this.state.message.type}`}
            role="alert"
            style={{ width: '100%', marginBottom: '1rem' }}
          >
            {this.state.message.message}
          </div>
        )}
      </Modal>
    ) : null;
  }
}

const mapStateToProps = ({ AccountReducer }) => ({
  showQrModal: AccountReducer.showQrModal,
});

const mapDispatchToProps = {
  handleShowQrScanner,
};

export default connect(mapStateToProps, mapDispatchToProps)(QrScanner);
