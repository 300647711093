import { keys } from './constants';

const tryParse = (item) => {
  try {
    return JSON.parse(item);
  } catch (e) {
    return false;
  }
};

class Storage {
  constructor() {
    this.localStorage = window.localStorage;
    this.sessionStorage = window.sessionStorage;
  }

  getItem(item) {
    return this.localStorage.getItem(item);
  }

  setItem(key, value) {
    this.localStorage.setItem(key, value);
  }

  removeItem(key) {
    this.localStorage.removeItem(key);
  }

  getSessionItem(item) {
    return this.sessionStorage.getItem(item);
  }

  setSessionItem(key, value) {
    this.sessionStorage.setItem(key, value);
  }

  removeSessionItem(key) {
    this.sessionStorage.removeItem(key);
  }

  setOrderCode(orderCode) {
    this.setItem(keys.LastOrderCode, orderCode);
  }

  getOrderCode() {
    return this.getItem(keys.LastOrderCode);
  }

  removeOrderCode() {
    this.removeItem(keys.LastOrderCode);
  }

  getToken() {
    return this.getItem(keys.Token);
  }

  setToken(token) {
    this.setItem(keys.Token, token);
  }

  removeToken() {
    this.removeItem(keys.Token);
  }

  getRefresh() {
    return this.getItem(keys.Refresh);
  }

  setRefresh(refresh) {
    this.setItem(keys.Refresh, refresh);
  }

  removeRefresh() {
    this.removeItem(keys.Refresh);
  }

  getUser() {
    return tryParse(this.getItem(keys.User));
  }

  setUser(user) {
    this.setItem(keys.User, JSON.stringify(user));
  }

  removeUser() {
    this.removeItem(keys.User);
  }

  getAuth() {
    return {
      token: this.getToken(),
      refresh: this.getRefresh(),
      user: this.getUser(),
    };
  }

  setAuth(data) {
    this.setUser(data.user);
    this.setToken(data.token);
    this.setRefresh(data.refreshToken);
  }

  removeAuth() {
    this.removeUser();
    this.removeToken();
    this.removeRefresh();
    this.removeOrderCode();
  }

  setWaitingForEmailVerification(value) {
    this.setItem(keys.WaitingForEmailVerification, value);
  }

  getWaitingForEmailVerification() {
    return this.getItem(keys.WaitingForEmailVerification);
  }

  removeWaitingForEmailVerification() {
    this.localStorage.removeItem(keys.WaitingForEmailVerification);
  }
}

export default new Storage();
