import React from 'react';
import Modal from './Index';
import CloseButton from '../Button/Close';
import { BaseForm } from '..';
//import PropTypes from 'prop-types';

const Form = (props) => {
  return (
    props.show && (
      <Modal show={true} className="modal-container">
        <div className="modal-header border-0">
          <CloseButton onClick={props.onClose} />
        </div>
        <div className="modal-body">
          <BaseForm
            schema={props.schema}
            uiSchema={props.uiSchema}
            formData={props.formData}
            onSubmit={props.onSubmit}
          />
        </div>
      </Modal>
    )
  );
};

Form.propTypes = {};

export default Form;
