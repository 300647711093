import actionTypes from './types';
import { tatsApiCall } from '../../../../services/api';
import { HTTP } from '../../../../services/constants';

export const getSchedule = () => async (dispatch) => {
  try {
    const { data } = await tatsApiCall(
      `/Schedule/Get`,
      null,
      null,
      HTTP.POST,
      null,
      true,
    );

    dispatch({
      type: actionTypes.GET_SCHEDULE,
      payload: data.Data,
    });
    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const getTopics = (models) => async (dispatch) => {
  try {
    const { data } = await tatsApiCall(
      `/Schedule/GetTopics`,
      null,
      null,
      HTTP.POST,
      null,
      true,
    );
    const result = [];
    const map = new Map();
    if (data && data.Data && data.Data.length > 0)
      for (const item of data.Data) {
        if (!map.has(item.Id)) {
          map.set(item.Id, true);
          result.push({
            value: item.Id,
            label: item.Name,
          });
        }
      }
    dispatch({
      type: actionTypes.GET_TOPICS,
      payload: result,
    });
  } catch (e) {
    return { success: false, error: e };
  }
};
function onlyUnique(value, index, self) {
  return self.contains(value.id) === index;
}

export const startSchedule = (model) => async (dispatch) => {
  try {
    const { data } = await tatsApiCall(
      `/Schedule/Start`,
      model,
      null,
      HTTP.POST,
      null,
      true,
    );

    dispatch({
      type: actionTypes.START_SCHEDULE,
      payload: data.Data.Id,
    });
    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const cancelSchedule = (model) => async (dispatch) => {
  try {
    const { data } = await tatsApiCall(
      `/Schedule/CancelSchedule`,
      model,
      null,
      HTTP.POST,
      null,
      true,
    );

    dispatch({
      type: actionTypes.CANCEL_SCHEDULE,
      payload: true,
    });
    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const completeSchedule = (model) => async (dispatch) => {
  try {
    const { data } = await tatsApiCall(
      `/Schedule/CompleteSchedule`,
      model,
      null,
      HTTP.POST,
      null,
      true,
    );

    dispatch({
      type: actionTypes.COMPLETE_SCHEDULE,
      payload: data,
    });
    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const cancelSchedulePomodoro = (model) => async (dispatch) => {
  try {
    const { data } = await tatsApiCall(
      `/Schedule/CancelSchedule`,
      model,
      null,
      HTTP.POST,
      null,
      true,
    );

    dispatch({
      type: actionTypes.CANCEL_SCHEDULE_POMODORO,
      payload: {
        id: null,
      },
    });
    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const updatePomodoroData = (data) => (dispatch) => {
  try {
    dispatch({
      type: actionTypes.UPDATE_POMODORO_DATA,
      payload: data,
    });
    if (data === null) {
      dispatch({
        type: actionTypes.CANCEL_SCHEDULE_POMODORO,
        payload: null,
      });
    }
    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const updatePomodoroWarnModal = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_POMODORO_WARN_MODAL,
    payload: data,
  });
};

export const startPomodoroInterval = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.START_INTERVAL_REQUESTED,
    payload: id,
  });
};
