import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../components/Icon/Index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class CircleButton extends Component {
  render() {
    return (
      <button
        type="button"
        className={classNames('btn rounded-circle', this.props.className)}
        onClick={this.props.onClick}
        title={this.props.title}
        style={{ margin: '.25rem', marginRight: '0' }}
        name={this.props.name}
      >
        {this.props.icon && <FontAwesomeIcon icon={this.props.icon} />}
        {this.props.children}
      </button>
    );
  }
}

CircleButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
};

export default CircleButton;
