import axios from 'axios';
import axiosRetry from 'axios-retry';
import {
  HTTP,
  endPoint,
  tatsEndPoint,
  S3BucketEndPoint,
  S3ContractBucketEndPoint,
  keys,
  MaxReviewFileSize,
  tongucAkademiEndpoint,
} from './constants';
import storage from './storage';
import { history } from '../configureStore';
import { store } from '../App';
import { toast } from 'react-toastify';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import actionTypes from '../routes/actions/Account/types';

import * as Sentry from '@sentry/browser';
import { ContentState } from 'draft-js';
axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
});
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    Sentry.captureException(error);
  }

  return Promise.reject(error);
});

const parseJwt = (token) => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const expired = (expiresAt, minutes = 1) => {
  const timespan = new Date().getTime() / 1000;

  return expiresAt - minutes * 60 <= timespan;
};

const refreshToken = async (refresh) => {
  const { data } = await axios({
    url: `${endPoint}/token/refresh`,
    data: refresh,
    method: HTTP.POST,
  });

  return data;
};

export const getToken = async () => {
  const auth = storage.getAuth();

  if (auth.token && auth.user) {
    const jwt = parseJwt(auth.token);

    if (expired(jwt.exp)) {
      try {
        const data = await refreshToken({
          token: auth.token,
          refreshToken: auth.refresh,
        });
        storage.setAuth({ ...data, user: auth.user });
        return data.token;
      } catch (error) {
        storage.removeAuth();
        return null;
      }
    }
    return auth.token;
  }
  return null;
};

export const apiCall = async (
  url = '',
  data = null,
  params = null,
  method = HTTP.GET,
  headers = {},
  withAuth = true,
  withAntiForgeryToken = false,
  customConfig = {},
  apiEndpoint = endPoint,
) => {
  let token = null;

  if (withAuth) {
    token = await getToken();
  }

  let aftHeaders = {};
  if (withAntiForgeryToken) {
    var aftHeadersResult = await getAftHeaders();
    aftHeaders = { ...aftHeadersResult };
  }
  return axios({
    url: `${apiEndpoint}${url}`,
    data,
    params,
    method,
    headers: {
      ...headers,
      ...aftHeaders,
      Authorization: `Bearer ${withAuth ? token : ''}`,
    },
    maxContentLength: MaxReviewFileSize,
    withCredentials: true,
    ...customConfig,
  })
    .then((p) => {
      return p;
    })
    .catch((err) => {
      if (err.toString() != 'Error: Network Error') throw err;
      else return { data: null };
    });
};

const getAftHeaders = async () => {
  try {
    const {
      data: { requestToken, headerName },
    } = await apiCall('/aft', null, null, HTTP.GET, null, false);
    var result = {
      [headerName]: requestToken,
    };
    return Promise.resolve(result);
  } catch (e) {
    console.error(e);
    toast.error('Bir hata oluştu #aft');
    return Promise.reject('Bir hata oluştu #aft');
  }
};

export const tatsApiCall = async (
  url = '',
  data = {},
  params = null,
  method = HTTP.GET,
  headers = {},
  withAuth = true,
) => {
  let token = null;

  const payload = data === null ? {} : data;
  payload.dk = true;

  if (withAuth) {
    token = await getToken();
    if (token) {
      payload.bearer = `Bearer ${token}`;
    } else {
      token = '';
      withAuth = false;
    }
  }

  return axios({
    url: `${tatsEndPoint}${url}`,
    data: { ...payload },
    params,
    method,
    headers: {
      ...headers,
      Authorization: withAuth ? `Bearer ${token}` : '',
    },
  })
    .then((p) => {
      return p;
    })
    .catch((err) => {
      if (err.toString() != 'Error: Network Error') throw err;
      else return { data: null };
    });
};

export const tongucAkademiApiCall = async (
  url = '',
  data = {},
  params = null,
  method = HTTP.GET,
  headers = {},
  withAuth = true,
) => {
  let token = null;

  const payload = data === null ? {} : data;
  payload.dk = true;

  if (withAuth) {
    token = await getToken();
    if (token) {
      payload.bearer = `Bearer ${token}`;
    } else {
      token = '';
      withAuth = false;
    }
  }

  return axios({
    url: `${tongucAkademiEndpoint}${url}`,
    data: { ...payload },
    params,
    method,
    headers: {
      ...headers,
      Authorization: withAuth ? `Bearer ${token}` : '',
    },
  })
    .then((p) => {
      return p;
    })
    .catch((err) => {
      if (err.toString() != 'Error: Network Error') throw err;
      else return { data: null };
    });
};

export const bucketCall = async (
  url = '',
  data = null,
  params = null,
  method = HTTP.GET,
  headers = {},
) => {
  return axios({
    url: `${S3BucketEndPoint}${url}`,
    data,
    params,
    method,
    headers: {
      ...headers,
    },
  });
};

export const contractBucketCall = async (
  url = '',
  data = null,
  params = null,
  method = HTTP.GET,
  headers = {},
) => {
  return axios({
    url: `${S3ContractBucketEndPoint}${url}`,
    data,
    params,
    method,
    headers: {
      ...headers,
    },
  });
};
export const contractBucketCallBulk = async (contracts) => {
  let promises = [];
  for (let i = 0; i < contracts.length; i++) {
    var promise = axios({
      url: `${S3ContractBucketEndPoint}/contract-${contracts[i].contractId}.json`,
      data: null,
      params: null,
      method: HTTP.GET,
      headers: {},
    });
    promises.push(promise);
  }

  var result = await axios.all(promises).then(
    axios.spread((...args) => {
      return args;
    }),
  );
  return result;
};

axios.interceptors.response.use(
  (response) => {
    store.dispatch(hideLoading());
    return response;
  },
  (error) => {
    store.dispatch(hideLoading());
    if (!error.response) {
      console.error('# NETWORK ERROR # ', error);

      var isDeskMode = history.location.pathname.includes('/dijitalkitap');
      const isTatsApiError =
        error.config.url && error.config.url.includes(tatsEndPoint);

      if (!isTatsApiError || isDeskMode) {
        // mağaza modunda tats api hatalarında hata vermesin
        history.push('/bakim-modu');
      }

      return Promise.reject(error);
    }
    const { ignoreUnAuthorizedError } = error.response.config;
    if (error.response.status === 401 && !ignoreUnAuthorizedError) {
      store.dispatch({ type: 'ACCOUNT/LOGOUT' });
      if (history.location && history.location.pathname) {
        storage.setItem(keys.LastLocation, history.location.pathname);
      }
      history.push('/', { showLogin: true, authenticated: false });
      store.dispatch({
        type: actionTypes.LOGIN_REQUIRED,
        payload: true,
      });
    } else if (error.response.status === 422) {
      const { errors } = error.response.data;
      const message = Object.keys(errors)
        .reverse()
        .map((key) => errors[key])
        .join(',');
      toast.error(`Lütfen, ${message}`);
    } else if (error.response.status === 406) {
      //demo user
      const response = {
        data: `Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor.`,
      };
      toast.error(response.data);

      return Promise.reject({ response: response, data: response.data });
    }
    return Promise.reject(error.response);
  },
);

axios.interceptors.request.use(
  (config) => {
    store.dispatch(showLoading());
    config.maxContentLength = MaxReviewFileSize;
    config.maxBodyLength = MaxReviewFileSize;
    return config;
  },
  (error) => {
    store.dispatch(hideLoading());
    // eslint-disable-next-line no-undef
    return Promise.reject(error);
  },
);

export default apiCall;
