import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Breadcrumb, RadialChart, Tooltip } from '../../components';
import { RadialChart as ReactRadialChart } from 'react-vis';
import { ScoreBadge } from '../../components';
import * as actions from '../../routes/actions/Study';
import * as studyActions from '../../routes/actions/Study';
import { connect } from 'react-redux';
import queryString from 'query-string';

import lessonIcons from '../../assets/images/dersler';
import Icon from '../../components/Icon/Index';
import { Player } from '@lottiefiles/react-lottie-player';

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faCheckCircle, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const customPalette = ['#ffcbcb', '#cdffd1', '#dedede'];

const emoji = {
  HAPPY: require('../../assets/images/emoji_1.svg'),
  SAD: require('../../assets/images/emoji_2.svg'),
};

const choises = ['C', 'B', 'A', 'N', 'C', 'C', 'B', 'A', 'N', 'C'];
const answers = ['C', 'B', 'D', 'A', 'C', 'C', 'B', 'D', 'A', 'C'];

class Summary extends Component {
  constructor(props) {
    super(props);
    this.player = React.createRef();
  }
  async componentDidMount() {
    var { testId } = this.props.match.params;

    const search = queryString.parse(this.props.location.search);
    const isVirtual = search.v || false;
    this.setState({
      isVirtual,
    });

    this.props.getScores();
    await this.props.getTestsQuestions(testId, isVirtual);

    var { testsQuestions } = this.props;

    const testPath = isVirtual
      ? testId + '?v=true'
      : testsQuestions.Sorular[0].TestID;

    var data = testsQuestions.Detail
      ? [
          {
            theta: testsQuestions.Detail.FalseCount,
            label: 'Yanlış',
            color: 1,
          },
          { theta: testsQuestions.Detail.EmptyCount, label: 'Boş', color: 2 },
          { theta: testsQuestions.Detail.TrueCount, label: 'Doğru', color: 3 },
        ]
      : [];

    var breadcrumbs = testsQuestions.Sorular
      ? [
          {
            title: 'Kütüphanem',
            url: '/dijitalkitap',
          },
          ...(isVirtual
            ? []
            : [
                {
                  title: testsQuestions.Sorular[0].YayinAd,
                  url: `/dijitalkitap/book/lessons/${testsQuestions.Sorular[0].yayinID}`,
                },
              ]),
          {
            title: isVirtual
              ? 'Hata Kutusu Testi'
              : testsQuestions.Sorular[0].TestAd,
            url: `/dijitalkitap/book/lesson/subject/quiz/answers/${testPath}`,
          },
          {
            title: 'Sonuç',
            active: true,
          },
        ]
      : [
          {
            title: 'Kütüphanem',
            url: '/dijitalkitap',
          },
          {
            title: 'Kitap',
            url: '#',
          },
          {
            title: 'Test',
            url: '#',
          },
          {
            title: 'Sonuç',
            active: true,
          },
        ];
    this.setState({
      data,
      breadcrumbs,
    });
  }
  state = {
    value: false,
    data: [
      { theta: 0, label: 'Doğru', color: 1 },
      { theta: 0, label: 'Yanlış', color: 2 },
      { theta: 0, label: 'Boş', color: 3 },
    ],
    experience: [
      { theta: 212, label: 'Puan', color: 2 },
      { theta: 798, label: 'Kalan Puan', color: 1 },
    ],
    breadcrumbs: [
      {
        title: 'Kütüphanem',
        url: '/dijitalkitap',
      },
      {
        title: 'Kitap',
        url: '/dijitalkitap/book/lessons',
      },
      {
        title: 'Test',
        url: '/dijitalkitap/book/lesson/subject/quiz/answers',
      },
      {
        title: 'Sonuç',
        active: true,
      },
    ],
  };

  getLessonLevel = (score) => {
    if (score && score.Branch) {
      let lessonLevel = {};
      lessonLevel.title = score.Branch.Name;
      lessonLevel.prefix = lessonLevel.title.charAt(0).toUpperCase();
      lessonLevel.score = score.Score.CurrentScore;
      lessonLevel.level = score.Score.CurrentLevel;
      lessonLevel.totalScore = score.Score.CurrentScore + score.Score.DueScore;
      lessonLevel.allScore = score.Score.TotalScore;
      lessonLevel.badgeCount = 0;
      lessonLevel.icon =
        lessonIcons[lessonLevel.title.toLowerCase().charAt(0)] || lessonIcons.d;
      return lessonLevel;
    }
    return null;
  };

  render() {
    var { testsQuestions, scores, user } = this.props;
    var { testId } = this.props.match.params;
    const branchId =
      (testsQuestions.Sorular &&
        testsQuestions.Sorular[0] &&
        Number(testsQuestions.Sorular[0].Brans)) ||
      -1;
    const branchScore = scores.find(
      (s) => s.Branch && s.Branch.Id === branchId,
    ) || { Score: {} };
    const branchLevel = this.getLessonLevel(branchScore);
    const mainLevel = (this.props.scores &&
      this.props.scores.find((s) => s.Branch === null)) || { Score: {} };

    // let classId = this.props.classId;
    // if (testsQuestions.Sorular && this.state.isVirtual) {
    //   classId = Math.max(...testsQuestions.Sorular.map(p => p.sinif));
    // }
    // const questionOptions = studyActions.questionOptions(classId);
    const maxCevapSecenek =
      (testsQuestions.Sorular &&
        Math.max(...testsQuestions.Sorular.map((p) => p.cevapSecenek))) ||
      0;
    const questionOptions = studyActions.questionOptions2(maxCevapSecenek);

    return (
      <>
        <div className="row">
          <div className="col-12">
            <Breadcrumb items={this.state.breadcrumbs} />
          </div>
          <div className="col-lg-9 p-xs-0">
            <div className="card h-100">
              <div className="card-body p-0">
                <div className="row m-0 h-100">
                  <div className="col-lg-8 pb-0 pb-sm-4 p-sm-4">
                    <div className="d-flex justify-content-between">
                      <div className="flex-1 text-center d-flex flex-column align-items-center">
                        {this.state.data && (
                          <ReactRadialChart
                            innerRadius={window.innerWidth > 568 ? 70 : 40}
                            radius={window.innerWidth > 568 ? 100 : 60}
                            getAngle={(d) => d.theta}
                            data={this.state.data}
                            onValueMouseOver={(v) =>
                              this.setState({ value: v })
                            }
                            onSeriesMouseOut={(v) =>
                              this.setState({ value: false })
                            }
                            width={window.innerWidth > 568 ? 240 : 130}
                            height={window.innerWidth > 568 ? 240 : 130}
                            padAngle={0}
                            colorRange={customPalette}
                            className="mt-4 mt-sm-0"
                          >
                            <h1
                              style={{
                                width: window.innerWidth > 568 ? 72 : 48,
                                height: window.innerWidth > 568 ? 72 : 48,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                              }}
                            >
                              {testsQuestions.Detail && (
                                <img
                                  src={
                                    testsQuestions.Detail.Success > 0
                                      ? emoji.HAPPY
                                      : emoji.SAD
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                              )}
                            </h1>
                          </ReactRadialChart>
                        )}
                        {branchLevel && (
                          <div className="d-none d-sm-flex flex-column">
                            <h6 className="color-484848">Kazanılan Puan :</h6>
                            <ScoreBadge
                              className="badge-score-custom mb-2 text-truncate"
                              icon="point"
                              title={`+${branchLevel.allScore} Puan`}
                              value={branchLevel.allScore}
                              branch={branchLevel.title}
                            />
                          </div>
                        )}
                      </div>

                      {testsQuestions && testsQuestions.Detail && (
                        <div className="flex-1 pt-4 answers-stats-container pt-sm-0">
                          <div className="d-none d-sm-block">
                            <img
                              src={require('../../assets/images/icons/icon-clock.svg')}
                              alt="..."
                              width="20"
                            />
                            <span className="fs-14 font-weight-600 mx-2 color-919191">
                              Çözüm Süresi
                            </span>
                            <span className="fs-14 font-weight-600 color-919191">
                              {testsQuestions.Detail.SolveTime}
                            </span>
                          </div>

                          <div className="mt-3 d-none d-sm-block">
                            <span className="badge badge-success font-weight-600 fs-14 badge-pill mr-1">
                              <span className="mr-2">
                                {testsQuestions.Detail.TrueCount}
                              </span>

                              <span className="d-xs-none">D</span>
                              <span className="hidden-lg">oğru</span>
                            </span>
                            <span className="badge badge-red-new font-weight-600 fs-14 badge-pill mr-1">
                              <span className="mr-2">
                                {testsQuestions.Detail.FalseCount}
                              </span>
                              <span className="d-xs-none">Y</span>
                              <span className="hidden-lg">anlış</span>
                            </span>
                            <span className="badge badge-gray font-weight-600 fs-14 badge-pill mr-1">
                              <span className="mr-2">
                                {testsQuestions.Detail.EmptyCount}
                              </span>
                              <span className="d-xs-none">B</span>
                              <span className="hidden-lg">oş</span>
                            </span>
                          </div>

                          <p className="fs-16 font-weight-600 d-none d-sm-inline-block color-484848 mb-1 mt-4">
                            {(
                              testsQuestions.Detail.TrueCount -
                              testsQuestions.Detail.FalseCount / 3
                            ).toFixed(2)}{' '}
                            Net
                          </p>
                          {branchLevel && (
                            <ScoreBadge
                              className="badge-score-custom d-flex d-sm-none mb-3"
                              icon="point"
                              title={`+${branchLevel.allScore} Puan`}
                              value={branchLevel.allScore}
                              branch={branchLevel.title}
                              style={{ width: 126, height: 32 }}
                            />
                          )}

                          <h5 className="font-weight-600 mb-3 fs-21">
                            Yüzdelik Dilimi
                          </h5>
                          <p className="fs-14 stats-item color-919191 font-weight-600 mb-3">
                            Türkiye bazında: %{testsQuestions.Detail.Rank}
                          </p>
                          <p className="fs-14 stats-item font-weight-600 color-919191">
                            {testsQuestions.Detail.CityName} ilinde: %
                            {testsQuestions.Detail.CityRank}
                          </p>
                        </div>
                      )}
                    </div>

                    {testsQuestions && testsQuestions.Detail && (
                      <div className="d-flex d-sm-none flex-column">
                        <div>
                          <img
                            src={require('../../assets/images/icons/icon-clock.svg')}
                            alt="..."
                            width="20"
                          />
                          <span className="fs-12 font-weight-bold mx-2 color-919191">
                            Çözüm Süresi
                          </span>
                          <span className="fs-12 font-weight-bold color-919191">
                            {testsQuestions.Detail.SolveTime}
                          </span>
                        </div>

                        <div className="mt-3 mb-3 d-flex align-items-center justify-content-between font-weight-bold">
                          <span className="fs-14 text-success">
                            {testsQuestions.Detail.TrueCount} Doğru
                          </span>
                          <span className="fs-14 text-danger-new">
                            {testsQuestions.Detail.FalseCount} Yanlış
                          </span>
                          <span className="fs-14 color-484848">
                            {testsQuestions.Detail.EmptyCount} Boş
                          </span>
                          <span
                            style={{
                              display: 'inline-block',
                              width: 2,
                              height: 25,
                              background: '#979797',
                            }}
                          />
                          <span className="fs-16 color-484848">
                            {testsQuestions.Detail.Net} Net
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {branchLevel && (
                    <div className="col-lg-4 p-xs-0 mx-3 mx-sm-0 py-4 text-center summary-border summary-branch-stats pt-2">
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <h5
                          className="text-center text-truncate fs-20 mb-0 color-484848 font-weight-600"
                          title={branchLevel.title}
                        >
                          {branchLevel.title}
                        </h5>
                        <img
                          src={branchLevel.icon}
                          width="35"
                          alt={branchLevel.title}
                        />
                      </div>

                      <div className="d-flex flex-row flex-md-column align-items-center justify-content-between justify-content-sm-center">
                        <div>
                          {branchLevel && (
                            <RadialChart
                              current={branchLevel.score}
                              max={branchLevel.totalScore}
                              labels={['Seviye']}
                              label={branchLevel.level}
                              chartHeight={window.innerWidth > 568 ? 250 : 180}
                              chartWidth={window.innerWidth > 568 ? 190 : 120}
                              size={50}
                              key={branchLevel.totalScore}
                              summary
                            />
                          )}

                          <div
                            className="fs-16 font-weight-500 color-9b9b9b position-relative"
                            style={{ marginTop: -16, marginBottom: 10 }}
                          >
                            <Tooltip
                              placement="top"
                              trigger="hover"
                              tooltip={`${branchLevel.level}. Seviyede toplam ${branchLevel.score} puan kazandın`}
                            >
                              {branchLevel.score}
                            </Tooltip>
                            /
                            <Tooltip
                              placement="top"
                              trigger="hover"
                              tooltip={`Bir sonraki seviye için ${branchLevel.totalScore} puana erişmelisin`}
                            >
                              {branchLevel.totalScore}
                            </Tooltip>
                          </div>
                        </div>

                        <div
                          className="text-center ml-3"
                          // style={{ width: 120 }}
                        >
                          <ScoreBadge
                            className="badge-score-custom mb-2"
                            icon="point"
                            title={`${branchLevel.allScore} Puan`}
                            value={branchLevel.allScore}
                            style={{ height: 32 }}
                          />
                          <ScoreBadge
                            className="badge-rosette"
                            icon="badge"
                            title={`${branchLevel.badgeCount} Rozet`}
                            value={branchLevel.badgeCount}
                            style={{ height: 32 }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 p-xs-0 text-center">
            <div className="card h-100 mt-3 mt-lg-0">
              <div className="card-body">
                <div className="pt-2">
                  <div
                    className="d-flex flex-row justify-content-between"
                    style={{ minHeight: 35 }}
                  >
                    <h5 className="text-center fs-20 color-484848 font-weight-600">
                      Master Seviye
                    </h5>
                  </div>
                </div>
                {mainLevel && (
                  <div className="d-flex flex-row flex-md-column align-items-center">
                    <div>
                      {mainLevel && (
                        <RadialChart
                          current={mainLevel.Score.CurrentScore}
                          max={
                            mainLevel.Score.CurrentScore +
                            mainLevel.Score.DueScore
                          }
                          labels={['Seviye']}
                          label={mainLevel.Score.CurrentLevel}
                          key={mainLevel.Score.CurrentLevel}
                          chartHeight={window.innerWidth > 568 ? 250 : 180}
                          chartWidth={window.innerWidth > 568 ? 190 : 120}
                          size={90}
                          summary={window.innerWidth > 568 ? true : false}
                        />
                      )}

                      <div
                        className="fs-16 font-weight-600 color-9b9b9b position-relative"
                        style={{ marginTop: -16, marginBottom: 10 }}
                      >
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip={`${mainLevel.Score.CurrentLevel}. Seviyede toplam ${mainLevel.Score.CurrentScore} puan kazandın`}
                        >
                          {mainLevel.Score.CurrentScore}
                        </Tooltip>
                        /
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip={`Bir sonraki seviye için ${
                            mainLevel.Score.CurrentScore +
                            mainLevel.Score.DueScore
                          } puana erişmelisin`}
                        >
                          {mainLevel.Score.CurrentScore +
                            mainLevel.Score.DueScore}
                        </Tooltip>
                      </div>
                    </div>

                    <div className="text-center ml-3">
                      <ScoreBadge
                        title={`${mainLevel.Score.TotalScore} Puan`}
                        value={mainLevel.Score.TotalScore}
                        icon="point"
                        className="badge-score-custom mb-2"
                        style={{ height: 32 }}
                      />
                      <ScoreBadge
                        className="badge-rosette"
                        icon="badge"
                        title={`${0} Rozet`}
                        value={0}
                        style={{ height: 32 }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 p-xs-0 mt-4">
            <div className="card answer-keys">
              <div className="card-body">
                <h5 className="mt-3 mb-0 mt-sm-0">Cevap Anahtarı</h5>

                <table className="table table-borderless summary-table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      {questionOptions.map((item, i) => (
                        <th scope="col" className="pl-1 pl-sm-3" key={i}>
                          {item}
                        </th>
                      ))}

                      <th scope="col" className="pl-1 pl-sm-3 text-center">
                        Doğru Cevap
                      </th>
                      <th scope="col" className="text-center">
                        İzle
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {testsQuestions.Sorular &&
                      testsQuestions.Sorular.map((q, i) => (
                        <tr
                          key={i}
                          className={classNames({
                            'bg-light-green': q.Durum === 'D',
                            'bg-light-red': q.Durum === 'Y',
                            'bg-light-gray': q.Durum === 'B',
                          })}
                        >
                          <td className="font-weight-600">
                            {this.state.isVirtual ? q.NewNo : q.No}
                          </td>
                          {questionOptions.map((title, i) => (
                            <td key={i}>
                              {i <
                                studyActions.questionOptions2(q.cevapSecenek)
                                  .length && (
                                <label className="radio-container answers-custom-checkbox summary-checked">
                                  <input
                                    type="radio"
                                    name={`answer_${q.Key}`}
                                    value={title}
                                    checked={q.OCevap === title}
                                  />
                                  <span className="radio-checkmark" />
                                </label>
                              )}
                            </td>
                          ))}
                          <td className="text-center font-weight-500 text-dark">
                            {q.ACevap}
                          </td>
                          <td
                            className="text-center"
                            style={{
                              display:
                                q.videoLink === '' || q.videoLink == null
                                  ? 'table-column'
                                  : 'table-cell',
                            }}
                          >
                            <Link
                              className="d-flex justify-content-center align-items-center"
                              to={`/dijitalkitap/book/lesson/subject/quiz/play/${testId}?q=${
                                this.state.isVirtual ? q.NewNo : q.No
                              }${this.state.isVirtual ? '&v=true' : ''}`}
                              onClick={(e) =>
                                q.videoLink === '' ? e.preventDefault() : true
                              }
                            >
                              <span className="fs-12 font-weight-600 text-dark d-none d-sm-none d-md-inline mr-2">
                                Çözüm Videosu
                              </span>
                              {q.Durum === 'D' ? (
                                <div
                                  style={{ height: '34px', width: '39px' }}
                                  className="d-flex align-items-center justify-content-center"
                                >
                                  <Icon icon="iVideo" />
                                </div>
                              ) : (
                                <Player
                                  ref={this.player}
                                  autoplay={true}
                                  loop={true}
                                  controls={true}
                                  src={require('../../assets/animation/lf20_i9kq6zfx.json')}
                                  style={{ height: '34px', width: '39px' }}
                                ></Player>
                              )}
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Summary.propTypes = {};

const mapStateToProps = ({ AccountReducer, AnswerReducer, StudyReducer }) => ({
  scores: StudyReducer.scores,
  testsQuestions: StudyReducer.testsQuestions,
  user: AccountReducer.user,
  classId: AnswerReducer.classId,
});

const mapDispatchToProps = {
  getScores: actions.getScores,
  getTestsQuestions: actions.getTestsQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
