import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Input extends Component {
  handleChange = (event) => {
    const { onChange } = this.props;
    const target = event.target;

    if (target.name === 'taxNumber') {
      const re = /^[0-9\b]+$/;
      if (target.value === '' || re.test(target.value)) {
        onChange(target.name, target.value);
      }
    } else {
      onChange(target.name, target.value);
    }
  };

  render() {
    const { name, schema, uiSchema, onChange, value, valid, ...props } =
      this.props;
    return (
      <input
        type={schema.format || 'text'}
        id={name}
        name={name}
        onChange={this.handleChange}
        value={value || ''}
        maxLength={schema.maxLength}
        {...props}
      />
    );
  }
}

Input.propTypes = {
  name: PropTypes.string,
};

export default Input;
