import React from 'react';
//import PropTypes from 'prop-types';
import { TooltipMenu } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const widgets = ['Highlight', 'TextView', 'PlayerView', 'ImageView'];

const Placeholder = ({ componentId, onAddClick, mode }) => {
  return (
    <div className="card">
      <div className="card-body">
        <p className="card-text">Buraya içerik ekleyin.</p>
        {mode === 'draft' && (
          <TooltipMenu
            placement="bottom"
            trigger="click"
            tooltip={TooltipMenu}
            content={() => (
              <div className="list-group list-group-flush">
                {widgets.map((name, i) => (
                  <button
                    type="button"
                    className="list-group-item list-group-item-action"
                    onClick={() => onAddClick(componentId, name)}
                    key={i}
                  >
                    {name}
                  </button>
                ))}
              </div>
            )}
          >
            <button type="button" className="btn btn-secondary">
              <FontAwesomeIcon icon={faPlus} /> Ekle
            </button>
          </TooltipMenu>
        )}
      </div>
    </div>
  );
};

Placeholder.propTypes = {};

export default Placeholder;
