import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import Highlighter from 'react-highlight-words';
import { resetSearch, search, flexSearch } from '../routes/actions/Product';
import * as studyActions from '../routes/actions/Study';
import Icon from '../components/Icon/Index';
import classNames from 'classnames';
import { Button } from '../components';
import { setSearched } from '../helpers';
//import PropTypes from 'prop-types';

class SearchBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      showResultBox: false,
    };

    this.keyword = React.createRef();
  }

  onSearch = (event) => {
    const keyword = event.target.value;
    const { isDeskMode } = this.props;
    // if (keyword.length > 2) {
    if (!isDeskMode) this.props.flexSearch(keyword);
    else this.props.studySearch(keyword);

    // }
  };

  submitForm = (event) => {
    const { isDeskMode } = this.props;
    event.preventDefault();

    if (this.state.keyword.length < 3) {
      return;
    }

    const parsed = queryString.parse();
    parsed.k = this.state.keyword;
    const stringified = queryString.stringify(parsed);

    this.setState(
      {
        showResultBox: false,
        fromSubmit: true,
      },
      () => {
        isDeskMode
          ? this.props.history.push({
              pathname: `/dijitalkitap/searchresult`,
              search: stringified,
            })
          : this.props.history.push({
              pathname: '/urunler',
              search: stringified,
            });
      },
    );
    setSearched(this.state.keyword);
  };

  goToDetail = (item) => {
    const { history } = this.props;
    this.setState(
      {
        showResultBox: false,
      },
      () => {
        if (item.Type === 'Book') {
          history.push({
            pathname: `/dijitalkitap/book/lessons/${item.Id}`,
          });
        } else if (item.Type === 'Quiz') {
          history.push({
            pathname: `/dijitalkitap/book/lesson/subject/quiz/answers/${item.Id}`,
          });
        } else {
          const parsed = queryString.parse();
          parsed.k = item;
          const stringified = queryString.stringify(parsed);
          history.push({
            pathname: '/urunler',
            search: stringified,
          });
        }
        setSearched(this.state.keyword);
      },
    );
  };

  goToProduct = (event, product) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState(
      {
        showResultBox: false,
      },
      () => {
        this.props.history.push({
          pathname: `/urunler/detay/${product.id}`,
        });
      },
    );
  };

  clearSearchedList = () => {
    window.localStorage.removeItem('SEARCHED');
  };

  clearRecentlyViewed = () => {
    window.localStorage.removeItem('VISIT_PRODUCTS');
  };

  closeSearch = () => {
    this.setState({
      showResultBox: false,
      fromFocus: false,
    });
  };

  focusHandler = () => {
    // if (this.state.keyword.length && ((!this.props.isDeskMode && this.props.searchResult.length) || (this.props.isDeskMode && this.props.studySearchResult.length))) {

    this.setState({
      showResultBox: true,
      fromFocus: true,
    });
    // }
  };

  blurHandler = () => {
    this.setState({
      showResultBox: false,
    });
  };

  componentDidUpdate() {
    if (!this.props.keyword.length && this.keyword.current.value.length) {
      this.keyword.current.value = '';
    }
    //  else if (!this.keyword.current.value.length) {
    //     this.setState({ showResultBox: false });
    // }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.fromFocus) {
      return {
        ...prevState,
        fromFocus: false,
        keyword: nextProps.keyword,
        showResultBox: true,
      };
    }

    if (
      nextProps.keyword !== prevState.keyword &&
      ((!nextProps.isDeskMode && nextProps.searchResult.length) ||
        (nextProps.isDeskMode && nextProps.studySearchResult.length)) &&
      !prevState.fromSubmit
    ) {
      return {
        ...prevState,
        fromFocus: false,
        showResultBox: true,
        keyword: nextProps.keyword,
      };
    }

    return {
      ...prevState,
      fromFocus: false,
      fromSubmit: false,
      keyword: nextProps.keyword,
      // showResultBox: false,
    };
  }

  emptySearchBox(searchedList, recentlyViewed) {
    return (
      <div className="history-list">
        {recentlyViewed.length > 0 && (
          <div className="history-list-item">
            <div className="history-list-item-header">
              <label className="history-list-item-title">Son Baktıklarım</label>
              <span
                className="remove-items-button"
                onMouseDown={() => this.clearRecentlyViewed()}
              >
                Temizle
              </span>
            </div>
            <div className="history-list-item-body">
              <ul className="last-search-book">
                {recentlyViewed.reverse().map((item) => (
                  <li className="last-search-book-item" key={item.id}>
                    <a
                      href="javascript:;"
                      onMouseDown={(e) => this.goToProduct(e, item)}
                    >
                      <img
                        className="img-fluid"
                        src={item.imageUrl}
                        alt={item.name}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {searchedList.length > 0 && (
          <div className="history-list-item">
            <div className="history-list-item-header">
              <label className="history-list-item-title">Son Aradıklarım</label>
              <span
                onMouseDown={() => this.clearSearchedList()}
                className="remove-items-button"
              >
                Temizle
              </span>
            </div>
            <div className="history-list-item-body">
              <ul className="last-search-result">
                {searchedList.map((item, i) => (
                  <li className="last-search-result-item" key={i}>
                    <a
                      href="javascript:;"
                      onMouseDown={(e) => this.goToDetail(item)}
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
  dirtySearchBox() {
    return (
      <div>
        {this.isDeskMode ? (
          this.props.studySearchResult.map((item) => (
            <div
              key={item.id}
              className="result-item pl-1"
              onMouseDown={(e) => this.goToDetail(item)}
            >
              <span className="font-weight-600" />
              <Highlighter
                highlightClassName="font-weight-600"
                searchWords={[this.state.keyword]}
                autoEscape={true}
                textToHighlight={
                  item.Type === 'Quiz'
                    ? item.BookName + ' -> ' + item.Name
                    : item.Name
                }
              />
            </div>
          ))
        ) : this.props.searchResult.length > 0 ? (
          this.props.searchResult.map((item) => (
            <a
              href="javascript:;"
              onMouseDown={(e) => this.goToProduct(e, item)}
              key={item.id}
              className="result-item pl-1"
            >
              <span className="font-weight-600" />
              <Highlighter
                highlightClassName="font-weight-600"
                searchWords={[this.state.keyword]}
                autoEscape={true}
                textToHighlight={item.name}
              />
            </a>
          ))
        ) : (
          <div>
            <a
              href="javascript:;"
              onMouseDown={(e) => this.goToDetail(this.props.keyword)}
              className="result-item"
            >
              {this.props.keyword}
            </a>
          </div>
        )}
        <hr />
        <a
          onMouseDown={this.submitForm}
          onTouchStart={this.submitForm}
          href="javascript:;"
          className="text-blue fs-14 font-weight-500 text-decoration-none"
        >
          Tüm sonuçları göster
        </a>
      </div>
    );
  }
  render() {
    const { isDeskMode } = this.props;
    const data = window.localStorage.getItem('SEARCHED');
    const searchedList = data ? JSON.parse(data) : [];
    const recentlyViewedLS = window.localStorage.getItem('VISIT_PRODUCTS');
    const recentlyViewed = recentlyViewedLS ? JSON.parse(recentlyViewedLS) : [];
    return (
      <div
        className={classNames(
          'card card-search s flex-lg-2 flex-sm-1 ',
          this.props.className,
        )}
      >
        <form
          className={`form-inline ${this.state.showResultBox ? 'active' : ''}`}
          onSubmit={this.submitForm}
          onBlur={this.blurHandler}
        >
          <input
            ref={this.keyword}
            onChange={this.onSearch}
            onClick={this.focusHandler}
            value={this.props.keyword}
            autoComplete="off"
            type="text"
            className="form-control search-input d-none d-md-block m-0"
            id="search-input"
            placeholder="Arama Yap"
          />
          <Icon className="search-icon" icon="iSearch" />
        </form>
        <div className="mobile-search-box ml-auto">
          <Button
            onClick={this.focusHandler}
            className={'btn-custom  mr-3 d-block d-md-none'}
          >
            <Icon icon="iSearch" />
          </Button>
        </div>
        <div
          className={`card search-result-card overflow-auto ${
            this.state.showResultBox ? 'd-flex' : ''
          }`}
          id="resultCard"
        >
          <form className="mobile-search-input-box" onSubmit={this.submitForm}>
            <button onClick={this.closeSearch} className="btn btn-mobile-back">
              <Icon icon="iMobileFilterBack" />
            </button>
            <div className="mobile-search-input">
              <button className="btn mobile-search-icon">
                <Icon icon="iSearch" />
              </button>
              <input
                ref={this.keyword}
                onChange={this.onSearch}
                onClick={this.focusHandler}
                autoComplete="off"
                type="text"
                className="form-control"
                placeholder="Arama Yap"
              />
            </div>
          </form>
          <hr className="m-0 d-none d-md-block" />
          {this.state.keyword.length > 0
            ? this.dirtySearchBox()
            : this.emptySearchBox(searchedList, recentlyViewed)}
        </div>
        <div
          onClick={this.closeSearch}
          className={`search-backdrop ${
            this.state.showResultBox ? 'active' : ''
          }`}
        />
      </div>
    );
  }
}

SearchBox.propType = {};

const mapStateToProps = ({ ProductReducer, StudyReducer }) => ({
  searchResult: ProductReducer.searchResult,
  keyword: ProductReducer.keyword,
  studySearchResult: StudyReducer.searchResult,
});

const mapDispatchToProps = {
  search: search,
  flexSearch: flexSearch,
  resetSearch: resetSearch,
  studySearch: studyActions.search,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SearchBox));
