import React, { Component } from 'react';

import { MainLogo } from '../../assets/images';

export default class UnderConstruction extends Component {
  render() {
    console.error('under construction', 'rendered');
    return (
      <article style={{ padding: '10% 0 ' }}>
        <h1 style={{ fontSize: '50px', textAlign: 'center' }}>
          <MainLogo style={{ width: '350px' }} />
          <br />
          <br />
          Sizlere daha iyi hizmet verebilmek için kısa bir süreliğine sitemizi
          bakıma aldık
        </h1>
      </article>
    );
  }
}
