import React, { Component } from 'react';
import MaskComponent from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ScoreBadge } from '../../../components/index';

class PhoneWidget extends Component {
  handleChange = (event) => {
    const { onChange } = this.props;
    const target = event.target;
    onChange(target.name, target.value);
  };

  render() {
    const { className, name, uiSchema, value, onClick, verified, readOnly } =
      this.props;
    const options = uiSchema['ui:options'];

    var activity = { Score: 0, Coins: 0 };
    if (this.props.activityTypes && this.props.activityTypes.length > 0) {
      activity = this.props.activityTypes.find(
        (x) => x.Name === 'verify-mobile',
      );
    }

    return (
      <>
        <div className="input-group mb-3">
          <MaskComponent
            className={className}
            name={name}
            value={value}
            onChange={this.handleChange}
            {...options}
            readOnly={readOnly}
          />
          {!verified ? (
            <div className="input-group-append">
              <button
                type="button"
                className="btn btn-outline-dark text-capitalize"
                disabled={readOnly}
                onClick={onClick}
              >
                Doğrula
              </button>
              <ScoreBadge
                icon="point"
                value={activity.Score}
                className="badge-score-custom mb-1"
              />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

PhoneWidget.propTypes = {};

export default PhoneWidget;
