import React, { PureComponent } from 'react';
import { setCookie } from '../../helpers/functions';

class Switch extends PureComponent {
  constructor(props) {
    super(props);
    this.switch = React.createRef();
    this.state = { checked: true };
  }
  switchTo = (e) => {
    if (!this.switch.current.checked) {
      setCookie('v2', false, 365);
    }
    this.setState({ checked: this.switch.current.checked }, () => {
      this.switch.current.disabled = true;
      window.location.href = '/';
    });
  };
  render() {
    return (
      <div className="new-design-toggle">
        {this.state.checked ? (
          <input
            type="checkbox"
            id="new-design"
            ref={this.switch}
            checked
            onChange={(e) => this.switchTo(e)}
          />
        ) : (
          <input
            type="checkbox"
            id="new-design"
            ref={this.switch}
            onChange={(e) => this.switchTo(e)}
          />
        )}

        <label for="new-design">
          <b className="switch">
            <b className="dot"></b>
          </b>
          <span>Yeni Tasarım</span>
        </label>
      </div>
    );
  }
}

export default Switch;
