import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import { routes } from '../routes';
import LoadingBar from 'react-redux-loading-bar';
import { Pomodoro, Modal } from '../components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../routes/actions/Desk/Scheduler';
import TopNavbar from './TopNavbar';

class DeskLayout extends Component {
  render() {
    const profileRoute = routes[0].routes.find((p) => p.path === '/hesabim');
    return (
      <>
        <LoadingBar
          style={{ position: 'fixed', zIndex: 99999, width: '100%' }}
        />
        <TopNavbar />
        <Navbar routes={profileRoute.routes} />
        <main role="main" className="container mt-2 mb-2">
          {routes[1].routes.map((item, i) => (
            <Route
              path={item.path}
              exact={item.routes ? false : true}
              component={item.component}
              key={i}
            />
          ))}
        </main>

        <Pomodoro />
        <Footer />
      </>
    );
  }

  componentWillUnmount() {
    if (this.props.pomodoro && this.props.pomodoro.isOn)
      this.props.updatePomodoroWarnModal(true);
  }
}

// export default DeskLayout;

DeskLayout.propTypes = {};

const mapStateToProps = ({ SchedulerReducer }) => ({
  pomodoro: SchedulerReducer.pomodoro,
  pomodoroWarnModal: SchedulerReducer.pomodoroWarnModal,
});

const mapDispatchToProps = {
  updatePomodoroWarnModal: actions.updatePomodoroWarnModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(DeskLayout);
