import React, { Component } from 'react';
import { connect } from 'react-redux';
import ItemLaterProduct from './ItemLaterProduct';
//import PropTypes from "prop-types";
import * as actions from '../../routes/actions/Account';

class ListLaterProduct extends Component {
  componentDidMount() {
    const authenticated = this.props.user.id ? true : false;
    if (
      !this.props.showCloseButton &&
      authenticated &&
      !this.props.laterProducts
    ) {
      this.props.getLaterProducts();
    }
  }

  render() {
    const { items, showMoreList, laterProducts, showCloseButton, ...rest } =
      this.props;
    return (
      <div className={`product-group mb-4 ${showMoreList ? 'active' : ''}`}>
        {items.map((product, i) => {
          return (
            <ItemLaterProduct
              showInShowcase={
                product.isDigital == true
                  ? items.some(
                      (x) => x.id == product.publishedDigitalProductConcatenate,
                    ) == true
                    ? false
                    : true
                  : true
              }
              id={i}
              key={product.id}
              product={product}
              showCloseButton={showCloseButton}
              handleRemove={this.props.handleRemove}
              {...rest}
            />
          );
        })}
      </div>
    );
  }
}

ListLaterProduct.propTypes = {};
const mapStateToProps = ({ AccountReducer }) => ({
  laterProducts: AccountReducer.laterProducts,
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  getLaterProducts: actions.getLaterProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListLaterProduct);
