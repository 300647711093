import React, { Component } from 'react';
//import PropTypes from "prop-types";
import { connect } from 'react-redux';
import classNames from 'classnames';
import _last from 'lodash/last';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { customStyles } from '../../helpers/index';
import { customFilterOption } from '../../components/Select/Utility';
import * as actions from '../../routes/actions/Account';

const IssueSelect = ({
  issues,
  onSelect,
  index,
  parentId,
  selectedValues,
  validIssue,
}) => {
  let items = [];

  if (index == 0)
    items = issues.map((issue) => {
      return { value: issue.mainSubjectCode, label: issue.mainSubjectName };
    });
  else if (index == 1) {
    items = issues
      .find((i) => i.mainSubjectCode === selectedValues[index])
      .callSubjects?.map((issue) => {
        return {
          value: issue.callSubjectCode,
          label: issue.callSubjectName,
        };
      });
  }

  const selectedValue =
    selectedValues.length > index + 1 ? selectedValues[index + 1] : null;
  const selected = items.find((p) => p.value === selectedValue);

  return (
    items.length > 0 && (
      <Select
        isSearchable={false}
        options={items}
        id="subject"
        name="subject"
        className={classNames(
          'mb-4',
          validIssue === false && index + 1 === selectedValues.length
            ? 'is-invalid'
            : 'valid',
        )}
        styles={customStyles}
        placeholder="Seçiniz"
        onChange={(selected) => {
          onSelect(index, selected);
        }}
        value={selected || null}
        key={parentId}
        filterOption={customFilterOption}
      />
    )
  );
};

class SupportFrom extends Component {
  state = {
    selected: 0,
    toggleSelected: 0,
    components: [0],
    note: '',
    orderNumber: '',
    validOrder: true,
    validIssue: true,
    validNote: true,
  };

  componentDidMount() {
    this.props.getIssueCategories();
  }

  handleOrderChange = (selected) => {
    this.setState({
      orderNumber: selected.label,
      validOrder: selected.label !== '',
    });
  };

  handleSelect = (index, { value }) => {
    const { components } = this.state;
    const { issues } = this.props;
    const items = components.slice(0, index + 1);
    items.push(value);
    this.setState({ components: items, validIssue: true });

    let categoryItems = [];
    if (index == 0)
      categoryItems = issues.map((issue) => {
        return { value: issue.mainSubjectCode, label: issue.mainSubjectName };
      });
    else if (index == 1) {
      categoryItems = issues
        .find((i) => i.mainSubjectCode === components[index])
        .callSubjects?.map((issue) => {
          return {
            value: issue.callSubjectCode,
            label: issue.callSubjectName,
          };
        });
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'form_click',
      formId: '1', //Form için bir ID değeri gönderilmelidir.
      formCategory: categoryItems.find((ci) => ci.value == value).label || '', //Formun kategori değeri gönderilmelidir.
      formStep: index + 1, //Formda bulunulan adımın değeri gönderilmelidir.
      formInput: 'Konu', //Form içerisinde kullanıcının tıkladığı input alanı girilmelidir.
    });
  };

  handleTextChange = ({ target: { value } }) => {
    this.setState({ note: value });
  };

  handleCreate = async () => {
    const { components, note, orderNumber, toggleSelected } = this.state;
    const { createCrmIssue, issues } = this.props;

    if (toggleSelected !== 0 || orderNumber) {
      if (components.length < 3) {
        this.setState({ validIssue: false });
        return;
      }
      if (!note) {
        this.setState({ validNote: false });
        return;
      }

      const mainSubject = issues.find(
        (i) => i.mainSubjectCode == components[1],
      );
      const callSubject = issues
        .find((i) => i.mainSubjectCode == components[1])
        .callSubjects?.find((cs) => cs.callSubjectCode == components[2]);

      const { success, error } = await createCrmIssue({
        mainSubjectName: mainSubject.mainSubjectName,
        mainSubjectCode: mainSubject.mainSubjectCode,
        callSubjectName: callSubject.callSubjectName,
        callSubjectCode: callSubject.callSubjectCode,
        customerMessage: orderNumber
          ? `${note} Sipariş Numarası: ${orderNumber}`
          : note,
      });
      if (success) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'form_submision',
          formId: 1, //Form için bir ID değeri gönderilmelidir.
          formCategory: mainSubject.mainSubjectName || '', //Formun kategori değeri gönderilmelidir.
          formStep: 1, //Formda bulunulan adımın değeri gönderilmelidir.
        });
        this.setState(
          {
            components: [0],
            note: '',
            orderNumber: '',
            validNote: true,
            toggleSelected: 0,
          },
          () =>
            toast.success(
              'Talebiniz alınmıştır. En kısa sürede dönüş yapılacaktır. Teşekkür ederiz.',
            ),
        );
      } else {
        toast.error(error.message);
      }
    } else {
      this.setState({ validOrder: false });
    }
  };

  render() {
    const { components, note, orderNumber, validIssue, validNote, validOrder } =
      this.state;
    const { issues, orders } = this.props;
    const items = orders.map((order) => {
      return {
        value: order.id,
        label: order.id,
      };
    });
    const selected = orderNumber
      ? items.find((p) => p.label === orderNumber)
      : null;
    return (
      <div className="card feedback-container" id="siparis-bilgi">
        <div className="card-header">
          <span className="d-inline-block fs-18 font-weight-600 color-484848">
            Siparişle ilgili
          </span>

          <div className="toggle-select ml-3">
            <div
              className={classNames('toggle-select-item', {
                active: this.state.toggleSelected === 0,
              })}
              onClick={() => this.setState({ toggleSelected: 0 })}
            >
              Evet
            </div>
            <div
              className={classNames('toggle-select-item', {
                active: this.state.toggleSelected === 1,
              })}
              onClick={() =>
                this.setState({ toggleSelected: 1, orderNumber: '' })
              }
            >
              Hayır
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="col-12 col-md-6 p-xs-0">
            {this.state.toggleSelected === 0 && (
              <div className="form-group">
                <label htmlFor="myOrders">Siparişlerim</label>
                <Select
                  isSearchable={false}
                  className={classNames(
                    validOrder === false ? 'is-invalid' : 'valid',
                  )}
                  options={items}
                  styles={customStyles}
                  placeholder="Seçiniz"
                  onChange={this.handleOrderChange}
                  value={selected}
                />
                {!validOrder && (
                  <div className="invalid-feedback">
                    Lütfen şikayette bulunmak istediğiniz siparişi seçiniz.
                  </div>
                )}
              </div>
            )}

            <div className="form-group">
              <label htmlFor="subject">Konu</label>
              {components.map((parentId, i) => (
                <IssueSelect
                  isSearchable={false}
                  key={parentId}
                  parentId={parentId}
                  issues={issues}
                  onSelect={this.handleSelect}
                  index={i}
                  selectedValues={components}
                  validIssue={validIssue}
                />
              ))}
              {!validIssue && (
                <div className="invalid-feedback">
                  Lütfen alt konu başlığı seçiniz.
                </div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="note">Konu</label>
              <textarea
                name="note"
                id="note"
                rows="3"
                className={classNames(
                  'form-control',
                  validNote === false ? 'is-invalid' : 'valid',
                )}
                onChange={this.handleTextChange}
                value={note}
              />
              {!validNote && (
                <div className="invalid-feedback">Lütfen not giriniz.</div>
              )}
            </div>
          </div>

          <button
            className="btn btn-primary border-radius-12 fs-16 font-weight-bold text-capitalize py-2 px-4 mt-2"
            onClick={this.handleCreate}
          >
            Gönder
          </button>
        </div>
      </div>
    );
  }
}

SupportFrom.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  issues: AccountReducer.issues,
});

const mapDispatchToProps = {
  getIssueCategories: actions.getIssueCategories,
  createCrmIssue: actions.createCrmIssue,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportFrom);
