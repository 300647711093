const LOGIN = 'ACCOUNT/LOGIN';
const LOGOUT = 'ACCOUNT/LOGOUT';
const GET_BASKET = 'PRODUCT/GET_BASKET';
const GET_TAXONOMIES = 'PRODUCT/GET_TAXONOMIES';
const GET_FOOTER_TAXONOMIES = 'PRODUCT/GET_FOOTER_TAXONOMIES';
const GET_JUST_TAXONOMIES = 'PRODUCT/GET_JUST_TAXONOMIES';
const GET_TAXONOMIES_BY_CODE = 'PRODUCT/GET_TAXONOMIES_BY_CODE';
const GET_PRODUCTS_BY_ID = 'PRODUCT/GET_PRODUCTS_BY_ID';
const GET_PAGE_PRODUCTS = 'PRODUCT/GET_PAGE_PRODUCTS';
const GET_RELATED_PRODUCTS = 'PRODUCT/GET_RELATED_PRODUCTS';
const GET_PRODUCT_BY_ID = 'PRODUCT/GET_PRODUCT_BY_ID';
const GET_BRANDS = 'PRODUCT/GET_BRANDS';
const GET_COINS = 'PRODUCT/GET_COINS';
const GET_COMMENTS = 'PRODUCT/GET_COMMENTS';
const POST_COMMENT = 'PRODUCT/POST_COMMENT';
const ADD_PRODUCT_TO_BASKET = 'PRODUCT/ADD_PRODUCT_TO_BASKET';
const ADD_PRODUCT_TO_NOTIFY_ME = 'PRODUCT/ADD_PRODUCT_TO_NOTIFY_ME';
const GET_SHIPPING = 'PRODUCT/GET_SHIPPING';
const SEARCH = 'PRODUCT/SEARCH';
const DELETE_PRODUCT_TO_BASKET = 'PRODUCT/DELETE_PRODUCT_TO_BASKET';
const DELETE_ALL_BASKET = 'PRODUCT/DELETE_ALL_BASKET';
const RESET_SEARCH = 'PRODUCT/RESET_SEARCH';
const SEND_VIDEO_REVIEW = 'PRODUCT/SEND_VIDEO_REVIEW';
const GET_NOTIFICATIONS = 'PRODUCT/GET_NOTIFICATIONS';
const GET_STORES = 'PRODUCT/GET_STORES';
const DO_RESERVATION = 'PRODUCT/DO_RESERVATION';
const CANCEL_RESERVATION = 'PRODUCT/CANCEL_RESERVATION';
const GET_RESERVATION = 'PRODUCT/GET_RESERVATION';
const GET_RESERVATIONS = 'PRODUCT/GET_RESERVATIONS';
const GET_ACTIVITY_TYPES = 'PRODUCT/GET_ACTIVITY_TYPES';
const GET_STORE_REVIEWS = 'PRODUCT/GET_STORE_REVIEWS';
const GET_BASKET_TAXONOMIES = 'PRODUCT/GET_BASKET_TAXONOMIES';
const GET_ALL_PRODUCTS = 'PRODUCT/GET_ALL_PRODUCTS';
const ADD_BUY_LATER_PRODUCT = 'PRODUCT/ADD_BUY_LATER_PRODUCT';
const GET_BOOK_ANALYSIS = 'PRODUCTS/GET_BOOK_ANALYSIS';

export default {
  LOGIN,
  LOGOUT,
  GET_BASKET,
  GET_TAXONOMIES,
  GET_FOOTER_TAXONOMIES,
  GET_JUST_TAXONOMIES,
  GET_TAXONOMIES_BY_CODE,
  GET_PRODUCTS_BY_ID,
  GET_PAGE_PRODUCTS,
  GET_RELATED_PRODUCTS,
  GET_PRODUCT_BY_ID,
  GET_BRANDS,
  GET_COINS,
  GET_COMMENTS,
  POST_COMMENT,
  ADD_PRODUCT_TO_BASKET,
  ADD_PRODUCT_TO_NOTIFY_ME,
  DELETE_PRODUCT_TO_BASKET,
  DELETE_ALL_BASKET,
  GET_SHIPPING,
  SEARCH,
  RESET_SEARCH,
  SEND_VIDEO_REVIEW,
  GET_NOTIFICATIONS,
  GET_STORES,
  DO_RESERVATION,
  CANCEL_RESERVATION,
  GET_RESERVATION,
  GET_RESERVATIONS,
  GET_ACTIVITY_TYPES,
  GET_STORE_REVIEWS,
  GET_BASKET_TAXONOMIES,
  GET_ALL_PRODUCTS,
  ADD_BUY_LATER_PRODUCT,
  GET_BOOK_ANALYSIS,
};
