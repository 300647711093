import React, { Component } from 'react';
//import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';
import { customStylesAccount } from '../../helpers';
import { customFilterOption } from './Utility';

const options = [{ value: '', label: 'Seçiniz...' }];

class Town extends Component {
  render() {
    const { cities, cityId, onChange, valid, value, readOnly } = this.props;
    const city = cities.find((p) => p.value === cityId);
    const items = city
      ? options.concat(
          city.towns.map((town) => {
            return { value: town.value, label: town.label };
          }),
        )
      : options;
    const selected = items.find((p) => p.value === value);
    return (
      <Select
        placeholder="Seçiniz..."
        className={classNames(valid === false ? 'is-invalid' : 'valid')}
        options={items}
        styles={customStylesAccount}
        onChange={onChange}
        value={selected || options[0]}
        // filterOption={customFilterOption}
        isDisabled={readOnly}
      />
    );
  }
}

Town.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  cities: AccountReducer.cities,
});

export default connect(mapStateToProps)(Town);
