import React, { useMemo } from 'react';
import { formatDate as dateFormatter, customSelectStyle } from '../../helpers';
import like from '../../assets/images/icons/like.svg';
import likeGray from '../../assets/images/icons/like-gray.svg';

import clock from '../../assets/images/icons/clock.svg';
import clockActive from '../../assets/images/icons/clock-active.svg';

import truck from '../../assets/images/icons/truck.svg';
import truckActive from '../../assets/images/icons/truck-active.svg';

import imgCircleCheck from '../../assets/images/icons/circle-check.svg';
import imgCircleCheckGray from '../../assets/images/icons/circle-check-gray.svg';

export default function OrderStep({ order, showOnlyLastStep }) {
  let allDigitalProducts;
  const steps = [
    {
      id: 1,
      title: 'Siparişiniz Alındı',
      date: '-',
      subTitle: '',
      icon: '',
      isCancelStatus: false,
      backgroundColor: '#edffe8',
      fontColor: '#015314',
      isActive: false,
      trackNumber: '',
      trackURL: '',
    },
    {
      id: 2,
      title: 'Siparişiniz Hazırlanıyor',
      date: '-',
      subTitle: '',
      icon: '',
      isCancelStatus: false,
      backgroundColor: '#fff1e7',
      fontColor: '#682501',
      isActive: false,
      trackNumber: '',
      trackURL: '',
    },
    {
      id: 3,
      title: 'Kargoya Verildi',
      date: '-',
      subTitle: '',
      icon: '',
      isCancelStatus: false,
      backgroundColor: '#fff6dc',
      fontColor: '#9f7600',
      isActive: false,
      trackNumber: '',
      trackURL: '',
    },
    {
      id: 4,
      title: 'Teslim Edildi',
      date: '-',
      subTitle: '',
      icon: '',
      isCancelStatus: false,
      backgroundColor: '#edffe8',
      fontColor: '#029425',
      isActive: false,
      trackNumber: '',
      trackURL: '',
    },
  ];
  const digitalSteps = [
    {
      id: 1,
      title: 'Siparişiniz Alındı',
      date: '-',
      subTitle: '',
      icon: '',
      isCancelStatus: false,
      backgroundColor: '#edffe8',
      fontColor: '#015314',
      isActive: false,
    },
    {
      id: 2,
      title: 'Teslim Edildi',
      date: '-',
      subTitle: '',
      icon: '',
      isCancelStatus: false,
      backgroundColor: '#edffe8',
      fontColor: '#029425',
      isActive: false,
    },
  ];
  const parsedOrder = useMemo(() => {
    if (!order?.deliveries?.length) return [];
    allDigitalProducts = order.lines.every((p) => p.isDigital);
    const historyItems = order.deliveries[0].statusHistory;

    let isCanceled = false;
    let isCanceledDate = '';
    let lastActiveStep = 0;
    if (allDigitalProducts) {
      digitalSteps.map((step) => {
        let activeStep = historyItems.find((h) => h.id == step.id);
        if (activeStep != undefined) {
          step.subTitle = activeStep.title.length > 0 ? activeStep.title : '';
          step.date =
            activeStep.date.length > 0 ? formatDate(activeStep.date) : '';
          step.isCancelStatus = activeStep.isCancelStatus === true;
          let maxStep = Math.max(...historyItems.map((o) => o.id));
          step.isActive =
            ((maxStep == 2 || maxStep == 4) && step.id == 2) ||
            (maxStep == 1 && step.id == 1)
              ? true
              : false;
          lastActiveStep = parseInt(step.id.toString());

          if (step.id === 1) {
            step.icon = step.isActive === true ? like : likeGray;
          }
          if (step.id === 2) {
            step.icon =
              step.isActive === true ? imgCircleCheck : imgCircleCheckGray;
            let findedItem = historyItems.find((h) => h.isCancelStatus);
            if (findedItem) {
              step.isCanceledOrder = true;
              step.isCanceledDate =
                findedItem.date.length > 0 ? formatDate(findedItem.date) : '';
              step.subTitle = findedItem.title || '';
            }
          }
        }
      });
    } else {
      steps.map((step) => {
        historyItems.map((historyItem) => {
          if (step.id === historyItem.id) {
            step.subTitle =
              historyItem.title.length > 0 ? historyItem.title : '';
            step.date =
              historyItem.date.length > 0 ? formatDate(historyItem.date) : '';
            step.isCancelStatus = historyItem.isCancelStatus === true;

            isCanceled = historyItem.isCancelStatus === true;
            isCanceledDate =
              historyItem.isCancelStatus === true
                ? historyItem.date.length > 0
                  ? formatDate(historyItem.date)
                  : ''
                : '';
            step.isActive = true;
            lastActiveStep = parseInt(step.id.toString());
          } else {
            step.isActive = false;
          }
          if (step.id === 1) {
            step.icon = step.isActive === true ? like : likeGray;
          }
          if (step.id === 2) {
            step.icon = step.isActive === true ? clockActive : clock;
          }
          if (step.id === 3) {
            step.icon = step.isActive === true ? truckActive : truck;
            step.trackNumber = order.trackNumber;
            step.trackURL = order.trackUrl2;
          }
          if (step.id === 4) {
            step.icon =
              step.isActive === true ? imgCircleCheck : imgCircleCheckGray;
            step.isCanceledOrder = isCanceled;
            step.isCanceledDate = isCanceledDate;
          }
        });
      });
    }

    if (showOnlyLastStep) {
      return allDigitalProducts
        ? digitalSteps.filter((m) => m.id === lastActiveStep)
        : steps.filter((m) => m.id === lastActiveStep);
    }

    return allDigitalProducts ? digitalSteps : steps;
  }, [order, showOnlyLastStep]);
  function formatDate(date) {
    const _date = new Date(date);
    return dateFormatter.dateCustom(_date);
  }

  return (
    <ul
      className={`list-group list-group-horizontal-sm d-md-flex justify-content-between order-detail d-sm-none ${
        showOnlyLastStep ? 'bg-transparent' : 'bg-f4f4f4'
      }`}
    >
      {parsedOrder.map((element) => (
        <li
          key={element.id}
          className="list-group-item border-0 color-484848 p-xs-0"
        >
          <div
            className="d-flex justify-content-between border-radius-16 p-2"
            style={
              element.isActive
                ? {
                    backgroundColor: `${element.backgroundColor}`,
                    color: `${element.fontColor}`,
                  }
                : { backgroundColor: 'transparent' }
            }
          >
            <div className="d-flex flex-column align-items-center">
              <div className="icon-box icon-contain d-flex align-items-center ">
                <img className="icon" src={element.icon} alt="" />
                <span className="d-block font-weight-bold">
                  {element.title}
                </span>
              </div>

              {allDigitalProducts ? (
                <>
                  <span className="font-weight-500 fs-14 ps-25">
                    {element.id === 2 && element.isCanceledOrder === true
                      ? element.isCanceledDate
                      : element.date}
                  </span>
                  {element.id === 2 && element.isCanceledOrder === true
                    ? element.subTitle
                    : element.subTitle.length > 0 && element.subTitle}
                </>
              ) : (
                <>
                  <span className="font-weight-500 fs-14 ps-25 text-center">
                    {element.id === 4 && element.isCanceledOrder === true
                      ? element.isCanceledDate
                      : element.date}
                  </span>
                  <span className="font-weight-500 fs-14 text-center kargoTakipNoText">
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          element.id === 3 && element.trackNumber > 0
                            ? 'KargoTakipNo: ' + '<br>' + element.trackNumber
                            : '',
                      }}
                    />
                  </span>
                  <span className="font-weight-500 fs-14 ps-25 text-center">
                    {element.id === 3 && element.trackNumber > 0 ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: element.trackURL }}
                      />
                    ) : (
                      ''
                    )}
                  </span>
                  <span className="ps-25">
                    {element.id === 4 && element.isCanceledOrder === true
                      ? element.subTitle
                      : element.subTitle.length > 0 && element.subTitle}
                  </span>
                </>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
