import React from 'react';
import { Modal } from '../../components';
import CloseButton from '../../components/Button/Close';

const CompleteModal = (props) => (
  <Modal
    show={props.show}
    className="modal-container complete-modal non-modal-height"
  >
    <CloseButton className="mobile-top-center" onClick={props.onCloseClick} />
    <div className="modal-body p-5">
      {props.isBlank && <p className="fs-14">Boş bıraktığın soru bulunuyor.</p>}
      <p className="fs-14 font-weight-600 color-484848">
        Yalnız bir kez değerlendirme yapılacaktır. Devam etmek istiyor musunuz?
      </p>

      <div className="d-flex justify-content-center mt-3">
        <button
          type="button"
          className="btn btn-primary ml-0 ml-sm-2 mr-2 mr-sm-0 text-capitalize border-radius-4 px-4 fs-14 font-weight-600 mt-2 mt-sm-0"
          onClick={props.onSaveClick}
        >
          Evet, Devam Et
        </button>
        <button
          type="button"
          className="btn btn-outline-dark ml-0 ml-sm-2 text-capitalize border-radius-4 px-4 fs-14 font-weight-600 mt-2 mt-sm-0"
          onClick={props.onCloseClick}
        >
          Hayır, Teste Dön
        </button>
      </div>
    </div>
  </Modal>
);

export default CompleteModal;
