import React, { Component } from 'react';
import { RadialChart } from 'react-vis';
import classNames from 'classnames';

const experience = [
  { theta: 2500, label: 'Çalışma', color: 1 },
  { theta: 500, label: 'Mola', color: 2 },
];
const motivation = [
  {
    time: 30,
    text: ['Hadi devam/Dayan biraz ...'],
    tree: ['', '-1'],
  },
  {
    time: 60,
    text: ['Hadi başlayalım', 'Hadi devam/Dayan biraz ...'],
    tree: ['', '-2_1', '-2_2'],
  },
  {
    time: 90,
    text: [
      'Hadi başlayalım',
      'Çok iyi gidiyorsun',
      'Hadi devam/Dayan biraz ...',
    ],
    tree: ['', '-3_1', '-3_2', '-3_3'],
  },
  {
    time: 120,
    text: [
      'Hadi başlayalım',
      'Süper çalışıyorsun',
      'Çok iyi gidiyorsun',
      'Hadi devam/Dayan biraz ...',
    ],
    tree: ['', '-4_1', '-4_2', '-4_3', '-4_4'],
  },
  {
    time: 150,
    text: [
      'Hadi başlayalım',
      'Çalışkan',
      'Süper çalışıyorsun',
      'Çok iyi gidiyorsun',
      'Hadi devam/Dayan biraz ...',
    ],
    tree: ['', '-5_1', '-5_2', '-5_3', '-5_4', '-5_5'],
  },
  {
    time: 180,
    text: [
      'Hadi başlayalım',
      'Süper bir seçim',
      'Çalışkan',
      'Süper çalışıyorsun',
      'Çok iyi gidiyorsun',
      'Hadi devam/Dayan biraz ...',
    ],
    tree: ['', '-6_1', '-6_2', '-6_3', '-6_4', '-6_5', '-6_6'],
  },
];
class SchedulerTree extends Component {
  state = {
    selected: 0,
    selectedMotivation: 'Hadi devam/Dayan biraz ...',
    treeName: '',
    data: experience,
  };

  handleChange() {
    const count =
      motivation.find((x) => x.time === this.props.selectedTime).text.length -
      parseInt(this.props.remaningTime / 60 / 30, 10) -
      1;
    let desc = motivation.find((x) => x.time === this.props.selectedTime).text[
      count
    ];
    const treen =
      this.props.remaningTime == 0
        ? motivation.find((x) => x.time === this.props.selectedTime).tree[
            count + 1
          ]
        : motivation.find((x) => x.time === this.props.selectedTime).tree[
            count
          ];
    const minute = this.props.remaningTime / 60;
    if (
      (this.props.selectedTime !== 30 && minute >= 25 && minute < 30) ||
      (this.props.selectedTime !== 60 && minute >= 55 && minute < 60) ||
      (this.props.selectedTime !== 90 && minute >= 85 && minute < 90) ||
      (this.props.selectedTime !== 120 && minute >= 115 && minute < 120) ||
      (this.props.selectedTime !== 150 && minute >= 145 && minute < 150)
    )
      desc = '5 dakika mola';
    this.setState({
      treeName: treen == undefined ? '' : treen,
      selectedMotivation: desc,
      data: [
        { theta: this.props.remaningTime, label: 'Çalışma', color: 1 },
        { theta: this.props.time, label: 'Mola', color: 2 },
      ],
    });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.time !== this.props.time &&
      this.props.remaningTime !== null &&
      this.props.selectedTime
    ) {
      this.handleChange();
    }
    if (prevProps.selectedTime !== this.props.selectedTime) {
      this.changeChartData();
    }
  }
  changeChartData() {
    const count = this.props.selectedTime / 30;
    let data = [];
    for (let index = 0; index < count; index++) {
      data = data.concat(experience);
    }
    this.setState({
      data: data,
    });
  }
  bindChart() {
    let html = <></>;

    if (this.props.selectedTime === 30) {
      html = (
        <>
          <span className={classNames('tree-chart-badge', 's-30--0')}>30</span>
        </>
      );
    }

    if (this.props.selectedTime === 60) {
      html = (
        <>
          <span className={classNames('tree-chart-badge', 's-60--0')}>60</span>
          <span className={classNames('tree-chart-badge', 's-60--30')}>30</span>
        </>
      );
    }

    if (this.props.selectedTime === 90) {
      html = (
        <>
          <span className={classNames('tree-chart-badge', 's-90--0')}>90</span>
          <span className={classNames('tree-chart-badge', 's-90--30')}>30</span>
          <span className={classNames('tree-chart-badge', 's-90--60')}>60</span>
        </>
      );
    }

    if (this.props.selectedTime === 120) {
      html = (
        <>
          <span className={classNames('tree-chart-badge', 's-120--0')}>
            120
          </span>
          <span className={classNames('tree-chart-badge', 's-120--30')}>
            30
          </span>
          <span className={classNames('tree-chart-badge', 's-120--60')}>
            60
          </span>
          <span className={classNames('tree-chart-badge', 's-120--90')}>
            90
          </span>
        </>
      );
    }

    if (this.props.selectedTime === 150) {
      html = (
        <>
          <span className={classNames('tree-chart-badge', 's-150--0')}>
            150
          </span>
          <span className={classNames('tree-chart-badge', 's-150--30')}>
            30
          </span>
          <span className={classNames('tree-chart-badge', 's-150--60')}>
            60
          </span>
          <span className={classNames('tree-chart-badge', 's-150--90')}>
            90
          </span>
          <span className={classNames('tree-chart-badge', 's-150--120')}>
            120
          </span>
        </>
      );
    }

    if (this.props.selectedTime === 180) {
      html = (
        <>
          <span
            className={classNames('tree-chart-badge', 'solarized', 's-180--0')}
          >
            180
          </span>
          <span
            className={classNames('tree-chart-badge', 'solarized', 's-180--30')}
          >
            30
          </span>
          <span className={classNames('tree-chart-badge', 's-180--60')}>
            60
          </span>
          <span className={classNames('tree-chart-badge', 's-180--90')}>
            90
          </span>
          <span className={classNames('tree-chart-badge', 's-180--120')}>
            120
          </span>
          <span className={classNames('tree-chart-badge', 's-180--150')}>
            150
          </span>
        </>
      );
    }

    return html;
  }
  render() {
    return (
      <>
        <RadialChart
          innerRadius={window.innerWidth > 568 ? 80 : 80}
          radius={window.innerWidth > 568 ? 100 : 65}
          getAngle={(d) => d.theta}
          data={this.state.data}
          width={window.innerWidth > 568 ? 240 : 180}
          height={window.innerWidth > 568 ? 200 : 180}
          padAngle={0.04}
          className={`m-auto ${
            this.props.desk == 'narrow' ? 'pomodoro-mobile-container' : ''
          }`}
          colorRange={['#22ff08', '#ff0909']}
        >
          <img
            src={require(
              `../../assets/images/tree/tree-step${this.state.treeName}.svg`,
            )}
            alt="..."
            className="tree-chart-img"
            className={classNames('tree-chart-img')}
          />
          {this.bindChart()}
        </RadialChart>
        {this.props.desk != 'narrow' && (
          <p className="color-484848 fs-14 text-center d-none d-sm-block font-weight-500 mt-4 mb-0">
            {this.props.selectedTime} dk
          </p>
        )}
        {this.props.desk !== 'narrow' ? (
          <p className="color-9b9b9b fs-14 text-center font-weight-600 d-block mt-2 mt-sm-0 m-0">
            {this.state.selectedMotivation}
          </p>
        ) : null}
      </>
    );
  }
}

export default SchedulerTree;
