import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class IconButton extends Component {
  render() {
    const { children, className, icon, iconClassName, ...rest } = this.props;
    return (
      <button
        type="button"
        className={classNames('btn', this.props.className)}
        {...rest}
      >
        <FontAwesomeIcon
          icon={icon}
          className={classNames('mr-2', iconClassName)}
        />
        {children}
      </button>
    );
  }
}

IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default IconButton;
