import React, { Component } from 'react';
//import PropTypes from "prop-types";
import classNames from 'classnames';
import { Manager, Reference, Popper } from 'react-popper';

class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.buttonRef = null;
    this.contentRef = null;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleClickOutside = (event) => {
    if (this.mounted !== true) {
      return;
    }
    if (this.buttonRef && this.buttonRef.contains(event.target)) {
      return;
    } else if (this.contentRef && this.contentRef.contains(event.target)) {
      return;
    }
    this.setState({ show: false });
  };

  setButtonRef = (node, ref) => {
    this.buttonRef = node;
    ref(node);
  };

  setContentRef = (node) => {
    this.contentRef = node;
  };

  handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (this.state.show) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    } else {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
    this.setState({ show: !this.state.show });
  };

  render() {
    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <button
              className="btn btn-link ml-3 box-shadow-none nav-link p-0 position-relative d-xl-flex d-none"
              ref={(node) => this.setButtonRef(node, ref)}
              onClick={this.handleClick}
            >
              {this.props.children}
            </button>
          )}
        </Reference>
        {this.state.show && (
          <Popper placement="top" innerRef={this.setContentRef}>
            {({ ref, style, placement, arrowProps }) => (
              <div
                className={classNames('card tooltip', this.props.className, {
                  show: this.state.show,
                })}
                ref={ref}
                //   style={style}
                data-placement={placement}
                onClick={this.handleClick}
              >
                {this.props.body}
                <div ref={arrowProps.ref} style={arrowProps.style} />
              </div>
            )}
          </Popper>
        )}
      </Manager>
    );
  }
}

DropdownMenu.propTypes = {};

export default DropdownMenu;
