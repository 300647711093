import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../routes/actions/Account';
import { Form } from '../../components';
import FormSchemas from '../../variables/FormSchemas';
import { toast } from 'react-toastify';

class Settings extends PureComponent {
  constructor(props) {
    super(props);
  }

  updateUserProfile = async (user) => {
    const _user = { ...user };
    if (user.schoolId && this.props.schools.length) {
      let school = this.props.schools.find((s) => s.value === user.schoolId);
      _user.schoolName = school && school.label ? school.label : '';
    }

    const { success, error } = await this.props.updateUserProfile(_user);
    if (success) {
      toast.success('Bilgileriniz güncellendi.');

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'user_data',
        userId: _user?.iamUserId,
        email: _user?.email,
        classId: _user?.classId,
      });
    } else if (error) {
      // toast.error(error);
    }
  };

  shouldComponentUpdate() {
    return false;
  }

  async componentDidMount() {
    await this.props.refreshUserInStorage();
    this.forceUpdate();
  }

  render() {
    const user =
      this.props.user.tckn === '0'
        ? Object.assign({}, this.props.user, { tckn: '' })
        : this.props.user;
    return (
      <>
        <h3 className="fs-18 color-484848 font-weight-600">Hesap Bilgilerim</h3>
        {user.demo ? (
          <Form
            schema={FormSchemas.demoAccount.schema}
            uiSchema={FormSchemas.demoAccount.uiSchema}
            formData={user}
            onSubmit={this.updateUserProfile}
            showHeader={false}
          />
        ) : (
          <Form
            schema={FormSchemas.account.schema}
            uiSchema={FormSchemas.account.uiSchema}
            formData={user}
            onSubmit={this.updateUserProfile}
            showHeader={false}
          />
        )}
      </>
    );
  }
}

Settings.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  user: AccountReducer.user,
  schools: AccountReducer.schools,
});

const mapDispatchToProps = {
  updateUserProfile: actions.updateUserProfile,
  refreshUserInStorage: actions.refreshUserInStorage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
