import React from 'react';

const ViewList = (props) => {
  return (
    <div className="view-list d-lg-flex ml-auto">
      <button
        onClick={props.list3}
        className={`btn view-button ${!props.showMoreList ? 'active' : ''}`}
      >
        <div />
        <div />
        <div />
      </button>
      <button
        onClick={props.list4}
        className={`btn view-button ${props.showMoreList ? 'active' : ''}`}
      >
        <div />
        <div />
        <div />
        <div />
      </button>
    </div>
  );
};

export default ViewList;
