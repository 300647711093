import React from 'react';

import {
  FilterQuestion,
  FilterRating,
  FilterLesson,
  FilterClasses,
} from '../../components';
//import PropTypes from 'prop-types';

const SideBar = (props) => {
  const { selectedBrandId, firstFilteredTaxonomyId, filters } = props;
  const showBrandQuantity = selectedBrandId ? false : false;

  var {
    brands: nBrands,
    taxonomies: nTaxonomies,
    filteredProducts,
    allProducts,
  } = props;

  let brands = nBrands && [...nBrands];
  const sortedBrands = brands.sort((a, b) => {
    if (a.brandType === 2 && b.brandType === 1) return -1; // 2 olanlar önce
    if (a.brandType === 1 && b.brandType === 2) return 1; // 1 olanlar sonra
    return 0; // Aynı tipse sıralamada değişiklik yapma
  });

  let taxonomies = nTaxonomies && [...nTaxonomies];
  if (filteredProducts) {
    for (let i = 0; i < taxonomies.length; i++) {
      for (let j = 0; j < taxonomies[i].taxons.length; j++) {
        var taxon = taxonomies[i].taxons[j];

        taxonomies[i].taxons[j].count = filteredProducts.filter((x) =>
          x.taxons.some((t) => t == taxon.id),
        ).length;
        taxonomies[i].count = taxonomies[i].taxons
          .map((x) => x.count)
          .reduce((a, b) => a + b, 0);
      }
    }
    for (let i = 0; i < brands.length; i++) {
      var brand = brands[i];

      if (isTaxonomyFiltered()) {
        brand.count =
          filteredProducts.filter((x) => x.brandId === brand.id).length +
          filteredProducts.filter(
            (x) =>
              x.brands.some((b) => b === brand.id) && x.brandId !== brand.id,
          )?.length;
      } else {
        brand.count =
          allProducts?.filter((x) => x.brandId === brand.id)?.length +
          allProducts.filter(
            (x) =>
              x.brands.some((b) => b === brand.id) && x.brandId !== brand.id,
          )?.length;
      }
    }
  }

  function renderTaxonItems(taxonomy) {
    if (taxonomy.type === 'point') {
      return (
        <FilterRating
          taxonomy={taxonomy}
          prefix={props.prefix}
          filters={props.filters}
          applyFilter={props.applyFilter}
        />
      );
    }
    if (taxonomy.type === 'question') {
      return (
        <FilterQuestion
          taxonomy={taxonomy}
          prefix={props.prefix}
          filters={props.filters}
          applyFilter={props.applyFilter}
          firstFilteredTaxonomyId={props.firstFilteredTaxonomyId}
        />
      );
    }
    if (taxonomy.code === 'dersler') {
      return (
        <FilterLesson
          taxonomy={taxonomy}
          prefix={props.prefix}
          filters={props.filters}
          applyFilter={props.applyFilter}
          firstFilteredTaxonomyId={props.firstFilteredTaxonomyId}
        />
      );
    }
    if (taxonomy.code === 'siniflar') {
      return (
        <FilterClasses
          taxonomy={taxonomy}
          prefix={props.prefix}
          filters={props.filters}
          applyFilter={props.applyFilter}
          selectedBrandId={props.selectedBrandId}
          showBrandQuantity={showBrandQuantity}
          firstFilteredTaxonomyId={props.firstFilteredTaxonomyId}
        />
      );
    }
    return taxonomy.taxons.length &&
      taxonomy.taxons.some((taxon) => taxon.count) &&
      (!showBrandQuantity ||
        taxonomy.taxons.some(
          (x) => x.brands && x.brands.some((b) => b.id === selectedBrandId),
        )) ? (
      <div key={taxonomy.id} className="sidebar--item">
        <h6>{taxonomy.name}</h6>
        <div className="sidebar--item-detail">
          {taxonomy.taxons.map(
            (taxon) =>
              (taxon.count > 0 ||
                taxonomy.taxons.some((t) =>
                  props.filters.some((f) => f.id == t.id),
                )) &&
              (!showBrandQuantity ||
                taxon.brands.some((x) => x.id === selectedBrandId)) && (
                <div
                  key={taxon.id}
                  className="form-group form-check brand-checkbox"
                >
                  <input
                    onChange={(e) => props.applyFilter(e, false)}
                    checked={props.filters?.some(
                      (filter) => filter.id == taxon.id,
                    )}
                    value={taxon.id}
                    type="checkbox"
                    id={`taxon-${taxon.id}${
                      props.prefix ? `-${props.prefix}` : ''
                    }`}
                    name={taxon.name}
                  />
                  <label
                    htmlFor={`taxon-${taxon.id}${
                      props.prefix ? `-${props.prefix}` : ''
                    }`}
                    className="d-flex flex-column ml-2 mb-0"
                  >
                    <h5 className="checkbox-label">{taxon.name}</h5>
                  </label>
                </div>
              ),
          )}
        </div>
      </div>
    ) : null;
  }
  const isFirstFilteredTaxonomy = (taxonomyId) => {
    if (props.filters?.length) {
      return firstFilteredTaxonomyId === taxonomyId;
    }

    return false;
  };

  return (
    <React.Fragment>
      {
        <div>
          {taxonomies?.map((taxonomy) => {
            return taxonomy.taxons.length &&
              taxonomy.taxons.some((taxon) => taxon.count) &&
              (!showBrandQuantity ||
                taxonomy.taxons.some(
                  (x) =>
                    x.brands && x.brands.some((b) => b.id === selectedBrandId),
                ))
              ? renderTaxonItems(taxonomy)
              : renderTaxonItems(taxonomy);
          })}
        </div>
      }
      {brands && brands.length && (
        <div className="sidebar--item">
          <h6>Markalar</h6>
          <div className="sidebar--item-detail">
            {sortedBrands.map(
              (brand) =>
                brand.count > 0 && (
                  <div
                    key={brand.id}
                    className="form-group form-check brand-checkbox"
                  >
                    <input
                      onChange={(e) => props.applyFilter(e, true)}
                      checked={props.filters?.some(
                        (filter) => filter.id == brand.id,
                      )}
                      value={brand.id}
                      type="checkbox"
                      id={`taxon-${brand.id}${
                        props.prefix ? `-${props.prefix}` : ''
                      }`}
                      name={brand.name}
                    />
                    <label
                      htmlFor={`taxon-${brand.id}${
                        props.prefix ? `-${props.prefix}` : ''
                      }`}
                      className="d-flex flex-column ml-2 mb-0"
                    >
                      <h5 className="checkbox-label">{brand.name}</h5>
                    </label>
                  </div>
                ),
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
  function isTaxonomyFiltered() {
    return firstFilteredTaxonomyId > 0;
  }
};

SideBar.propTypes = {};

export default SideBar;
