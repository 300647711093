import actionTypes from '../../../actions/Desk/ProfileLesson/types';

export const initialState = {
  score: {
    CurrentScore: 0,
    CurrentLevel: 0,
    TotalScore: 0,
  },
  branch: '',
  test: null,
  activities: [],
  badges: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE_LESSON:
      return {
        ...state,
        badges: action.payload.badges,
        score: action.payload.score,
        branch: action.payload.branch,
        activities: action.payload.activities,
        test: action.payload.subject,
      };
    default:
      return state;
  }
};
