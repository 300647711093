import React from 'react';
//import PropTypes from 'prop-types';
import classNames from 'classnames';

const Bar = ({ className, value }) => {
  const valueText = `${value > 100 ? 100 : value}%`;
  return (
    <div className={classNames('progress', className)}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: valueText }}
      >
        {valueText}
      </div>
    </div>
  );
};

Bar.propTypes = {};

export default Bar;
