import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RadialChart from '../Charts/RadialChart';
import { ScoreBadge } from '..';
import Tooltip from '../Tooltip/Tooltip';

class ProgressCard extends Component {
  render() {
    const { lesson } = this.props;
    return lesson ? (
      <div className="card proggress-card text-decoration-none">
        <Link to={`/dijitalkitap/profile/lesson/${lesson.id}`}>
          <div className="card-header bg-transparent border-0 pb-0 d-flex justify-content-between">
            <div
              className="card-title fs-20 font-weight-600 color-484848"
              style={{ height: 45 }}
            >
              {lesson.title.length > 15
                ? lesson.title.substring(0, 14) + '...'
                : lesson.title}
            </div>
            <div className="card-icon">
              <img src={lesson.icon} height="40" width="40" alt="" />
            </div>
          </div>

          <div className="card-body text-center p-0 pb-2">
            <RadialChart
              key={this.props.key}
              current={lesson.score}
              max={lesson.totalScore}
              labels={this.props.labels}
              label={lesson.level}
              chartHeight={window.innerWidth > 568 ? 225 : 180}
              background="#c0cbfc"
              primaryColor="#6e87fe"
              size={50}
            />

            {/* <RadialChart
                                key={mainLevel.Score.CurrentLevel}
                                current={mainLevel.Score.CurrentScore}
                                max={mainLevel.Score.CurrentScore + mainLevel.Score.DueScore}
                                labels={["Tecrübe"]}
                                label={mainLevel.Score.CurrentLevel}
                                chartHeight={250}
                                size={90}
                            /> */}

            <div className="color-9b9b9b d-none d-sm-block position-relative font-weight-600 mt-1">
              <Tooltip
                placement="top"
                trigger="hover"
                tooltip={`${lesson.level}. Seviyede toplam ${lesson.score} puan kazandın`}
              >
                {lesson.score}
              </Tooltip>{' '}
              /{' '}
              <Tooltip
                placement="top"
                trigger="hover"
                tooltip={`Bir sonraki seviye için ${lesson.totalScore} puana erişmelisin`}
              >
                {lesson.totalScore}
              </Tooltip>
            </div>

            <div>
              <ScoreBadge
                title={lesson.score + ' Puan'}
                icon="point"
                className="badge-score-custom"
              />
            </div>

            <div>
              <ScoreBadge
                title={lesson.badgeCount + ' Rozet'}
                icon="badge"
                className="badge-rosette"
              />
            </div>
            <div className="color-9b9b9b d-sm-none position-relative font-weight-600 mt-1">
              <Tooltip
                placement="top"
                trigger="hover"
                tooltip={`${lesson.level}. Seviyede toplam ${lesson.score} puan kazandın`}
              >
                {lesson.score}
              </Tooltip>{' '}
              /{' '}
              <Tooltip
                placement="top"
                trigger="hover"
                tooltip={`Bir sonraki seviye için ${lesson.totalScore} puana erişmelisin`}
              >
                {lesson.totalScore}
              </Tooltip>
            </div>
          </div>
        </Link>
      </div>
    ) : null;
  }
}

ProgressCard.defaultProps = {
  cardText: 'Matematik',
  labels: [''],
  series: [0],
};

export default ProgressCard;
