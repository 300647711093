import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal } from '../../components';
import CloseButton from '../../components/Button/Close';
import * as actions from '../../routes/actions/Account';
import { contractHelper, formatDate as dateFormatter } from '../../helpers';
import OrderStep from './OrderStep';
import { replaceDotWithComma } from '../../helpers';

const Contract = ({ contract, user, order }) => {
  let html = contract.content;
  if (user && order) {
    html = contractHelper.fillContractFields(
      html,
      contractHelper.updateFields(contract.contractKeys, { user, order }),
    );
  }
  return (
    <div
      className="card-body pt-0 fs-14"
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
};

class Order extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { showModal: null };
    this.orderId = this.props.match.params.id;
  }

  componentDidMount() {
    this.props.getOrder(this.orderId);
  }

  formatDate(date) {
    return date ? dateFormatter.dateCustom(new Date(date)) : null;
  }
  // formatDate(date) {
  //   return date ? dateFormatter.dateCustomShort(new Date(date)) : null;
  // }
  formatDateCustom(date) {
    return date ? dateFormatter.shortDate(new Date(date)) : null;
  }

  handleClose = () => {
    this.setState({ showModal: null });
  };

  handleShow = (id) => {
    this.setState({ showModal: id });
  };

  render() {
    const { order } = this.props;
    const hasDigitalProduct = this.props.order.lines.some((p) => p.isDigital);
    const OrderContracts = ({ contracts }) => {
      return (
        <div className="order-contracts">
          {contracts &&
            contracts.map((contract, i) => (
              <React.Fragment key={i}>
                <a
                  href="#"
                  className="fs-14 color-6e87ff font-weight-600"
                  onClick={() => this.handleShow(contract.id)}
                >
                  {contract.name}
                </a>
                <Modal
                  className="modal-container contract-modal non-modal-height overflow-scroll"
                  show={this.state.showModal === contract.id}
                  dialogClassName="modal-xl"
                >
                  <div className="modal-header border-0">
                    <CloseButton onClick={this.handleClose} />
                    <div className="modal-header bt-dedede:50">
                      <h5
                        className="modal-title font-weight-600 color-484848"
                        style={{ fontSize: 20 }}
                      >
                        {contract.name}
                      </h5>
                    </div>
                  </div>
                  <div className="modal-body">
                    <iframe
                      src={contract.url}
                      style={{ width: '100%', minHeight: '362px' }}
                    ></iframe>
                  </div>
                </Modal>
              </React.Fragment>
            ))}
        </div>
      );
    };
    return (
      <>
        <h3 className="fs-18 color-484848 font-weight-bold mb-4">
          Sipariş Detay
        </h3>
        <div className="card order-detail-container d-none d-sm-block">
          <div className="card-body">
            <ul className="list-group list-group-horizontal-lg d-flex justify-content-between pb-2">
              <li className="list-group-item border-0 color-484848 p-0 fs-14 font-weight-500">
                <h6>
                  Sipariş No: <span>{order.id}</span>
                </h6>

                <span>{this.formatDate(order.date)}</span>

                <h6 className="fs-15 font-weight-600 mt-4">
                  Teslimat Bilgileri
                </h6>
                {order.deliveryAddress ? (
                  <p>
                    {order.deliveryAddress.firstname}{' '}
                    {order.deliveryAddress.lastname}{' '}
                    {order.deliveryAddress.lines} <br />
                    {order.deliveryAddress.town &&
                      order.deliveryAddress.town.name}
                    /{order.deliveryAddress.city.name}
                  </p>
                ) : null}
              </li>
              <li className="list-group-item border-0 color-484848 fs-14 font-weight-500 p-0">
                <h6>Sipariş Özeti</h6>
                <span>{order.lines ? order.lines.length : 0} Ürün</span>
              </li>
              <li className="list-group-item border-0 color-484848 fs-14 font-weight-500 p-0">
                <h6>Alıcı:</h6>
                <span>{order.fullname}</span>
              </li>
              <li className="list-group-item border-0 p-0 color-484848  p-0">
                <table className="table table-borderless fs-14 font-weight-600 order-table m-0">
                  <tr>
                    <td style={{ paddingTop: 0 }}>Ürün Toplam</td>
                    <td style={{ paddingTop: 0 }}>
                      {replaceDotWithComma(order.itemTotal.toFixed(2))} ₺
                    </td>
                  </tr>
                  <tr>
                    <td>Vergi Toplam</td>
                    <td>
                      {replaceDotWithComma(Math.abs(order.taxTotal).toFixed(2))}{' '}
                      ₺
                    </td>
                  </tr>
                  <tr>
                    <td>Kargo Toplam</td>
                    <td>
                      {replaceDotWithComma(
                        (order.shipTotal - order.adjustmentTotal).toFixed(2),
                      )}{' '}
                      ₺
                    </td>
                  </tr>
                  {order.total -
                    (order.itemTotal +
                      order.taxTotal +
                      order.shipTotal -
                      order.adjustmentTotal) >
                  0 ? (
                    <tr>
                      <td>Komisyon Toplam</td>
                      <td>
                        {replaceDotWithComma(
                          (
                            order.total -
                            (order.itemTotal +
                              order.taxTotal +
                              order.shipTotal -
                              order.adjustmentTotal)
                          ).toFixed(2),
                        )}{' '}
                        ₺
                      </td>
                    </tr>
                  ) : null}

                  <tr>
                    <td>Toplam</td>
                    <td className="text-primary">
                      {replaceDotWithComma((order.total || 0).toFixed(2))} ₺
                    </td>
                  </tr>
                </table>
              </li>
            </ul>

            <div className="d-flex align-items-center flex-wrap justify-content-between">
              <OrderStep order={order} showOnlyLastStep={true} />

              <OrderContracts contracts={order.contracts} />

              <div className="d-flex">
                {order &&
                order.deliveries &&
                order.deliveries.length &&
                order.deliveries[0].statusHistory &&
                order.deliveries[0].statusHistory.length === 1 ? (
                  <div className="btn color-202124 btn-order-status mr-2 d-flex align-items-center justify-content-center">
                    Ödeme Bekleniyor
                  </div>
                ) : null}
              </div>
            </div>
            <hr />

            {hasDigitalProduct ? (
              <ul className="list-group list-group-horizontal-lg d-flex  pb-2">
                <li className="list-group-item border-0 color-484848 p-0 fs-14 font-weight-500">
                  <h6>Ürün</h6>
                  {order.lines
                    .filter((x) => x.isDigital)
                    .map((line) => (
                      <p>{line.product.name}</p>
                    ))}
                </li>
                <li
                  className="list-group-item border-0 color-484848 p-0 fs-14 font-weight-500"
                  style={{ marginLeft: '55px' }}
                >
                  <h6>Aktivasyon Kodları</h6>
                  {order.lines
                    .filter((x) => x.isDigital)
                    .map((line) => (
                      <p>{line.activationKey}</p>
                    ))}
                </li>
              </ul>
            ) : null}
            {/* <div className="row">
              <ul className="list-group list-group-horizontal-md d-flex justify-content-between">
              
              <li className="list-group-item border-0 color-484848">Ürün
                
                </li>
                <li className="list-group-item border-0 color-484848">Aktivasyon Kodu
                
                </li>
                </ul>
                {order.lines.map((line) => (                     
                        <ul className="list-group list-group-horizontal-md d-flex justify-content-between">
                          <li className="list-group-item border-0 color-484848">{line.product.name}</li>                
                          <li className="list-group-item border-0 color-484848">{line.product.id}</li>      
                        </ul>          
                      
                    ))}
              
            </div> */}
            <hr />
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-column">
                  <div className="color-919191 fs-14 font-weight-600 py-2">
                    Son İşlem: {this.formatDateCustom(order.date)}
                  </div>

                  <div className="d-flex flex-wrap">
                    {order.lines.map((line) => (
                      <div
                        className="card product-card-md"
                        onClick={() =>
                          this.props.history.push(
                            `/urunler/detay/${line.product.id}`,
                          )
                        }
                        key={line.id}
                      >
                        <figure>
                          <img
                            src={line.product.imageUrl}
                            alt={line.product.name}
                          />
                        </figure>
                        <div className="card-body text-truncate">
                          <h6 className="card-title text-wrap m-0 color-919191 fs-12 font-weight-500">
                            {line.product.name}
                            {line.price === 0 && (
                              <small
                                className="fs-12 font-weight-500 ml-2"
                                style={{ color: '#ff0000' }}
                              >
                                <i>Hediye</i>
                              </small>
                            )}
                          </h6>
                        </div>
                        <div className="card-footer">
                          <p className="card-text text-primary fs-16 font-weight-600">
                            {replaceDotWithComma(line.total.toFixed(2))} ₺
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card order-detail-container d-block d-sm-none">
          <div className="card-body">
            <ul className="list-group list-group-horizontal-lg d-flex justify-content-between pb-2">
              <li className="list-group-item border-0 color-484848 p-0">
                <div className="mb-2">
                  <h6 className="fs-14 font-weight-600 mb-0">
                    Sipariş No: <span>{order.id}</span>
                  </h6>
                  <span className="color-545454 fs-14 font-weight-500">
                    {this.formatDate(order.date)}
                  </span>
                </div>
                <div className="mb-2">
                  <h6 className="fs-14 mb-0">Sipariş Özeti:</h6>
                  <span className="fs-14">
                    {order.lines ? order.lines.length : 0} Ürün
                  </span>
                </div>
                <div className="mb-2">
                  <h6 className="fs-14 mb-0">Alıcı:</h6>
                  <span className="fs-14">{order.fullname}</span>
                </div>
              </li>
              <li className="list-group-item p-0 border-0 color-484848">
                <OrderStep order={order} showOnlyLastStep={true} />
              </li>
              <li className="list-group-item border-0 color-484848 p-0">
                <h6 className="fs-15 font-weight-600 color-484848 mt-4 mb-0">
                  Teslimat Bilgileri
                </h6>
                {order.deliveryAddress ? (
                  <p className="fs-14 color-484848 font-weight-500">
                    {order.deliveryAddress.firstname}{' '}
                    {order.deliveryAddress.lastname}{' '}
                    {order.deliveryAddress.lines} <br />
                    {order.deliveryAddress.town &&
                      order.deliveryAddress.town.name}
                    /{order.deliveryAddress.city.name}
                  </p>
                ) : null}
              </li>
              <li className="list-group-item border-top-dedede border-0 p-0">
                <table className="table table-borderless fs-14 font-weight-600 color-484848 order-table m-0">
                  <tr>
                    <td style={{ paddingTop: 0 }}>Ürün Toplam:</td>
                    <td style={{ paddingTop: 0 }}>
                      {replaceDotWithComma(order.itemTotal.toFixed(2))} ₺
                    </td>
                  </tr>
                  <tr>
                    <td>Vergi Toplam:</td>
                    <td>
                      {replaceDotWithComma(Math.abs(order.taxTotal).toFixed(2))}{' '}
                      ₺
                    </td>
                  </tr>
                  <tr>
                    <td>Kargo Toplam:</td>
                    <td>
                      {replaceDotWithComma(
                        (order.shipTotal - order.adjustmentTotal).toFixed(2),
                      )}{' '}
                      ₺
                    </td>
                  </tr>
                  <tr>
                    <td>Toplam:</td>
                    <td className="text-primary">
                      {replaceDotWithComma((order.total || 0).toFixed(2))} ₺
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
            <div className="d-flex align-items-center justify-content-between border-top-dedede">
              <div>
                <OrderContracts contracts={order.contracts} />
                <div className="color-919191 fs-14 font-weight-600 pt-2">
                  Son İşlem: {this.formatDateCustom(order.date)}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 p-0">
                <div className="d-flex flex-wrap">
                  {order.lines.map((line) => (
                    <div
                      className="card product-card-md d-flex flex-row mobile-product"
                      onClick={() =>
                        this.props.history.push(
                          `/urunler/detay/${line.product.id}`,
                        )
                      }
                      key={line.id}
                    >
                      <figure style={{ height: 60, minWidth: 46, width: 46 }}>
                        <img
                          src={line.product.imageUrl}
                          alt={line.product.name}
                        />
                      </figure>
                      <div style={{ marginLeft: 8 }}>
                        <div className="card-body">
                          <h6 className="card-title text-wrap m-0 color-919191 fs-12 font-weight-500">
                            {line.product.name}
                            {line.price === 0 && (
                              <small
                                className="fs-12 font-weight-500 ml-2"
                                style={{ color: '#ff0000' }}
                              >
                                <i>Hediye</i>
                              </small>
                            )}
                          </h6>
                        </div>
                        <div className="card-footer p-0">
                          <p className="card-text text-primary fs-14 font-weight-600">
                            {(line.total + line.taxTotal).toFixed(2)} ₺
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Order.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  order: AccountReducer.order,
  user: AccountReducer.user,
  contracts: AccountReducer.contracts,
});

const mapDispatchToProps = {
  getOrder: actions.getOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
