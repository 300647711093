import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  iconHearth,
  iconHearthOuter,
  iconProductClose,
} from '../../assets/images/icons';
import * as actions from '../../routes/actions/Account';
import {
  addProductToBasket,
  calculateProductPrice,
} from '../../routes/actions/Product';
import Icon from '../Icon/Index';
import { replaceDotWithComma } from '../../helpers';
import { addProductToNotifyMe } from '../../routes/actions/Product';
import { getSettings } from '../../routes/actions/Settings';
class ItemLaterProduct extends Component {
  constructor(props) {
    super(props);
  }

  addProductToBasket = async (product) => {
    const item = {
      key: this.props.basketKey,
      productId: product.id,
      quantity: 1,
      update: false,
      ...product,
    };

    const { success, error } = await this.props.addProductToBasket(
      item,
      this.props.allTaxonomies,
    );

    if (success) {
      toast.success('Ürün sepetinize eklendi.');
    } else {
      toast.error(error.message);
    }
  };

  removeFromLaterProducts = async (product) => {
    const item = {
      ProductId: product.id,
    };

    const { success, error } = await this.props.removeFromLaterProducts(item);

    if (success) {
      toast.success(`${product.name} daha sonra alacaklarımdan çıkarıldı`);
    } else {
      toast.error(error.message);
    }
  };

  componentDidMount() {
    this.props.getSettings();
  }

  render() {
    const { product, showCloseButton, handleRemove, showInShowcase } =
      this.props;
    return (
      product &&
      showInShowcase === true && (
        <div id={this.props.id} className="card product-card">
          <div className="product-card-inner">
            <div className="product-features"></div>
            <Link to={`/urunler/detay/${product.id}`}>
              <figure>
                <img
                  src={product.imageUrl}
                  className="product-img"
                  alt={product.name}
                  title={product.name}
                  loading="lazy"
                />
              </figure>
            </Link>
            {showCloseButton === true && (
              <button
                className="btn btn-link favories-close-button"
                style={{ width: '38px', height: '38px' }}
                onClick={() =>
                  handleRemove
                    ? handleRemove(product.id)
                    : this.removeFromLaterProducts(product, 'product')
                }
              >
                <img
                  src={iconProductClose}
                  alt=""
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '32px',
                    MozBorderRadius: '32px',
                    WebkitBorderRadius: '32px',
                  }}
                />
              </button>
            )}
            <div className="card-footer">
              <Link to={`/urunler/detay/${product.id}`}>
                <p className="product-title">{product.name}</p>
              </Link>

              {!product.isInStock && this.props.enableNotifyMe ? (
                <div className="product-operations">
                  <span className="price-pale flex-2">
                    {replaceDotWithComma(
                      calculateProductPrice(product).toFixed(2),
                    )}{' '}
                    ₺
                  </span>
                  <button
                    className="btn add-to-notify-me flex-3"
                    onClick={() => this.addProductToNotifyMe(product)}
                  >
                    Haber ver
                  </button>
                </div>
              ) : (
                <div className="product-operations">
                  <span className="price flex-2">
                    {replaceDotWithComma(
                      calculateProductPrice(product).toFixed(2),
                    )}{' '}
                    ₺
                  </span>
                  <button
                    className="btn add-to-cart flex-3"
                    onClick={() =>
                      this.addProductToBasket(product, this.props.allTaxonomies)
                    }
                  >
                    SEPETE EKLE
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

ItemLaterProduct.propTypes = {};

const mapStateToProps = ({ ProductReducer, SettingsReducer }) => ({
  basketKey: ProductReducer.basketKey,
  enableNotifyMe: SettingsReducer.enableNotifyMe,
  allTaxonomies: ProductReducer.taxonomies,
});

const mapDispatchToProps = {
  loginRequired: actions.loginRequired,
  addProductToBasket: addProductToBasket,
  removeFromLaterProducts: actions.removeFromLaterProducts,
  addProductToNotifyMe: addProductToNotifyMe,
  getSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemLaterProduct);
