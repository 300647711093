import React from 'react';

const FilterQuestion = (props) => {
  const questionItem = props.taxonomy.taxons
    .filter(
      (m) =>
        m.count > 0 ||
        props.taxonomy.taxons.some((t) =>
          props.filters.some((f) => f.id == t.id),
        ),
    )
    .map((item) => (
      <div key={item.id} className="form-group form-check brand-checkbox">
        <input
          onChange={(e) => props.applyFilter(e, false)}
          checked={props.filters?.some((filter) => filter.id == item.id)}
          value={item.id}
          id={`taxon-${item.id}${props.prefix ? `-${props.prefix}` : ''}`}
          name={item.name}
          type="checkbox"
        />
        <label
          htmlFor={`taxon-${item.id}${props.prefix ? `-${props.prefix}` : ''}`}
          className="d-flex justify-content-between ml-2 "
        >
          <h5 className="checkbox-label d-flex mb-0">{item.name}</h5>
          <img
            src={
              item.id === 'q6'
                ? null
                : require(`../../assets/images/sideBar/${item.id}.svg`)
            }
            alt=""
          />
        </label>
      </div>
    ));

  return (
    <div>
      <div className="sidebar--item">
        <div className="filter-question">
          {!props.isFilterModal && <h6>Soru Sayısı</h6>}
          <div className="mr-3">{questionItem}</div>
        </div>
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default FilterQuestion;
