import React, { Component } from 'react';
//import PropTypes from 'prop-types';

class Privacy extends Component {
  render() {
    return (
      <>
        <h3 className="mt-2">Çerez Politikası</h3>
        <div className="card">
          <div className="card-header"></div>
          <div className="card-body pt-0 fs-14">
            <p>
              Çerezler, tarayıcınız tarafından bilgisayarınızın sabit diskinde
              saklanan küçük bilgilerdir. Genellikle ziyaret ettiğiniz internet
              sitesini kullanmanız sırasında size kişiselleştirilmiş bir deneyim
              sunmak, sunulan hizmetleri geliştirmek ve deneyiminizi
              iyileştirmek için kullanılır ve bir internet sitesinde gezinirken
              kullanım kolaylığına katkıda bulunabilir.
            </p>

            <h6>TÜRLERİNE GÖRE ÇEREZLER</h6>

            <ul>
              <li>
                <strong>Zorunlu/Teknik Çerezler</strong>: Ziyaret ettiğiniz
                internet sitesinin düzgün şekilde çalışabilmesi için zorunlu
                çerezlerdir. Bu tür çerezlerin amacı, sitenin çalışmasını
                sağlamak yoluyla gerekli hizmet sunmaktır. Örneğin, internet
                sitesinin güvenli bölümlerine erişmeye, özelliklerini
                kullanabilmeye, üzerinde gezinti yapabilmeye olanak verir.
              </li>
              <li>
                <strong>Analitik Çerezler</strong>: İnternet sitesinin kullanım
                şekli, ziyaret sıklığı ve sayısı, hakkında bilgi toplayan ve
                ziyaretçilerin siteye nasıl geçtiğini gösterirler. Bu tür
                çerezlerin kullanım amacı, sitenin işleyiş biçimini
                iyileştirerek performans arttırmak ve genel eğilim yönünü
                belirlemektir. Ziyaretçi kimliklerinin tespitini sağlayabilecek
                verileri içermezler.
                <br />
                Örneğin, gösterilen hata mesajı sayısı veya en çok ziyaret
                edilen sayfaları gösterirler.
              </li>
              <li>
                <strong>İşlevsel/Fonksiyonel Çerezler</strong>: Ziyaretçinin
                site içerisinde yaptığı seçimleri kaydederek bir sonraki
                ziyarette hatırlar. Bu tür çerezlerin amacı ziyaretçilere
                kullanım kolaylığı sağlamaktır. Örneğin, site kullanıcısının
                ziyaret ettiği her bir sayfada kullanıcı şifresini tekrar
                girmesini önler.
              </li>
              <li>
                <strong>Hedefleme/Reklam Çerezleri</strong>: Ziyaretçilere
                sunulan reklamların etkinliğinin ölçülmesi ve reklamların kaç
                kere görüntülendiğinin hesaplanmasını sağlarlar. Bu tür
                çerezlerin amacı, ziyaretçilerin ilgi alanlarına özelleştirilmiş
                reklamların sunulmasıdır. Aynı şekilde, ziyaretçilerin
                gezinmelerine özel olarak ilgi alanlarının tespit edilmesini ve
                uygun içeriklerin sunulmasını sağlarlar. Örneğin, ziyaretçiye
                gösterilen reklamın kısa süre içinde tekrar gösterilmesini
                engeller.
              </li>
            </ul>

            <h6>İNTERNET SİTEMİZDE KULLANILAN ÇEREZLER</h6>

            <p>
              Sitemizin kullandığı zorunlu, fonksiyonel ve analitik çerezler:
            </p>

            <ul>
              <li>
                İnternet sitesi ara yüzünün ve özelliklerinin arzu edildiği gibi
                kullanılması için zorunludur.
              </li>
              <li>
                Gelecekte gerçekleştireceğiniz ziyaretler sırasındaki
                faaliyetleri hızlandırmanızı sağlar.
              </li>
              <li>
                İnternet sitesinin ziyaretçiler tarafından nasıl kullanıldığını
                anlamamızı sağlayarak, istatistiki verileri bir araya
                getirmemize ve bu veriler doğrultusunda içerik geliştirmemize
                yardımcı olur.
              </li>
              <li>
                Hiçbir koşulda kimliğinizi tanımlayabileceğimiz verileri
                toplamaz, saklamaz ve işlememizi sağlamaz, kişisel veri niteliği
                taşımaz.
              </li>
              <li>
                Bilgisayarınıza zarar vermez ve virüs içermez.
                <br />
                Sitemizin kullandığı zorunlu, fonksiyonel ve analitik çerezler;
                sitemizi ziyaretiniz sırasında kişisel verilerinizi elde etmekte
                olup, kişisel verileriniz temel hak ve özgürlüklerinize zarar
                vermemek kaydıyla, meşru menfaatlerimiz için zorunlu olunması
                sebebiyle işlenmektedir.
              </li>
            </ul>

            <h6>ÇEREZ TERCİHLERİ NASIL YÖNETİLİR?</h6>

            <p>
              Çerezlerin kullanımına ilişkin tercihlerinizi değiştirmek ya da
              çerezleri engellemek veya silmek için tarayıcınızın ayarlarını
              değiştirmeniz yeterlidir.
            </p>

            <p>
              Birçok tarayıcı çerezleri kontrol edebilmeniz için size çerezleri
              kabul etme veya reddetme, yalnızca belirli türdeki çerezleri kabul
              etme ya da bir internet sitesinin cihazınıza çerez depolamayı
              talep ettiğinde tarayıcı tarafından uyarılma seçeneği sunar.
            </p>

            <p>
              Aynı zamanda, daha önce tarayıcınıza kaydedilmiş çerezlerin
              silinmesi de mümkündür.
            </p>

            <p>
              Çerezleri devre dışı bırakır veya reddederseniz, bazı tercihleri
              manuel olarak ayarlamanız gerekebilir, hesabınızı
              tanıyamayacağımız ve ilişkilendiremeyeceğimiz için internet
              sitesindeki bazı özellikler ve hizmetler düzgün çalışmayabilir.
              Tarayıcınızın ayarlarını aşağıdaki tablodan ilgili link’e
              tıklayarak değiştirebilirsiniz.
            </p>

            <table width="100%" style={{ marginBottom: '1rem' }}>
              <tbody>
                <tr>
                  <td>Google Chrome</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647"
                    >
                      http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Internet Explorer</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                    >
                      https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Mozilla Firefox</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://support.mozilla.com/en-US/kb/Cookies"
                    >
                      http://support.mozilla.com/en-US/kb/Cookies
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Opera</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.opera.com/browser/tutorials/security/privacy/"
                    >
                      http://www.opera.com/browser/tutorials/security/privacy/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Safari</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://support.apple.com/kb/ph19214?"
                    >
                      https://support.apple.com/kb/ph19214?
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Microsoft Edge</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorer-delete-manage-cookies"
                    >
                      https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorer-delete-manage-cookies
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              Çerezlerin nasıl yerleştirildiği, nasıl görüleceği ve nasıl
              yönetilip silineceği dahil olmak üzere çerezler hakkında daha
              fazla bilgi almak için{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://www.allaboutcookies.org"
              >
                www.allaboutcookies.org
              </a>{' '}
              veya{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://www.aboutcookies.org"
              >
                www.aboutcookies.org
              </a>{' '}
              sayfalarını ziyaret edebilir, Politika ile ilgili tüm soru ve
              görüşlerinizi bize iletmek için ‘İletişim’ bölümünü
              kullanabilirsiniz.
            </p>
          </div>
        </div>
      </>
    );
  }
}

Privacy.propTypes = {};

export default Privacy;
