import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import CloseButton from '../components/Button/Close';
import { slugify } from '../helpers';
import { connect } from 'react-redux';

class CookiePolicy extends PureComponent {
  render() {
    const { contracts } = this.props;
    var contract = contracts && contracts.find((x) => x.contractTypeId === 4);
    return contract ? (
      <div className="cookie-policy">
        <div className="container">
          <div>
            İnternet sitemizden en iyi şekilde faydalanabilmeniz ve internet
            sitemize yapacağınız ziyaretleri kişiselleştirebilmek için
            çerezlerden faydalanıyoruz. Dilediğiniz halde çerez ayarlarınızı
            değiştirebilirsiniz. Detaylı bilgi için{' '}
            <Link
              to={`/sozlesmeler/${slugify(contract.contractName + ' ' + contract.contractTypeId)}`}
            >
              tıklayınız
            </Link>
            .
          </div>
          <CloseButton
            className="modal-mobile-white-btn-close"
            onClick={this.props.hide}
          />
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = ({ AccountReducer }) => ({
  contracts: AccountReducer.contracts,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CookiePolicy);
