const LOGIN = 'ACCOUNT/LOGIN';
const UPDATE_CLASS = 'ACCOUNT/UPDATE_CLASS';
const LOGOUT = 'ACCOUNT/LOGOUT';
const REGISTER = 'ACCOUNT/REGISTER';
const CHANGE_PASSWORD = 'ACCOUNT/CHANGE_PASSWORD';
const GET_RESERVATIONS = 'ACCOUNT/GET_RESERVATIONS';
const GET_ADDRESSES = 'ACCOUNT/GET_ADDRESSES';
const CREATE_ADDRESS = 'ACCOUNT/CREATE_ADDRESS';
const UPDATE_ADDRESS = 'ACCOUNT/UPDATE_ADDRESS';
const DELETE_ADDRESS = 'ACCOUNT/DELETE_ADDRESS';
const GET_ORDERS = 'ACCOUNT/GET_ORDERS';
const GET_COIN_ORDERS = 'ACCOUNT/GET_COIN_ORDERS';
const GET_ORDER = 'ACCOUNT/GET_ORDER';
const CREATE_ORDER = 'ACCOUNT/CREATE_ORDER';
const UPDATE_ORDER = 'ACCOUNT/UPDATE_ORDER';
const GET_SHIPPING_OPTIONS = 'ACCOUNT/GET_SHIPPING_OPTIONS';
const GET_FAVORITES = 'ACCOUNT/GET_FAVORITES';
const GET_LATER_PRODUCTS = 'ACCOUNT/GET_LATER_PRODUCTS';

const ADD_TO_FAVORITES = 'ACCOUNT/ADD_TO_FAVORITES';
const ADD_TO_FAVORITES_BRAND = 'ACCOUNT/ADD_TO_FAVORITES_BRAND';
const REMOVE_FROM_FAVORITES = 'ACCOUNT/REMOVE_FROM_FAVORITES';
const REMOVE_FROM_FAVORITES_BRAND = 'ACCOUNT/REMOVE_FROM_FAVORITES_BRAND';
const REMOVE_FROM_LATER_PRODUCTS = 'REMOVE_FROM_LATER_PRODUCTS';
const GET_CITIES = 'ACCOUNT/GET_CITIES';
const GET_TOWNS = 'ACCOUNT/GET_TOWNS';
const GET_TOWN_BY_NAME = 'ACCOUNT/GET_TOWN_BY_NAME';

const GET_SCHOOLS = 'ACCOUNT/GET_SCHOOLS';
const CHANGE_TOWN = 'ACCOUNT/CHANGE_TOWN';
const RESET_CITY_TOWN = 'ACCOUNT/RESET_CITY_TOWN';
//const GET_ISSUE_TYPES_AND_CATEGORIES = "ACCOUNT/GET_ISSUE_TYPES_AND_CATEGORIES";
const GET_ISSUE_TYPES = 'ACCOUNT/GET_ISSUE_TYPES';
const CREATE_ISSUE = 'ACCOUNT/CREATE_ISSUE';
const CHANGE_PREFERENCES = 'ACCOUNT/CHANGE_PREFERENCES';
const UPDATE_USER_PROFILE = 'ACCOUNT/UPDATE_USER_PROFILE';
const LOGIN_REQUIRED = 'ACCOUNT/LOGIN_REQUIRED';
const VERIFIED = 'ACCOUNT/VERIFIED';

const GET_CONTRACTS = 'ACCOUNT/GET_CONTRACTS';
const GET_IS_EMAIL_VERIFIED = 'ACCOUNT/GET_IS_EMAIL_VERIFIED';
const GET_CONTRACTS_NO_CONTENT = 'ACCOUNT/GET_CONTRACTS_NO_CONTENT';
const GET_UNSIGNED_CONTRACTS = 'ACCOUNT/GET_UNSIGNED_CONTRACTS';
const SIGN_CONTRACT = 'ACCOUNT/SIGN_CONTRACT';

const GET_MASTERPASS_TOKENS = 'ACCOUNT/GET_MASTERPASS_TOKENS';
const UPDATE_MASTERPASS_NUMBER = 'ACCOUNT/UPDATE_MASTERPASS_NUMBER';

const DELETE_ALL_BASKET = 'PRODUCT/DELETE_ALL_BASKET';

const FETCH_PAYMENT = 'ACCOUNT/FETCH_PAYMENT';
const SHOW_QR_SCANNER_MODAL = 'ACCOUNT/SHOW_QR_SCANNER_MODAL';
const HIDE_QR_SCANNER_MODAL = 'ACCOUNT/HIDE_QR_SCANNER_MODAL';
const HANDLE_MODAL = 'ACCOUNT/HANDLE_MODAL';
export default {
  GET_CONTRACTS,
  GET_CONTRACTS_NO_CONTENT,
  GET_IS_EMAIL_VERIFIED,
  DELETE_ALL_BASKET,
  GET_UNSIGNED_CONTRACTS,
  SIGN_CONTRACT,
  LOGIN,
  UPDATE_CLASS,
  LOGOUT,
  REGISTER,
  CHANGE_PASSWORD,
  GET_RESERVATIONS,
  GET_ADDRESSES,
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
  GET_ORDERS,
  GET_COIN_ORDERS,
  GET_ORDER,
  CREATE_ORDER,
  UPDATE_ORDER,
  GET_SHIPPING_OPTIONS,
  GET_FAVORITES,
  GET_LATER_PRODUCTS,
  ADD_TO_FAVORITES,
  ADD_TO_FAVORITES_BRAND,
  REMOVE_FROM_FAVORITES,
  REMOVE_FROM_LATER_PRODUCTS,
  REMOVE_FROM_FAVORITES_BRAND,
  GET_CITIES,
  GET_TOWNS,
  GET_TOWN_BY_NAME,
  GET_SCHOOLS,
  CHANGE_TOWN,
  RESET_CITY_TOWN,
  //GET_ISSUE_TYPES_AND_CATEGORIES,
  GET_ISSUE_TYPES,
  CREATE_ISSUE,
  CHANGE_PREFERENCES,
  UPDATE_USER_PROFILE,
  LOGIN_REQUIRED,
  VERIFIED,
  GET_MASTERPASS_TOKENS,
  UPDATE_MASTERPASS_NUMBER,
  FETCH_PAYMENT,
  SHOW_QR_SCANNER_MODAL,
  HIDE_QR_SCANNER_MODAL,
  HANDLE_MODAL,
};
