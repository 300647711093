import React, { Component, useEffect, useState } from 'react';
import { orderSuccess } from '../../variables/General';
import { LinkButton } from '../../components';
import { connect, useDispatch } from 'react-redux';
import { getOrder, checkCampaigns } from '../../routes/actions/Account';
import { deleteAllItemInBasket } from '../../routes/actions/Product';
import { Helmet } from 'react-helmet';
import mp from '../../assets/images/mp.png';
import storage from '../../services/storage';
import { keys } from '../../services/constants';
import { Link } from 'react-router-dom';

const OrderSuccessMessage = ({ order }) => {
  const dispatch = useDispatch();
  const [productCampaigns, setProductCampaigns] = useState([]);

  const getProductCampaigns = async () => {
    const _productCampaigns = await dispatch(checkCampaigns(order.id));
    setProductCampaigns(_productCampaigns);
  };
  useEffect(() => {
    getProductCampaigns();
  }, []);

  return (
    <>
      {productCampaigns.length > 0 ? (
        <a
          href={productCampaigns[0].bannerYonlendirmeLink}
          className="col-12 d-flex flex-column align-items-center justify-content-center"
        >
          <img
            src={productCampaigns[0].bannerSrc}
            alt="..."
            width={'100%'}
            height={300}
          />
          <div className="ml-0 ml-md-3 mt-3 color-484848">
            {order.lines.some((l) => l.isDigital) &&
              !order.lines.some((l) => l.isDigital == false) && (
                <>
                  <h2>Dijital Kitap siparişiniz alındı.</h2>
                  <p>
                    Dijital Kitabınıza ait aktivasyon kodu üyelik e-posta
                    adresinize ve kayıtlı telefon numaranıza sms yolu ile
                    iletildi.
                  </p>
                  <p>
                    Size göndermiş olduğumuz Aktivasyon Kodu ile dijital
                    kitabınızı{' '}
                    <Link to="/dijitalkitap">Tonguç Mağaza Dijital Kitap</Link>{' '}
                    bölümünden ya da TATS uygulaması üzerinden hemen aktif
                    edebilir ve kullanmaya başlayabilirsiniz.
                  </p>
                  <p style={{ color: 'red' }}>
                    Dijital Kitap satın alımlarında kargo ile kitap gönderimi
                    yapılmamaktadır.
                  </p>
                </>
              )}
            {order.lines.some((l) => l.isDigital) &&
              order.lines.some((l) => l.isDigital == false) && (
                <>
                  <h2>Sepetinizde bir veya birden fazla Dijital Kitap var.</h2>
                  <p>
                    Dijital Kitabınıza ait aktivasyon kodu üyelik e-posta
                    adresinize ve kayıtlı telefon numaranıza sms yolu ile
                    iletildi.
                  </p>
                  <p>
                    Size göndermiş olduğumuz Aktivasyon Kodu ile dijital
                    kitabınızı{' '}
                    <Link to="/dijitalkitap">Tonguç Mağaza Dijital Kitap</Link>{' '}
                    bölümünden ya da TATS uygulaması üzerinden hemen aktif
                    edebilir ve kullanmaya başlayabilirsiniz.
                  </p>
                  <p style={{ color: 'red' }}>
                    Dijital Kitap satın alımlarında kargo ile kitap gönderimi
                    yapılmamaktadır.
                  </p>
                  <p>
                    Siparişinizde bulunan Fijital Kitaplar (Fiziksel ve Dijital
                    Özellikli Kitaplar) ise en kısa süre içerisinde kargolanarak
                    tarafınıza gönderilecektir.
                  </p>
                </>
              )}
            {!order.lines.some((l) => l.isDigital) &&
              order.lines.some((l) => l.isDigital == false) && (
                <h2>Teşekkürler Siparişiniz Alınmıştır</h2>
              )}
            <div style={{ textAlign: 'center' }}>
              <span className="font-weight-bold pr-2">
                {' '}
                Sipariş Numaranız :
              </span>
              <span className="font-weight-bold">{order.id}</span>
            </div>
            {window.location.href.indexOf('mp=true') !== -1 ? (
              <img
                style={{ marginTop: '-3px' }}
                width="100"
                src={mp}
                alt="MasterPass"
              />
            ) : null}
          </div>
        </a>
      ) : (
        <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-center">
          <img src={orderSuccess} alt="..." style={{ maxWidth: 300 }} />
          <div className="ml-0 ml-md-3 mt-3 mt-md-0 color-484848">
            {order.lines.some((l) => l.isDigital) &&
              !order.lines.some((l) => l.isDigital == false) && (
                <>
                  <h2>Dijital Kitap siparişiniz tamamlandı.</h2>
                  <p>
                    Dijital Kitabınıza ait aktivasyon kodu üyelik e-posta
                    adresinize ve kayıtlı telefon numaranıza sms yolu ile
                    iletildi.
                  </p>
                  <p>
                    Size göndermiş olduğumuz Aktivasyon Kodu ile dijital
                    kitabınızı{' '}
                    <Link to="/dijitalkitap">Tonguç Mağaza Dijital Kitap</Link>{' '}
                    bölümünden ya da TATS uygulaması üzerinden hemen aktif
                    edebilir ve kullanmaya başlayabilirsiniz.
                  </p>
                  <p style={{ color: 'red' }}>
                    Dijital Kitap satın alımlarında kargo ile kitap gönderimi
                    yapılmamaktadır.
                  </p>
                </>
              )}
            {order.lines.some((l) => l.isDigital) &&
              order.lines.some((l) => l.isDigital == false) && (
                <>
                  <h2>Siparişiniz Alındı.</h2>
                  <p>Sepetinizde bir veya birden fazla Dijital Kitap var.</p>
                  <p>
                    Dijital Kitabınıza ait aktivasyon kodu üyelik e-posta
                    adresinize ve kayıtlı telefon numaranıza sms yolu ile
                    iletildi.
                  </p>
                  <p>
                    Size göndermiş olduğumuz Aktivasyon Kodu ile dijital
                    kitabınızı{' '}
                    <Link to="/dijitalkitap">Tonguç Mağaza Dijital Kitap</Link>{' '}
                    bölümünden ya da TATS uygulaması üzerinden hemen aktif
                    edebilir ve kullanmaya başlayabilirsiniz.
                  </p>
                  <p style={{ color: 'red' }}>
                    Dijital Kitap satın alımlarında kargo ile kitap gönderimi
                    yapılmamaktadır.
                  </p>
                  <p>
                    Siparişinizde bulunan Fijital Kitaplar (Fiziksel ve Dijital
                    Özellikli Kitaplar) ise en kısa süre içerisinde kargolanarak
                    tarafınıza gönderilecektir.
                  </p>
                </>
              )}
            {!order.lines.some((l) => l.isDigital) &&
              order.lines.some((l) => l.isDigital == false) && (
                <h2>Teşekkürler Siparişiniz Alınmıştır</h2>
              )}
            <span className="font-weight-bold pr-2"> Sipariş Numaranız :</span>
            <span className="font-weight-bold">{order.id}</span>
            {window.location.href.indexOf('mp=true') !== -1 ? (
              <img
                style={{ marginTop: '-3px' }}
                width="100"
                src={mp}
                alt="MasterPass"
              />
            ) : null}
          </div>
        </div>
      )}

      <div className="col-12 mt-5 text-center">
        {order.paymentMethod === 2 && (
          <>
            <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-center">
              <strong>Dikkat!</strong>
              <div className="ml-0 ml-md-3 mt-3 mt-md-0">
                <div>
                  Havale / EFT yaparken açıklama kısmına sipariş numaranızı
                  yazmayı unutmayınız!
                </div>
                <div>
                  Havale / EFT ile ödeme yaptıktan 1 gün sonra ödemeniz
                  onaylanacak ve kargo gönderimi sağlanacaktır.
                </div>
                <div>
                  1 gün içinde yapılmayan havale / EFT taleplerinde sipariş
                  iptal edilmektedir.
                </div>
              </div>
            </div>
            <div className="card-body fs-12 color-484848 font-weight-500">
              <p>
                <strong>Hesap Adı: </strong>Doğru Kaynak Dağıtım Teknoloji A.Ş.
              </p>
              <p>
                <strong>IBAN:</strong> TR62 0006 4000 0011 1071 0257 51
              </p>
              <p>
                <strong>Banka:</strong> Türkiye İş Bankası{' '}
                <strong>Şube Kodu:</strong> 1107 <strong>Hesap No:</strong>{' '}
                1025751
              </p>
            </div>
          </>
        )}

        {order.paymentMethod === 3 && (
          <p>
            <strong> Dikkat!</strong> Kapıda Ödeme siparişiniz operatörlerimiz
            tarafından kontrol edildikten sonra onaylayıp kargolama sürecini
            başlatacaklardır.
          </p>
        )}
        {/*
      {order.paymentMethod === 4 && (
        <p>
          <strong> Dikkat!</strong> Jeton ile Ödeme siparişinizin kargolama süreci en kısa zamanda başlayacaktır.
        </p>
      )} */}
      </div>
    </>
  );
};

class Success extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { orderNumber },
      },
    } = this.props;
    this.state = {
      orderNumber: orderNumber,
    };
  }

  async componentDidMount() {
    await this.props.getOrder(this.state.orderNumber);
    const { order } = this.props;
    if (order.paid === true) {
      this.props.deleteAllItemInBasket();
      // datalayer purchase

      const items = order.lines.map((l) => {
        return {
          item_id: l.productId,
          item_name: l.product.name,
          coupon: '',
          discount: 0,
          index: 0,
          item_brand: l.product.brand,
          item_category: l.product.category,
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_category5: '',
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: l.price.toFixed(2),
          quantity: l.quantity,
        };
      });

      if (typeof window !== 'undefined') {
        // gtm - datalayer
        if (window.dataLayer != null && !this.purchaseEventFired) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
              transaction_id: order.id,
              value: order.total.toFixed(2),
              tax: order.taxTotal.toFixed(2),
              shipping: order.shipTotal.toFixed(2),
              currency: 'TRY',
              coupon: '',
              items: items,
            },
          });
          this.purchaseEventFired = true;
        }
      }
    }
  }

  render() {
    const { order, match } = this.props;
    let ids = [];
    let products = [];

    order.lines.forEach((e) => {
      ids.push(e.productId);
      products.push(e);
    });

    const contents = order.lines.map((l) => {
      return {
        id: l.productId,
        quantity: l.quantity,
      };
    });

    const productPrice = parseInt(order.total).toFixed(2);
    const tax =
      parseInt(order.total).toFixed(2) - parseInt(order.itemTotal).toFixed(2);
    const user = storage.getItem(keys.User);
    return (
      <>
        {order && order.id && (
          <Helmet>
            <title>Tonguç'la çalış, Başarıya alış!</title>
            <link rel="canonical" href="https://www.dogrukaynak.com/" />
            <script>
              {`gtag('event', 'page_view', {
                      'send_to':'AW-596855146',
                      'ecomm_prodid': [${ids.join(',')}],
                      'ecomm_totalvalue': ${productPrice},
                      'ecomm_pagetype': 'purchase'
                    });`}
            </script>
            <script>
              {`gtag('event', 'conversion', {
                          'send_to': 'AW-10844265923/8nZsCIWcyJMDEMO7-bIo',      
                          'value':  ${productPrice},      
                          'currency': 'TRY',      
                          'transaction_id':  ${order.id}  
                      });`}
            </script>
            <script>
              {`fbq('track', 'Purchase', {   
                content_ids: [${ids.join(',')}],   
                value: ${productPrice},    
                currency: 'TRY',
                content_type: 'product',
                contents: ${JSON.stringify(contents)}
                });`}
            </script>
            <script>
              {`tdl.convert({
              'amount': ${parseInt(order.itemTotal).toFixed(2)},
              'adv_sub': '${order.id}'
              });
            
              `}
            </script>
          </Helmet>
        )}
        <div className="container">
          <div className="row success-container">
            {match.params.type === 'jeton' ? (
              <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-center">
                <img src={orderSuccess} alt="..." style={{ maxWidth: 300 }} />
                <div className="ml-0 ml-md-3 mt-3 mt-md-0 color-484848">
                  <h2>Teşekkürler Jetonlarınız hesabınıza eklenmiştir.</h2>
                </div>
              </div>
            ) : (
              <OrderSuccessMessage order={order || {}} />
            )}
            <div className="col-12 text-center">
              {match.params.type === 'sepet' ? (
                <LinkButton
                  className="btn-orange text-capitalize"
                  to="/hesabim/siparislerim"
                >
                  Siparişlerim
                </LinkButton>
              ) : (
                <LinkButton
                  className="btn-orange text-capitalize"
                  to="/hesabim/jetonlarim"
                >
                  Jetonlarım
                </LinkButton>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Success.propTypes = {};
const mapStateToProps = ({ AccountReducer, ProductReducer }) => ({
  basket: ProductReducer.basket,
  order: AccountReducer.order,
});

const mapDispatchToProps = {
  getOrder,
  checkCampaigns,
  deleteAllItemInBasket,
};

export default connect(mapStateToProps, mapDispatchToProps)(Success);
