import React, { Component } from 'react';
//import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';
import { customStylesAccount } from '../../helpers';
import * as actions from '../../routes/actions/Account';
import { customFilterOption } from './Utility';

const options = [{ value: '', label: 'Seçiniz...' }];

class City extends Component {
  componentDidMount() {
    this.props.getCities(this.props.value);
  }

  render() {
    const { cities, onChange, valid, value, readOnly } = this.props;
    const selected =
      cities && cities.length > 0 ? cities.find((p) => p.value === value) : '';

    const items = options.concat(
      cities.map((city) => {
        return { value: city.value, label: city.label };
      }),
    );

    return (
      <Select
        placeholder="Seçiniz..."
        className={classNames(valid === false ? 'is-invalid' : 'valid')}
        options={items}
        styles={customStylesAccount}
        onChange={onChange}
        value={selected || options[0]}
        // filterOption={customFilterOption}
        isDisabled={readOnly}
      />
    );
  }
}

City.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  cities: AccountReducer.cities,
});

const mapDispatchToProps = {
  getCities: actions.getCities,
};

export default connect(mapStateToProps, mapDispatchToProps)(City);
