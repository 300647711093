import AccountReducer from './Account';
import ContentReducer from './Content';
import CoinReducer from './Coin';
import AnswerReducer from './Desk/Answer';
import ProductReducer from './Product';
import StoreReducer from './Store';
import StudyReducer from './Study';
import SettingsReducer from './Settings';
import QuizReducer from './Desk/Quiz';
import ProfileLessonReducer from './Desk/ProfileLesson';
import ChaptersReducer from './Desk/Chapters';
import SchedulerReducer from './Desk/Scheduler';
import RecommendationProductsReducer from './RecommendationProducts';
import BookRecommenderReducer from './BookRecommender';
import ContactReducer from './Contact';
import ModeReducer from './Mode';
export default {
  AccountReducer,
  ContentReducer,
  ProductReducer,
  StoreReducer,
  StudyReducer,
  AnswerReducer,
  QuizReducer,
  ChaptersReducer,
  ProfileLessonReducer,
  SchedulerReducer,
  SettingsReducer,
  CoinReducer,
  RecommendationProductsReducer,
  BookRecommenderReducer,
  ContactReducer,
  ModeReducer,
};
