import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../components/Modal/Index';
import { contractHelper } from '../../helpers';
import {
  signContract,
  getUnsignedContracts,
} from '../../routes/actions/Account';

const Contract = ({ contract, user, order }) => {
  let html = contract.content;
  if (user && order) {
    html = contractHelper.fillContractFields(
      html,
      contractHelper.updateFields(contract.contractKeys, { user, order }),
    );
  }
  return (
    <div
      className="card-body pt-0 fs-14"
      style={{ height: '150px', overflowY: 'scroll' }}
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
};

const ContractNames = ({ contracts }) => {
  return (
    <>
      {contracts.map((contract, i) => (
        <React.Fragment key={i}>
          <strong>{contract.contractName}</strong>
          {i !== contracts.length - 1 ? `'ni, ` : `'ni `}
        </React.Fragment>
      ))}
    </>
  );
};

class Contracts extends Component {
  acceptContracts(contracts) {
    contracts.forEach(async (contract, i) => {
      await this.props.signContract(contract);
      // if (i === contracts.length - 1) {
      //     await this.props.getUnsignedContracts();
      // }
    });
  }
  render() {
    const { unsignedContracts, user, order } = this.props;
    const contracts = unsignedContracts.filter((x) => x.isMandatory === 1);
    return (
      <Modal
        className="modal-container non-modal-height"
        dialogClassName="modal-lg"
        show={contracts && contracts.length > 0}
      >
        <div className="modal-header bt-dedede:50">
          <h5
            className="modal-title text-center font-weight-600 color-484848"
            style={{ fontSize: 20 }}
          >
            Devam Etmek İçin Sözleşmeleri Kabul Etmeniz Gerekiyor
          </h5>
        </div>
        <div className="modal-body">
          {contracts.map((item, i) => (
            <div className="card mb-2">
              <div className="card-header">
                <h3>{item.contractName}</h3>
              </div>
              <Contract contract={item} user={user} order={order} />
            </div>
          ))}
        </div>

        <div className="modal-footer justify-content-center flex-column flex-sm-row">
          <span className="fs-15">
            <ContractNames contracts={contracts} /> okudum, anladım ve
          </span>
          <button
            type="button"
            className="btn btn-link text-primary fs-15 font-weight-500 cursor-pointer ml-0 pl-0"
            onClick={() => this.acceptContracts(contracts)}
            // name="register"
          >
            kabul ediyorum.
          </button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ AccountReducer }) => ({
  user: AccountReducer.user,
  order: AccountReducer.order,
  unsignedContracts: AccountReducer.unsignedContracts,
});

const mapDispatchToProps = {
  signContract,
  getUnsignedContracts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
