import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class RadialChart extends Component {
  constructor(props) {
    super(props);
    let series =
      [] || (this.props.max && this.props.current)
        ? [(this.props.current / this.props.max) * 100]
        : this.props.series;
    this.state = {
      options: {
        chart: {
          toolbar: {
            show: false,
          },
          // margin: [-50, 0, 0, 0]
        },
        colors: props.summary ? ['#6e87fe'] : [props.primaryColor],
        plotOptions: {
          radialBar: {
            size: props.size,
            startAngle: 0,
            endAngle: 360,
            offsetY: -15,
            hollow: {
              margin: 0,
              size: '60%',
              background: '#fff',
              position: 'front',
              dropShadow: {
                enabled: false,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              show: true,
              background: props.summary ? '#c0cbfc' : props.background,
              strokeWidth: '150%',
              opacity: 1,
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 0,
                opacity: 0,
              },
            },

            dataLabels: {
              name: {
                offsetY: -7,
                show: true,
                color: props.summary ? '#6e87fe' : props.primaryColor,
                fontSize: '14px',
              },
              value: {
                formatter: function (val) {
                  var v =
                    props.label ||
                    (props.prefix ? props.prefix : '') + parseInt(val);
                  return v;
                },
                color: '#484848',
                fontSize: props.prefix ? '28px' : '32px',
                fontWeight: 'bold',
                show: true,
                offsetY: 5,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
        labels: props.labels,
      },
      series: series,
    };
  }

  render() {
    return (
      <Chart
        className="apexchart-value-bold"
        options={this.state.options}
        series={
          this.props.max && this.props.current
            ? [(this.props.current / this.props.max) * 100]
            : this.props.series
        }
        type="radialBar"
        height={this.props.chartHeight}
        width={this.props.chartWidth}
      />
    );
  }
}

RadialChart.defaultProps = {
  background: '#ffcec5',
  primaryColor: '#fe846e',
  labels: [''],
  series: [0],
  chartHeight: 200,
  prefix: '',
  size: undefined,
};

export default RadialChart;
