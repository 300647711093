import actionTypes from './types';
import apiCall from '../../../services/api';

export const getContact = () => async (dispatch) => {
  const { data } = await apiCall('/contact/get');
  dispatch({
    type: actionTypes.GET_CONTACT,
    payload: data,
  });
};
