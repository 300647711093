import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

class MPSecure3D extends Component {
  componentDidMount() {
    const { location } = this.props;
    window.parent.postMessage(
      {
        source: 'payment-frame-result',
        payload: queryString.parse(location.search),
      },
      '*',
    );
  }
  render() {
    return (
      <>
        <form method="POST" name="MP3DForm" id="MP3DForm">
          Lütfen bekleyiniz...
        </form>
      </>
    );
  }
}

MPSecure3D.propTypes = {
  location: PropTypes.object.isRequired,
};

export default MPSecure3D;
