import React, { Component } from 'react';
//import PropTypes from "prop-types";
import { ImageOrientationSelect } from '../..';

class ImageOrientation extends Component {
  handleChange = (selected) => {
    const { name, onChange } = this.props;
    onChange(name, selected.value);
  };

  render() {
    const { name, valid, value } = this.props;
    return (
      <ImageOrientationSelect
        name={name}
        onChange={this.handleChange}
        value={value}
        valid={valid}
      />
    );
  }
}

ImageOrientation.propTypes = {};

export default ImageOrientation;
