import actionTypes from '../../actions/BookRecommender/types';

export const initialState = {
  classes: [],
  branches: [],
  recommendBook: [],
};

export default function RecommendationProductsReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case actionTypes.GET_CLASSES:
      return {
        ...state,
        classes: action.payload,
      };
    case actionTypes.GET_BRANCHES_BY_CLASS_ID:
      return {
        ...state,
        branches: action.payload,
      };
    case actionTypes.GET_RECOMMEND_BOOK:
      return {
        ...state,
        recommendBook: action.payload,
      };
    default:
      return state;
  }
}
