import React, { Component } from 'react';
import { underConstruction } from '../variables/General';

class ErrorLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: [],
    };
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      // history.push("/", {});
      window.location.href = window.location.origin.toString();
    }, 10000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    // console.error('error page', 'rendered');
    return (
      <main role="main" className="container mt-2 h-100">
        {/* <Switch>
          {errorRoutes.map((item, i) => (
            <Route
              path={item.path}
              exact={item.routes ? false : true}
              component={item.component}
              key={i}
            />
          ))}
          <Route component={NotFound} />
        </Switch> */}
        <div className="row text-center">
          <div className="col-xl-12 justify-content-center">
            <img src={underConstruction} alt="..." style={{ maxWidth: 500 }} />
          </div>
          <div className="col-12 mt-1">
            {/* <h1 className="text-muted">
              Bakım yapılıyor lütfen daha sonra tekrar deneyin
            </h1> */}
            <p>Sayfa otomatik olarak yenilenecektir..</p>
          </div>
          <div className="col-12">
            <a
              className="btn-primary p-2"
              href={window.location.origin.toString()}
            >
              Ana Sayfa
            </a>
          </div>
        </div>

        {/* <div className="row">
          <div className="col">
            <div className="alert alert-danger" role="alert">
              <p className="text-center">Bakım yapılıyor lütfen daha sonra tekrar deneyin.</p>
              <div
                className="btn-toolbar justify-content-center"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <LinkButton className="btn-primary" to={`/`}>
                  Anasayfa
                </LinkButton>
              </div>
            </div>
          </div> 
        </div>*/}
      </main>
    );
  }
}

export default ErrorLayout;
