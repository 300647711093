import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

class CheckButton extends Component {
  state = { checked: false };
  render() {
    return (
      <button
        type="button"
        className={classNames('btn', this.props.className)}
        onClick={() => this.setState({ checked: !this.state.checked })}
        title={this.props.title}
      >
        <FontAwesomeIcon
          icon={this.state.checked ? faCheckSquare : faSquare}
          className="mr-2"
        />
        {this.props.children}
      </button>
    );
  }
}

CheckButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default CheckButton;
