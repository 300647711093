import React, { Component } from 'react';
//import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Select from 'react-select';
import { customStyles } from '../../helpers';
import * as actions from '../../routes/actions/Product';
import { customFilterOption } from './Utility';

const options = [{ value: '', label: 'Tüm Markalar' }];

class Brand extends Component {
  componentDidMount() {
    this.props.getBrands();
  }

  render() {
    const { brands, onChange, user } = this.props;
    const items = options.concat(
      brands.map((brand) => {
        return { value: brand.id, label: brand.name };
      }),
    );

    const selected = items.find((p) => p.value === user.brandId);
    return (
      <Select
        isSearchable={false}
        isMulti={true}
        placeholder="Marka seçiniz"
        options={items}
        styles={customStyles}
        onChange={onChange}
        value={selected}
        filterOption={customFilterOption}
        isDisabled={selected ? true : false}
      />
    );
  }
}

Brand.propTypes = {};

const mapStateToProps = ({ AccountReducer, ProductReducer }) => ({
  user: AccountReducer.user,
  brands: ProductReducer.brands,
});

const mapDispatchToProps = {
  getBrands: actions.getBrands,
};

export default connect(mapStateToProps, mapDispatchToProps)(Brand);
