import moment from 'moment';
import 'moment/locale/tr';
import { isIOS } from 'react-device-detect';

//initalize moment locale.
moment.locale('tr');

const shortDate = (date) => moment(date).locale('tr').format('Do MMMM');

const shortTime = (date) => moment(date).locale('tr').format('Do MMMM - h:mm');

const dateCustom = (date) => moment(date).locale('tr').format('D MMMM - h:mm');

const dateCustomShort = (date) => moment(date).locale('tr').format('D MMMM');

const fromNow = (date) => moment(date).locale('tr').fromNow();

//eslint-disable-next-line
const date = (date) => moment(date).locale('tr').format('DD.MM.YYYY');
const dateLong = (date) => {
  if (isIOS) {
    return moment(new Date(date))
      .add(-3, 'hours')
      .locale('tr')
      .format('DD MMMM dddd YYYY - HH.mm');
  }
  return moment(new Date(date))
    .locale('tr')
    .format('DD MMMM dddd YYYY - HH.mm');
};
//eslint-disable-next-line
const toCode = (date) => moment(date).locale('tr').format('DDMMYYYY');

export const formatDate = {
  shortDate,
  shortTime,
  fromNow,
  date,
  dateLong,
  toCode,
  dateCustom,
  dateCustomShort,
};
