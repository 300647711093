import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Select from 'react-select';
import 'react-popper-tooltip/dist/styles.css';
import { Button, Modal, RatingSelect } from '../../components';
import CloseButton from '../Button/Close';
import {
  //GoogleMapsImage,
  GoogleMapsSearch,
  //GoogleMapsAPIKey
} from '../../services/constants';
import { formatDate as dateFormatter, customStyles } from '../../helpers';
import { geolocated } from 'react-geolocated'; // to find location
// import Geocode from "react-geocode"; //to find address from location
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { getTownByName } from '../../routes/actions/Account';
import { doReservation, getStoreReviews } from '../../routes/actions/Product';

import { pilotTowns } from '../../services/constants';

const sortOptions = [
  {
    value: 'order',
    label: 'Sırala',
  },
  {
    value: 'close',
    label: 'Yakınlığa göre',
  },
  {
    value: 'point',
    label: 'Puana göre',
  },
];

const AdressTooltip = ({ store, selectedItem }) => (
  <div
    className="card p-2"
    style={{
      zIndex: 2,
      width: '18rem',
      position: 'fixed',
      top: '50%',
      transform: 'translateY(-50%)',
      display: selectedItem.addressId === store.addressId ? 'block' : 'none',
    }}
  >
    <a
      href={GoogleMapsSearch(store.address)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {/* <img
        className="card-img-top"
        src={GoogleMapsImage(store.address, "272x136", 15)}
        alt={store.name}
      /> */}
    </a>
    <div className="card-body">
      <h6 className="card-title">{store.name}</h6>
      <p className="card-text">{store.address}</p>
    </div>
  </div>
);

const comments = [{ id: 1 }, { id: 2 }, { id: 3 }];

class Store extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      showStores: false,
      comments,
      selectedItem: {},
      distances: [],
      activeStore: {},
      sortType: sortOptions[0],
    };

    this.google = {};
  }

  onChange = (option) => {
    this.setState({ sortType: option });
  };

  onScriptLoad = () => {
    this.google = window.google;
  };

  // componentDidMount() {
  //   if (!window.google) {
  //     const s = document.createElement("script");
  //     s.type = "text/javascript";
  //     s.src = `https://maps.google.com/maps/api/js?key=${GoogleMapsAPIKey}`;
  //     const x = document.getElementsByTagName("script")[0];
  //     x.parentNode.insertBefore(s, x);
  //     // Below is important.
  //     //We cannot access google.maps until it's finished loading
  //     s.addEventListener("load", e => {
  //       this.onScriptLoad();
  //     });
  //   } else {
  //     this.onScriptLoad();
  //   }
  // }

  formatDate(date) {
    return date ? dateFormatter.dateLong(new Date(date)) : null;
  }

  getStores = async () => {
    const townId =
      this.props.user && this.props.user.town ? this.props.user.town.id : 0;
    let { locationByTown } = this.props;
    let tId = locationByTown.id > 0 ? locationByTown.id : townId;
    if (tId > 0) {
      let lines = this.props.product
        ? [
            {
              productId: this.props.product.id,
              quantity: this.props.productCount,
            },
          ]
        : this.props.basket.filter((x) => x.total !== 0);
      await this.props.getStores(tId, lines);

      let addresses = [];
      addresses = this.props.stores.map(
        (s) => `${s.address} ${s.town.name}/${s.city.name}`,
      );

      if (this.props.coords && this.props.coords.latitude) {
        const service = new this.google.maps.DistanceMatrixService();

        service.getDistanceMatrix(
          {
            origins: [
              `${this.props.coords.latitude},${this.props.coords.longitude}`,
            ],
            destinations: addresses,
            travelMode: this.google.maps.TravelMode.DRIVING,
            unitSystem: this.google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          (result, status) => {
            if (status === 'OK') {
              this.setState({ distances: result.rows[0].elements });
            }
          },
        );
      }
    } else {
      toast.error(
        'Konum bilginiz bulunamadığından en yakın kırtasiyeyi bulamadık :(',
      );
    }
  };

  showCommentsModal = async (event, store) => {
    event.preventDefault();
    await this.props.getStoreReviews(store.id);
    this.setState({ show: true, activeStore: store });
  };

  showStoresModal = async (e) => {
    e.preventDefault();
    await this.getStores();
    this.setState({ showStores: true });
  };

  closeCommentsModal = () => {
    this.setState({ show: false });
  };

  closeStoresModal = () => {
    this.setState({ showStores: false });
  };

  showMore = () => {
    const { comments } = this.state;
    for (let index = 5; index < 15; index++) {
      this.state.comments.push({ id: index });
    }
    this.setState({ comments });
  };

  // componentDidUpdate() {
  //   if (
  //     this.props.coords &&
  //     this.props.coords.latitude &&
  //     this.props.locationByTown.id === 0
  //   ) {
  //     Geocode.setApiKey(GoogleMapsAPIKey);
  //     Geocode.enableDebug();
  //     Geocode.fromLatLng(
  //       this.props.coords.latitude,
  //       this.props.coords.longitude
  //     ).then(
  //       async response => {
  //         const address = response.results[0];
  //         const city = address.address_components.find(x =>
  //           x.types.some(t => t === "administrative_area_level_1")
  //         );
  //         const town = address.address_components.find(x =>
  //           x.types.some(t => t === "administrative_area_level_2")
  //         );
  //         await this.props.getTownByName(city.long_name, town.long_name);
  //       },
  //       error => {
  //         console.error("gc-error", error);
  //       }
  //     );
  //   }
  // }

  showStore = (store) => {
    this.setState({ selectedItem: store });
  };

  doReservation = async (e, store) => {
    e.preventDefault();

    let lines = this.props.product
      ? [
          {
            productId: this.props.product.id,
            quantity: this.props.productCount,
            name: this.props.product.name,
          },
        ]
      : this.props.basket
          .filter((x) => x.total !== 0)
          .map((x) => ({
            productId: x.productId,
            quantity: x.quantity,
            name: x.product.name,
          }));

    const reservation = {
      StoreId: store.id,
      StoreAddressId: store.addressId,
      StoreName: store.name,
      Products: lines,
    };

    const { success, error } = await this.props.doReservation(
      reservation,
      this.props.history.location.pathname.indexOf('odeme') !== -1,
    );

    if (success) {
      this.closeStoresModal();
      this.props.history.push('/urunler/rezervasyon');
    } else {
      toast.error(error);
    }
  };

  render() {
    if (
      (this.props.isGeolocationAvailable === false ||
        this.props.isGeolocationEnabled === false) &&
      (!this.props.user.town || !(this.props.user.town.id > 0))
    ) {
      return null;
    }

    const townId =
      this.props.user && this.props.user.town ? this.props.user.town.id : 0;
    let { locationByTown } = this.props;
    let t = locationByTown.id > 0 ? locationByTown.id : townId;
    if (!pilotTowns.includes(t)) {
      return null;
    }

    let townName =
      locationByTown.id > 0 ? locationByTown.name : this.props.user.town.name;

    const _stores = Object.assign([], this.props.stores);

    this.state.distances.forEach((result, i) => {
      _stores[i].title = `${_stores[i].name} ${
        result.status === 'OK' ? `(${result.distance.text})` : ''
      }`;
      _stores[i].distance = result.status === 'OK' ? result.distance.value : 0;
    });

    let storeList = [];

    switch (this.state.sortType.value) {
      case sortOptions[1].value:
        storeList = _stores.concat().sort((a, b) => a.distance - b.distance);
        break;
      case sortOptions[2].value:
        storeList = _stores.concat().sort((a, b) => b.rating - a.rating);
        break;
      default:
        storeList = _stores;
        break;
    }

    return this.state.showStores === false ? (
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary btn-search-store"
        onClick={this.showStoresModal}
      >
        En Yakın Kitapçıdan Al
      </button>
    ) : (
      <Modal
        show={this.state.showStores}
        onCloseClick={this.closeStoresModal}
        dialogClassName="modal-lg"
        className="modal-container non-modal-height library-store"
      >
        <CloseButton
          className="mobile-top-center modal-mobile-white-btn"
          onClick={this.closeStoresModal}
        />
        <div>
          <div className="modal-header d-flex flex-column flex-sm-row align-items-center">
            <div>
              <h5>EN YAKIN KİTAPÇIDAN AL</h5>
            </div>
            <div>
              <Select
                isSearchable={false}
                options={sortOptions}
                styles={customStyles}
                onChange={this.onChange}
                defaultValue={sortOptions[0]}
                className="w-200"
              />
            </div>
          </div>
        </div>
        <div className="modal-body">
          {this.props.stores.length === 0 && (
            <>
              <div className="alert alert-danger" role="alert">
                {`${townName} ilçesinde size yakın kitapçılarımızın stoğunda ürün(ler) bulunmamaktadır.`}
              </div>
            </>
          )}
          <ul
            className="list-group list-group-flush stores-list d-none d-lg-flex"
            style={{ minHeight: 315 }}
          >
            {storeList.map((store) => (
              <div
                key={store.addressId}
                className="list-item"
                //onMouseLeave={() => this.showStore({})}
                onMouseOver={() => this.showStore(store)}
              >
                <AdressTooltip
                  store={store}
                  selectedItem={this.state.selectedItem}
                />
                <li className="list-group-item">
                  <h6 className="store-title">{store.name}</h6>
                  <h2 className="rating-component">
                    <RatingSelect initialRating={store.rating / 10} readonly />
                    <button
                      type="button"
                      className="rate-text"
                      onClick={(event) => this.showCommentsModal(event, store)}
                    >
                      {store.commentsCount} yorum
                    </button>
                  </h2>
                  <Button
                    type="button"
                    className="btn btn-link btn-primary stores-reservation-button"
                    onClick={(e) => this.doReservation(e, store)}
                  >
                    Rezerve Et
                  </Button>
                </li>
              </div>
            ))}
          </ul>

          <ul
            className="list-group list-group-flush d-flex d-lg-none overflow-auto"
            style={{ maxHeight: 300 }}
          >
            {storeList.map((store) => (
              <li
                key={store.id}
                className="list-group-item border-0 bt-dedede:50 p-0 pb-2 mb-2 d-flex justify-content-between"
              >
                <div className="flex-1">
                  <h6 className="fs-14 font-weight-500 color-919191 mb-3">
                    {store.name}
                  </h6>
                  <RatingSelect initialRating={store.rating / 10} readonly />
                  <span
                    className="color-484848 fs-14 ml-3"
                    onClick={(event) => this.showCommentsModal(event, store)}
                  >
                    {`${store.commentsCount} Yorum`}
                  </span>
                  <div className="mt-3">
                    <button
                      onClick={(e) => this.doReservation(e, store)}
                      type="button"
                      className="btn btn-primary text-capitalize"
                    >
                      Rezerve Et
                    </button>
                  </div>
                </div>
                <div>
                  {/* <img
                    style={{ width: 100 }}
                    src={GoogleMapsImage(store.address, "100x100", 15)}
                    alt={store.name}
                  /> */}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <Modal
          show={this.state.show}
          onCloseClick={this.closeCommentsModal}
          dialogClassName="modal-lg"
        >
          <div className="modal-container">
            <div className="modal-header">
              <CloseButton onClick={this.closeCommentsModal} />
              <h5>
                {this.state.activeStore.name}{' '}
                <span className="text-muted">
                  ({this.state.activeStore.commentsCount} yorum)
                </span>
              </h5>
            </div>
            <div className="modal-body">
              <ul className="list-group list-group-flush comment-list">
                {this.props.storeReviews.map((comment) => (
                  <>
                    <li
                      className="list-group-item comment-item border-none p-0 mt-2"
                      key={comment.id}
                    >
                      <div>
                        <figure className="figure mr-2 float-left">
                          <div className="default-avatar">
                            {comment.name
                              ? comment.name.charAt(0).toUpperCase() +
                                comment.name
                                  .split(' ')
                                  .slice(-1)[0]
                                  .charAt(0)
                                  .toUpperCase()
                              : 'DK'}
                          </div>
                        </figure>
                        <span>
                          <span className="comment-user-name mt-0">
                            {comment.name}
                          </span>
                          <br />
                          <span className="time">
                            {this.formatDate(comment.insertDate)}
                          </span>
                        </span>
                      </div>
                      <div>
                        <h2 className="rating-component mb-0">
                          <RatingSelect
                            initialRating={Math.round(
                              (comment.proximityReview +
                                comment.findingProductReview +
                                comment.genialityReview) /
                                10 /
                                3,
                            )}
                            readonly
                          />
                        </h2>
                        <p className="comment">{comment.comment}</p>
                      </div>
                    </li>
                  </>
                ))}
              </ul>
              {/* <div className="d-flex flex-row justify-content-between">
                <button
                    type="button"
                    className="btn btn-link btn-show-more"
                    onClick={this.showMore}
                >
                    Daha Fazla Göster(125)
                </button>
                </div> */}
            </div>
          </div>
        </Modal>
      </Modal>
    );
  }
}

Store.propTypes = {};

const mapStateToProps = ({ AccountReducer, ProductReducer }) => ({
  user: AccountReducer.user,
  locationByTown: AccountReducer.locationByTown,
  basket: ProductReducer.basket,
  storeReviews: ProductReducer.storeReviews,
});

const mapDispatchToProps = {
  doReservation,
  getTownByName,
  getStoreReviews,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  geolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  })(withRouter(Store)),
);
