import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './configureStore';
import ReactGA from 'react-ga';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { cssTransition, ToastContainer } from 'react-toastify';
import {
  DeskLayout,
  StoreLayout,
  FrameLayout,
  MessageLayout,
  BlogLayout,
} from './layout';
import LoginWithToken from './views/Account/LoginWithToken';
import RedirectToProductShow from './views/Account/RedirectToProductShow';
import RedirectToFilterProduct from './views/Account/RedirectToFilterProduct';
import ScrollToTop from './ScrollToTop';
import ErrorLayout from './layout/ErrorLayout';
import MPSecure3D from './views/Payment/MPSecure3D';
import Mobile3dPayment from './views/Payment/Mobile3dPayment';
import { Helmet } from 'react-helmet';
import Modal from './components/Modal/Index';

import CloseButton from './components/Button/Close';
import moment from 'moment';
export const store = configureStore({});

const initializeReactGA = () => {
  ReactGA.initialize('UA-94050230-1');
  ReactGA.initialize('AW-596855146');
  history.listen((location) => ReactGA.pageview(location.pathname));
  //ReactGA.pageview("/");
  /* ReactGA.event({
    category: 'User',
    action: 'Create an Account'
  }); */
};

const Zoom = cssTransition({
  // zoomIn will become zoomIn--top-right or zoomIn--top-left and so on
  enter: 'zoomIn',
  // zoomIn will become zoomOut--top-right or zoomOut--top-left and so on
  exit: 'zoomOut',
  duration: [750, 750],
  // default to false
  // appendPosition: true
});

initializeReactGA();

const oldToNewMap = {
  '/tongucmagaza': '/urunler',
  '/tongucmagaza/siparisim': '/sepetim',
  '/bilgilerim': '/hesabim/hesap-bilgilerim',
  '/bilgilerim/gecmissiparislerim': '/hesabim/siparislerim',
  '/bilgilerim/iletisim': '/hesabim/adres-bilgilerim',
  '/bilgilerim/yeniparola': '/hesabim/sifre-degistir',
  '/satisnoktasi': '/satis-noktalarimiz',
  '/hakkimizda': '/biz-kimiz',
  '/merhaba/contract/120': '/sozlesmeler/gizlilik-sozlesmesi-3',
  '/merhaba/contract/116': '/sozlesmeler/mesafeli-satis-sozlesmesi-2',
  '/merhaba/contract/103': '/sozlesmeler/aydinlatma-ve-riza-metni-7',
  '/merhaba/contract/100': '/sozlesmeler/uyelik-sozlesmesi-1',
};

class App extends Component {

  state = {
    showPopup: false
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const utmCampaign = urlParams.get('utm_campaign');
    var activeDate = moment(new Date()).format('YYYY-MM-DD')
    if(activeDate == "2024-11-29" && utmCampaign !=undefined && utmCampaign == "tm-super-cuma-yuzde-15"){
       this.setState({ showPopup: true })
    }
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Helmet>
            <meta
              name="description"
              content="Güncel müfredata uygun soru bankaları, konu anlatımlı kitaplar ve denemeler uygun fiyat, taksit ve kapıda ödeme seçenekleri ile Tonguç Mağaza’da."
            />
            <title>Tonguç’la çalış, Başarıya alış!</title>
          </Helmet>
          <ScrollToTop>
            <Switch>
              <Redirect from="/index.html" to="/" />
              <Route path="/desk">
                <Redirect to="/dijitalkitap" />
              </Route>
              {Object.keys(oldToNewMap).map((oldPath) => (
                <Redirect
                  key={oldPath}
                  from={oldPath}
                  to={oldToNewMap[oldPath]}
                  exact={true}
                />
              ))}
              <Route
                path="/odeme/mobile-3d/:orderId"
                component={Mobile3dPayment}
              />
              <Route
                path="/tongucmagaza/filter/:rest*"
                component={RedirectToFilterProduct}
              />
              <Route
                path="/tongucmagaza/kitapdetay/:productId"
                component={RedirectToProductShow}
              />
              <Route path="/lwt" component={LoginWithToken} />
              <Route path="/dijitalkitap" component={DeskLayout} />
              <Route path="/blog" component={BlogLayout} exact={true} />
              <Route path="/MPSecure3D" component={MPSecure3D} />
              <Route path="/pay3d" component={FrameLayout} />
              <Route path="/bakim-modu" component={ErrorLayout} exact={true} />
              <Route path="/" component={StoreLayout} />
              <Route path="/bakim" component={MessageLayout} exact={true} />
            </Switch>
          </ScrollToTop>
        </ConnectedRouter>

        <Modal
          dialogClassName="modal-lg"
          show={this.state.showPopup}
          onCloseClick={() => this.setState({ showPopup: false })}
          className="modal-container non-modal-height registration-modal"
        >
          <div className="modal-header">
                <div className="modal-header-mp text-center">
                  <h5 style={{width: '100%'}}>tonguçMAĞAZA Süper Cuma Fırsatı Başladı!</h5>
                </div>
                <CloseButton onClick={() => this.setState({ showPopup: false })} />
          </div>
          <div className="modal-body">
            <div className="col-12 col-md-12 text-center">
            Tüm Kitap Siparişlerinde Geçerli %15 İndirim İçin Kupon Kodu :
            <h5></h5>
            <h5>SUPER15</h5>
            </div>
          </div>
        </Modal>
        <ToastContainer
          toastClassName="toast"
          transition={Zoom}
          autoClose={5000}
        />
      </Provider>
    );
  }
}

export default App;
