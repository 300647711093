import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Fields from './Fields';
import ajv from './Validate';
import { deepEquals } from './Utility';
import _get from 'lodash/get';

const FieldList = ({
  schema,
  uiSchema,
  formData,
  keys,
  errors,
  accountSettings,
  ...props
}) => {
  const rows = [];
  var index = -1;
  keys.forEach((name) => {
    const fieldSchema = schema.properties[name];
    const fieldUiSchema = uiSchema[name];
    const value = _get(formData, name);
    // const placeholder = fieldUiSchema && fieldUiSchema["ui:placeholder"];
    const readOnly =
      fieldUiSchema && fieldUiSchema['ui:readonly']
        ? fieldUiSchema['ui:readonly']
        : false;
    const fieldErrors = errors ? errors[name] : [];

    const args = {};
    const options = fieldUiSchema && fieldUiSchema['ui:options'];
    if (options && options.dependencies) {
      options.dependencies.map((prop) => (args[prop] = _get(formData, prop)));
    }

    let FieldComponent = Fields['default'];
    if (fieldUiSchema && fieldUiSchema['ui:field']) {
      const fieldName = fieldUiSchema['ui:field'];
      FieldComponent = Fields[fieldName] || FieldComponent;
    }

    const fieldProps = {
      name,
      schema: fieldSchema,
      uiSchema: fieldUiSchema,
      className: classNames(fieldUiSchema && fieldUiSchema.classNames),
      value,
      errors: fieldErrors,
      // placeholder,
      readOnly,
      args,
      ...props,
    };
    if (!options || options.enableAddRow !== false) {
      rows.push({ columns: [] });
      index++;
    }
    rows[index].columns.push({ component: FieldComponent, props: fieldProps });
  });

  return rows.map((row, i) => (
    <div
      className={`form-row ${accountSettings ? 'account-settings-item' : ''}`}
      key={i}
    >
      {row.columns.map((column, ii) => {
        const FieldComponent = column.component;
        return (
          <FieldComponent {...column.props} formData={formData} key={ii} />
        );
      })}
    </div>
  ));
};

class BaseForm extends Component {
  state = {
    formData: {},
    errors: null,
    changed: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.changed ||
      deepEquals(nextProps.formData, prevState.formData)
    ) {
      return { changed: false };
    }
    return { formData: Object.assign({}, nextProps.formData), errors: null };
  }

  handleChange = (name, value) => {
    const { formData } = this.state;

    if (value !== '') {
      formData[name] = value;
    } else {
      delete formData[name];
    }

    if (this.props.onChange) {
      this.props.onChange(formData);
    }
    this.setState({ formData, changed: true });
  };

  validate = () => {
    const { formData } = this.state;
    const { schema } = this.props;
    const valid = ajv.validate(schema, formData);
    let errors = null;

    if (!valid) {
      errors = this.transformErrors(ajv.errors);
    }

    this.setState({ errors, changed: true });

    return valid;
  };

  handleBlur = () => {
    if (this.state.errors) {
      this.validate();
    }
  };

  handleSubmit = (event) => {
    const { formData } = this.state;
    const { onSubmit } = this.props;
    if (event) event.preventDefault();

    if (onSubmit && this.validate()) {
      onSubmit(formData);
    }
  };

  transformErrors = (errors) => {
    const { schema } = this.props;
    let messages = {};
    errors.forEach((error) => {
      let propertyName = error.dataPath.slice(1);
      let errorMessages = messages[propertyName] || [];
      let title = schema.properties.hasOwnProperty(propertyName)
        ? schema.properties[propertyName].title
        : '';
      switch (error.keyword) {
        case 'required':
          if (propertyName === 'classId') {
            errorMessages.push(`Lütfen seçim yapınız.`);
            Object.assign(messages, { [propertyName]: errorMessages });
          } else {
            errorMessages.push(`${title} bilgisi boş olamaz.`);
            Object.assign(messages, { [propertyName]: errorMessages });
          }
          break;
        case 'compare':
          propertyName = error.params.keyword;
          //let title = schema.properties[propertyName].title;
          errorMessages.push('Şifre alanları uyuşmuyor.');
          Object.assign(messages, { [propertyName]: errorMessages });
          break;
        case 'passwordPolicy':
          propertyName = error.params.keyword;
          //let title = schema.properties[propertyName].title;
          errorMessages.push(
            'Şifreniz ardışık sayılar, ardışık harfler, birbirini tekrar eden karakterler içermemelidir!',
          );
          Object.assign(messages, { [propertyName]: errorMessages });
          break;
        case 'type':
          errorMessages.push(`${title} giriş yapılan değer tipi uygun değil.`);
          Object.assign(messages, { [propertyName]: errorMessages });
          break;
        case 'minLength':
          errorMessages.push(
            `${title} bilgisi en az ${error.params.limit} karakter olmalıdır.`,
          );
          Object.assign(messages, { [propertyName]: errorMessages });
          break;
        case 'format':
          if (error.params && error.params.format === 'customdate') {
            errorMessages.push('Lütfen geçerli bir tarih seçiniz.');
            Object.assign(messages, { [propertyName]: errorMessages });
          } else if (error.params && error.params.format === 'address-area') {
            errorMessages.push('Lütfen geçerli bir adres giriniz.');
            Object.assign(messages, { [propertyName]: errorMessages });
          }
          break;
        case 'minimum':
          if (propertyName === 'classId') {
            errorMessages.push(`Lütfen seçim yapınız.`);
            Object.assign(messages, { [propertyName]: errorMessages });
          }
          break;
        default:
          break;
      }
    });

    return messages;
  };

  render() {
    const { id, schema, uiSchema, className } = this.props;
    const keys = Object.keys(schema.properties);
    const options = uiSchema && uiSchema['ui:options'];
    let horizontal = options && options.horizontal ? options.horizontal : false;

    return (
      <form onSubmit={this.handleSubmit} id={id}>
        <FieldList
          className={className}
          schema={schema}
          uiSchema={uiSchema}
          formData={this.state.formData}
          horizontal={horizontal}
          keys={keys}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          errors={this.state.errors}
          onSubmit={this.props.onSubmit}
          accountSettings={this.props.accountSettings}
        />
        {this.props.children ? (
          this.props.children
        ) : (
          <button type="submit" className="btn btn-submit-form">
            Kaydet
          </button>
        )}
      </form>
    );
  }
}

BaseForm.propTypes = {
  formData: PropTypes.object,
  schema: PropTypes.object,
  uiSchema: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default BaseForm;
