import actionTypes from '../../../actions/Desk/Answer/types';

export const initialState = {
  questions: [],
  yayinID: null,
  answer: null,
  detail: {
    Rank: 0,
    CityRank: 0,
    CityName: '',
    SolveTime: '',
    TrueCount: 0,
    FalseCount: 0,
    Net: 0,
  },
  classId: 8,
  testId: 0,
  testsQuestions: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_TEST_QUESTION_ANSWER:
      return { ...state, answers: action.payload };
    case actionTypes.GET_TESTS_QUESTION:
      return {
        ...state,
        questions: action.payload.questions.Sorular,
        testId: action.payload.testId,
        testsQuestions: action.payload.testsQuestions,
        yayinID: action.payload.yayınID,
        detail: action.payload.Detail,
        classId: action.payload.sinif,
      };
    default:
      return state;
  }
};
