import React, { Component } from 'react';
import queryString from 'query-string';
import { Breadcrumb } from '../../components';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { tongucSinavdaEndpoint } from '../../services/constants';
import storage from '../../services/storage';
import { keys } from '../../services/constants';
import { toast } from 'react-toastify';
import { getToken } from '../../services/api';
import {
  loginRequired,
  getIsEmailVerified,
} from '../../routes/actions/Account';

import { getSettings } from '../../routes/actions/Settings';

const breadcrumbs = [
  {
    title: 'Anasayfa',
    url: '/dijitalkitap',
  },
  {
    title: 'Sınav Modu',
    url: '/dijitalkitap/sinav-modu',
  },
];

class ExamMode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEndpointDown: false,
    };
  }

  componentDidMount() {
    if (this.props.user.demo) {
      toast.error(
        'Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor.',
      );
      this.props.loginRequired(true);
      this.props.history.push('/dijitalkitap');
    } else if (!this.props.user.id) {
      if (this.props.history.location && this.props.history.location.pathname) {
        storage.setItem(
          keys.LastLocation,
          `${this.props.history.location.pathname}${this.props.history.location.search}`,
        );
        this.props.history.push('/', { showLogin: true, authenticated: false });
      }
    } else if (this.props.user.id && !this.props.user.isMailVerified) {
      this.props.getIsEmailVerified();
      this.props.getSettings();
    }
    this.checkTongucSinavdaStatus();
    this.generateQueryString();
    window.addEventListener('message', this.handleFrameMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleFrameMessage);
  }

  handleFrameMessage = ({ data }) => {
    if (data.event === 'token-expired') {
      this.generateQueryString();
      return;
    }
  };

  checkTongucSinavdaStatus() {
    fetch(`${tongucSinavdaEndpoint}HealthCheck.ashx`)
      .then((res) => {
        if (res.status != 200) throw new Error('Not 200');
        //hiçbir şey yapma
      })
      .catch((err) => {
        this.setState({
          isEndpointDown: true,
        });
      });
  }

  goToVerificationPage() {
    const { history } = this.props;

    if (this.props.user.id && !this.props.user.isMailVerified) {
      storage.setItem(
        keys.MailVerificationRedirectLocation,
        this.props.match.url,
      );
      this.props.getIsEmailVerified();
    }

    history.push('/hesabim/hesap-bilgilerim');
  }

  async generateQueryString() {
    const query = queryString.parse(window.location.search);
    query.token = await getToken();
    if (this.props.match.params.examCode) {
      query.qrstring = this.props.match.params.examCode;
    }
    var generatedQueryString = queryString.stringify(query);
    this.setState({ queryString: generatedQueryString });
  }

  handleIframeLoaded = (e) => {
    setTimeout(() => {
      if (document.getElementById('sinavda-iframe').height == '900px')
        document.getElementById('sinavda-iframe').height = '901px';
      else if (document.getElementById('sinavda-iframe').height == '901px')
        document.getElementById('sinavda-iframe').height = '900px';
    }, 1000);
  };

  render() {
    var isMailVerified = this.props.user.isMailVerified;
    return (
      <>
        <div className="row">
          <div className="col-10">
            <Breadcrumb items={breadcrumbs} />
            <h1>Sınav Modu</h1>
          </div>
          <div
            className="col-12 justify-content-md-between flex-wrap align-items-center"
            style={{
              height:
                isMailVerified && this.state.isEndpointDown == false
                  ? ''
                  : '716px',
            }}
          >
            <div className="col-12 mt-3 mb-3">
              {this.props.checkMailVerification === false || isMailVerified ? (
                this.state.isEndpointDown ? (
                  <div className="card alert alert-danger">
                    <div role="alert">
                      Şu anda sınav modülümüzde bir problem yaşıyoruz.
                    </div>
                  </div>
                ) : (
                  <iframe
                    className="card"
                    src={
                      this.state.queryString && this.state.queryString.length
                        ? `${tongucSinavdaEndpoint}Pages/Embedded/Login.aspx?${this.state.queryString}`
                        : ''
                    }
                    title="Tonguç Sınavda"
                    width="100%"
                    height="900px"
                    id="sinavda-iframe"
                    onLoad={(e) => this.handleIframeLoaded(e)}
                  />
                )
              ) : (
                <div className="card alert alert-danger">
                  <div role="alert">
                    Sınava girebilmek için mail adresinizi doğrulamanız
                    gerekiyor. Doğrulama sayfasına gitmek için{' '}
                    <Link onClick={() => this.goToVerificationPage()}>
                      tıklayınız.
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ AccountReducer, SettingsReducer }) => ({
  user: AccountReducer.user,
  checkMailVerification: SettingsReducer.checkMailVerification,
});

const mapDispatchToProps = {
  loginRequired,
  getIsEmailVerified,
  getSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ExamMode));
