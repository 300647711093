import actionTypes from './types';
import { tatsApiCall } from '../../../../services/api';
import { HTTP } from '../../../../services/constants';

export const fetchProfileLesson = (bransKod) => async (dispatch, getState) => {
  // ###### burası branş detay sayfasında çalışıyor.
  const model = {
    bransKod: bransKod,
  };
  const scoreRequest = tatsApiCall(
    '/Score/GetScores',
    model,
    null,
    HTTP.GET,
    null,
    true,
  );
  const badgeRequest = tatsApiCall(
    '/Score/GetUserBadges',
    model,
    null,
    HTTP.POST,
    null,
    true,
  );
  const activityRequest = tatsApiCall(
    `/Score/GetActivities`,
    model,
    null,
    HTTP.POST,
  );
  const subjectRequest = tatsApiCall(
    `/BookOperations/Branstest`,
    model,
    null,
    HTTP.POST,
  );

  const scores = await scoreRequest;
  const badges = await badgeRequest;
  const activities = await activityRequest;
  const subject = await subjectRequest;

  let result = [];
  let branch;
  if (subject.data.Testler != null) {
    branch = subject.data.Testler[0].bransAd;
    subject.data.Testler.reduce(function (res, value) {
      if (!res[value.testUniteAd]) {
        res[value.testUniteAd] = {
          testUniteAd: value.testUniteAd,
          testUnite: value.testUnite,
          testSoruSayisi: 0,
          toplamDogru: 0,
          toplamYanlis: 0,
          toplamBos: 0,
          net: 0,
          toplamTestSayisi: 0,
          cozulenTestSayisi: 0,
        };
        result.push(res[value.testUniteAd]);
      }
      res[value.testUniteAd].testSoruSayisi += value.testSoruSayisi;
      res[value.testUniteAd].toplamDogru += value.toplamDogru;
      res[value.testUniteAd].toplamYanlis += value.toplamYanlis;
      res[value.testUniteAd].toplamBos += value.toplamBos;
      res[value.testUniteAd].net += value.net;
      res[value.testUniteAd].toplamTestSayisi += 1;
      res[value.testUniteAd].cozulenTestSayisi += value.testCozum ? 1 : 0;
      return res;
    }, {});
    // scores.data[0].Branch = subject.data.Testler[0].bransAd;
  }
  subject.data.Testler = result;
  const branchActivity = activities.data.filter(
    (x) => x.BranchId === parseInt(bransKod),
  );
  const branchScore = scores.data.find(
    (x) => x.Branch !== null && x.Branch.Id === parseInt(bransKod),
  );
  const branchBadges = badges.data.filter(
    (x) => x.Branch !== null && x.Branch.Id === parseInt(bransKod),
  );

  dispatch({
    type: actionTypes.FETCH_PROFILE_LESSON,
    payload: {
      subject: subject.data,
      activities: branchActivity,
      score:
        branchScore !== undefined
          ? branchScore !== null
            ? branchScore.Score
            : null
          : null,
      badges: branchBadges,
      branch: branch,
    },
  });
};

export const badged = [
  {
    Id: 3,
    Name: 'Abakus',
    Title: 'Matematik 1. seviyede kazanılacak rozet',
    BranchId: 6,
    BadgeTypeId: 1,
    CreateBy: 0,
    CreateDate: '2019-09-25T00:00:00',
    UpdateBy: 0,
    UpdateDate: '2019-09-25T00:00:00',
    Score: 0,
  },
  {
    Id: 13,
    Name: 'Beyinyakici',
    Title: 'Matematik 17. seviyede kazanılacak rozet',
    BranchId: 6,
    BadgeTypeId: 1,
    CreateBy: 0,
    CreateDate: '2019-09-25T00:00:00.01',
    UpdateBy: 0,
    UpdateDate: '2019-09-25T00:00:00.01',
    Score: 0,
  },
  {
    Id: 17,
    Name: 'Caylak',
    Title: 'Matematik 2. seviyede kazanılacak rozet',
    BranchId: 6,
    BadgeTypeId: 1,
    CreateBy: 0,
    CreateDate: '2019-09-25T00:00:00.013',
    UpdateBy: 0,
    UpdateDate: '2019-09-25T00:00:00.013',
    Score: 0,
  },
  {
    Id: 18,
    Name: 'Dahi',
    Title: 'Matematik 19. seviyede kazanılacak rozet',
    BranchId: 6,
    BadgeTypeId: 1,
    CreateBy: 0,
    CreateDate: '2019-09-25T00:00:00.017',
    UpdateBy: 0,
    UpdateDate: '2019-09-25T00:00:00.017',
    Score: 0,
  },

  {
    Id: 40,
    Name: 'Hesapmakinesi',
    Title: 'Matematik 9. seviyede kazanılacak rozet',
    BranchId: 6,
    BadgeTypeId: 1,
    CreateBy: 0,
    CreateDate: '2019-09-25T00:00:00.04',
    UpdateBy: 0,
    UpdateDate: '2019-09-25T00:00:00.04',
    Score: 0,
  },
  {
    Id: 41,
    Name: 'Hevesli',
    Title: 'Matematik 5. seviyede kazanılacak rozet',
    BranchId: 6,
    BadgeTypeId: 1,
    CreateBy: 0,
    CreateDate: '2019-09-25T00:00:00.043',
    UpdateBy: 0,
    UpdateDate: '2019-09-25T00:00:00.043',
    Score: 0,
  },
  {
    Id: 43,
    Name: 'Islemci',
    Title: 'Matematik 7. seviyede kazanılacak rozet',
    BranchId: 6,
    BadgeTypeId: 1,
    CreateBy: 0,
    CreateDate: '2019-09-25T00:00:00.047',
    UpdateBy: 0,
    UpdateDate: '2019-09-25T00:00:00.047',
    Score: 0,
  },
  {
    Id: 44,
    Name: 'Islemkupu',
    Title: 'Matematik 15. seviyede kazanılacak rozet',
    BranchId: 6,
    BadgeTypeId: 2,
    CreateBy: 0,
    CreateDate: '2019-09-25T00:00:00.047',
    UpdateBy: 0,
    UpdateDate: '2019-09-25T00:00:00.047',
    Score: 0,
  },

  {
    Id: 55,
    Name: 'Matcanavari',
    Title: 'Matematik 20. seviyede kazanılacak rozet',
    BranchId: 6,
    BadgeTypeId: 2,
    CreateBy: 0,
    CreateDate: '2019-09-25T00:00:00.063',
    UpdateBy: 0,
    UpdateDate: '2019-09-25T00:00:00.063',
    Score: 0,
  },
  {
    Id: 56,
    Name: 'Matedor',
    Title: 'Matematik 11. seviyede kazanılacak rozet',
    BranchId: 6,
    BadgeTypeId: 2,
    CreateBy: 0,
    CreateDate: '2019-09-25T00:00:00.063',
    UpdateBy: 0,
    UpdateDate: '2019-09-25T00:00:00.063',
    Score: 0,
  },

  {
    Id: 62,
    Name: 'Pilove',
    Title: 'Matematik 13. seviyede kazanılacak rozet',
    BranchId: 6,
    BadgeTypeId: 1,
    CreateBy: 0,
    CreateDate: '2019-09-25T00:00:00.07',
    UpdateBy: 0,
    UpdateDate: '2019-09-25T00:00:00.07',
    Score: 0,
  },
];
