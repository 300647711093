import actionTypes from '../../actions/Study/types';

export const initialState = {
  avatars: [],
  activityTypes: [],
  activities: [],
  scores: [],
  badges: [],
  bransTest: [],
  book: {
    Analiz: {},
    Book: {},
    product: {
      images: ['', ''],
    },
  },
  books: [],
  docs: [],
  wallet: {
    coins: 0,
    coinTL: 0.0,
    bonus: 0,
    bonusTL: 0,
    bonusExpire: '',
  },
  analize: {
    aktifYayinCozulenToplamSoru: 0,
    aktifYayinToplamSoru: 0,
    toplamCozulenSoru: 0,
    toplamDogruSoru: 0,
    toplamYanlisSoru: 0,
    toplamBosSoru: 0,
    toplamCalisilanSayfa: 0,
    soruCozumYuzdesi: 0,
    konuAnlatimYuzdesi: 0,
    gunlukOrtalamaCozulenSoru: 0,
    gunlukOrtalamaCalismaSuresi: 0,
    enCokCalisilanDers: 'm',
    enBasariliDers: 'm',
    enCokHataYapilanDers: 'm',
    bransAnaliz: [],
    hataKutusu: {
      hataKutusuDetay: [],
    },
  },
  taxonomies: [],
  searchResult: [],
  testId: 0,
  testsQuestions: {},
  userBranches: [],
  userBadges: [],
  usersBooksForDashboard: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT: {
      return { ...state, ...initialState };
    }

    case actionTypes.GET_LIBRARY:
      return {
        ...state,
        books: action.payload.books,
        //taxonomies: action.payload.taxonomies,
        // book: { Analiz: {}, Book: {} }
      };

    case actionTypes.GET_ACTIVITY_TYPES:
      return { ...state, activityTypes: action.payload };

    case actionTypes.GET_USER_BRANCHES:
      return { ...state, userBranches: action.payload };

    case actionTypes.SEARCH_BY_KEYWORD:
      return { ...state, searchResult: action.payload.data };

    case actionTypes.GET_ACTIVITIES:
      return { ...state, activities: action.payload };

    case actionTypes.GET_AVATARS:
      return { ...state, avatars: action.payload };

    case actionTypes.GET_BOOK_DETAILS:
      return { ...state, book: action.payload };

    case actionTypes.GET_BOOK_DOCS:
      return { ...state, docs: action.payload };

    case actionTypes.GET_SCORES:
      return { ...state, scores: action.payload };

    case actionTypes.TESTS_QUESTIONS:
      return {
        ...state,
        testId: action.payload.testId,
        testsQuestions: action.payload.testsQuestions,
      };

    case actionTypes.GET_BADGES:
      return { ...state, badges: action.payload };

    case actionTypes.GET_USER_BADGES:
      return { ...state, userBadges: action.payload };

    case actionTypes.GET_BRANS_TEST:
      return { ...state, bransTest: action.payload };

    case actionTypes.GET_ANALIZES:
      return {
        ...state,
        analize: action.payload.analize,
        taxonomies: action.payload.taxonomies,
      };

    case actionTypes.FETCH_DESK:
      return {
        ...state,
        taxonomies: action.payload.taxonomies,
        scores: action.payload.scores,
        avatars: action.payload.avatars,
        books: [],
        book: { Analiz: {}, Book: {} },
      };

    case actionTypes.FETCH_PROFILE:
      return {
        ...state,
        taxonomies: action.payload.taxonomies,
        activities: action.payload.activities,
        scores: action.payload.scores,
        badges: action.payload.badges,
        wallet: action.payload.wallet,
      };

    case actionTypes.GET_USERS_BOOKS_FOR_DASHBOARD:
      return { ...state, usersBooksForDashboard: action.payload };

    case actionTypes.SET_INITIAL_BOOKS:
      return { ...state, book: initialState.book, docs: initialState.docs };
    default:
      return state;
  }
};
