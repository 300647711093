import React, { Component } from 'react';
//import PropTypes from "prop-types";
import { ClassroomSelect } from '../..';

class Classroom extends Component {
  handleChange = (selected) => {
    const { name, onChange } = this.props;
    onChange(name, selected.value);
  };

  render() {
    const { name, valid, value, readOnly, uiSchema, placeholder } = this.props;
    const additionalProps = (uiSchema && uiSchema['ui:additionalProps']) || {};
    return (
      <ClassroomSelect
        name={name}
        onChange={this.handleChange}
        value={value}
        valid={valid}
        readOnly={readOnly}
        placeholder={placeholder}
        {...additionalProps}
      />
    );
  }
}

Classroom.propTypes = {};

export default Classroom;
