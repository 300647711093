import React from 'react';
import Icon from '../../components/Icon/Index';

const QuestionModal = ({
  handleSubmit,
  errors,
  comment,
  show,
  onToggle,
  product,
}) => (
  <div className={`commentModal questionModal ${show ? 'd-flex' : 'd-none'}`}>
    <div className="modal-box">
      <div className="modal-header">
        <span />
        <div className="close-btn" onClick={onToggle}>
          <Icon icon="iClose" />
        </div>
        <h5>Soru-Cevap</h5>
      </div>
      <div className="modal-body">
        <div className="img-container">
          <img className="img-fluid" src={product.imageUrl} alt="" />
        </div>
        <form className="w-100 mt-2" onSubmit={handleSubmit}>
          <div className="form-group d-flex">
            <p>Ürün hakkında aklına gelen her soruyu sorabilirsin</p>

            <textarea
              ref={comment}
              name="commentText"
              id="commentText"
              className={`form-control ${errors.comment ? 'is-invalid' : ''}`}
              rows="5"
              placeholder="Ürün"
            />

            {errors.comment ? (
              <>
                <small
                  id="commentText_help"
                  className="form-text text-muted col-sm-12"
                />
                <div className="invalid-feedback">
                  Yorum bilgisi boş olamaz.
                </div>
              </>
            ) : null}
          </div>
          <div className="flex-row d-flex">
            <button type="submit" className="btn comment-button mt-2">
              Sorumu Gönder
            </button>
          </div>
        </form>
      </div>
    </div>
    <div
      className="modal-backdrop"
      style={{ opacity: 0.4 }}
      onClick={onToggle}
    ></div>
  </div>
);
export default QuestionModal;
