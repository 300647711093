import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Creatable from 'react-select/lib/Creatable';
import { formatDate } from '../../helpers/formatDatetime';
import { Breadcrumb, Modal, SchedulerTree } from '../../components';
import { customStyles, customStylesLessons, padStart } from '../../helpers';
import { imgPuan } from '../../assets/images';
import CloseButton from '../../components/Button/Close';
import { ScoreBadge } from '../../components';
import * as actions from '../../routes/actions/Desk/Scheduler';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { getActivityTypes } from '../../routes/actions/Product';
import Icon from '../../components/Icon/Index';

let treeItems = [];
const breadcrumbs = [
  {
    title: 'Anasayfa',
    url: '/dijitalkitap',
  },
  {
    title: 'Programcı',
    active: true,
  },
];
const durations = [30, 60, 90, 120, 150, 180];

const filters = ['Bugün', 'Bu Hafta', 'Bu Ay', '2019-2020'];

const SurrenderModal = (props) => {
  return (
    <Modal show={props.show} className="modal-container non-modal-height">
      <CloseButton onClick={props.onCancelClick} className="z-index-4" />
      <div className="modal-body text-center p-5">
        <img
          alt=""
          src={require('../../assets/images/tree/tree-step_soil.svg')}
          style={{ width: 170, height: 170 }}
        />

        <p className="text-center color-484848 fs-14 my-3 font-weight-500">
          Hay aksi, programı tamamlasaydın <br />
          puan kazanacaktın
        </p>

        <button
          className="btn btn-primary border-radius-4 text-white text-capitalize mt-3 px-5 fs-14"
          onClick={props.onCloseClick}
        >
          Tamam
        </button>
      </div>
    </Modal>
  );
};

class Scheduler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 30,
      filter: 'Bugün',
      selectedTopics: null,
      filterScheduler: [],
      multiValue: [],
      isStart: false,
      isGiveup: false,
      id: null,
      treeName: '',
      showSurrenderModal: false,
      zoomValue: 1,

      activities: this.props.scheduler.slice(0, 4),
      isShowAll: false,

      remaningTime: 30 * 60, // second
      remaningTimePretty: '30:00',
      time: 0,
      isOn: false,
      start: 0,
      timePretty: '00:00',

      diffX: 0,
      diffY: 0,
      dragging: false,
      styles: {
        transform: 'scale(1.2)',
      },
    };
    this.handleMultiChange = this.handleMultiChange.bind(this);

    this._dragStart = this._dragStart.bind(this);
    this._dragging = this._dragging.bind(this);
    this._dragEnd = this._dragEnd.bind(this);

    window.addEventListener('focus', () => this.checkFocus());
    window.addEventListener('blur', () => this.checkFocus());
  }

  checkFocus() {
    const hasFocus = document.hasFocus();
    if (this.state.isOn && !this.props.pomodoro.show && !hasFocus) {
      this.handleStopClick();
    }
  }

  componentDidUpdate(prevProps, next) {
    let { pomodoro } = this.props;
    // pomodoro = { ...pomodoro, show: false };
    // this.props.updatePomodoroData(pomodoro);
    if (
      pomodoro &&
      pomodoro.id &&
      !this.state.isOn &&
      pomodoro.isOn &&
      pomodoro.remaningTime > 0 &&
      this.props.id
    ) {
      let remaningTimePretty = this.secondsToTime(pomodoro.remaningTime);
      this.setState(
        {
          time: pomodoro.time,
          remaningTime: pomodoro.remaningTime,
          remaningTimePretty,
          selected: pomodoro.selected,
          isOn: true,
          start: pomodoro.start,
          isStart: true,
          id: pomodoro.id,
        },
        () => this.startTimer(pomodoro.start),
      );
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
    }
  }

  async componentDidMount() {
    this.props.getActivityTypes();
    this.props.getTopics();
    await this.props.getSchedule();
    this.changeFilter(this.state.filter);
  }

  componentWillUnmount(next) {
    let { id, start, remaningTime, time } = this.state;
    let { pomodoro } = this.props;
    pomodoro = {
      ...pomodoro,
      id,
      show: true,
      isOn: this.state.isOn,
      start,
      remaningTime,
      time,
      selected: this.state.selected,
    };

    this.props.updatePomodoroData(pomodoro);

    clearInterval(this.timer);
    // this.stopTimer()
  }

  change = (value) => {
    this.setState({
      selected: value,
      time: 0,
      remaningTime: value * 60,
      remaningTimePretty: value + ':00',
    });
  };

  changeFilter = (value) => {
    let filterValues = this.props.scheduler;
    const millisecondsPerDay = 1000 * 60;
    if (this.props.scheduler && this.props.scheduler.length > 0) {
      const valueList = filterValues.filter(
        (x) =>
          new Date(
            new Date(x.UpdateDate).getTime() - new Date(x.StartDate).getTime(),
          ),
        //  /
        //   millisecondsPerDay >
        // 10
      );
      switch (value) {
        case 'Bugün':
          filterValues = valueList.filter(
            (x) =>
              new Date(x.StartDate).toLocaleDateString() ===
              new Date().toLocaleDateString(),
          );
          break;
        case 'Bu Hafta':
          let startWeek =
            new Date().getDate() -
            new Date().getDay() +
            (new Date().getDay() === 0 ? -6 : 1);
          let weekStartDate = new Date(new Date().setDate(startWeek));
          let weekEndDate = new Date(new Date().setDate(startWeek + 6));
          filterValues = valueList.filter(
            (x) =>
              new Date(x.StartDate).toLocaleDateString() >=
                weekStartDate.toLocaleDateString() &&
              new Date(x.StartDate).toLocaleDateString() <=
                weekEndDate.toLocaleDateString(),
          );
          break;
        case 'Bu Ay':
          let y = new Date().getFullYear(),
            m = new Date().getMonth();
          let firstDay = new Date(y, m, 1);
          let lastDay = new Date(y, m + 1, 0);
          filterValues = valueList.filter(
            (x) =>
              new Date(x.StartDate).toLocaleDateString() >=
                firstDay.toLocaleDateString() &&
              new Date(x.StartDate).toLocaleDateString() <=
                lastDay.toLocaleDateString(),
          );
          break;
        case '2019-2020':
          filterValues = valueList.filter(
            (x) =>
              new Date(x.StartDate).getFullYear() === 2019 ||
              new Date(x.StartDate).getFullYear() === 2020,
          );
          break;
      }
      this.renderTree(filterValues);
      this.setState({
        filterScheduler: filterValues,
        filter: value,
      });
    } else {
      this.setState({
        filter: value,
      });
    }
  };

  renderTree(filterValues) {
    let values = filterValues.filter((x) => x.Status === 3 || x.Status === 2);
    treeItems = [];
    for (let i = 0; i < values.length; i = i + 10) {
      treeItems.push(
        <tr className="w-100" key={i}>
          <td>
            {values[i] && (
              <img
                src={require(
                  `../../assets/images/tree/${this.typeTree(
                    values[i].Status == 2 ? '' : values[i].Span,
                  )}.svg`,
                )}
                alt="..."
              />
            )}
          </td>
          <td>
            {values[i + 1] && (
              <img
                src={require(
                  `../../assets/images/tree/${this.typeTree(
                    values[i + 1].Status == 2 ? '' : values[i + 1].Span,
                  )}.svg`,
                )}
                alt="..."
              />
            )}
          </td>
          <td>
            {values[i + 2] && (
              <img
                src={require(
                  `../../assets/images/tree/${this.typeTree(
                    values[i + 2].Status == 2 ? '' : values[i + 2].Span,
                  )}.svg`,
                )}
                alt="..."
              />
            )}
          </td>
          <td>
            {values[i + 3] && (
              <img
                src={require(
                  `../../assets/images/tree/${this.typeTree(
                    values[i + 3].Status == 2 ? '' : values[i + 3].Span,
                  )}.svg`,
                )}
                alt="..."
              />
            )}
          </td>
          <td>
            {values[i + 4] && (
              <img
                src={require(
                  `../../assets/images/tree/${this.typeTree(
                    values[i + 4].Status == 2 ? '' : values[i + 4].Span,
                  )}.svg`,
                )}
                alt="..."
              />
            )}
          </td>
        </tr>,
      );
    }
  }

  typeTree(value) {
    switch (value) {
      case 30:
        return 'tree_1';
      case 60:
        return 'tree_2';
      case 90:
        return 'tree_3';
      case 120:
        return 'tree_4';
      case 150:
        return 'tree_5';
      case 180:
        return 'tree_6';
      default:
        return 'soil';
    }
  }

  handleMultiChange(option) {
    this.setState({
      multiValue: option,
    });
  }
  handleStopClick = async () => {
    this.stopTimer();
    const model = {
      Schedule: {
        Id: this.state.id,
      },
    };

    const { success, error } = await this.props.cancelSchedule(model);

    if (success) {
      let { pomodoro } = this.props;
      pomodoro = { ...pomodoro, id: null, isOn: false, show: false };

      this.props.updatePomodoroData(null);

      await this.props.getSchedule();

      this.changeFilter(this.state.filter);

      this.resetTimer();
      this.setState({
        isStart: false,
        id: null,
        time: 0,
        remaningTime: this.state.selected * 60,
        showSurrenderModal: true,
      });
    } else {
      if (error.length) {
        toast.error(error);
      }
    }
  };
  handleStartClick = async () => {
    const model = {
      Schedule: {
        Span: this.state.selected,
        Topics: this.state.multiValue.map((p) => p.label),
      },
    };
    this.setState({
      isStart: true,
      isGiveup: false,
    });
    const { success, error } = await this.props.startSchedule(model);

    if (success) {
      this.setState(
        {
          id: this.props.id,
        },
        () => this.startTimer(),
      );
    } else {
      if (error.length) {
        toast.error(error);
      }
      this.setState({
        isStart: false,
      });
    }
  };
  startTimer(start = null) {
    this.setState(
      {
        isOn: true,
        time: this.state.time,
        timePretty: this.state.timePretty,
        start: start || Date.now() - this.state.time,
        treeName: '',
      },
      () => {
        let { pomodoro } = this.props;

        this.props.updatePomodoroData({
          ...pomodoro,
          id: this.props.id,
          start: this.state.start,
          remaningTime: this.state.remaningTime,
          isOn: true,
        });
      },
    );

    this.timer = setInterval(() => {
      let time = this.state.time + 1;
      let timePretty = this.secondsToTime(time);
      let remaningTime = this.state.remaningTime - 1;
      let remaningTimePretty = this.secondsToTime(remaningTime);
      if (time > 20) {
        this.setState({
          isGiveup: true,
        });
      }
      this.setState({
        time: time,
        timePretty: timePretty,
        remaningTime: remaningTime,
        remaningTimePretty: remaningTimePretty,
      });
      if (remaningTime === 0) {
        this.stopTimer();
        // this.resetTimer();
        this.completeSchedule();
      }
    }, 1000);
  }

  completeSchedule = async () => {
    const model = {
      Schedule: {
        Id: this.state.id,
      },
    };
    const { success, error } = await this.props.completeSchedule(model);
    if (success) {
      let { pomodoro } = this.props;
      pomodoro = { ...pomodoro, id: null, isOn: false, show: false };

      await this.props.updatePomodoroData(null);
      this.setState({
        isStart: false,
        isOn: false,
        id: null,
        isGiveup: false,
      });
      await this.props.getSchedule();
      this.changeFilter(this.state.filter);
    } else {
      if (error.length) {
        toast.error(error);
      }
    }
  };
  stopTimer() {
    // this.setState({ isOn: false })
    clearInterval(this.timer);
  }

  resetTimer() {
    this.setState({ time: 0, remaningTimePretty: '00:00', isOn: false });
  }
  secondsToTime(secs, showHours = false) {
    // let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(secs / 60) || 0;

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds) || 0;

    let obj = {
      // "h": hours,
      m: minutes,
      s: seconds,
    };
    let result = this.zeroPad(obj.m) + ':' + this.zeroPad(obj.s);
    return result;
  }

  zeroPad(number) {
    return padStart(number, 2, '0');
  }
  showAllActivity = () => {
    this.setState({
      isShowAll: !this.state.isShowAll,
      activities: this.state.isShowAll
        ? this.state.filterScheduler
        : this.state.filterScheduler.slice(0, 4),
    });
  };

  _dragStart(e) {
    this.setState({
      diffX: e.pageX,
      diffY: e.pageY,
      dragging: true,
    });
  }

  _dragging(e) {
    if (this.state.dragging) {
      var left = e.pageX - this.state.diffX;
      var top = e.pageY - this.state.diffY;

      this.setState({
        styles: {
          transform: `translate(${left}px, ${top}px) scale(${this.state.zoomValue})`,
        },
      });
    }
  }

  _dragEnd() {
    this.setState({
      dragging: false,
    });
  }

  render() {
    this.state.activities = this.state.isShowAll
      ? this.state.filterScheduler
      : this.state.filterScheduler.slice(0, 4);
    var activity = { Score: 0, Coins: 0 };
    if (this.props.activityTypes && this.props.activityTypes.length > 0) {
      activity = this.props.activityTypes.find(
        (x) => x.Name === 'complete-task',
      );
    }
    const puan = (this.state.selected / 30) * activity.Score;
    return (
      <div className="row">
        <div className="col-12 p-xs-0">
          <Breadcrumb items={breadcrumbs} />
        </div>
        <div className="col-12 d-none d-sm-flex">
          <h1 className="color-484848">Programcı</h1>
        </div>

        <div className="col-12 p-xs-0">
          <div className="card scheduler-container">
            <div className="card-body">
              <div className="container-fluid p-0">
                <div className="row d-flex flex-column flex-lg-row align-items-center">
                  <div className="col-12 col-lg-4">
                    <h5 className="text-left text-lg-center color-484848 mt-2 mb-4 my-lg-0 fs-24 font-weight-500">
                      Ne kadar çalışacaksın?
                    </h5>
                  </div>

                  <div className="col-12 col-lg-8 p-xs-0 p-0 px-lg-2">
                    <div
                      className="tab-bar d-inline-flex mb-0 mb-sm-4 my-lg-0"
                      role="group"
                    >
                      <div className="tab-header">
                        {durations.map((value, i) => (
                          <button
                            type="button"
                            key={i}
                            className={classNames('btn', {
                              active: this.state.selected === value,
                            })}
                            disabled={this.state.isStart}
                            onClick={() => this.change(value)}
                          >
                            {value} dk
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4 p-xs-0 pt-sm-4">
                    <div className="d-flex d-lg-none align-items-center remaining-time mb-3 mt-4">
                      <span className="stopwatch">
                        {this.state.remaningTimePretty}
                      </span>
                    </div>
                    <div className="d-flex d-lg-none mb-3 align-items-center justify-content-center">
                      <button
                        className="btn btn-outline-dark btn-scheduler d-block text-capitalize fs-18 px-4 py-1 font-weight-600 border-radius-8"
                        disabled={!this.state.isStart || !this.state.isGiveup}
                        title={
                          this.state.isStart &&
                          !this.state.isGiveup &&
                          'Program başlatıldıktan sonra ilk 10 dk pes edilemez'
                        }
                        onClick={this.handleStopClick}
                      >
                        Pes Et
                      </button>
                      <button
                        className="btn btn-primary btn-scheduler d-block text-capitalize ml-2 fs-18 px-4 py-1 font-weight-600 border-radius-8"
                        disabled={this.state.isStart}
                        onClick={this.handleStartClick}
                      >
                        Başlat
                      </button>
                    </div>
                    <SchedulerTree
                      selectedTime={this.state.selected}
                      time={this.state.time}
                      remaningTime={this.state.remaningTime}
                    />
                    <div className="mt-2 d-flex justify-content-center justify-content-lg-start">
                      <ScoreBadge
                        title={`+${puan} Puan`}
                        value={puan}
                        icon="point"
                        className="badge-score-custom text-capitalize font-weight-500 "
                      />
                    </div>
                  </div>

                  <div className="col-12 col-lg-8 p-xs-0">
                    <div className="d-none d-lg-flex align-items-center remaining-time mb-3 mt-4">
                      <span className="stopwatch">
                        {this.state.remaningTimePretty}
                      </span>
                    </div>
                    <div className="d-none d-lg-flex completed-container">
                      <label
                        className="pr-2"
                        style={{ borderRight: '2px solid #dedede' }}
                      >
                        <img
                          src={require('../../assets/images/emoji_1.svg')}
                          style={{ width: 20 }}
                          alt="..."
                        />
                        <span className="color-484848 fs-14 font-weight-500 ml-1">
                          {
                            this.props.scheduler.filter((x) => x.Status === 3)
                              .length
                          }
                          &nbsp; Tamamlanan
                        </span>
                      </label>
                      <label className="ml-2">
                        <img
                          src={require('../../assets/images/emoji_2.svg')}
                          className="mr-1"
                          style={{ width: 20 }}
                          alt="..."
                        />
                        <span className="color-484848 fs-14 font-weight-500">
                          {
                            this.props.scheduler.filter((x) => x.Status === 2)
                              .length
                          }
                          &nbsp; Pes Edilen
                        </span>
                      </label>
                    </div>

                    <div className="mt-4 button-container d-none d-lg-flex">
                      <button
                        className="btn btn-primary d-block text-capitalize fs-18 px-4 py-1 font-weight-600 border-radius-8"
                        disabled={this.state.isStart}
                        onClick={this.handleStartClick}
                      >
                        Başlat
                      </button>
                      <button
                        className="btn btn-outline-dark d-block text-capitalize fs-18 px-4 py-1 font-weight-600 ml-2 border-radius-8"
                        disabled={!this.state.isStart || !this.state.isGiveup}
                        title={
                          this.state.isStart &&
                          !this.state.isGiveup &&
                          'Program başlatıldıktan sonra ilk 10 dk pes edilemez'
                        }
                        onClick={this.handleStopClick}
                      >
                        Pes Et
                      </button>

                      {/* {this.state.isStart ? (
                        <button
                          className="btn btn-outline-dark d-sm-none text-capitalize fs-18 px-4 py-1 font-weight-600 border-radius-8"
                          disabled={!this.state.isStart || !this.state.isGiveup}
                          title={
                            this.state.isStart &&
                            !this.state.isGiveup &&
                            "Program başlatıldıktan sonra ilk 10 dk pes edilemez"
                          }
                          onClick={this.handleStopClick}
                        >
                          Pes Et
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary text-capitalize d-sm-none fs-18 px-4 py-1 font-weight-600 border-radius-8"
                          disabled={this.state.isStart}
                          onClick={this.handleStartClick}
                        >
                          Başlat
                        </button>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-md-6 col-lg-5 col-xl-4 p-xs-0 mb-2">
                    <div className="form-group">
                      <label
                        className="fs-18 font-weight-600 color-484848"
                        htmlFor="selectSubjects"
                      >
                        “Çalışacağın konuyu Yaz ya da Seç
                      </label>
                      <Creatable
                        isMulti
                        options={this.props.topics}
                        id="selectSubjects"
                        value={this.state.multiValue}
                        disabled={this.state.isStart}
                        styles={customStylesLessons}
                        placeholder="Seçiniz"
                        onChange={this.handleMultiChange}
                      />
                      <div className="d-flex d-lg-none mt-4 completed-container justify-content-center justify-content-sm-start">
                        <label
                          className="pr-2"
                          style={{ borderRight: '2px solid #dedede' }}
                        >
                          <img
                            src={require('../../assets/images/emoji_1.svg')}
                            style={{ width: 20 }}
                            alt="..."
                          />
                          <span className="color-484848 fs-14 font-weight-500 ml-1">
                            {
                              this.props.scheduler.filter((x) => x.Status === 3)
                                .length
                            }
                            &nbsp; Tamamlanan
                          </span>
                        </label>
                        <label className="ml-2">
                          <img
                            src={require('../../assets/images/emoji_2.svg')}
                            className="mr-1"
                            style={{ width: 20 }}
                            alt="..."
                          />
                          <span className="color-484848 fs-14 font-weight-500">
                            {
                              this.props.scheduler.filter((x) => x.Status === 2)
                                .length
                            }
                            &nbsp; Pes Edilen
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 p-xs-0 mb-4">
                    <div className="tab-bar" role="group">
                      <div className="tab-header">
                        {filters.map((value, i) => (
                          <button
                            key={i}
                            type="button"
                            className={classNames('btn font-weight-600', {
                              active: this.state.filter === value,
                            })}
                            onClick={() => this.changeFilter(value)}
                          >
                            {value}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <small id="emailHelp" className="form-text text-muted">
                  Listede olmayan seçenekleri girebilirsiniz.
                </small> */}
                {this.state.filterScheduler === null ||
                  (this.state.filterScheduler.length === 0 ? (
                    <div className="row justify-content-between">
                      <h4 className="w-100 text-center">
                        Kayıtlı programınız bulunmamaktadır.
                      </h4>
                    </div>
                  ) : (
                    <div className="row mt-4">
                      <div className="col-12 col-md-6 p-xs-0 activity-max2-height">
                        <div className="tree-table-container">
                          <table
                            id="tree-table"
                            className={`tree-table table table-responsive d-block d-sm-table p-0`}
                            style={
                              this.state.zoomValue === 1.2
                                ? this.state.styles
                                : { transform: `scale(1)` }
                            }
                            onMouseDown={
                              this.state.zoomValue === 1.2 && this._dragStart
                            }
                            onMouseMove={
                              this.state.zoomValue === 1.2 && this._dragging
                            }
                            onMouseUp={
                              this.state.zoomValue === 1.2 && this._dragEnd
                            }
                          >
                            <tbody>{treeItems}</tbody>
                          </table>
                        </div>
                        {treeItems && treeItems.length > 0 && (
                          <div className="zoom-group ml-auto mr-auto mt-2">
                            <button
                              className="btn"
                              onClick={() => this.setState({ zoomValue: 1 })}
                            >
                              <Icon icon="iMinusBlack" />
                            </button>
                            <span>
                              {this.state.zoomValue === 1 ? '50%' : '100%'}
                            </span>
                            <button
                              className="btn"
                              onClick={() => this.setState({ zoomValue: 1.2 })}
                            >
                              <Icon icon="iPlusBlack" />
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="col-12 col-md-6 p-xs-0 mt-2 mt-sm-0">
                        <ul className="list-group activity-max2-height">
                          {this.state.activities &&
                            this.state.activities.map((activity, i) => (
                              <li className="list-group-item border-0" key={i}>
                                <p className="fs-16 color-484848 font-weight-600">
                                  {activity.Topics.join(', ')}
                                </p>

                                <div className="d-flex justify-content-between fs-16 font-weight-600 align-items-center flex-wrap mt-2">
                                  <img
                                    src={require(
                                      activity.Status === 3
                                        ? '../../assets/images/emoji_1.svg'
                                        : '../../assets/images/emoji_2.svg',
                                    )}
                                    style={{ width: 26 }}
                                    className="mr-1"
                                    alt="..."
                                  />
                                  <span className="color-9b9b9b mr-1">
                                    {formatDate.fromNow(activity.UpdateDate)}
                                  </span>
                                  <span className="color-9b9b9b mr-1">
                                    {activity.Span}dk
                                  </span>
                                  <span className="img-badge small">
                                    <img src={imgPuan} alt="..." />
                                    <span>{activity.GainedScore} Puan</span>
                                  </span>
                                </div>
                              </li>
                            ))}
                        </ul>
                        {this.state.activities &&
                          this.state.activities.length > 0 && (
                            <div className=" d-flex flex-row justify-content-center ml-2">
                              <Link
                                className="fs-13 w-100 font-weight-200 d-none d-sm-flex flex-column align-items-center"
                                onClick={this.showAllActivity}
                              >
                                <span className="color-c0cbfc">
                                  {!this.state.isShowAll
                                    ? 'DAHA FAZLA'
                                    : 'GİZLE'}
                                </span>
                                <hr
                                  className="w-100 m-0 "
                                  style={{ borderTop: '2px solid #cecece' }}
                                />
                                <Icon
                                  icon={
                                    !this.state.isShowAll
                                      ? 'iChevronDown'
                                      : 'iChevronUp'
                                  }
                                  className="ml-2"
                                />
                              </Link>
                              <Link
                                className="fs-16 w-100 font-weight-200 d-block d-sm-none flex-column align-items-center"
                                onClick={this.showAllActivity}
                              >
                                <hr
                                  className="w-100 m-0 "
                                  style={{ borderTop: '2px solid #cecece' }}
                                />
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                  <span className="color-6c83ff fs-13 font-weight-600">
                                    {!this.state.isShowAll
                                      ? 'DAHA FAZLA'
                                      : 'GİZLE'}
                                  </span>

                                  <Icon
                                    icon={
                                      !this.state.isShowAll
                                        ? 'iChevronDown'
                                        : 'iChevronUp'
                                    }
                                    className="ml-2"
                                  />
                                </div>
                              </Link>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        {/* <Pomodoro /> */}

        <SurrenderModal
          show={this.state.showSurrenderModal}
          onCloseClick={() => this.setState({ showSurrenderModal: false })}
          onCancelClick={() => {
            this.setState({ showSurrenderModal: false });
          }}
        />
      </div>
    );
  }
}

Scheduler.propTypes = {};

const mapStateToProps = ({ SchedulerReducer, ProductReducer }) => ({
  scheduler: SchedulerReducer.scheduler,
  topics: SchedulerReducer.topics,
  id: SchedulerReducer.id,
  activityTypes: ProductReducer.activityTypes,
  pomodoro: SchedulerReducer.pomodoro,
});

const mapDispatchToProps = {
  getSchedule: actions.getSchedule,
  getTopics: actions.getTopics,
  startSchedule: actions.startSchedule,
  cancelSchedule: actions.cancelSchedule,
  completeSchedule: actions.completeSchedule,
  startPomodoroInterval: actions.startPomodoroInterval,
  updatePomodoroData: actions.updatePomodoroData,
  getActivityTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
