import actionTypes from './types';
import apiCall from '../../../services/api';
import { HTTP } from '../../../services/constants';

export const getStoresByCityId = (cityId) => async (dispatch) => {
  const { data } = await apiCall(`/store/city/${cityId}`);

  dispatch({
    type: actionTypes.GET_STORES_BY_CITY_ID,
    payload: data,
  });
};

export const getStoresByTownId = (townId) => async (dispatch) => {
  const { data } = await apiCall(`/store/town/${townId}`);

  dispatch({
    type: actionTypes.GET_STORES_BY_TOWN_ID,
    payload: data,
  });
};

export const checkReview = (storeId) => async (dispatch) => {
  const { data } = await apiCall(`/store/review/check/${storeId}`);

  return {
    success: data.success,
    error:
      'Bu satış noktamıza daha önce yorum yapmışsın, başka bir satış noktasını değerlendirip puan kazanmaya devam edebilirsin.',
  };
};

export const addReview = (review) => async (dispatch) => {
  try {
    const { data } = await apiCall(
      '/store/review',
      review,
      null,
      HTTP.POST,
      null,
      true,
    );

    if (!data.statu) {
      return { success: false, error: data.error };
    }

    dispatch({
      type: actionTypes.ADD_STORE_REVIEW,
      payload: data,
    });

    return { success: true };
  } catch (error) {
    return { success: false, error: error.response.data };
  }
};
