import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import apiCall from '../services/api';
import Icon from '../components/Icon/Index';
import { Link } from 'react-router-dom';
import * as contentActions from '../routes/actions/Content';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Skeleton from '@material-ui/lab/Skeleton';

const HeadBanner = forwardRef(({ mobile, showModal, mode, ...props }, ref) => {
  const [page, setPage] = useState([]);
  const windowWidth = window.screen.width;

  useImperativeHandle(ref, () => ({
    newDataFromParent(newData) {
      setPage(newData);
    },
  }));

  useEffect(() => {
    if (props.bannerName) {
      apiCall(`/contents/content/${props.bannerName}/1`).then((res) => {
        setPage(res.data);
      });
    }
  }, [props.bannerName]);

  const handleClick = (event) => {
    if (!mobile) {
      showModal(props.bannerName);
    } else {
      if (props.onChange) {
        props.onChange(event.target.name, props.bannerName);
      }
    }
  };

  const toggleShowBanner = async (active) => {
    const newState = {
      ...page,
      active,
    };
    setPage(newState);
    await props.savePage(newState.id, newState);
    const result = await props.publishPage(newState.id);
    if (result.success && active === 1) {
      toast.success('Banner canlıya alındı.');
    }
    if (result.success && active === 0) {
      toast.success('Banner canlıdan çıkarıldı.');
    }
  };

  if (mode !== 'draft' && page.active === 0) {
    return null;
  }
  return (
    <>
      {page.content === undefined ? (
        <div>
          <div
            className={`head-banner ${
              mobile
                ? 'head-banner--mobile d-block d-lg-none'
                : 'd-none d-lg-block'
            }`}
          >
            <Skeleton variant="rect" width={1900} height={100} />
          </div>
        </div>
      ) : (
        <div>
          <div
            className={`head-banner ${
              mobile
                ? 'head-banner--mobile d-block d-lg-none'
                : 'd-none d-lg-block'
            }`}
          >
            {mode === 'draft' && (
              <div className="editable-container">
                <button
                  className="btn"
                  name="editableBanner"
                  onClick={handleClick}
                >
                  <Icon icon="iEditable" />
                </button>
                {page?.active === 1 && (
                  <button className="btn" onClick={() => toggleShowBanner(0)}>
                    <Icon icon="iEyeOff" />
                  </button>
                )}
                {page?.active === 0 && (
                  <button className="btn" onClick={() => toggleShowBanner(1)}>
                    <Icon icon="iEyeOn" />
                  </button>
                )}
              </div>
            )}
            {page.content?.target ? (
              <a href={page.content?.target || '/'} target="_blank">
                <img
                  srcSet={`${page.content?.mobile} 768w, ${page.content?.web} 1078w`}
                  sizes={`(max-width: 768px) 768w, 100%`}
                  src={`${page.content?.web}`}
                  className="img-fluid w-100"
                  width={`${windowWidth > 768 ? 1519 : 768}`}
                  height={`${windowWidth > 768 ? 80 : 56}`}
                />
              </a>
            ) : (
              <img
                srcSet={`${page.content?.mobile} 768w, ${page.content?.web} 1078w`}
                sizes={`(max-width: 768px) 768w, 100%`}
                src={`${page.content?.web}`}
                className="img-fluid w-100"
                width={`${windowWidth > 768 ? 1519 : 768}`}
                height={`${windowWidth > 768 ? 80 : 56}`}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
});

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {
  savePage: contentActions.savePage,
  publishPage: contentActions.publishPage,
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(HeadBanner);
