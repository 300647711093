import actionTypes from './types';
import {
  HTTP,
  defaultValues,
  keys,
  settings,
} from '../../../services/constants';

import apiCall, { tatsApiCall, bucketCall } from '../../../services/api';

export const getCoinById = (id) => async (dispatch, getState) => {
  try {
    const coinRequest = await apiCall(`/coins/${id}/`);

    dispatch({
      type: actionTypes.GET_COIN_BY_ID,
      payload: coinRequest.data,
    });
  } catch (error) {
    //eslint-disable-next-line
  }
};
