import actionTypes from './types';
import { bucketCall } from '../../../services/api';

export const getSettings = () => async (dispatch, getState) => {
  const lastSettingsUpdateDate =
    getState().SettingsReducer.lastSettingsUpdateDate;
  var now = new Date();
  var fiveMinutesAgo = new Date(now.setMinutes(now.getMinutes() - 5));

  //settings son 5 dk'dan daha eski ise güncelle
  if (lastSettingsUpdateDate > fiveMinutesAgo) return;

  const settings = bucketCall('/ui-settings.json');
  const { data } = await settings;

  dispatch({
    type: actionTypes.GET_SETTINGS,
    payload: data,
  });
};
