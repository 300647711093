import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar, LinkButton, Breadcrumb } from '../../components';
import StatsErrorBox from './StatsErrorBox';
import StatsLessons from './StatsLessons';
import dersImages from '../../assets/images/dersler';

import * as actions from '../../routes/actions/Study';
import * as actionsChapters from '../../routes/actions/Desk/Chapters';

const breadcrumbs = [
  {
    title: 'Anasayfa',
    url: '/dijitalkitap',
  },
  {
    title: 'Analizci',
    active: true,
  },
];

const LessonErrorBox = ({ lesson, img, count, onClick }) => (
  <div
    className="card lesson-error-box overflow-hidden mb-4 cursor-pointer"
    onClick={onClick}
  >
    <div className="card-header font-weight-600 color-484848 bg-transparent border-0 d-block pb-0">
      <h4 className="fs-18 text-truncate">{lesson.bransAd}</h4>
      <p className="fs-14">Hata Kutusu</p>
    </div>
    <div className="card-body d-flex align-items-center pt-0">
      <img src={img} alt="" className="lesson-img" />
      <h1 className="lesson-error-box-count">{count}</h1>

      <img
        src={require('../../assets/images/attension.svg')}
        alt=""
        className="attension-img"
      />
    </div>
  </div>
);

class Stats extends Component {
  constructor(props) {
    super(props);

    this.state = { show: false };
  }

  componentDidMount() {
    this.props.getAnalizes();

    this.props.getBransWithAllTests();
  }

  handleClick = (bransId) => {
    this.setState({ bransId, show: true });
  };

  handleHataOkClick = async (data) => {
    const result = await this.props.createRandomTest(data);
    if (result.success) {
      // const { testId } = this.props;
      // this.props.history.push(`/dijitalkitap/book/lesson/subject/quiz/${testId}?v=true`);
      this.props.history.push(
        `/dijitalkitap/book/lesson/subject/quiz/${result.testId}?v=true`,
      );
    }
  };

  render() {
    const progress =
      this.props.analize.aktifYayinCozulenToplamSoru &&
      this.props.analize.aktifYayinToplamSoru
        ? Math.round(
            (this.props.analize.aktifYayinCozulenToplamSoru /
              this.props.analize.aktifYayinToplamSoru) *
              100,
          )
        : 0;
    const userClass = this.props.user.classId
      ? { name: `${this.props.user.classId}. Sınıf` }
      : {};

    return (
      <div className="row desk-stats">
        <div className="col-12">
          <Breadcrumb items={breadcrumbs} />
        </div>
        <div className="col-12 p-xs-0">
          <div className="card p-3 p-sm-4">
            <div className="avatar d-flex flex-row align-items-center">
              <img
                src={
                  this.props.user.avatar
                    ? this.props.user.avatar.path
                    : 'https://cdn.dogrukaynak.com/avatars/default/default.svg'
                }
                className="avatar-img"
                alt=""
              />
              <div className="ml-2">
                <p className="avatar-name">
                  {this.props.user.firstname} {this.props.user.lastname}
                </p>
                <p className="avatar-class">{userClass.name}</p>
              </div>
            </div>

            <div className="row mt-3 mb-2 align-items-center">
              <div className="col-md-3 p-xs-0 fs-12 font-weight-600">
                <span className="color-919191">Soru Hazinem:</span>
                <span className="ml-2 color-484848">
                  {this.props.analize.aktifYayinToplamSoru} adet
                </span>
              </div>
              <div className="col-md-6 mb-3 mb-sm-0 p-xs-0">
                <ProgressBar
                  className="progress-thick width-95"
                  value={progress}
                />
              </div>
              <div className="col-md-3 p-xs-0">
                <LinkButton
                  className="btn-primary new-book-btn"
                  to="/urunler"
                  external
                >
                  Yeni Kitap Al
                </LinkButton>
              </div>
            </div>

            <div className="row mt-4 mt-sm-5">
              <div className="col-lg-4 col-md-5 p-xs-0 mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="stats-text">Toplam Çözülen Soru Sayısı</span>
                  <div className="badge-container">
                    <span className="badge badge-blue fs-16 badge-pill">
                      {this.props.analize.toplamCozulenSoru}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="stats-text"> Çalışma Süresi</span>
                  <div className="badge-container">
                    <span className="badge badge-light-blue fs-15 badge-pill">
                      {this.props.analize.gunlukOrtalamaCalismaSuresi} Saat
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="stats-text"> Toplam Doğru Sayısı</span>
                  <div className="badge-container">
                    <img
                      src={require('../../assets/images/icons/success.svg')}
                      alt=""
                      width="25"
                    />
                    <b className="fs-14 font-weight-600 color-9b9b9b ml-2">
                      {this.props.analize.toplamDogruSoru}
                    </b>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="stats-text"> Toplam Yanlış Sayısı</span>
                  <div className="badge-container">
                    <img
                      src={require('../../assets/images/icons/error.svg')}
                      alt=""
                      width="25"
                    />
                    <b className="fs-14 font-weight-600 color-9b9b9b ml-2">
                      {this.props.analize.toplamYanlisSoru}
                    </b>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="stats-text">Toplam Boş Sayısı</span>
                  <div className="badge-container">
                    <img
                      src={require('../../assets/images/icons/info.svg')}
                      alt=""
                      width="25"
                    />
                    <b className="fs-14 font-weight-600 color-9b9b9b ml-2">
                      {this.props.analize.toplamBosSoru}
                    </b>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 p-xs-0">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="stats-text">
                    Günlük Ortalama Çözülen Soru Sayısı
                  </span>
                  <div className="badge-container">
                    <span className="badge badge-blue fs-15 badge-pill">
                      {this.props.analize.gunlukOrtalamaCozulenSoru}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="stats-text">
                    Günlük Ortalama Çalışma Süresi
                  </span>
                  <div className="badge-container">
                    <span className="badge badge-light-blue fs-15 badge-pill">
                      {this.props.analize.gunlukOrtalamaCalismaSuresi} dk
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="stats-text">En Çok Çalışılan Ders</span>
                  <div className="badge-container">
                    <img
                      src={
                        this.props.analize.enCokCalisilanDers
                          ? dersImages[
                              this.props.analize.enCokCalisilanDers
                                .charAt(0)
                                .toLowerCase()
                            ]
                          : dersImages['m']
                      }
                      alt=""
                      width="30"
                    />
                    <b className="fs-14 font-weight-600 color-9b9b9b ml-2">
                      {this.props.analize.enCokCalisilanDers}
                    </b>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="stats-text">En Başarılı Ders</span>
                  <div className="badge-container">
                    <img
                      src={
                        this.props.analize.enBasariliDers
                          ? dersImages[
                              this.props.analize.enBasariliDers
                                .charAt(0)
                                .toLowerCase()
                            ]
                          : dersImages['m']
                      }
                      alt=""
                      width="30"
                    />
                    <b className="fs-14 font-weight-600 color-9b9b9b ml-2">
                      {this.props.analize.enBasariliDers}
                    </b>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="stats-text">En Çok Hata Yapılan Ders</span>
                  <div className="badge-container">
                    <img
                      src={
                        this.props.analize.enCokHataYapilanDers
                          ? dersImages[
                              this.props.analize.enCokHataYapilanDers
                                .charAt(0)
                                .toLowerCase()
                            ]
                          : dersImages['m']
                      }
                      alt=""
                      width="30"
                    />
                    <b className="fs-14 font-weight-600 color-9b9b9b ml-2">
                      {this.props.analize.enCokHataYapilanDers}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            {this.props.analize.bransAnaliz &&
              this.props.analize.bransAnaliz.map((lesson) => (
                <div
                  className="col-lg-3 col-6  branch-container"
                  key={lesson.bransId}
                >
                  <StatsLessons lesson={lesson} />
                </div>
              ))}
          </div>

          <div className="row mt-4">
            {this.props.analize.hataKutusu &&
              this.props.analize.hataKutusu.hataKutusuDetay.map((lesson, i) => (
                <div
                  className="col-lg-3 col-6 lesson-error-box-container"
                  key={i}
                >
                  <LessonErrorBox
                    lesson={lesson}
                    img={
                      dersImages[lesson.bransAd.charAt(0).toLowerCase()] ||
                      dersImages['m']
                    }
                    count={lesson.yanlisSoru + lesson.bosSoru}
                    onClick={() => this.handleClick(lesson.bransKod)}
                  />
                </div>
              ))}
          </div>
        </div>
        {/* -------Component-------- */}
        <StatsErrorBox
          show={this.state.show}
          onCloseClick={() => this.setState({ show: false })}
          onOkClick={this.handleHataOkClick}
          branslar={this.props.branslar}
          // unites={this.props.unites}
          bransId={this.state.bransId}
          errors={this.props.analize.hataKutusu.hataKutusuDetay}
        />
      </div>
      // <div className="row">
      //   <div className="col-12">
      //     <Breadcrumb items={breadcrumbs} />
      //   </div>
      //   <div className="col-12">
      //     <h1>
      //       Barış ÖZKAN <span className="badge badge-secondary">8. Sınıf</span>
      //     </h1>
      //     <div className="d-flex flex-row justify-content-between my-4" />
      //   </div>
      //   <div className="col-xl-5">
      //     <h2> Soru Hazinem: 1200 adet</h2>
      //   </div>
      //   <div className="col-xl-5">
      //     <ProgressBar className="progress-thick mb-2" value="50" />
      //   </div>
      //   <div className="col-xl-2 text-right">
      //     <LinkButton className="btn-primary mb-2" to="/">
      //       <FontAwesomeIcon icon={faPlus} className="mr-1" />
      //       Yeni Kitap Al
      //     </LinkButton>
      //   </div>
      //   <div className="col-xl-6">
      //     <ul className="list-group">
      //       <li className="list-group-item d-flex justify-content-between align-items-center">
      //         Toplam Çözülen Soru Sayısı
      //         <span className="badge badge-primary badge-pill">300</span>
      //       </li>
      //       <li className="list-group-item d-flex justify-content-between align-items-center">
      //         Çalışma Süresi
      //         <span className="badge badge-primary badge-pill">~7 saat</span>
      //       </li>
      //       <li className="list-group-item d-flex justify-content-between align-items-center">
      //         Toplam Doğru Sayısı
      //         <span className="badge badge-success badge-pill">250</span>
      //       </li>
      //       <li className="list-group-item d-flex justify-content-between align-items-center">
      //         Toplam Yanlış Sayısı
      //         <span className="badge badge-danger badge-pill">30</span>
      //       </li>
      //       <li className="list-group-item d-flex justify-content-between align-items-center">
      //         Toplam Boş Sayısı
      //         <span className="badge badge-secondary badge-pill">20</span>
      //       </li>
      //     </ul>
      //   </div>
      //   <div className="col-xl-6">
      //     <ul className="list-group">
      //       <li className="list-group-item d-flex justify-content-between align-items-center">
      //         Günlük Ortalama Çözülen Soru Sayısı
      //         <span className="badge badge-primary badge-pill">30</span>
      //       </li>
      //       <li className="list-group-item d-flex justify-content-between align-items-center">
      //         Günlük Ortalama Çalışma Süresi
      //         <span className="badge badge-primary badge-pill">20 dk</span>
      //       </li>
      //       <li className="list-group-item d-flex justify-content-between align-items-center">
      //         En Çok Çalışılan Ders
      //         <FontAwesomeIcon icon={faHeading} />
      //       </li>
      //       <li className="list-group-item d-flex justify-content-between align-items-center">
      //         En Başarılı Ders
      //         <FontAwesomeIcon icon={faFont} />
      //       </li>
      //       <li className="list-group-item d-flex justify-content-between align-items-center">
      //         En Çok Hata Yapılan Ders
      //         <FontAwesomeIcon icon={faBold} />
      //       </li>
      //     </ul>
      //   </div>
      //   <div className="col-xl-12 d-flex flex-row flex-wrap mt-4">
      //     {lessons.map(value => (
      //       <Link to="/dijitalkitap/analizci/ders">
      //         <div className="card text-center m-2" style={{ width: 250 }}>
      //           <div className="card-header">
      //             <FontAwesomeIcon icon={faHeading} className="float-left" />
      //             {value.title}
      //           </div>
      //           <div className="card-body">
      //             <h5 className="card-title">120/500 Soru</h5>
      //             <ProgressBar className="mb-2" value="25" />
      //             <p className="card-text">4 saat</p>
      //             <ul className="list-group list-group-horizontal-sm w-100">
      //               <li className="list-group-item list-group-item-success">
      //                 D 90
      //               </li>
      //               <li className="list-group-item list-group-item-danger">
      //                 Y 10
      //               </li>
      //               <li className="list-group-item list-group-item-secondary">
      //                 B 20
      //               </li>
      //             </ul>
      //           </div>
      //           <div className="card-footer text-muted">
      //             <FontAwesomeIcon icon={faSmile} />
      //           </div>
      //         </div>
      //       </Link>
      //     ))}
      //   </div>
      //   <div className="col-xl-12 d-flex flex-row flex-wrap mt-4">
      //     {lessons.map(value => (
      //       <div
      //         className="card text-center m-2"
      //         style={{ width: 250, height: 250, cursor: "pointer" }}
      //         onClick={() => this.setState({ show: true })}
      //       >
      //         <div className="card-body">
      //           <div
      //             className="progress"
      //             style={{
      //               position: "absolute",
      //               left: 10,
      //               top: 10,
      //               width: 230,
      //               height: 160,
      //               opacity: 0.8,
      //               filter: "alpha(opacity=80)"
      //             }}
      //           >
      //             <div
      //               className="progress-bar bg-danger"
      //               role="progressbar"
      //               style={{
      //                 position: "absolute",
      //                 bottom: 0,
      //                 width: "100%",
      //                 height: "70%"
      //               }}
      //             >
      //               30
      //             </div>
      //           </div>
      //         </div>
      //         <div className="card-footer text-muted">
      //           <h5 className="card-title">{value.title} Hata Kutusu</h5>
      //         </div>
      //       </div>
      //     ))}

      //   </div>
      // </div>
    );
  }
}

Stats.propTypes = {};

const mapStateToProps = ({
  AccountReducer,
  ProductReducer,
  StudyReducer,
  ChaptersReducer,
}) => ({
  user: AccountReducer.user,
  analize: StudyReducer.analize,
  taxonomies: StudyReducer.taxonomies,
  branslar: ChaptersReducer.branslar,
  unites: ChaptersReducer.unites,
  testId: ChaptersReducer.testId,
});

const mapDispatchToProps = {
  getAnalizes: actions.getAnalizes,
  getBransWithAllTests: actionsChapters.getBransWithAllTests,
  createRandomTest: actionsChapters.createRandomTest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
