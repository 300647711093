export const getAndCalculateCountsOfTaxonomies = (t, p) => {
  let taxonomies = [...t];
  let filteredProducts = [...p];
  if (filteredProducts) {
    for (let i = 0; i < taxonomies.length; i++) {
      for (let j = 0; j < taxonomies[i].taxons.length; j++) {
        var taxon = taxonomies[i].taxons[j];

        taxonomies[i].taxons[j].count = filteredProducts.filter((x) =>
          x.taxons.some((t) => t == taxon.id),
        ).length;
        taxonomies[i].count = taxonomies[i].taxons
          .map((x) => x.count)
          .reduce((a, b) => a + b, 0);
      }
    }
  }

  return taxonomies;
};
