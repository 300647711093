import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from '../views';
import Footer from './Footer';
import { routes } from '../routes';
import LoadingBar from 'react-redux-loading-bar';
import CookiePolicy from './CookiePolicy';
import CookieContracts from './CookieContracts';
import storage from '../services/storage';
import { keys } from '../services/constants';
import TopMenuBlog from './TopMenuBlog';
import NavbarBlog from './NavbarBlog';

class BlogLayout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCookiePolicy: storage.getItem(keys.CookiePolicy) ? false : true,
      routes: [],
    };
  }

  componentDidMount() {
    const blogRoutes = routes.find((p) => p.path === '/blog');
  }

  hideCookiePolicy = () => {
    storage.setItem(keys.CookiePolicy, true);
    this.setState({
      showCookiePolicy: false,
    });
  };

  render() {
    const isPaymentPage = this.props.location.pathname.includes('odeme/');
    const blogRoutes = routes.find((p) => p.path === '/blog').routes;
    return (
      <>
        <LoadingBar
          style={{ position: 'fixed', zIndex: 99999, width: '100%' }}
        />
        <NavbarBlog />
        <TopMenuBlog isPaymentPage={isPaymentPage} />
        <main role="main" className="container" style={{ minHeight: '665px' }}>
          <Switch>
            {blogRoutes.map((item, i) => (
              <Route
                path={item.path}
                exact={item.routes ? false : true}
                component={item.component}
                key={i}
              />
            ))}
            <Route component={NotFound} />
          </Switch>
        </main>
        <Footer isPaymentPage={isPaymentPage} light />
        {this.state.showCookiePolicy ? (
          <CookiePolicy hide={this.hideCookiePolicy} />
        ) : null}
        {
          <CookieContracts
            bottom={this.state.showCookiePolicy ? '75px' : '0'}
          />
        }
      </>
    );
  }
}

export default BlogLayout;
