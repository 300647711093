import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudUploadAlt,
  faWrench,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

class Toolbar extends Component {
  handleClick = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.name);
    }
  };

  render() {
    const published = this.props.modified || this.props.published !== 0;
    return (
      <div
        className="btn-toolbar bg-light justify-content-end mb-2"
        role="toolbar"
        aria-label="Toolbar with button groups"
      >
        {this.props.mode === 'draft' ? (
          <React.Fragment>
            {this.props.children}
            <button
              type="button"
              className="btn btn-primary text-capitalize"
              onClick={this.handleClick}
              name="publish"
              disabled={published}
            >
              <FontAwesomeIcon className="mr-2" icon={faCloudUploadAlt} />
              Yayınla
            </button>
            <button
              type="button"
              className="btn btn-primary mx-2 text-capitalize"
              onClick={this.handleClick}
              name="live"
              disabled={!this.props.modified}
            >
              <FontAwesomeIcon className="mr-2" icon={faSave} /> Kaydet
            </button>
            <button
              type="button"
              className="btn btn-primary mx-2 text-capitalize"
              onClick={this.handleClick}
              name="cancel"
            >
              <FontAwesomeIcon className="mr-2" icon={faTimes} /> Kapat
            </button>
          </React.Fragment>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-primary mx-2 text-capitalize"
              onClick={this.handleClick}
              name="draft"
            >
              <FontAwesomeIcon className="mr-2" icon={faWrench} /> Düzenle
            </button>
          </>
        )}
      </div>
    );
  }
}

Toolbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  mode: PropTypes.oneOf(['draft', 'live']),
  onChange: PropTypes.func,
};

export default Toolbar;

// PROD: istek üzerine yapılan değişiklikler geri alınmak zorunda kalındı

// import React, { Component } from "react";
// import PropTypes from "prop-types";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCloudUploadAlt,
//   faWrench,
//   faSave,
//   faTimes,
//   faClock
// } from "@fortawesome/free-solid-svg-icons";

// class Toolbar extends Component {
//   handleClick = event => {
//     if (this.props.onChange) {
//       this.props.onChange(event.target.name);
//     }
//   };

//   render() {
//     const published = this.props.modified || this.props.published !== 0;
//     const publishVisible = this.props.publishVisible;
//     const sendForApprovalVisible = this.props.sendForApprovalVisible;
//     const sendForApprovaled = this.props.sendForApprovaled;
//     const confirmedVisible = this.props.confirmedVisible;
//     const returnVisible = this.props.returnVisible;
//     const historyVisible = this.props.historyVisible;
//     const saveVisible = this.props.saveVisible;
//     const pageStatus = this.props.pageStatus;
//     return (
//       <div
//         className="btn-toolbar bg-light justify-content-end mb-2"
//         role="toolbar"
//         aria-label="Toolbar with button groups"
//       >
//         {this.props.mode === "draft" ? (
//           <React.Fragment>
//             {this.props.children}
//             {(pageStatus == 3 && publishVisible) &&
//               <button
//                 type="button"
//                 className="btn btn-primary mx-2 text-capitalize"
//                 onClick={this.handleClick}
//                 name="publish"
//               >
//                 <FontAwesomeIcon className="mr-2" icon={faCloudUploadAlt} />
//                 Yayınla
//          </button>
//             }
//             {((pageStatus == 0 || pageStatus == 1) && sendForApprovalVisible) &&
//               <button
//                 type="button"
//                 className="btn btn-primary text-capitalize"
//                 onClick={this.handleClick}
//                 name="sendForApproval"
//               >
//                 Onaya Gönder
//          </button>
//             }
//             {(confirmedVisible && pageStatus == 2) &&
//               <button
//                 type="button"
//                 className="btn btn-primary mx-2 text-capitalize"
//                 onClick={this.handleClick}
//                 name="confirm"
//               >
//                 Onayla
//          </button>
//             }
//             {(returnVisible && pageStatus == 2) &&
//               <button
//                 type="button"
//                 className="btn btn-primary mx-2 text-capitalize"
//                 onClick={this.handleClick}
//                 name="return"
//               >
//                 İade Et
//          </button>
//             }
//             {historyVisible &&
//               <button
//                 type="button"
//                 className="btn btn-primary mx-2 text-capitalize"
//                 onClick={this.handleClick}
//                 name="history"
//               >
//                 {/* <FontAwesomeIcon className="mr-2" icon={faClock} /> */}
//                 Geçmiş
//          </button>
//             }
//             {saveVisible &&
//               <button
//                 type="button"
//                 className="btn btn-primary mx-2 text-capitalize"
//                 onClick={this.handleClick}
//                 name="live"
//                 disabled={!this.props.modified}
//               >
//                 <FontAwesomeIcon className="mr-2" icon={faSave} /> Kaydet
//          </button>
//             }
//             <button
//               type="button"
//               className="btn btn-primary mx-2 text-capitalize"
//               onClick={this.handleClick}
//               name="cancel"
//             >
//               <FontAwesomeIcon className="mr-2" icon={faTimes} /> Kapat
//          </button>
//           </React.Fragment>
//         ) : (
//             <>
//               <button
//                 type="button"
//                 className="btn btn-primary mx-2 text-capitalize"
//                 onClick={this.handleClick}
//                 name="draft"
//               >
//                 <FontAwesomeIcon className="mr-2" icon={faWrench} /> Düzenle
//             </button>
//             </>
//           )}
//       </div>
//     );
//   }
// }

// Toolbar.propTypes = {
//   children: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node
//   ]),
//   mode: PropTypes.oneOf(["draft", "live"]),
//   onChange: PropTypes.func
// };

// export default Toolbar;
