import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getLocation } from '../../../helpers';

class Url extends Component {
  handleChange = (event) => {
    const { onChange } = this.props;
    const target = event.target;
    var location = getLocation(target.value);
    if (location.origin === window.location.origin)
      onChange(target.name, location.pathname + location.search);
    else onChange(target.name, location.href);
  };

  render() {
    const { name, schema, uiSchema, onChange, value, valid, ...props } =
      this.props;
    return (
      <input
        type={'text'}
        id={name}
        name={name}
        onChange={this.handleChange}
        value={value || ''}
        maxLength={schema.maxLength}
        {...props}
      />
    );
  }
}

Url.propTypes = {
  name: PropTypes.string,
};

export default Url;
