import { faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import Icon from '../components/Icon/Index';
import { splitPrice } from '../helpers';
import * as actions from '../routes/actions/Product';
import RemoveModal from '../views/Product/RemoveModal';
import storage from '../services/storage';
import { keys } from '../services/constants';
import { NumericInput } from '../components';

export const BasketNav = (props) => {
  const { location, basket } = props;
  // const [basketCount, setBasketCount] = useState();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // const discountGet = () => {
  //  storage.getItem(keys.CouponDiscount) ?? 0.0;
  // }
  var discountData = storage.getItem(keys.CouponDiscount);
  discountData = JSON.parse(discountData);
  const couponExpireDate = discountData?.couponExpireDate || '';
  const expireDate = new Date(couponExpireDate);
  const now = new Date();
  const isListDiscount = discountData?.isListIndirim || false;
  const discount =
    now > expireDate ? 0.0 : discountData?.toplamKuponIndirimi || 0.0;
  useEffect(() => {
    // if (basket.length !== basketCount) {
    props.getBasket();
    // setBasketCount(basket.length);
    // }
  }, [props.location.pathname]);

  if (location.pathname.startsWith('/sepetim') || basket.length === 0) {
    return null;
  }
  if (location.pathname.startsWith('/odeme') || basket.length === 0) {
    return null;
  }
  const formatPrice = (item) => {
    const price = splitPrice(item);

    return (
      <>
        {price[0]},<small>{price[1]}₺</small>
      </>
    );
  };
  const price = basket.reduce(
    (a, c) => {
      if (c.product?.getable) {
        if (isListDiscount) {
          a.productTotal += c.price * c.quantity;
          a.taxTotal += (c.priceWithTax - c.price) * c.quantity;
        } else {
          a.productTotal += c.total * c.quantity;
          //a.taxTotal += c.taxTotal * c.quantity;
        }
      }
      return a;
    },
    { productTotal: 0, taxTotal: 0 },
  );

  const openRemoveModal = async (product) => {
    setShowRemoveModal(true);
    setSelectedProduct(product);
  };

  const closeRemoveModal = () => {
    setShowRemoveModal(false);
  };

  const deleteProductToBasket = async (product) => {
    const item = {
      key: props.basketKey,
      productId: product.productId,
      ...product,
    };

    const { success, error } = await props.deleteProductToBasket(item);

    if (success) {
      props.getBasket();
      toast.success('Ürün sepetten çıkarıldı');
    } else {
      toast.error(error.message);
    }
  };

  const addBuyLaterProduct = async (product) => {
    const { success, error } = await props.addBuyLaterProduct(
      product.productId,
    );
    if (success) {
      toast(customToastComment({ success, error }), {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        closeButton: false,
      });

      props.deleteProductToBasket({
        key: props.basketKey,
        productId: product.productId,
        ...product,
      });
      closeRemoveModal();
    } else {
      toast.error(error.message);
    }
  };

  const customToastComment = () => (
    <div className="custom-toast">
      <Icon icon="iSuccess" className="mr-3" />
      <div className="d-flex flex-column">
        <span>Ürün daha sonra al listene eklendi.</span>
      </div>
    </div>
  );

  const updateProduct = async (product, value) => {
    if (value === "") {
      return;
    }
  
    let quantity = parseInt(value, 10);
  
    if (quantity > 100) {
      quantity = 100;   
    }
  
    if (product.quantity === quantity) {
      return;
    }
  
    const item = {
      key: props.basketKey,
      productId: product.productId,
      update: true,
      ...product,
      quantity,
    };
  
    const { success, error } = await props.addProductToBasket(
      item,
      props.allTaxonomies
    );
  
    if (success) {
      toast.success("Ürün sayısı güncellendi");
    } else {
      toast.error(error.message);
    }
  };
  

  const decrease = (item) => {
    let currentValue = item.quantity > 1 ? item.quantity - 1 : 1;
    if (currentValue < 1) {
      currentValue = 1;
    }
    updateProduct(item, currentValue);
  };

  const increase = (item) => {
    let maxValue = 100;
    let currentValue = parseInt(item.quantity) + 1;
    if (currentValue > maxValue) {
      currentValue = maxValue;
    }
    updateProduct(item, currentValue);
  };

  return (
    <div className="basket-nav">
      <div className="basket-nav-head">
        <span className="basket-nav-title">Ara Toplam</span>
        <div className="basket-nav-price">
          {formatPrice(
            (price.productTotal + price.taxTotal - discount).toFixed(2),
          )}
        </div>
        <Link className="btn btn-basket-link" to="/sepetim">
          Sepete Git
        </Link>
      </div>
      <div className="basket-nav-inner">
        <ul className="basket-list-container">
          {basket.map((item) => (
            <li className="basket-item">
              <Link
                to={`/urunler/detay/${item.productId}`}
                key={item.productId}
              >
                <div className="img-wrapper">
                  <img src={item.product.imageUrl} alt={item.product.name} />
                  <div className="basket-item-price">
                    {formatPrice(item.total.toFixed(2))}
                  </div>
                </div>
              </Link>

              {item.price === 0 ? (
                <div className="d-flex justify-content-center align-items-center">
                  <br />
                  <small
                    className="fs-12 font-weight-500"
                    style={{ color: '#ff0000' }}
                  >
                    <i>Hediye</i>
                  </small>
                </div>
              ) : null}
              {item.price !== 0 && (
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div
                    className="d-flex align-items-center input-group-prepend"
                    style={{ borderRadius: 4 }}
                  >
                   
                    <NumericInput
                     type="text"
                     maxLength="3"
                     className="form-control "
                     basketClassname="true"
                     style={{
                       background: "white",
                       textAlign: "center",
                       borderRadius: 5,
                       fontWeight: "600",
                       color: "#484848",
                       fontSize: "18px",
                       height: "25px",
                       margin: 0,
                       padding: 0,
                       width: "70px",
                     }}
                     disabled={
                      !item.product?.getable ||
                      item.product?.isDigital
                     }
                     onChange={(value) => updateProduct(item, value)} 
                      defaultValue={item.quantity}
                   />
     

                 
                  </div>
                  <button
                    className="btn btn-light p-0"
                    onClick={() => openRemoveModal(item)}
                  >
                    <FontAwesomeIcon
                      className="basket-item-icons"
                      icon={faTrash}
                    />
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      <RemoveModal
        show={showRemoveModal}
        closeRemoveModal={closeRemoveModal}
        deleteProductToBasket={deleteProductToBasket}
        selectedProduct={selectedProduct}
        selectedProductName={selectedProduct?.name}
        addBuyLaterProduct={addBuyLaterProduct}
      />
    </div>
  );
};

const mapStateToProps = ({ ProductReducer }) => ({
  basket: ProductReducer.basket,
  basketKey: ProductReducer.basketKey,
  allTaxonomies: ProductReducer.taxonomies,
});

const mapDispatchToProps = {
  getBasket: actions.getBasket,
  deleteProductToBasket: actions.deleteProductToBasket,
  addBuyLaterProduct: actions.addBuyLaterProduct,
  addProductToBasket: actions.addProductToBasket,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(BasketNav);
