import React, { Component } from 'react';
import Icon from '../../Icon/Index';
//import PropTypes from "prop-types";

class Password extends Component {
  constructor(props) {
    super(props);

    this.state = { showPassword: false };
  }

  togglePassword = (e) => {
    e.preventDefault();

    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { name, schema, uiSchema, onChange, value, valid, ...props } =
      this.props;
    const options = {};
    if (uiSchema) {
      Object.assign(options, uiSchema['ui:options']);
    }
    return (
      <div className="input-group mb-3">
        <input
          type={this.state.showPassword ? 'text' : 'password'}
          id={name}
          name={name}
          onChange={({ target: { name, value } }) => onChange(name, value)}
          value={value || ''}
          {...options}
          {...props}
        />
        <div className="input-group-append" onClick={this.togglePassword}>
          <span className="badge verified-badge">
            {this.state.showPassword ? (
              <Icon icon="iEyeMask" />
            ) : (
              <Icon icon="iEyeUnmask" />
            )}
          </span>
        </div>
      </div>
    );
  }
}

Password.propTypes = {};

export default Password;
