import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TooltipTrigger from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import IconButton from '../Button/Icon';

const Menu = ({ children, store, hideArrow, items, onItemClick, ...props }) => (
  <TooltipTrigger
    {...props}
    placement="top-end"
    modifiers={{ inner: { enabled: true } }}
    tooltip={({ tooltipRef, getTooltipProps }) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: 'tooltip-container',
          /* your props here */
        })}
      >
        <div className="d-flex flex-row">
          {items.map((item, i) => (
            <IconButton
              className={classNames('btn-sm btn-link', item.className)}
              icon={item.icon}
              title={item.title}
              key={i}
              onClick={() => onItemClick(item)}
            />
          ))}
        </div>
      </div>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <div
        {...getTriggerProps({
          ref: triggerRef,
          className: 'trigger',
        })}
      >
        {children}
      </div>
    )}
  </TooltipTrigger>
);

Menu.propTypes = {
  items: PropTypes.array,
};

export default Menu;
