/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable object-curly-spacing */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { PureComponent } from 'react';
//import PropTypes from 'prop-types';
import classNames from 'classnames';
import SideBar from './SideBar';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../routes/actions/Product';
import { Breadcrumb } from '../../components';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Item from '../../components/Product/Item';
import { customStyles, setPrerenderTimeout } from '../../helpers';
import { Modal } from '../../components/index';
import CloseButton from '../../components/Button/Close';

const options = [
  {
    value: 'order',
    label: 'Sırala',
  },
  {
    value: 'increasing',
    label: 'Fiyat Artan',
  },
  {
    value: 'decreasing',
    label: 'Fiyat Azalan',
  },
  {
    value: 'atoz',
    label: 'A-Z',
  },
  {
    value: 'ztoa',
    label: 'Z-A',
  },
];

/* const menuItems = [
  {
    name: "homepage",
    icon: faHome,
    title: "Anasayfada Göster"
  },
  {
    name: "remove",
    icon: faTrash,
    className: "text-danger",
    title: "Düzenle"
  }
]; */

const classes = [{ name: '5.Sınıf' }, { name: '6.Sınıf' }, { name: '7.Sınıf' }];

const exams = [{ name: 'YGS' }, { name: 'DGS' }, { name: 'KPSS' }];

const lessons = [{ name: 'Türkçe' }, { name: 'Fen' }, { name: 'Matematik' }];

const breadcrumbs = [
  {
    title: 'Anasayfa',
    url: '/',
  },
  {
    title: 'Ürün Arama',
    active: true,
  },
];

const FilterModal = (props) => (
  <Modal {...props} className="filter-modal">
    <div
      className="modal-header align-items-center"
      style={{ justifyContent: 'left' }}
    >
      <button
        onClick={props.onCloseClick}
        className="btn btn-link text-decoration-none"
      >
        <img
          src={require('../../assets/images/icons/arrow-left.svg')}
          alt="..."
        />
      </button>
      <h5 className="modal-title">Filtrele</h5>
    </div>
    <div className="modal-body">
      <div className="mb-4">
        <div className="brand-show-filter-item-btn">
          Matematik Okulu
          <button className="close" style={{ marginLeft: '15px' }}>
            &times;
          </button>
        </div>
        <div className="brand-show-filter-item-btn">
          Matematik Okulu
          <button className="close" style={{ marginLeft: '15px' }}>
            &times;
          </button>
        </div>
        <div className="brand-show-filter-item-btn">
          Matematik Okulu
          <button className="close" style={{ marginLeft: '15px' }}>
            &times;
          </button>
        </div>
        <Link
          className="fs-14"
          style={{ color: 'black', marginLeft: '20px', fontWeight: 500 }}
        >
          Filtreleri Temizle
        </Link>
      </div>

      <div>
        <h6>Sınavlar</h6>

        {exams.map((item, i) => (
          <div key={i} className="form-group form-check brand-checkbox">
            <input type="checkbox" id={item.name + i} />
            <label
              htmlFor={item.name + i}
              className="d-flex flex-column ml-2 mb-0"
            >
              <h5 className="checkbox-label">
                {item.name}
                <span className="badge badge-warning text-white badge-pill ml-2">
                  {5 * (i + 1)}
                </span>
              </h5>
            </label>
          </div>
        ))}
      </div>
      <div>
        <h6>Sınavlar</h6>

        {exams.map((item, i) => (
          <div key={i} className="form-group form-check brand-checkbox">
            <input type="checkbox" id={item.name + i} />
            <label
              htmlFor={item.name + i}
              className="d-flex flex-column ml-2 mb-0"
            >
              <h5 className="checkbox-label">
                {item.name}
                <span className="badge badge-warning text-white badge-pill ml-2">
                  {5 * (i + 1)}
                </span>
              </h5>
            </label>
          </div>
        ))}
      </div>
      <div>
        <h6>Sınavlar</h6>

        {exams.map((item, i) => (
          <div key={i} className="form-group form-check brand-checkbox">
            <input type="checkbox" id={item.name + i} />
            <label
              htmlFor={item.name + i}
              className="d-flex flex-column ml-2 mb-0"
            >
              <h5 className="checkbox-label">
                {item.name}
                <span className="badge badge-warning text-white badge-pill ml-2">
                  {5 * (i + 1)}
                </span>
              </h5>
            </label>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <h6>Sınıflar</h6>

        {classes.map((item, i) => (
          <div key={i} className="form-group form-check brand-checkbox">
            <input type="checkbox" id={item.name + i} />
            <label
              htmlFor={item.name + i}
              className="d-flex flex-column ml-2 mb-0"
            >
              <h5 className="checkbox-label">
                {item.name}
                <span className="badge badge-warning text-white badge-pill ml-2">
                  {5 * (i + 1)}
                </span>
              </h5>
            </label>
          </div>
        ))}

        <div>
          <Link className="fs-12 text-blue">
            Tümünü Gör
            <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
          </Link>
        </div>
      </div>
      <div className="mt-4">
        <h6>Dersler</h6>

        {lessons.map((item, i) => (
          <div key={i} className="form-group form-check brand-checkbox">
            <input type="checkbox" id={item.name + i} />
            <label
              htmlFor={item.name + i}
              className="d-flex flex-column ml-2 mb-0"
            >
              <h5 className="checkbox-label">
                {item.name}
                <span className="badge badge-warning text-white badge-pill ml-2">
                  {5 * (i + 1)}
                </span>
              </h5>
            </label>
          </div>
        ))}
        <div>
          <Link className="fs-12 text-blue">
            Tümünü Gör
            <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
          </Link>
        </div>
      </div>
    </div>
  </Modal>
);

const SortModal = (props) => (
  <Modal {...props} className="modal-container non-modal-height">
    <div className="modal-header">
      <h5 className="modal-title">Sırala</h5>
      <CloseButton onClick={props.onCloseClick} />
    </div>
    <div className="modal-body">
      <ul className="list-group">
        {options.map((item, i) => (
          <li
            key={i}
            className="list-group-item border-radius-0 border-0 fs-14 color-484848"
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  </Modal>
);

class Search extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { show: false, showSortModal: false };
    this.applyFilter = this.applyFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidMount() {
    this.props.getProductsById('', '', '');
  }

  componentDidUpdate() {
    setPrerenderTimeout();
  }

  applyFilter(filter) {}

  removeFilter(filter) {}

  clearFilters() {}

  render() {
    const filteredProducts = this.props.filters.length
      ? this.props.products.filter(
          (product) =>
            this.props.filters.filter(
              (filter) => product.taxons.filter((t) => t === filter.id).length,
            ).length,
        )
      : this.props.products;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <Breadcrumb items={breadcrumbs} />
          </div>

          {/* Arama sonucu bulunamadı alanı gizlendi */}
          {/*
              <div className="text-center col-12 d-none">
                <div style={{ marginBottom: "10rem", marginTop: "2rem" }}>
                  <p className="fs-18">
                    <span className="font-weight-500">"Asdkasşldkasdla"</span>&nbsp;
                    ile ilgili sonuç bulunamamıştır
                  </p>
                  <img
                    src={require("../../assets/images/icons/icon-sad.svg")}
                    alt="..."
                  />
                </div>
                <h1>İlginizi Çekebilir</h1>
                <div className="row">
                  <div className="col-md-3 col-sm-4 col-6">
                    <Item />
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <Item />
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <Item />
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <Item />
                  </div>
                </div>
              </div>
            */}
          {/* Arama sonucu bulunamadı  alanı gizlendi*/}

          <div className="col-12">
            <h1>Tüm Ürünler</h1>
          </div>
          <div className="col-12 d-block d-lg-none">
            <div
              className={classNames('collapse', { show: this.state.show })}
              id="collapseExample"
            >
              <div className="card card-body">
                <h5 className="card-title">
                  <button
                    type="button"
                    className="close float-right"
                    onClick={() => this.setState({ show: false })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </h5>
                <h6 className="card-subtitle">Sırala</h6>
                <Select
                  isSearchable={false}
                  options={options}
                  defaultValue={options[0]}
                  className="w-100"
                />
                <h6 className="card-subtitle mt-2">Filtrele</h6>
                <SideBar prefix="m" filteredProducts={filteredProducts} />
              </div>
            </div>
          </div>

          <div className="col-sm-3 brand-show-filter">
            <SideBar
              filter={this.applyFilter}
              filteredProducts={filteredProducts}
            />
          </div>

          <div className="col-sm-9 brand-show-list xs-mt-30">
            <div className="row by-dedede py-2 mb-3 brand-show-mobile-filter">
              <div className="col-6">
                <button
                  onClick={() => this.setState({ show: true })}
                  className="btn btn-link text-decoration-none w-100 text-dark text-capitalize font-weight-500 br-dedede"
                >
                  Filterele
                </button>
              </div>
              <div className="col-6">
                <button
                  onClick={() => this.setState({ showSortModal: true })}
                  className="btn btn-link text-decoration-none w-100 text-dark text-capitalize font-weight-500"
                >
                  Sırala
                </button>
              </div>
            </div>

            <div className="card-body p-1 brand-show-filter">
              <div>
                {this.props.filters.map((filter) => (
                  <div className="brand-show-filter-item-btn" key={filter.id}>
                    {filter.name}
                    <button
                      onClick={() => this.removeFilter(filter)}
                      className="close"
                      style={{ marginLeft: '15px' }}
                    >
                      &times;
                    </button>
                  </div>
                ))}

                <Link
                  className="fs-14"
                  style={{
                    color: 'black',
                    marginLeft: '20px',
                    fontWeight: 500,
                    lineHeight: 2.5,
                  }}
                  onClick={this.clearFilters}
                >
                  Filtreleri Temizle
                </Link>
              </div>
            </div>
            <hr />
            <div className="d-flex flex-row justify-content-between w-100">
              <span className="mt-2 fs-12" style={{ fontWeight: '500' }}>
                {this.props.products.length} ürün var
              </span>
              <Select
                isSearchable={false}
                options={options}
                defaultValue={options[0]}
                className="brand-show-filter-select"
                styles={customStyles}
              />
            </div>

            <div className="row">
              <div className="col-12">
                {filteredProducts.map((product, index) => {
                  return (
                    <Item
                      key={product.id}
                      product={product}
                      addProductToBasket={this.props.addProductToBasket}
                      basketKey={this.props.basketKey}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <FilterModal
          show={this.state.show}
          onCloseClick={() => this.setState({ show: false })}
        />

        <SortModal
          show={this.state.showSortModal}
          onCloseClick={() => this.setState({ showSortModal: false })}
        />
      </>
    );
  }
}

Search.propTypes = {};

const mapStateToProps = ({ ProductReducer }) => ({
  products: ProductReducer.products,
  basket: ProductReducer.basket,
  basketKey: ProductReducer.basketKey,
  taxonomies: ProductReducer.taxonomies,
});

const mapDispatchToProps = {
  getProductsById: actions.getProductsById,
  addProductToBasket: actions.addProductToBasket,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
