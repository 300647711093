import React, { Component } from 'react';
//import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { CitySelect } from '../..';
import { getTowns } from '../../../routes/actions/Account';

class City extends Component {
  handleChange = (selected) => {
    const { name, onChange, uiSchema } = this.props;
    const options = uiSchema['ui:options'];

    onChange(
      name,
      selected.value ? { id: selected.value, name: selected.label } : undefined,
    );

    if (options && options.setter) {
      Object.keys(options.setter).map((key) =>
        onChange(key, options.setter[key]),
      );
    }
  };

  render() {
    const { name, valid, value, readOnly, accountSettings } = this.props;
    const cityId = value ? value.id : null;
    return (
      <CitySelect
        name={name}
        onChange={this.handleChange}
        value={cityId}
        valid={valid}
        readOnly={readOnly}
      />
    );
  }
}

const mapStateToProps = ({ AccountReducer }) => ({
  cities: AccountReducer.cities,
});

const mapDispatchToProps = {
  getTowns: getTowns,
};

export default connect(mapStateToProps, mapDispatchToProps)(City);
