import actionTypes from '../../actions/Coin/types';

export const initialState = {
  id: 0,
  name: '',
  body: '',
  price: 0,
  quantity: 0,
  bonus: 0,
  image: '',
  discount: 0,
  bonusExpire: '',
  coin: {},
};

export default function coinReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_COIN_BY_ID:
      return action.payload;
    default:
      return state;
  }
}

// export default (state = initialState, action) => {
//   switch (action.type) {
//     case actionTypes.GET_COIN_BY_ID:
//       {
//         let data = {
//           ...state,
//           coin: action.payload.coin,
//           coins: initialState.coin
//         };
//         return data;
//       }
//     default:
//       return state;
//     }
// };
