import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Index';

const CloseButton = ({ className, onClick }) => {
  return (
    <button
      type="button"
      className={classNames('modal-close-button btn', className)}
      onClick={onClick}
    >
      <Icon icon="iClose" />
    </button>
  );
};

export default CloseButton;
