import actionTypes from '../../actions/Mode/types';

export const initialState = {
  mode: '',
};

export default function RecommendationProductsReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case actionTypes.HANDLE_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    default:
      return state;
  }
}
