const GET_PAGES = 'CONTENT/GET';
const GET_MYPAGES = 'CONTENT/GET_MYPAGES';
const GET_BY_NAME = 'CONTENT/GET_BY_NAME';
const GET_HISTORY_BY_NAME = 'CONTENT/GET_HISTORY_BY_NAME';
const UPDATE_BY_NAME = 'CONTENT/UPDATE_BY_NAME';
const ADD_COMMENT_TO_WALL = 'CONTENT/ADD_COMMENT_TO_WALL';
const GET_MAIN_PAGE = 'CONTENT/GET_MAIN_PAGE';
const CHANGE_PAGE = 'CHANGE/PAGE';
const CHANGE_MAIN_PAGE = 'CHANGE/MAIN_PAGE';
const CREATE_PAGE = 'CREATE/PAGE';
const SAVE_PAGE = 'SAVE/PAGE';
const PUBLISH_PAGE = 'PUBLISH/PAGE';
const DELETE_PAGE = 'DELETE/PAGE';
const FILE_UPLOAD = 'CONTENT/FILE_UPLOAD';
const SEND_FOR_APPROVAL_PAGE = 'SEND_FOR_APPROVAL/PAGE';
const CONFIRM_PAGE = 'CONFIRM/PAGE';
const RETURN_PAGE = 'RETURN/PAGE';

export default {
  GET_PAGES,
  GET_MYPAGES,
  GET_BY_NAME,
  GET_HISTORY_BY_NAME,
  GET_MAIN_PAGE,
  CHANGE_PAGE,
  CHANGE_MAIN_PAGE,
  CREATE_PAGE,
  PUBLISH_PAGE,
  SAVE_PAGE,
  DELETE_PAGE,
  FILE_UPLOAD,
  UPDATE_BY_NAME,
  ADD_COMMENT_TO_WALL,
  SEND_FOR_APPROVAL_PAGE,
  CONFIRM_PAGE,
  RETURN_PAGE,
};
