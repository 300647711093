import actionTypes from '../../actions/RecommendationProducts/types';

export const initialState = {
  products: [],
  categories: [],
};

export default function RecommendationProductsReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case actionTypes.GET_RECOMMENDATION_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case actionTypes.GET_RECOMMENDATION_GATEGORİES:
      return {
        ...state,
        categories: action.payload,
      };
    default:
      return state;
  }
}
