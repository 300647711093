import React, { useState } from 'react';
import Modal from '../../components/Modal/Index';
import { BaseForm } from '../../components';
import FormSchemas from '../../variables/FormSchemas';
import CloseButton from '../../components/Button/Close';
import Turnstile, { useTurnstile } from 'react-turnstile';
import { turnstileSiteKeys } from '../../services/constants';

const Login = ({ login, onClick, ...props }) => {
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
    remember: true,
    cfToken: '',
  });

  var turnstile = useTurnstile();
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const handleChange = (updatedFormData) => {
    setCredentials((prevState) => ({
      ...prevState,
      ...updatedFormData, // Sync the entire form data, including username and password
    }));
  };

  return props.show ? (
    <Modal
      className="loginModal modal-container non-modal-height"
      show={props.show}
      onCloseClick={props.onCloseClick}
    >
      <div className="modal-header bt-dedede:50">
        <div className="modal-tab">
          <button
            type="button"
            className="btn active"
            name="login"
            onClick={onClick}
          >
            Giriş Yap
          </button>
          <button
            type="button"
            className="btn"
            name="register"
            onClick={onClick}
          >
            Üye Ol
          </button>
        </div>
        <CloseButton onClick={props.onCloseClick} />
        <span />
      </div>
      <div className="modal-body">
        <BaseForm
          id="login"
          schema={FormSchemas.login.schema}
          uiSchema={FormSchemas.login.uiSchema}
          onChange={handleChange}
          onSubmit={(data) => {
            setSubmitEnabled(false);
            turnstile.reset();
            login(data);
          }}
          formData={credentials}
        >
          <div className="d-flex justify-content-center">
            <Turnstile
              sitekey={turnstileSiteKeys.Login}
              autoResetOnExpire={true}
              onLoad={() => setSubmitEnabled(false)}
              onVerify={(token) => {
                setCredentials((prevState) => ({
                  ...prevState,
                  cfToken: token,
                }));
                setSubmitEnabled(true);
              }}
              onExpire={() => {
                setSubmitEnabled(false);
                turnstile.reset();
              }}
              onError={() => {
                setSubmitEnabled(false);
                turnstile.reset();
              }}
              language="tr"
            />
          </div>
          <button
            className="btn btn-primary btn-block py-2 fs-16 font-weight-bold border-radius-12"
            type="submit"
            disabled={!submitEnabled}
            style={{ height: 56 }}
          >
            Giriş Yap
          </button>
          <div className="d-flex my-4">
            <button
              onClick={onClick}
              name="forgotten"
              className="btn btn-link color-727272 cursor-pointer p-0 fs-14 font-weight-500 text-right text-transform-none"
              type="button"
            >
              Şifremi unuttum
            </button>
          </div>
          {false && props.showResend && (
            <button
              onClick={onClick}
              name="confirm"
              className="btn btn-link text-primary cursor-pointer fs-15 btn-block font-weight-500 text-center mt-2 text-transform-none"
              type="button"
            >
              Aktivasyon mailini tekrar gönder
            </button>
          )}
        </BaseForm>
      </div>
    </Modal>
  ) : null;
};

export default Login;
