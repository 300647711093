import React from 'react';

const Badge = ({ title, src }) => {
  return (
    <div className="card text-center">
      <div className="card-body">
        <h4 className="fs-20 font-weight-600 color-484848 my-2">{title}</h4>

        <img src={src} alt="..." className="img-fluid" />
      </div>
    </div>
  );
};

export default Badge;
