import React from 'react';
import classNames from 'classnames';
import Widgets from '../Widgets';
import Icon from '../../Icon/Index';

export default ({
  args,
  className,
  name,
  schema,
  uiSchema,
  value,
  onChange,
  onBlur,
  readOnly,
  placeholder,
  errors,
  accountSettings,
}) => {
  let widgetName = 'string';
  if (uiSchema && uiSchema['ui:widget']) {
    widgetName = uiSchema['ui:widget'];
  }
  const WidgetComponent = Widgets[widgetName] || Widgets['string'];
  const valid = !(errors && errors.length > 0);
  const widgetProps = {
    className: classNames(
      'form-control',
      valid === false ? 'is-invalid' : 'valid',
    ),
    name,
    schema,
    uiSchema,
    value,
    readOnly,
    placeholder,
    onChange,
    valid,
    onBlur,
    args,
    accountSettings,
  };
  return (
    <div className={classNames('form-group col', className)}>
      <label className="d-flex payment-label" htmlFor={name}>
        {schema.title}
        {schema?.tooltip ? (
          <div className="ml-2 cvv-icon">
            <Icon icon="iInfo" />
            <div className="cvv-tooltip">
              <Icon icon="iCVV" />
              <span>Kartınızın arka yüzündeki son 3 rakam</span>
            </div>
          </div>
        ) : null}
      </label>
      <WidgetComponent {...widgetProps} />
      <small id={`${name}_help`} className="form-text text-muted col-sm-12">
        {uiSchema && uiSchema['ui:description']}
      </small>
      {(errors || []).map((error, i) => (
        <div key={i} className="invalid-feedback">
          {error}
        </div>
      ))}
    </div>
  );
};
