import React, { Component } from 'react';
//import _remove from "lodash/remove";
//import PropTypes from 'prop-types';
//import classNames from "classnames";
import Modal from './Index';
import CloseButton from '../Button/Close';
import { move } from '../../helpers';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import IconButton from '../Button/Icon';
import { faSave } from '@fortawesome/free-solid-svg-icons';

const SortableItem = SortableElement(({ value }) => (
  <li class="list-group-item sortable-item d-flex justify-content-between align-items-center">
    {value.name}
    <span class="badge badge-light badge-pill">{value.index}</span>
  </li>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul class="list-group">
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item.id}`}
          index={index}
          value={{ name: item.name, index }}
        />
      ))}
    </ul>
  );
});

class SortProducts extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      items: props.items,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: move(items, oldIndex, newIndex),
    }));
  };

  handleSave = (event) => {
    const sort = this.state.items.map((item) => item.id);
    this.props.onChange(sort);
    this.props.onClose(event);
  };

  render() {
    const { onClose, show } = this.props;
    return (
      show && (
        <Modal
          show={true}
          className="modal-container"
          dialogClassName="modal-lg"
        >
          <div className="modal-header border-0">
            <CloseButton onClick={onClose} />
          </div>
          <div className="modal-body">
            <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />
          </div>
          <div className="modal-footer">
            <IconButton
              className="btn-primary"
              onClick={this.handleSave}
              icon={faSave}
            >
              Kaydet
            </IconButton>
          </div>
        </Modal>
      )
    );
  }
}

SortProducts.propTypes = {};

export default SortProducts;
