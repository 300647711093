import React, { Component } from 'react';
import _, { forEach } from 'lodash';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ProgressBar,
  LinkButton,
  InformationModal,
  Breadcrumb,
  InteractionWrapper,
} from '../../components';
import Book from './Book';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import * as actions from '../../routes/actions/Study';
import * as accountActions from '../../routes/actions/Account';
import * as quizActions from '../../routes/actions/Desk/Quiz';

import { toast } from 'react-toastify';
import { settings } from '../../services/constants';
import ReactPlyr from '../../components/Video/ReactPlyr';
import Modal from '../../components/Modal/Index';
import CloseButton from '../../components/Button/Close';
import Icon from '../../components/Icon/Index';
import dersImages from '../../assets/images/dersler';
import { RadialChart as ReactRadialChart } from 'react-vis';
import ResetQuiz from '../../components/Modal/ResetQuiz';
import { calculateNet } from '../../helpers/mathExtensions';
import { useState } from 'react';

const customPalette = ['#46ff54', '#FF3636', '#c2c2c2'];

const ListItemHeader = ({ title, value, onClick, active = false, iconUrl }) => (
  <div
    className="d-flex flex-row justify-content-between align-items-center"
    onClick={onClick}
  >
    <button
      type="button"
      className="btn box-shadow-none d-flex align-items-center color-484848 font-weight-600 fs-16 text-left w-100 w-sm-auto"
    >
      <div className="lesson-img-wrapper">
        <img src={iconUrl} className="img-fluid" />
      </div>
      <div className="lesson-inner">
        <span>{title}</span>
        <ProgressBar
          value={value}
          className={classNames('w-100 mt-2 mb-3 d-flex d-sm-none')}
        />

        <div className="more-lesson">
          <span>Testler için TIKLA</span>
          <span style={{ transform: active && 'rotate(180deg)' }}>
            <Icon icon="iArrowDownOrange" />
          </span>
        </div>
      </div>
    </button>
    <ProgressBar
      value={value}
      className={classNames('w-50 d-none d-sm-flex')}
    />
  </div>
);

const QuizView = ({
  quiz,
  toggleResetQuizModal,
  showResetQuizModal,
  index,
  showEmoji,
}) => {
  const [showModal, setShowModal] = useState(false);
  const pathnames = window.location.pathname.split('/');
  const [lessonId, setLessonId] = useState(pathnames[pathnames.length - 1]);
  return (
    <li className="list-group-item bt-dedede:50 d-flex quiz-list-item">
      <LinkButton
        className="color-919191 flex-1 text-left box-shadow-none"
        to={
          quiz.toplamDogru + quiz.toplamYanlis + quiz.toplamBos === 0
            ? `/dijitalkitap/book/lesson/subject/quiz/${quiz.testID}`
            : `/dijitalkitap/book/lesson/subject/quiz/summary/${quiz.testID}`
        }
      >
        <div className="quiz-inner">
          {quiz.toplamDogru + quiz.toplamYanlis + quiz.toplamBos !== 0 ? (
            <div
              className="quiz-img-wrapper"
              style={{ borderColor: '#46ff54' }}
            >
              <img src={dersImages.getIcon(quiz.bransAd)} alt="..." />
              <div className="quiz-count">{index + 1}</div>
              <div className="success-emoji-wrapper">
                {<img src={`${showEmoji(quiz)}`} />}
              </div>
            </div>
          ) : (
            <div
              className="quiz-img-wrapper"
              style={{ borderColor: '#f4f4f4' }}
            >
              <img src={dersImages.getIcon(quiz.bransAd)} alt="..." />
              <div className="quiz-count">{index + 1}</div>
            </div>
          )}

          <div className="quiz-content">
            <span>
              {quiz.testAdi}
              {quiz.isDijital === 1 && (
                <span
                  className=""
                  style={{
                    width: '72px',
                    height: '16px',
                    margin: '2px 0 0 6px',
                    padding: '1px 6px 3px 7px',
                    borderRadius: '8px',
                    backgroundColor: '#6e87ff',
                    display: 'inline-flex',
                  }}
                >
                  <i
                    style={{
                      width: '72px',
                      height: '11px',
                      fontSize: '10px',
                      fontWeight: 'bold',
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      letterSpacing: 'normal',
                      color: '#ffffff',
                    }}
                  >
                    {'Dijital Test'}
                  </i>
                </span>
              )}
            </span>
            <span>Toplam: {quiz.testSoruSayisi} Soru</span>
          </div>
        </div>
      </LinkButton>
      {quiz.toplamDogru + quiz.toplamYanlis + quiz.toplamBos !== 0 && (
        <div className="d-inline-block py-4 px-sm-0 px-2">
          <span className="badge badge-success mr-2">
            {quiz.toplamDogru}
            <span className="ml-1">Doğru</span>
          </span>
          <span className="badge badge-danger mr-2">
            {quiz.toplamYanlis}
            <span className="ml-1">Yanlış</span>
          </span>
          <span className="badge">
            {quiz.toplamBos}
            <span className="ml-1">Boş</span>
          </span>
          <div className="d-inline-block mt-2 mt-sm-0">
            <LinkButton
              title="Analiz Sonucu"
              className="btn btn-quiz ml-2"
              to={
                quiz.toplamDogru + quiz.toplamYanlis + quiz.toplamBos === 0
                  ? `/dijitalkitap/book/lesson/subject/quiz/${quiz.testID}`
                  : `/dijitalkitap/book/lesson/subject/quiz/summary/${quiz.testID}`
              }
            >
              <Icon icon="iStats" />
            </LinkButton>
            <button
              title="Çöp Tenekesi"
              className="btn ml-2"
              onClick={() => toggleResetQuizModal(quiz)}
            >
              <Icon icon={'iFilterDelete'} />
            </button>
          </div>
        </div>
      )}
      {quiz.toplamDogru + quiz.toplamYanlis + quiz.toplamBos === 0 && (
        <div className="d-flex align-items-center px-3 px-sm-0">
          {quiz.KonuAnlatimDokumanlari?.length > 0 && (
            <button
              onClick={() => {
                setShowModal(true);
              }}
              className="btn btn-link btn-primary fs-14 border-radius-8 font-weight-600 text-white text-capitalize mr-2"
              style={{ maxWidth: 130 }}
            >
              Konu Anlatımı
            </button>
          )}
          <Link
            to={`/dijitalkitap/book/lesson/subject/quiz/${quiz.testID}`}
            className="btn btn-link btn-primary fs-14 border-radius-8 font-weight-600 text-white text-capitalize mr-2"
            style={{ maxWidth: 112 }}
          >
            Online Çöz
          </Link>
          {quiz.isDijital === 0 && (
            <Link
              to={`/dijitalkitap/book/lesson/subject/quiz/answers/${quiz.testID}`}
              className="btn btn-link btn-outline-primary border-radius-8 fs-14 font-weight-600 mr-2 text-capitalize btn-hover-white"
              style={{ maxWidth: 110 }}
            >
              Cevap Gir
            </Link>
          )}

          {quiz.isDijital === 0 &&
            ((quiz.isCozumVideosunaSahip && (
              <Link
                to={`/dijitalkitap/book/lesson/subject/quiz/play/${quiz.testID}`}
                className="btn btn-link btn-watch fs-14 border-radius-8 font-weight-600 text-white text-capitalize"
                style={{
                  visibility: quiz.isCozumVideosunaSahip ? 'visible' : 'hidden',
                }}
              >
                <Icon icon="iVideo" className="mr-2" />
                {window.innerWidth < 475 ? 'İzle' : 'Çözüm İzle'}
              </Link>
            )) ||
              (quiz.isCozumPDFsineSahip && (
                <Link
                  to={
                    '/dijitalkitap/book/lessons/' +
                    lessonId +
                    '/read?file=' +
                    btoa(quiz.CozumPdfleri[0].DosyaUrl)
                  }
                  className="btn btn-link btn-watch fs-14 border-radius-8 font-weight-600 text-white text-capitalize"
                  style={{
                    visibility: quiz.isCozumPDFsineSahip ? 'visible' : 'hidden',
                  }}
                >
                  <Icon icon="iPdfSvg" className="mr-2" />
                  {window.innerWidth < 475 ? 'Gör' : 'Çözüm Gör'}
                </Link>
              )))}
        </div>
      )}
      {showModal ? (
        <Modal
          className="modal-container non-modal-height"
          dialogClassName="modal-md"
          show={showModal}
        >
          <div className="modal-header mx-12 pl-10">
            <div>
              <h5 className="modal-title" id="exampleModalLabel">
                {quiz.testAdi} - Konu Anlatımı
              </h5>
            </div>

            <CloseButton onClick={() => setShowModal(false)} />
          </div>
          <div className="modal-body">
            <div style={{ textAlign: 'center' }}>
              {quiz.toplamDogru + quiz.toplamYanlis + quiz.toplamBos === 0 ? (
                <div
                  className="d-flex align-items-center px-3 px-sm-0"
                  style={{ justifyContent: 'center' }}
                >
                  <Link
                    to={`/dijitalkitap/book/lesson/subject/quiz/${quiz.testID}`}
                    className="btn btn-link btn-primary fs-14 border-radius-8 font-weight-600 text-white text-capitalize mr-2"
                    style={{ maxWidth: 112 }}
                  >
                    Online Çöz
                  </Link>
                  {quiz.isDijital === 0 && (
                    <Link
                      to={`/dijitalkitap/book/lesson/subject/quiz/answers/${quiz.testID}`}
                      className="btn btn-link btn-outline-primary border-radius-8 fs-14 font-weight-600 mr-2 text-capitalize btn-hover-white"
                      style={{ maxWidth: 110 }}
                    >
                      Cevap Gir
                    </Link>
                  )}

                  {quiz.isDijital === 0 && quiz.isCozumVideosunaSahip && (
                    <Link
                      to={`/dijitalkitap/book/lesson/subject/quiz/play/${quiz.testID}`}
                      className="btn btn-link btn-watch fs-14 border-radius-8 font-weight-600 text-white text-capitalize"
                    >
                      <Icon icon="iVideo" className="mr-2" />
                      {window.innerWidth < 475 ? 'İzle' : 'Çözüm İzle'}
                    </Link>
                  )}
                </div>
              ) : null}
            </div>
            <div className="row">
              {quiz.KonuAnlatimDokumanlari &&
                quiz.KonuAnlatimDokumanlari.map((item, i) => (
                  <div
                    key={i}
                    className="col-6 col-sm-6 col-md-4 mt-3 mb-3 text-center"
                  >
                    <Link
                      to={
                        '/dijitalkitap/book/lessons/' +
                        lessonId +
                        '/read?file=' +
                        btoa(item.DosyaUrl)
                      }
                    >
                      <img
                        src={require('../../assets/images/images/konuanlatim.png')}
                        className="border-radius-8"
                        alt="..."
                        width={100}
                        height={100}
                      />
                    </Link>
                    <div>
                      <span>
                        {item.DosyaAd == '' || item.DosyaAd == null
                          ? 'Doküman'
                          : item.DosyaAd}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Modal>
      ) : null}
    </li>
  );
};

const UnitView = ({ id, isExam, unit, onCheck, onToggle, lessonId }) => (
  <>
    {
      <li className="list-group-item">
        <ListItemHeader
          title={unit.unitName}
          value={Math.round(
            (unit.tests.reduce(
              (p, c) => p + c.toplamDogru + c.toplamYanlis + c.toplamBos,
              0,
            ) /
              unit.tests.reduce((p, c) => p + c.testSoruSayisi, 0)) *
              100,
          )}
          onClick={() => onToggle(`U${id}`)}
          active={onCheck(`U${id}`)}
        />
        <div
          className={classNames('collapse', {
            show: onCheck(`U${id}`),
          })}
        >
          <ul className="list-group">
            {_.uniqBy(unit.tests, 'testID').map((quiz, iii) => (
              <QuizView
                quiz={quiz}
                key={quiz.testID}
                showEmoji={(quiz) => this.showEmoji(quiz)}
              />
            ))}
          </ul>
        </div>
      </li>
    }
  </>
);

const ExamView = ({
  id,
  isExam,
  tests,
  onCheck,
  onToggle,
  showResetQuizModal,
  toggleResetQuizModal,
  showEmoji,
}) => (
  <>
    {
      <li className="list-group-item">
        <ul className="list-group">
          {tests.map((quiz, index) => (
            <>
              <QuizView
                quiz={quiz}
                key={quiz.testID}
                showEmoji={(quiz) => showEmoji(quiz)}
                toggleResetQuizModal={toggleResetQuizModal}
                showResetQuizModal={showResetQuizModal}
                index={index}
              />
            </>
          ))}
        </ul>
      </li>
    }
  </>
);

const GetVideoFromBook = ({ book, show, showVideoPanel, user }) => {
  var videoLink = book.videoLink;

  const video = {
    url: '',
    type: '',
  };

  if (videoLink && videoLink.length) {
    if (videoLink.includes('youtube')) {
      video.type = 'youtube';
      if (videoLink.includes('embed')) {
        // www.youtube.com/embed/pxqRKh0QW3U?rel=0
        const urlParts = videoLink.split('/');
        const idWithQueryString = urlParts[urlParts.length - 1];
        const id = idWithQueryString.split('?')[0];
        video.url = id; //pxqRKh0QW3U
      } else {
        // https://www.youtube.com/watch?v=Bblydaep2Eo
        const urlParts = videoLink.split('?v=');
        const idWithQueryString = urlParts[urlParts.length - 1];
        const id = idWithQueryString.split('&')[0];
        video.url = id; //Bblydaep2Eo
      }
    } else if (videoLink.includes('.mp4')) {
      // https://video.yuksekbasari.com/CozumVideolar/0450431cc3f84a74bf75c6b666fd9a37.mp4 veya https://player.vimeo.com/external/272642997.hd.mp4?s=7ac329c7946482431ebb43554ad5660a2c742987&profile_id=175
      video.type = 'video';
      video.url = videoLink;
    } else if (videoLink.includes('vimeo') && !videoLink.includes('.m3u8')) {
      // https://player.vimeo.com/video/287256022
      video.type = 'vimeo';
      const urlParts = videoLink.split('/');
      const id = urlParts[urlParts.length - 1];
      video.url = id; //287256022
    }
  }
  return video.url ? (
    <div className="card mb-2 koclukCard">
      {showVideoPanel && (
        <button
          className="btn btn-info koclukBtn"
          onClick={() => showVideoPanel(true)}
        >
          Koçluk Videosunu Göster
        </button>
      )}
      {show && (
        <Modal
          className="modal-container non-modal-height"
          dialogClassName="modal-lg"
          show={show}
        >
          <CloseButton onClick={() => showVideoPanel(false)} />
          <div className="modal-body">
            <InteractionWrapper
              key={book.yayinID}
              videoProvider={video.type == 'video' ? 'cloudfront' : video.type}
              contentType="tanitim"
              contentId={book.yayinID}
              userId={(user && user.id) || 0}
            >
              {(markAsWatched) => (
                <ReactPlyr url={video.url} type={video.type} />
              )}
            </InteractionWrapper>
          </div>
        </Modal>
      )}
    </div>
  ) : null;
};

const extractUrlFromQueryString = ({
  url,
  lessonId,
  pdfAdditionalDocumentsUrl,
  pdfName,
}) => {
  if (url && url.length > 0) {
    const startIndex = url.indexOf('url=') + 4;
    const endIndex = url.indexOf('&', startIndex);
    const extractedUrl = url.substring(
      startIndex,
      endIndex !== -1 ? endIndex : undefined,
    );
    const cryptedUrl = btoa(
      extractedUrl + '&&&PdfBaslik=' + pdfName + pdfAdditionalDocumentsUrl,
    );
    return (
      '/dijitalkitap/book/lessons/' + lessonId + '/read?file=' + cryptedUrl
    );
  }
};

const GetDocumentFromBook = ({ book, docs, show, showDocPanel, lessonId }) => {
  var isDocument = book.dosyaVarMi;
  var ekDokumanlar = [];
  if (docs) {
    ekDokumanlar = docs.filter((doc) => doc.DosyaTipi === 2);
  }
  return null;
};

const GetPDFFromBook = ({ book, docs, show, showPDFPanel, lessonId }) => {
  var isDocument = book.dosyaVarMi;
  var pdfs = [];
  var pdfAdditionalDocuments = [];
  if (docs) {
    pdfs = docs.filter((doc) => doc.DosyaTipi === 1);
    pdfAdditionalDocuments = docs.filter((doc) => doc.DosyaTipi === 2);
  }
  var pdfAdditionalDocumentsUrl = '';
  if (isDocument && pdfAdditionalDocuments.length > 0) {
    pdfAdditionalDocuments.map(
      (item, i) =>
        (pdfAdditionalDocumentsUrl =
          pdfAdditionalDocumentsUrl +
          '&&&EkDokumanBaslik=' +
          (item.DosyaBaslik == '' || item.DosyaBaslik == null
            ? ''
            : encodeURIComponent(item.DosyaBaslik)) +
          '&&&DosyaUrl=' +
          item.DosyaUrl),
    );
  }

  const redirectToFirstPDF = () => {
    if (pdfs.length > 0) {
      const firstPDFUrl = extractUrlFromQueryString({
        url: pdfs[0].DosyaUrl,
        lessonId: lessonId,
        pdfAdditionalDocumentsUrl: pdfAdditionalDocumentsUrl,
        pdfName:
          pdfs[0].DosyaBaslik == '' || pdfs[0].DosyaBaslik == null
            ? encodeURIComponent('Doküman')
            : encodeURIComponent(pdfs[0].DosyaBaslik),
      });
      window.location.href = firstPDFUrl;
    }
  };

  return isDocument && pdfs.length > 0 ? (
    <div className="row">
      <div className="col-12 col-lg-1"> </div>
      <div
        className="col-12 col-lg-10"
        style={{ borderRadius: '10px !important;', marginTop: '10px' }}
      >
        <div className="card mb-2 kitapPdfCard">
          <button
            className="btn btn-info kitapPdfBtn d-flex align-items-center justify-content-center"
            onClick={redirectToFirstPDF}
          >
            <Icon icon="iPdfBookSvg" />
            <span style={{ marginLeft: '10px', fontWeight: '600' }}>
              KİTABI AÇ
            </span>
          </button>
        </div>
      </div>
      <div className="col-12 col-lg-1"> </div>
    </div>
  ) : null;
};

class Lessons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      list: [],
      showVideo: false,
      showResetQuizModal: false,
      selectedQuiz: [],
      data: [
        { theta: 0, label: 'Doğru', color: 1 },
        { theta: 0, label: 'Yanlış', color: 2 },
        { theta: 0, label: 'Boş', color: 3 },
      ],
      emojiList: [],
      userNet: '...',
    };

    const { id } = this.props.match.params;
    this.id = id;
    this.testCode = React.createRef();
  }

  async componentDidMount() {
    await this.props.getEmoji();
    var { emoji } = this.props;
    var emojiListReverse = emoji.emojiler.reverse();
    this.setState({ emojiList: emojiListReverse });

    await this.fetchBookDetails();
    this.props.getBookDocs(this.id);
  }
  async componentWillUnmount() {
    this.props.setInitialBook();
  }

  fetchBookDetails = async () => {
    const { book, user, getBookDetails } = this.props;
    await getBookDetails(this.id, this.props.book.Book.GrupId);

    const data = book.Analiz
      ? [
          { theta: book.Analiz.toplamDogruSoru, label: 'Doğru', color: 1 },
          {
            theta: book.Analiz.toplamYanlisSoru,
            label: 'Yanlış',
            color: 3,
          },
          { theta: book.Analiz.toplamBosSoru, label: 'Boş', color: 2 },
        ]
      : [];

    this.setState({
      data,
      userNet: calculateNet(
        user.classId,
        book.Analiz.toplamDogruSoru,
        book.Analiz.toplamYanlisSoru,
      ).toString(),
    });
  };

  showModal = () => {
    this.setState({ show: true });
  };
  closeModal = () => {
    this.setState({ show: false });
  };

  toggleResetQuizModal = (quiz, resetted) => {
    if (quiz) {
      this.setState({ selectedQuiz: quiz });
    }
    this.setState((prevState) => ({
      showResetQuizModal: !prevState.showResetQuizModal,
    }));
    if (resetted) {
      this.fetchBookDetails();
    }
  };

  toggle = (name) => {
    const { list } = this.state;
    const index = list.indexOf(name);
    if (index === -1) {
      list.push(name);
    } else {
      list.splice(index, 1);
    }
    this.setState({ list });
  };

  check = (name) => {
    const { list } = this.state;
    return list.some((p) => name === p);
  };

  goToTest = async (event) => {
    event.preventDefault();
    if (this.props.user.demo) {
      toast.error(
        'Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor.',
      );
      this.props.loginRequired(true);
      return;
    }
    const testCode = this.testCode.current.value;
    if (!testCode.trim()) {
      toast.error('Test kodu giriniz');
    } else {
      const test = await this.props.isTest(testCode);

      if (test.status) {
        if (test.IsQuiz) {
          if (!test.Detail)
            this.props.history.push(
              `/dijitalkitap/book/lesson/subject/quiz/answers/${testCode}`,
            );
          else
            this.props.history.push(
              `/dijitalkitap/book/lesson/subject/quiz/summary/${testCode}`,
            );
        } else {
          toast.error(
            'Girmiş olduğunuz koda ait gösterim hizmetimiz henüz bulunmamaktadır. Anlayışınız için teşekkür ederiz.',
          );
        }
      } else {
        toast.error(test.errorMessage);
      }
    }
  };
  showEmoji = (quiz) => {
    if (quiz.yuzde == 0 && quiz.testSoruSayisi > 0)
      quiz.yuzde = (quiz.toplamDogru / quiz.testSoruSayisi) * 100;
    const filterEmojiList = this.state.emojiList
      .sort((a, b) => (a.YuzdeDeger > b.YuzdeDeger ? 1 : -1))
      .findIndex((e) => e.YuzdeDeger >= quiz.yuzde);
    if (filterEmojiList != undefined) {
      return this.state.emojiList[filterEmojiList]?.Url;
    }
  };
  render() {
    const breadcrumbs = [
      {
        title: 'Anasayfa',
        url: '/dijitalkitap',
      },
      {
        title: 'Kütüphanem',
        url: '/dijitalkitap/books',
      },
    ];

    breadcrumbs.push({
      title: (this.props.book.Book && this.props.book.Book.yayinAdi) || '',
      active: true,
    });

    const progress =
      this.props.book.Analiz &&
      this.props.book.Analiz.aktifYayinCozulenToplamSoru &&
      this.props.book.Analiz.aktifYayinToplamSoru
        ? Math.round(
            (this.props.book.Analiz.aktifYayinCozulenToplamSoru /
              this.props.book.Analiz.aktifYayinToplamSoru) *
              100,
          )
        : 0;

    return (
      <>
        <div className="row">
          <div className="col-12 p-xs-0">
            <Breadcrumb items={breadcrumbs} />
          </div>
          <div className="col-12 p-xs-0 mb-4">
            <div className="d-flex align-items-center">
              <form
                onSubmit={this.goToTest}
                className="input-container lesson-form bg-f4f4f4 "
              >
                <div className="d-flex flex-column w-100">
                  <label htmlFor="test-code">Test Kodu</label>
                  <input
                    id="test-code"
                    ref={this.testCode}
                    type="text"
                    className="form-control fs-14"
                    placeholder="Karekodun yanındaki rakamları yazınız"
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-blue btn-lesson-submit border-radius-8"
                >
                  <span>İzle</span>
                  <img
                    src={require('../../assets/images/icons/chevron-right.svg')}
                  />
                </button>
              </form>

              <FontAwesomeIcon
                icon={faQuestionCircle}
                onClick={this.showModal}
                className="ml-2 color-919191"
              />
            </div>

            <InformationModal
              show={this.state.show}
              onCloseClick={() => this.setState({ show: false })}
              list={[
                'kod_girisi_ADIM1.jpg',
                'kod_girisi_ADIM2.jpg',
                'kod_girisi_ADIM3.jpg',
              ]}
            />
          </div>
          {this.props.book.Book ? (
            <div className="col-12 col-lg-3 p-xs-0 mb-4">
              <div className="lesson-book-wrapper">
                <Book
                  id={this.id}
                  digitalBookId={this.id}
                  progress={progress}
                  name={this.props.book.Book.yayinAdi}
                  imageUrl={this.props.book.Book.kapakAdres}
                  className={['box-shadow-none border-0', 'text-overflow-none']}
                />
                <GetVideoFromBook
                  book={this.props.book.Book}
                  show={this.state.showVideo}
                  user={this.props.user}
                  showVideoPanel={(show) => this.setState({ showVideo: show })}
                />
                <GetDocumentFromBook
                  book={this.props.book.Book}
                  docs={this.props.docs.Dokumanlar}
                  show={this.state.showDoc}
                  showDocPanel={(show) => this.setState({ showDoc: show })}
                  lessonId={this.id}
                />
                <GetPDFFromBook
                  book={this.props.book.Book}
                  docs={this.props.docs.Dokumanlar}
                  show={this.state.showPDF}
                  showPDFPanel={(show) => this.setState({ showPDF: show })}
                  lessonId={this.id}
                />

                {this.state.data && this.props.book.Analiz && (
                  <>
                    <div className="d-flex justify-content-center fs-14 font-weight-500 color-545454 my-2">
                      <span className="mr-1">Toplam Soru Adedi:</span>
                      <span>{this.props.book.Analiz.aktifYayinToplamSoru}</span>
                    </div>
                    {this.props.book.Analiz.toplamCozulenSoru?.length > 0 && (
                      <ReactRadialChart
                        innerRadius={window.innerWidth > 1200 ? 80 : 60}
                        radius={window.innerWidth > 1200 ? 110 : 90}
                        getAngle={(d) => d.theta}
                        data={this.state.data}
                        onValueMouseOver={(v) => this.setState({ value: v })}
                        onSeriesMouseOut={(v) =>
                          this.setState({ value: false })
                        }
                        width={window.innerWidth > 1200 ? 240 : 210}
                        height={window.innerWidth > 1200 ? 240 : 210}
                        padAngle={0}
                        colorRange={customPalette}
                        className="mt-4 mt-sm-0 mx-auto"
                      >
                        <div
                          className="radial-inner"
                          style={{
                            width: window.innerWidth > 568 ? 72 : 72,
                            height: window.innerWidth > 568 ? 72 : 72,
                          }}
                        >
                          <span>Çözülen</span>
                          <span>
                            {this.props.book.Analiz.toplamCozulenSoru}
                          </span>
                        </div>
                      </ReactRadialChart>
                    )}
                  </>
                )}
                {this.props.book.Analiz && (
                  <div className="book-stats-wrapper">
                    <div className="book-stats-top">
                      <div className="total-true-wrapper">
                        <div />
                        <span>Doğru</span>
                        <span>{this.props.book.Analiz.toplamDogruSoru}</span>
                      </div>
                      <div className="total-wrong-wrapper">
                        <div />
                        <span>Yanlış</span>
                        <span>{this.props.book.Analiz.toplamYanlisSoru}</span>
                      </div>
                      <div className="total-empty-wrapper">
                        <div />
                        <span>Boş</span>
                        <span>{this.props.book.Analiz.toplamBosSoru}</span>
                      </div>
                    </div>
                    <div className="book-stats-bottom">
                      <div>NET: {this.state.userNet}</div>
                      {/*<span>Başarı Yüzdesi: %34</span>*/}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          <div className="col-12 col-lg-9 p-xs-0">
            <h6 className="fs-20 font-weight-bold color-202124">
              Dijital Kitap
            </h6>
            <ul className="list-group card lesson-list-group">
              {this.props.book.Branslar
                ? this.props.book.Branslar.map((b, index) => (
                    <li
                      className="list-group-item bt-dedede bg-transparent"
                      key={b.BransKod}
                    >
                      <ListItemHeader
                        title={b.BransAd}
                        value={
                          b.analiz.toplamCozulenSoru &&
                          b.analiz.aktifYayinToplamSoru
                            ? Math.round(
                                (b.analiz.toplamCozulenSoru /
                                  b.analiz.aktifYayinToplamSoru) *
                                  100,
                              )
                            : 0
                        }
                        iconUrl={b.bransKapak}
                        onClick={() =>
                          this.toggle(`L${b.BransKod + index + 1}`)
                        }
                        active={this.check(`L${b.BransKod + index + 1}`)}
                      />
                      <div
                        className={classNames('collapse', {
                          show: this.check(`L${b.BransKod + index + 1}`),
                        })}
                      >
                        <ul className="list-group list-group-flush">
                          {!settings.showTopicsInBookDetail ||
                          (this.props.book.Book &&
                            this.props.book.Book.isSinav) ? (
                            <ExamView
                              tests={_.uniqBy([...b.testler], 'testID')}
                              key={b.BransKod}
                              isExam={
                                !settings.showTopicsInBookDetail ||
                                (this.props.book.Book &&
                                  this.props.book.Book.isSinav)
                              }
                              id={b.BransKod}
                              onCheck={this.check}
                              onToggle={(name) => this.toggle(name)}
                              showResetQuizModal={this.state.showResetQuizModal}
                              toggleResetQuizModal={(quiz) =>
                                this.toggleResetQuizModal(quiz)
                              }
                              showEmoji={(quiz) => this.showEmoji(quiz)}
                            />
                          ) : (
                            _.chain(b.testler)
                              .groupBy('testUniteAd')
                              .map((tests, unitName) => ({ unitName, tests }))
                              .value()
                              .map((unit, ii) => (
                                <UnitView
                                  unit={unit}
                                  key={ii}
                                  isExam={
                                    !settings.showTopicsInBookDetail ||
                                    (this.props.book.Book &&
                                      this.props.book.Book.isSinav)
                                  }
                                  id={b.BransKod + ii}
                                  onCheck={this.check}
                                  onToggle={(name) => this.toggle(name)}
                                  lessonId={this.id}
                                  showEmoji={(quiz) => this.showEmoji(quiz)}
                                />
                              ))
                          )}
                        </ul>
                      </div>
                    </li>
                  ))
                : this.props.book.Fasikul
                  ? this.props.book.Fasikuller.map((f, i) => {
                      return (
                        <li
                          className="list-group-item border-0 bt-dedede bg-transparent"
                          key={f.FasikulId}
                        >
                          <ListItemHeader
                            title={f.FasikulName}
                            value={0}
                            onClick={() => this.toggle(`F${f.FasikulId}`)}
                            active={this.check(`F${f.FasikulId}`)}
                          />
                          <div
                            className={classNames('collapse', {
                              show: this.check(`F${f.FasikulId}`),
                            })}
                          >
                            <ul className="list-group lesson-list-group">
                              {f.Branslar.map((b) => (
                                <li
                                  className="list-group-item border-0 bt-dedede bg-transparent"
                                  key={b.BransKod}
                                >
                                  <ListItemHeader
                                    title={b.BransAd}
                                    value={
                                      b.analiz.aktifYayinCozulenToplamSoru &&
                                      b.analiz.aktifYayinToplamSoru
                                        ? Math.round(
                                            (b.analiz
                                              .aktifYayinCozulenToplamSoru /
                                              b.analiz.aktifYayinToplamSoru) *
                                              100,
                                          )
                                        : 0
                                    }
                                    onClick={() =>
                                      this.toggle(`L${b.BransKod}`)
                                    }
                                    active={this.check(`L${b.BransKod}`)}
                                  />
                                  <div
                                    className={classNames('collapse', {
                                      show: this.check(`L${b.BransKod}`),
                                    })}
                                  >
                                    <ul className="list-group list-group-flush">
                                      {!settings.showTopicsInBookDetail ||
                                      (this.props.book.Book &&
                                        this.props.book.Book.isSinav) ? (
                                        <ExamView
                                          tests={_.uniqBy(
                                            [...b.testler],
                                            'testID',
                                          )}
                                          key={b.BransKod}
                                          isExam={
                                            !settings.showTopicsInBookDetail ||
                                            (this.props.book.Book &&
                                              this.props.book.Book.isSinav)
                                          }
                                          id={b.BransKod}
                                          onCheck={this.check}
                                          onToggle={(name) => this.toggle(name)}
                                          showEmoji={(quiz) =>
                                            this.showEmoji(quiz)
                                          }
                                          toggleResetQuizModal={(quiz) =>
                                            this.toggleResetQuizModal(quiz)
                                          }
                                        />
                                      ) : (
                                        _.chain(b.testler)
                                          .groupBy('testUniteAd')
                                          .map((tests, unitName) => ({
                                            unitName,
                                            tests,
                                          }))
                                          .value()
                                          .map((unit, ii) => (
                                            <UnitView
                                              unit={unit}
                                              key={ii}
                                              isExam={
                                                !settings.showTopicsInBookDetail ||
                                                (this.props.book.Book &&
                                                  this.props.book.Book.isSinav)
                                              }
                                              id={b.BransKod + ii}
                                              onCheck={this.check}
                                              onToggle={(name) =>
                                                this.toggle(name)
                                              }
                                              lessonId={this.id}
                                              showEmoji={(quiz) =>
                                                this.showEmoji(quiz)
                                              }
                                            />
                                          ))
                                      )}
                                    </ul>
                                  </div>
                                  {b.dijitalTestler
                                    ? b.dijitalTestler.map((dt) => (
                                        <div>
                                          <ListItemHeader
                                            title={dt.baskiTestPaketi.Ad}
                                            value={
                                              b.analiz
                                                .aktifYayinCozulenToplamSoru &&
                                              b.analiz.aktifYayinToplamSoru
                                                ? Math.round(
                                                    (b.analiz
                                                      .aktifYayinCozulenToplamSoru /
                                                      b.analiz
                                                        .aktifYayinToplamSoru) *
                                                      100,
                                                  )
                                                : 0
                                            }
                                            onClick={() =>
                                              this.toggle(
                                                `L${dt.baskiTestPaketi.BaskiID}-${dt.baskiTestPaketi.Ad}`,
                                              )
                                            }
                                            active={this.check(
                                              `L${b.BransKod}`,
                                            )}
                                          />

                                          <div
                                            className={classNames('collapse', {
                                              show: this.check(
                                                `L${dt.baskiTestPaketi.BaskiID}-${dt.baskiTestPaketi.Ad}`,
                                              ),
                                            })}
                                          >
                                            <ul className="list-group list-group-flush">
                                              {!settings.showTopicsInBookDetail ||
                                              (this.props.book.Book &&
                                                this.props.book.Book
                                                  .isSinav) ? (
                                                <ExamView
                                                  tests={_.uniqBy(
                                                    [...dt.testler],
                                                    'testID',
                                                  )}
                                                  key={
                                                    dt.baskiTestPaketi.BaskiID
                                                  }
                                                  isExam={
                                                    !settings.showTopicsInBookDetail ||
                                                    (this.props.book.Book &&
                                                      this.props.book.Book
                                                        .isSinav)
                                                  }
                                                  id={
                                                    dt.baskiTestPaketi.BaskiID
                                                  }
                                                  onCheck={this.check}
                                                  toggleResetQuizModal={(
                                                    quiz,
                                                  ) =>
                                                    this.toggleResetQuizModal(
                                                      quiz,
                                                    )
                                                  }
                                                  onToggle={(name) =>
                                                    this.toggle(name)
                                                  }
                                                />
                                              ) : (
                                                _.chain(dt.testler)
                                                  .groupBy('testUniteAd')
                                                  .map((tests, unitName) => ({
                                                    unitName,
                                                    tests,
                                                  }))
                                                  .value()
                                                  .map((unit, ii) => (
                                                    <UnitView
                                                      unit={unit}
                                                      key={ii}
                                                      isExam={
                                                        !settings.showTopicsInBookDetail ||
                                                        (this.props.book.Book &&
                                                          this.props.book.Book
                                                            .isSinav)
                                                      }
                                                      id={
                                                        dt.baskiTestPaketi.Ad +
                                                        ii
                                                      }
                                                      onCheck={this.check}
                                                      onToggle={(name) =>
                                                        this.toggle(name)
                                                      }
                                                      lessonId={this.id}
                                                      showEmoji={(quiz) =>
                                                        this.showEmoji(quiz)
                                                      }
                                                    />
                                                  ))
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      ))
                                    : null}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </li>
                      );
                    })
                  : null}
            </ul>
          </div>
        </div>
        <ResetQuiz
          show={this.state.showResetQuizModal}
          toggleResetQuizModal={this.toggleResetQuizModal}
          quiz={this.state.selectedQuiz}
        />
      </>
    );
  }
}

Lessons.propTypes = {};

const mapStateToProps = ({ AccountReducer, StudyReducer, QuizReducer }) => ({
  user: AccountReducer.user,
  books: StudyReducer.books,
  book: StudyReducer.book,
  emoji: QuizReducer.emoji,
  docs: StudyReducer.docs,
});

const mapDispatchToProps = {
  getBookDetails: actions.getBookDetails,
  getBookDocs: actions.getBookDocs,
  isTest: actions.isTest,
  loginRequired: accountActions.loginRequired,
  getEmoji: quizActions.getEmoji,
  setInitialBook: actions.setInitialBook,
};

export default connect(mapStateToProps, mapDispatchToProps)(Lessons);
