import svgDin from './din.svg';
import svgFen from './fen.svg';
import svgIngilizce from './ing.svg';
import svgMatematik from './mat.svg';
import svgSosyal from './sosyal.svg';
import svgTurkce from './turkce.svg';

export const imgDin = svgDin;
export const imgFen = svgFen;
export const imgInglizce = svgIngilizce;
export const imgMatematik = svgMatematik;
export const imgSosyal = svgSosyal;
export const imgTurkce = svgTurkce;

let icons = {
  d: svgDin,
  f: svgFen,
  i: svgIngilizce,
  m: svgMatematik,
  s: svgSosyal,
  t: svgTurkce,
  getIcon: (branchName) => {
    return (branchName && icons[branchName.charAt(0).toLowerCase()]) || icons.t;
  },
};

export default icons;
