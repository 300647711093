import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import classNames from 'classnames';

class Link extends PureComponent {
  handleOnClick = (external) => {
    const { history, to, target } = this.props;
    if (external) {
      if (target && target !== '_blank') {
        window.location.href = to;
      } else {
        window.open(to);
      }
    } else {
      history.push(to);
      window.scrollTo(0, 0);
    }
  };

  render() {
    return (
      <button
        type="button"
        title={this.props.title}
        className={classNames('btn', this.props.className)}
        onClick={() => this.handleOnClick(this.props.external)}
      >
        {this.props.children}
      </button>
    );
  }
}

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Link);
