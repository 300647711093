import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { iconClose } from '../../assets/images/icons';

class Toogle extends Component {
  render() {
    return (
      <>
        {this.props.show ? (
          <div className="w-100">
            <div className="d-flex flex-row justify-content-between">
              <h5 className="toggle-title">{this.props.title}</h5>
              <div className="flex-fill">
                <button
                  type="button"
                  className="close float-right"
                  onClick={this.props.onToggle}
                >
                  <img width="18" height="18" src={iconClose} alt="close" />
                </button>
              </div>
            </div>
            {this.props.children}
          </div>
        ) : (
          <button
            type="button"
            className={classNames('btn', this.props.className)}
            onClick={this.props.onToggle}
          >
            {this.props.label}
          </button>
        )}
      </>
    );
  }
}

Toogle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default Toogle;
