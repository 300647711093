import React from 'react';
import { connect } from 'react-redux';
import { handleShowQrScanner } from '../../routes/actions/Account';

const ScannerButton = (props) => {
  const handleClick = () => {
    props.handleShowQrScanner(!props.showQrModal);
  };

  return (
    <img
      style={{ cursor: 'pointer' }}
      src={require('../../assets/images/icons/qr-icon.png')}
      alt=""
      width="50px"
      onClick={handleClick}
    />
  );
};

const mapStateToProps = ({ AccountReducer }) => ({
  showQrModal: AccountReducer.showQrModal,
});

const mapDispatchToProps = {
  handleShowQrScanner,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScannerButton);
