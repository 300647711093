import React, { Component } from 'react';
import { connect } from 'react-redux';
import Item from './Item';
//import PropTypes from "prop-types";
import * as actions from '../../routes/actions/Account';
import IconButton from '../Button/Icon';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

class Showcase extends Component {
  componentDidMount() {
    const authenticated = this.props.user.id ? true : false;
    if (!this.props.showCloseButton && authenticated && !this.props.favorites) {
      this.props.getFavorites();
    }
  }

  isFavorite = (id) => {
    const { favorites } = this.props;
    const products = favorites && favorites.products ? favorites.products : [];
    return products.some((p) => p.id === id);
  };

  findAdvertorial = (index, lineItemCount) => {
    const { advertorials, mode } = this.props;
    const AdvertorialView = this.props.component;
    const item = advertorials.find((p) => parseInt(p.position) === index);
    const itemIndex = item ? advertorials.indexOf(item) : -1;
    return (
      <div
        className="col-12  p-0 advertorialView"
        key={`adv${lineItemCount}x_${index}_${index}`}
      >
        {item ? (
          <AdvertorialView
            item={item}
            menuItems={this.props.menuItems}
            mode={mode}
            onItemClick={this.props.onItemClick}
            index={itemIndex}
          />
        ) : (
          mode === 'draft' && (
            <div className="card mb-3 ">
              <div className="card-body">
                <IconButton
                  className="btn-primary mx-2 text-capitalize"
                  icon={faPlusCircle}
                  onClick={() =>
                    this.props.onItemClick(
                      { name: 'add' },
                      { position: index.toString() },
                      itemIndex,
                    )
                  }
                >
                  Reklam Ekle
                </IconButton>
              </div>
            </div>
          )
        )}
      </div>
    );
  };

  render() {
    const { items, favorites, showCloseButton, ...rest } = this.props;
    const smallDevices = window.matchMedia('(max-width: 1199.98px)');
    const lineItemCount = smallDevices.matches ? 2 : 3;
    let group = items.reduce((r, a, index) => {
      const key = Math.floor(index / lineItemCount);
      r[key] = [...(r[key] || []), a];
      return r;
    }, []);

    return (
      <>
        <div className="brandsProductList product-group">
          {group.map((line, i) => (
            <>
              {this.findAdvertorial(i, lineItemCount)}

              {line.map((product, ii) => {
                return (
                  <Item
                    key={product.id}
                    product={product}
                    favorite={showCloseButton || this.isFavorite(product.id)}
                    showCloseButton={showCloseButton}
                    handleRemove={this.props.handleRemove}
                    showInShowcase={true}
                    {...rest}
                  />
                );
              })}
            </>
          ))}
          {this.findAdvertorial(
            items.length + (lineItemCount - (items.length % lineItemCount)),
            lineItemCount,
          )}
        </div>
      </>
    );
  }
}

Showcase.propTypes = {};
const mapStateToProps = ({ AccountReducer }) => ({
  favorites: AccountReducer.favorites,
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  getFavorites: actions.getFavorites,
};

export default connect(mapStateToProps, mapDispatchToProps)(Showcase);
