import React, { Component } from 'react';
import classNames from 'classnames';
import {
  imgPuan,
  imgJeton,
  imgRozet,
  imgProductPoint,
  imgProductJeton,
} from '../../assets/images';
//import PropTypes from "prop-types";

const icons = {
  point: imgPuan,
  coin: imgJeton,
  badge: imgRozet,
  productpoint: imgProductPoint,
  productcoin: imgProductJeton,
};

class Score extends Component {
  render() {
    const {
      className,
      title,
      icon,
      branch,
      value,
      removePrefix,
      style,
      productDetailBadge,
    } = this.props;

    return (
      <span
        className={classNames(
          `badge badge-pill p-0 ${productDetailBadge ? 'product-detail-badge' : ''}`,
          className,
        )}
        style={style}
      >
        <img src={icons[productDetailBadge ? `product${icon}` : icon]} alt="" />
        <div className="d-flex flex-column text-left color-f28b00 fs-14 font-weight-bold">
          {branch && (
            <p className="branch-title text-dark text-truncate">{branch}</p>
          )}
          <p className={branch ? 'badge-sub-title' : 'badge-title'}>
            {title || `${removePrefix ? '' : '+'}${value} Puan`}
          </p>
        </div>
      </span>
    );
  }
}

Score.propTypes = {};

export default Score;
