import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';

function RedirectToProductShow(props) {
  const { productId } = useParams();

  useEffect(() => {
    props.history.push(`/urunler/detay/${productId}`);
  }, [props.history]);

  return null;
}

export default withRouter(RedirectToProductShow);
