import React, { Component } from 'react';
//import PropTypes from 'prop-types';

class Compliance extends Component {
  render() {
    return (
      <>
        <h3 className="mt-2">Aydınlatma ve Rıza Metni</h3>
        <div className="card">
          <div className="card-header">
            {/* <h5>
              Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Ve Rıza Metni
            </h5> */}
          </div>
          <div className="card-body pt-0 fs-14">
            <p>
              1. Aydınlatma Metni’nin Amacı ve Şirketimizin Veri Sorumlusu
              Konumu: Şirketimiz Yüksek Başarı Yayın Dağıtım ve Paz. A.Ş.
              (“Doğru Kaynak ” ve/veya “Şirket”), müşterilere ilişkin kişisel
              veriler bakımından 6698 sayılı Kişisel Verilerin Korunması Kanunu
              (“Kanun”) kapsamında “veri sorumlusu” sıfatına sahip olup işbu
              Aydınlatma ve Rıza Metni ile söz konusu Kanun uyarınca
              müşterilerin Doğru Kaynak tarafından gerçekleştirilen kişisel veri
              işleme faaliyetleri hakkında aydınlatılması ve aşağıda 3. maddede
              belirtilen durumlar için açık rızalarının temini hedeflenmektedir.
            </p>
            <p>
              2. Müşterilere Ait Kişisel Verilerin İşlenme Amacı: Müşterilere
              ait kişisel veriler, Doğru Kaynak tarafından sunulan ürün ve
              hizmetlerden ilgili kişileri faydalandırmak için gerekli
              çalışmaların iş birimleri tarafından yapılması ve ilgili iş
              süreçlerinin yürütülmesi, Doğru Kaynak tarafından yürütülen ticari
              faaliyetlerin gerçekleştirilmesi için ilgili iş birimleri
              tarafından gerekli çalışmaların yapılması ve buna bağlı iş
              süreçlerinin yürütülmesi, Doğru Kaynak ’nın ticari ve/veya iş
              stratejilerinin planlanması ve icrası, Doğru Kaynak ’nın ve Doğru
              Kaynak ile iş ilişkisi içerisinde olan ilgili kişilerin hukuki,
              teknik ve ticari-iş güvenliğinin temini ile Doğru Kaynak ’nın
              sunduğu ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım
              alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek ilgili
              kişilere önerilmesi ve tanıtılması için gerekli olan aktivitelerin
              planlanması ve icrası da dahil olmak üzere Kanun’un 5. ve 6.
              maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
              çerçevesinde işlenmektedir. Doğru Kaynak tarafından kişisel
              verilerin işlenmesine ilişkin detaylı bilgilere
              https://www.dogrukaynak.com/ adresinde yer alan Doğru Kaynak
              Tarafından 6698 sayılı Kanun Kapsamında Kişisel Verilerin
              İşlenmesi ve Korunmasına ilişkin Politika’dan ulaşılabilecektir.
            </p>
            <p>
              3. Müşterilerin Açık Rızası Doğrultusunda İşlenecek Kişisel
              Veriler ve İşleme Amaçları: Kanun’un 5/2 ile 6/3 maddesinde yer
              alan kişisel veri işleme şartlarının karşılanamadığı aşağıdaki
              durumlar için Doğru Kaynak tarafından kişisel verilerin
              işlenebilmesi için müşterilerin açık rızasının alınması
              gerekmektedir. Bu kapsamda müşterilerin kişisel verileri;
              müşterilere yönelik kampanyaların oluşturulması, çapraz satış
              yapılması, hedef kitle belirlenmesi, Müşteri hareketlerinin takip
              edilerek kullanıcı deneyimini arttırıcı faaliyetlerin yürütülmesi
              ve Doğru Kaynak ’ya ait internet sitesi ile mobil uygulamanın
              işleyişinin geliştirilmesi ve Müşteri ihtiyaçlarına göre
              kişiselleştirilmesi, doğrudan ve doğrudan olmayan pazarlama,
              kişiye özel pazarlama ve yeniden pazarlama faaliyetlerinin
              yürütülmesi, kişiye özel segmentasyon, hedefleme, analiz ve şirket
              içi raporlama faaliyetlerinin yürütülmesi, pazar araştırmaları,
              müşteri memnuniyeti aktivitelerinin planlanması ve icrası ile
              müşteri ilişkileri yönetimi süreçlerinin planlanması ve icrası
              amaçlarıyla dahil olmak üzere dahil olmak üzere Doğru Kaynak ’nın
              ürün ve/veya hizmetlerinin satış ve pazarlama süreçlerinin
              planlanması ve icrası, Doğru Kaynak ’nın sunduğu ürün ve/veya
              hizmetlere bağlılık oluşturulması ve/veya arttırılması
              süreçlerinin planlanması ve icrası kapsamında Müşteri’nin vereceği
              onayı doğrultusunda işlenebilecek ve işbu Aydınlatma ve Rıza
              Metni’nde belirtilen taraflarla paylaşılabilecektir.
            </p>
            <p>
              4. Müşterilere Ait Kişisel Verilerin Aktarımı: Müşterilere ait
              kişisel veriler, Doğru Kaynak tarafından sunulan ürün ve
              hizmetlerden ilgili kişileri faydalandırmak için gerekli
              çalışmaların iş birimleri tarafından yapılması ve ilgili iş
              süreçlerinin yürütülmesi, Doğru Kaynak tarafından yürütülen ticari
              faaliyetlerin gerçekleştirilmesi için ilgili iş birimleri
              tarafından gerekli çalışmaların yapılması ve buna bağlı iş
              süreçlerinin yürütülmesi, Doğru Kaynak ’nın ticari ve/veya iş
              stratejilerinin planlanması ve icrası, Doğru Kaynak ’nın ve Doğru
              Kaynak ile iş ilişkisi içerisinde olan ilgili kişilerin hukuki,
              teknik ve ticari-iş güvenliğinin temini ile Doğru Kaynak ’nın
              sunduğu ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım
              alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek ilgili
              kişilere önerilmesi ve tanıtılması için gerekli olan aktivitelerin
              planlanması ve icrası da dahil olmak üzere Kanun’un 8. ve 9.
              maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
              çerçevesinde Yüksek Başarı Yayın Dağıtım ve Paz. A.Ş., Şirket
              yetkilileri, iştiraklerimiz, iş ortaklarımız, tedarikçilerimiz,
              hissedarlarımız, kanunen yetkili kamu kurum ve kuruluşları ile
              özel kurumlar ile paylaşılabilecektir.
            </p>
            <p>
              5. Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi: Kişisel
              veriler, müşterilerden elektronik ortamda toplanmaktadır. Yukarıda
              belirtilen hukuki sebeplerle toplanan kişisel veriler Kanun’un 5.
              ve 6. maddelerinde ve bu Aydınlatma ve Rıza Metni’nde belirtilen
              amaçlarla işlenebilmekte ve aktarılabilmektedir.
            </p>
            <p>
              6. Kişisel Veri Sahibi Olarak Müşterilerin Hakları: Kanun’un 11.
              maddesi uyarınca veri sahipleri; (i) kendileri ile ilgili kişisel
              veri işlenip işlenmediğini öğrenme, (ii) kişisel verileri
              işlenmişse buna ilişkin bilgi talep etme, (iii) kişisel verilerin
              işlenme amacını ve bunların amacına uygun kullanılıp
              kullanılmadığını öğrenme, (iv) yurt içinde veya yurt dışında
              kişisel verilerin aktarıldığı üçüncü kişileri bilme, (v) kişisel
              verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
              düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel
              verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, (vi)
              Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
              olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
              kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini
              isteme ve bu kapsamda yapılan işlemin kişisel verilerin
              aktarıldığı üçüncü kişilere bildirilmesini isteme, (vii) işlenen
              verilerin münhasıran otomatik sistemler vasıtasıyla analiz
              edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
              çıkmasına itiraz etme ve (viii) kişisel verilerin kanuna aykırı
              olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın
              giderilmesini talep etme haklarına sahiptir. Söz konusu hakların
              kullanımına ilişkin talepler, kişisel veri sahipleri tarafından
              https://www.dogrukaynak.com/ adresinde yer alan Doğru Kaynak
              Tarafından 6698 sayılı Kanun Kapsamında Kişisel Verilerin
              İşlenmesi ve Korunmasına ilişkin Politika’da belirtilen
              yöntemlerle iletilebilecektir. Doğru Kaynak , söz konusu talepleri
              değerlendirerek 30 gün içerisinde sonuçlandıracaktır. Doğru Kaynak
              ’nın taleplere ilişkin olarak Kişisel Verileri Koruma Kurulu
              tarafından belirlenen (varsa) ücret tarifesi üzerinden ücret talep
              etme hakkı saklıdır.
            </p>

            <p>
              <a
                href="https://cdn.tongucmagaza.com/StorexTemp/kvk_politika.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                Kişisel Verilerin Korunması ve İşlenmesi Politikası
              </a>
              <br />
              <a
                href="https://cdn.tongucmagaza.com/StorexTemp/kvk_basvuru_formu.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                Kişisel Verilerin Korunması Başvuru Formu
              </a>
            </p>
          </div>
        </div>
      </>
    );
  }
}

Compliance.propTypes = {};

export default Compliance;
