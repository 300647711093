import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

class Result extends Component {
  sendMessageToParent = (succees, message) => {
    let dataToSend = {
      success: succees,
      message: message,
    };
    window.mobile && window.mobile.postMessage(JSON.stringify(dataToSend));
    console.table({
      success: succees,
      message: message,
    });
  };

  componentDidMount() {
    const { location } = this.props;
    var parsedQueryString = queryString.parse(location.search);
    window.parent.postMessage(
      {
        source: 'payment-frame-result',
        payload: parsedQueryString,
      },
      '*',
    );

    //#region mobil uygulama
    let success = parsedQueryString.status.toLowerCase() === 'true';
    this.sendMessageToParent(success, parsedQueryString.message);
    //#endregion
  }

  render() {
    return <div>Lütfen bekleyin...</div>;
  }
}

Result.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Result;
