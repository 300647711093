import React from 'react';

const OrderList = (props) => {
  return (
    <ul className="orderlist">
      {props.options.map((item, index) => (
        <li
          key={index}
          className={`orderlist-item btn ${
            props.value.label === item.label ? 'active' : ''
          }`}
          onClick={() => props.selectSort(item)}
        >
          {item.label}
        </li>
      ))}
    </ul>
  );
};

export default OrderList;
