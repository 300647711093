import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import CloseButton from '../components/Button/Close';
import { connect } from 'react-redux';
import { slugify } from '../helpers';
import { signContract, getUnsignedContracts } from '../routes/actions/Account';

const Contracts = ({ contracts }) => {
  return (
    <>
      {contracts.map((contract, i) => (
        <React.Fragment key={i}>
          <Link
            to={`/sozlesmeler/${slugify(contract.contractName + ' ' + contract.contractTypeId)}`}
            target="_blank"
            className="text-primary"
          >
            {contract.contractName}
          </Link>
          {i !== contracts.length - 1 ? `, ` : ``}
        </React.Fragment>
      ))}
    </>
  );
};

class CookieContracts extends PureComponent {
  acceptContracts(contracts) {
    contracts.forEach(async (contract, i) => {
      await this.props.signContract(contract);
      // if (i === contracts.length - 1) {
      //     await this.props.getUnsignedContracts();
      // }
    });
  }

  render() {
    var contracts = this.props.unsignedContracts.filter(
      (c) => c.isMandatory === 0,
    );
    return (
      contracts &&
      contracts.length > 0 && (
        <div className="cookie-policy" style={{ bottom: this.props.bottom }}>
          <div className="container">
            <div>
              Sitemizde yer alan <Contracts contracts={contracts} />{' '}
              değişmiştir. İncelemek için sözleşmelere tıklayabilirisiniz.
            </div>
            <CloseButton
              className="modal-mobile-white-btn"
              onClick={() => this.acceptContracts(contracts)}
            />
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = ({ AccountReducer }) => ({
  user: AccountReducer.user,
  order: AccountReducer.order,
  unsignedContracts: AccountReducer.unsignedContracts,
});

const mapDispatchToProps = {
  signContract,
  getUnsignedContracts,
};

export default connect(mapStateToProps, mapDispatchToProps)(CookieContracts);
