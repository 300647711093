import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from '../views';
import Navbar from './Navbar';
import Footer from './Footer';
import TopMenu from './TopMenu';
import HeadBanner from './HeadBanner';
import TopNavbar from './TopNavbar';
import { routes } from '../routes';
import LoadingBar from 'react-redux-loading-bar';
import CookiePolicy from './CookiePolicy';
import CookieContracts from './CookieContracts';
import storage from '../services/storage';
import { keys } from '../services/constants';
import CloseButton from '../components/Button/Close';
import { Modal } from '../components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../routes/actions/Desk/Scheduler';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import EditableBanner from '../components/Modal/EditableBanner';
import BasketNav from './BasketNav';

const SurrenderModal = (props) => {
  return (
    <Modal show={props.show} className="modal-container non-modal-height">
      <CloseButton onClick={props.onCancelClick} className="z-index-4" />
      <div className="modal-body text-center p-5">
        <img
          alt=""
          src={require('../assets/images/tree/tree-step_soil.svg')}
          style={{ width: 170, height: 170 }}
        />

        <p className="text-center color-484848 fs-14 my-3 font-weight-500">
          Hay aksi, programı tamamlasaydın <br />
          puan kazanacaktın
        </p>

        <button
          className="btn btn-primary border-radius-4 text-white text-capitalize mt-3 px-5 fs-14"
          onClick={props.onCloseClick}
        >
          Tamam
        </button>
      </div>
    </Modal>
  );
};

class StoreLayout extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showCookiePolicy: storage.getItem(keys.CookiePolicy) ? false : true,
      showSurrenderModal: this.props.pomodoro?.failed,
      showEditableBanner: false,
      editableBannerName: '',
      showBasketNav: true,
    };
  }

  anasayfaBanner2 = React.createRef();

  editableBannerPublish = (changedData) => {
    if (this[this.state.editableBannerName]?.current) {
      this[this.state.editableBannerName].current.newDataFromParent(
        changedData,
      );
    }
  };
  showEditableBanner = (bannerName) => {
    this.setState({
      showEditableBanner: true,
      editableBannerName: bannerName,
    });
  };

  hideCookiePolicy = () => {
    storage.setItem(keys.CookiePolicy, true);
    this.setState({
      showCookiePolicy: false,
    });
  };

  render() {
    const qs = queryString.parse(this.props.location.search);
    const isNoIndexN = qs.i === 'n';
    const profileRoute = routes[0].routes.find((p) => p.path === '/hesabim');
    const isPaymentPage = this.props.location.pathname.includes('odeme/');
    return (
      <>
        {isNoIndexN && (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}
        <LoadingBar
          style={{ position: 'fixed', zIndex: 99999, width: '100%' }}
        />
        <TopNavbar test={'prop-test'} />
        <HeadBanner
          showModal={this.showEditableBanner}
          ref={this.anasayfaBanner2}
          bannerName={'anasayfaBanner2'}
          mode={this.props.mode}
        />
        <Navbar
          light
          routes={profileRoute.routes}
          isPaymentPage={isPaymentPage}
        />
        <TopMenu isPaymentPage={isPaymentPage} />

        <main role="main">
          <Switch>
            {routes[0].routes.map((item, i) =>
              item.routes ? (
                <Route
                  path={item.path}
                  render={(props) => (
                    <item.component
                      routes={item.routes}
                      {...props}
                      isPaymentPage={isPaymentPage}
                    />
                  )}
                  key={i}
                />
              ) : (
                <Route
                  path={item.path}
                  exact={item.routes ? false : true}
                  component={item.component}
                  key={i}
                />
              ),
            )}
            <Route component={NotFound} />
          </Switch>
          <BasketNav />
        </main>
        <Footer isPaymentPage={isPaymentPage} light />
        <EditableBanner
          show={this.state.showEditableBanner}
          editableBannerName={this.state.editableBannerName}
          onClose={() => this.setState({ showEditableBanner: false })}
          onPublish={this.editableBannerPublish}
          bannerType={'headBanner'}
        />
        {this.state.showCookiePolicy ? (
          <CookiePolicy hide={this.hideCookiePolicy} />
        ) : null}
        {
          <CookieContracts
            bottom={this.state.showCookiePolicy ? '75px' : '0'}
          />
        }

        <SurrenderModal
          show={this.props.pomodoroWarnModal}
          onCloseClick={() => {
            this.props.updatePomodoroWarnModal(false);
          }}
          onCancelClick={() => {
            this.props.updatePomodoroWarnModal(false);
          }}
        />
        {/* <SwitchButton /> */}
      </>
    );
  }
}

StoreLayout.propTypes = {};

const mapStateToProps = ({ SchedulerReducer, ModeReducer }) => ({
  pomodoroWarnModal: SchedulerReducer.pomodoroWarnModal,
  mode: ModeReducer.mode,
});

const mapDispatchToProps = {
  updatePomodoroWarnModal: actions.updatePomodoroWarnModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(StoreLayout);
