import React from 'react';
import { LinkButton } from '..';
import CoinShow from '../../views/Product/CoinShow';
//import PropTypes from 'prop-types';

const Coin = ({ coin, showBuy }) => {
  return coin ? (
    <div className="card card-coin border-radius-16 border-e5e5e5 box-shadow-none w-10">
      <a href={`/jeton/detay/${coin.id}`}>
        <div className="card-img-top text-center">
          <img
            src={require('../../assets/images/payment/jetonlaode.png')}
            className="img-fluid mx-auto"
            alt={coin.name}
          />
        </div>
      </a>
      <div className="card-body text-center d-flex align-items-center justify-content-between flex-column">
        <a href={`/jeton/detay/${coin.id}`}>
          <h6 className="card-title fs-14 color-f28b00 text-lowercase font-weight-600">
            {`${coin.quantity + coin.bonus} Jeton`}
          </h6>
          <h5 className="color-484848 font-weight-600 my-2 d-flex align-items-center justify-content-center flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column">
            {coin.discount > 0 ? (
              <span className="badge badge-pink text-lowercase border-radius-16">
                {`%${coin.discount} İndirim`}
              </span>
            ) : (
              <span style={{ height: 32 }}> </span>
            )}
            <span className="text-price">{`${coin.price}₺`}</span>
          </h5>
        </a>
        {showBuy === true && (
          <div className="px-0 mt-3 w-100">
            <LinkButton
              className="btn-primary w-100 px-3 fs-14 text-capitalize font-weight-600 border-radius-8"
              to={`/odeme/jeton/${coin.id}`}
            >
              Satın Al
            </LinkButton>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

Coin.propTypes = {};

export default Coin;
