import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseForm from './BaseForm';

class Form extends Component {
  render() {
    const { title, description, showHeader, accountSettings } =
      this.props.schema;
    let show = title ? showHeader && true : false;
    return (
      <div
        className={`card ${
          this.props.accountSettings ? 'account-settings-container' : ''
        }`}
      >
        {show && (
          <div className="card-header">
            <h5 className="card-title">{title}</h5>
            {description && (
              <h6 className="card-subtitle mb-2 text-muted">{description}</h6>
            )}
          </div>
        )}

        <div className="card-body">
          <div className="container-fluid p-xs-0">
            <BaseForm {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  formData: PropTypes.object,
  schema: PropTypes.object,
  uiSchema: PropTypes.object,
};

export default Form;
