import actionTypes from './types';
import apiCall from '../../../services/api';
import { HTTP } from '../../../services/constants';

export const getPages = () => async (dispatch) => {
  const { data } = await apiCall(`/contents`);

  dispatch({
    type: actionTypes.GET_PAGES,
    payload: data,
  });
};

export const getMyPages = (page, isTest, filter) => async (dispatch) => {
  const { data, headers } = await apiCall(
    `/contents/mycontent?PageNumber=${page}&isTest=${isTest}`,
    filter,
    null,
    HTTP.POST,
    null,
    true,
  );
  dispatch({
    type: actionTypes.GET_MYPAGES,
    payload: data,
    headers: headers,
  });
};

export const getMainPage = (name, published) => async (dispatch) => {
  const { data } = await apiCall(
    `/contents/content/${name}/${name === 'anasayfa' ? published : published}`,
  );
  dispatch({
    type: actionTypes.GET_MAIN_PAGE,
    payload: data,
  });
};

export const getByName = (name, published) => async (dispatch) => {
  try {
    const { data } = await apiCall(`/contents/content/${name}/${published}`);
    dispatch({
      type: actionTypes.GET_BY_NAME,
      payload: Object.assign({}, data, { name }),
    });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const getHistoryByName = (name) => async (dispatch) => {
  try {
    const { data } = await apiCall(
      `/contents/content/history/${name}`,
      null,
      null,
      HTTP.GET,
      null,
      true,
    );

    dispatch({
      type: actionTypes.GET_HISTORY_BY_NAME,
      payload: data,
    });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const updateByName = (name, page) => async (dispatch) => {
  try {
    await apiCall(
      `/contents/content/${name}`,
      {
        content: page,
      },
      null,
      HTTP.PUT,
      null,
      true,
    );

    dispatch({
      type: actionTypes.UPDATE_BY_NAME,
      payload: Object.assign({ content: page }, { name }),
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const addCommentToWall = (name, comment) => async (dispatch) => {
  try {
    const { data } = await apiCall(
      `/contents/content/${name}`,
      {
        content: comment,
      },
      null,
      HTTP.POST,
      null,
      true,
    );

    dispatch({
      type: actionTypes.ADD_COMMENT_TO_WALL,
      payload: Object.assign(data, { name }),
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const createPage = (page) => async (dispatch) => {
  try {
    const { data } = await apiCall(
      '/contents',
      page,
      null,
      HTTP.POST,
      null,
      true,
    );

    dispatch({
      type: actionTypes.CREATE_PAGE,
      payload: data,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const savePage = (id, data) => async (dispatch) => {
  try {
    await apiCall(`/contents/${id}`, data, null, HTTP.PUT, null, true);
    dispatch({
      type: actionTypes.SAVE_PAGE,
      payload: data,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const deletePage = (page) => async (dispatch) => {
  try {
    await apiCall(`/contents/${page.id}`, null, null, HTTP.DELETE, null, true);

    dispatch({
      type: actionTypes.DELETE_PAGE,
      payload: page,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const changeMainPage = (page) => async (dispatch) => {
  //await apiCall("/contents/content/anasayfa", { content: page }, null, HTTP.POST, null, true);

  await apiCall(
    `/contents/content/anasayfa`,
    {
      content: page.content,
    },
    null,
    HTTP.PUT,
    null,
    true,
  );

  dispatch({
    type: actionTypes.CHANGE_MAIN_PAGE,
    payload: page,
  });
};

export const changePage = (page) => async (dispatch) => {
  dispatch({
    type: actionTypes.CHANGE_PAGE,
    payload: page,
  });
};

export const uploadFile = (name, folder, file) => async (dispatch) => {
  try {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const {
        data: { url },
      } = await apiCall(
        `/file/upload/${folder}`,
        formData,
        null,
        HTTP.POST,
        { 'Content-Type': 'multipart/form-data' },
        true,
      );
      dispatch({
        type: actionTypes.FILE_UPLOAD,
        payload: { name, url },
      });
    }

    return { success: true };
  } catch (error) {
    return { success: false, error: error.response.data };
  }
};

export const publishPage = (id) => async (dispatch) => {
  try {
    await apiCall(`/contents/publish/${id}`, {}, null, HTTP.POST, null, true);

    const { data } = await apiCall(
      `/contents/${id}`,
      null,
      null,
      HTTP.GET,
      null,
      true,
    );
    dispatch({
      type: actionTypes.PUBLISH_PAGE,
      payload: data,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const sendForApproval = (id) => async (dispatch) => {
  try {
    await apiCall(
      `/contents/sendForApproval/${id}`,
      {},
      null,
      HTTP.POST,
      null,
      true,
    );

    const { data } = await apiCall(
      `/contents/${id}`,
      null,
      null,
      HTTP.GET,
      null,
      true,
    );
    dispatch({
      type: actionTypes.SEND_FOR_APPROVAL_PAGE,
      payload: data,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const confirmPage = (id) => async (dispatch) => {
  try {
    await apiCall(`/contents/confirm/${id}`, {}, null, HTTP.POST, null, true);

    const { data } = await apiCall(
      `/contents/${id}`,
      null,
      null,
      HTTP.GET,
      null,
      true,
    );
    dispatch({
      type: actionTypes.CONFIRM_PAGE,
      payload: data,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const returnPage = (id, cause) => async (dispatch) => {
  try {
    await apiCall(
      `/contents/return/${id}`,
      { cause },
      null,
      HTTP.POST,
      null,
      true,
    );

    const { data } = await apiCall(
      `/contents/${id}`,
      null,
      null,
      HTTP.GET,
      null,
      true,
    );
    dispatch({
      type: actionTypes.RETURN_PAGE,
      payload: data,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};
