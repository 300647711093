import React from 'react';
import Badge from './Badge';

const List = ({ list }) => {
  let lists = [];
  for (var i = 0; i < list.length; i++) {
    lists.push(
      <div className="col-6 col-sm-4 col-md-3 mt-3">
        <Badge title={`${list[i].Badge.Name}`} src={list[i].Image} />
      </div>,
    );
  }
  return lists;
};

const BadgeList = (props) => {
  return <List list={props.list} />;
};

export default BadgeList;
