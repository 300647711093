import React, { PureComponent } from 'react';
import Modal from '../../components/Modal/Index';
import { BaseForm } from '../../components';
import FormSchemas from '../../variables/FormSchemas';
import CloseButton from '../../components/Button/Close';

class EmailActivation extends PureComponent {
  state = { formData: {} };
  constructor(props) {
    super(props);
  }

  activateEmail = (data) => {
    this.props.activateEmail(this.props.email, data.code);
  };

  sendActivationMail = (data) => {
    this.props.sendActivationMail({ email: this.props.email });
  };

  render() {
    return (
      <Modal
        className="forgotPasswordModal modal-container non-modal-height"
        show={this.props.show}
      >
        <div className="modal-header bg-0 mx-3">
          <h5 className="modal-title font-weight-600 color-484848">
            E-Posta hesabını doğrula
          </h5>
          <CloseButton onClick={this.props.onCloseClick} />
        </div>
        <div className="modal-body">
          <p>E-Posta doğrulamak için e-posta adresine bir kod gönderdik.</p>
          {this.props.show && (
            <BaseForm
              schema={FormSchemas.emailVerification.schema}
              uiSchema={FormSchemas.emailVerification.uiSchema}
              formData={this.state.formData}
              onSubmit={this.activateEmail}
              // onClick={this.props.onClick}
            >
              <div className="d-flex ">
                <button
                  type="submit"
                  className="btn btn-primary btn-block py-2 fs-13 border-radius-8 flex-fill"
                >
                  Gönder
                </button>
              </div>
            </BaseForm>
          )}

          <div className="modal-footer justify-content-center flex-column flex-sm-row">
            <br />
            <div className="" style={{ fontSize: 13 }}>
              <p>Not: Doğrulama e-postanızı almadıysanız:</p>
              <p>
                E-Posta adresinizi doğru şekilde yazdığınızdan emin olun,
                <br />
                Spam veya junk(istenmeyen) e-posta klasörünü kontrol edin.
              </p>
            </div>

            <button
              onClick={this.sendActivationMail}
              name="confirm"
              className="btn btn-link text-primary cursor-pointer fs-15 btn-block font-weight-500 text-center mt-2 text-transform-none"
              type="button"
            >
              Kodu Tekrar Gönder
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
export default EmailActivation;
