const GET_RECOMMENDATION_PRODUCTS = 'GET_RECOMMENDATION_PRODUCTS';
const GET_RECOMMENDATION_PRODUCTS_SUCCESS =
  'GET_RECOMMENDATION_PRODUCTS_SUCCESS';
const GET_RECOMMENDATION_GATEGORİES = '.GET_RECOMMENDATION_GATEGORİES';

export default {
  GET_RECOMMENDATION_PRODUCTS,
  GET_RECOMMENDATION_PRODUCTS_SUCCESS,
  GET_RECOMMENDATION_GATEGORİES,
};
