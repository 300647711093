/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';

import { initialState as AccountReducerState } from './routes/reducers/Account';
import { initialState as ContentReducerState } from './routes/reducers/Content';
import { initialState as ProductReducerState } from './routes/reducers/Product';
import { initialState as StudyReducerState } from './routes/reducers/Study';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@5
 *
 */

/**
 * Creates the main reducer with the dynamically injected ones
 */

export default (history, injectedReducers) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    loadingBar: loadingBarReducer,
    ...injectedReducers,
  });

  return (state, action) => {
    if (action.type === 'ACCOUNT/LOGOUT') {
      const _state = {
        AccountReducer: {
          ...AccountReducerState,
          contracts:
            state && state.AccountReducer ? state.AccountReducer.contracts : [],
          contractsNoContent:
            state && state.AccountReducer
              ? state.AccountReducer.contractsNoContent
              : [],
        },
        ContentReducer: {
          ...ContentReducerState,
          mainPage:
            state && state.ContentReducer ? state.ContentReducer.mainPage : {},
        },
        ProductReducer: {
          ...ProductReducerState,
          taxonomies:
            state && state.ProductReducer
              ? state.ProductReducer.taxonomies
              : [],
          basket:
            state && state.ProductReducer ? state.ProductReducer.basket : [],
          basketCount:
            state && state.ProductReducer
              ? state.ProductReducer.basketCount
              : 0,
          basketKey:
            state && state.ProductReducer ? state.ProductReducer.basketKey : [],
        },
      };

      return appReducer(_state, action);
    } else if (action.type === 'ACCOUNT/UPDATE_USER_PROFILE') {
      //Reset and Refetch scores, branches and badges when user change the class information from the account page
      const _state = {
        ...state,
        StudyReducer: {
          ...StudyReducerState,
          avatars: state.StudyReducer.avatars,
        },
      };

      return appReducer(_state, action);
    } else if (
      action.type === '@@router/LOCATION_CHANGE' &&
      action.payload.location &&
      action.payload.location.pathname === '/'
    ) {
      const _state = {
        ...state,
        StudyReducer: {
          ...StudyReducerState,
          searchResult: [],
        },
        ProductReducer: {
          ...ProductReducerState,
          taxonomies:
            state && state.ProductReducer
              ? state.ProductReducer.taxonomies
              : [],
          taxonomiesFooter:
            state && state.ProductReducer
              ? state.ProductReducer.taxonomiesFooter
              : [],
          brands:
            state && state.ProductReducer ? state.ProductReducer.brands : [],
          keyword: '',
          searchResult: [],
          basket:
            state && state.ProductReducer ? state.ProductReducer.basket : [],
          basketCount:
            state && state.ProductReducer
              ? state.ProductReducer.basketCount
              : 0,
          basketKey:
            state && state.ProductReducer ? state.ProductReducer.basketKey : [],
        },
      };

      return appReducer(_state, action);
    } else if (
      action.type === '@@router/LOCATION_CHANGE' &&
      action.payload.location &&
      action.payload.location.pathname === '/dijitalkitap'
    ) {
      const _state = {
        ...state,
        ProductReducer: {
          ...ProductReducerState,
          keyword: '',
          searchResult: [],
        },
      };

      return appReducer(_state, action);
    } else {
      return appReducer(state, action);
    }
  };
};
