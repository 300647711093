import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { customStyles } from '../../../helpers/index';
import { connect } from 'react-redux';
import { getCities, getTowns } from '../../../routes/actions/Account';
import { customFilterOption } from '../../Select/Utility';

class Region extends PureComponent {
  state = { towns: [] };

  componentDidMount() {
    if (!this.props.cities.length) {
      this.props.getCities();
    }
  }

  getTowns = (e) => {
    this.setState({ towns: e.towns });
  };

  render() {
    const { name, schema, uiSchema, className } = this.props;

    return (
      <div className={classNames('form-group col-12', className)}>
        <label htmlFor={name}>{schema.title}</label>
        <div className="d-flex">
          <Select
            id={`${name}_city`}
            name={`${name}_city`}
            className="flex-1"
            placeholder="İl Seçiniz"
            options={this.props.cities}
            styles={customStyles}
            onChange={this.getTowns}
            defaultValue={{ label: 'İl Seçiniz', value: null }}
            filterOption={customFilterOption}
          />
          <Select
            id={`${name}_town`}
            name={`${name}_town`}
            className="flex-1 ml-2"
            placeholder="İlçe Seçiniz"
            options={this.state.towns}
            styles={customStyles}
            defaultValue={{ label: 'İlçe Seçiniz', value: null }}
            filterOption={customFilterOption}
          />
        </div>
        <small className="form-text text-muted col-sm-12">
          {uiSchema && uiSchema['ui:description']}
        </small>
      </div>
    );
  }
}

const mapStateToProps = ({ AccountReducer }) => ({
  cities: AccountReducer.cities,
  towns: AccountReducer.towns,
});

const mapDispatchToProps = {
  getCities: getCities,
  getTowns: getTowns,
};

export default connect(mapStateToProps, mapDispatchToProps)(Region);
