import React from 'react';
import { Modal } from '../../components/index';
import { orderList } from '../../services/constants';

const SortModal = (props) => {
  return (
    <Modal
      onCloseClick={props.onCloseClick}
      show={props.show}
      className="modal-container non-modal-height sort-modal"
    >
      <div className="modal-header">
        <h5 className="modal-title">Sırala</h5>
      </div>
      <div className="modal-body">
        <ul className="list-group">
          {orderList.map((item, i) => (
            <li
              onClick={() => {
                props.selectSort(item);
                props.order(item);
                props.onCloseClick();
              }}
              key={i}
              // className="list-group-item border-0"
              className={`list-group-item border-0 ${
                props.value?.label === item?.label ? 'active' : ''
              }`}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
};

export default SortModal;
