import mainLogo from '../assets/images/main/logo.png';
import mapScreenShot from '../assets/images/maps/maps_screenshot.png';
import bigTree from '../assets/images/content/big_tree.png';
import orderSuccess from '../assets/images/content/order-success.png';
import notFound from '../assets/images/content/404.png';
import bakim from '../assets/images/content/bakim.jpg';
import underConstruction from '../assets/images/content/under_construction.jpg';

const logos = [
  require('../assets/images/logo/Logo_1.png'),
  require('../assets/images/logo/Logo_2.png'),
  require('../assets/images/logo/Logo_3.png'),
  require('../assets/images/logo/Logo_4.png'),
  require('../assets/images/logo/Logo_5.png'),
  require('../assets/images/logo/Logo_6.png'),
  require('../assets/images/logo/Logo_7.png'),
];

const previews = [
  { src: require('../assets/images/previews/book1/7sinif.yt.mat.1.jpg') },
  { src: require('../assets/images/previews/book1/7sinif.yt.mat.2.jpg') },
  { src: require('../assets/images/previews/book1/7sinif.yt.mat.3.jpg') },
  { src: require('../assets/images/previews/book1/7sinif.yt.mat.4.jpg') },
];

const previewVideos = [
  { src: 'https://youtu.be/gVDNiAk6LvM' },
  { src: 'https://youtu.be/aT_e37Cd6tU' },
  { src: 'https://youtu.be/8iofcI1A-og' },
  { src: 'https://youtu.be/gVDNiAk6LvM' },
  { src: 'https://youtu.be/aT_e37Cd6tU' },
];

const experience = [
  { theta: 212, label: 'Puan', color: 2 },
  { theta: 798, label: 'Kalan Puan', color: 1 },
];

const activities = [
  {
    date: new Date(),
    description: "O'dan 8'e testi bitti.",
    point: 300,
  },
  {
    date: new Date(new Date().getTime() - 10 * 60000),
    description: "O'dan 8'e testi bitti.",
    point: 300,
  },
  {
    date: new Date(new Date().getTime() - 120 * 60000),
    description: "O'dan 8'e testi bitti.",
    point: 500,
  },
  {
    date: new Date(new Date().getTime() - 24 * 60 * 60000),
    description: "O'dan 8'e testi bitti.",
    point: 300,
  },
  {
    date: new Date(new Date().getTime() - 5 * 24 * 60 * 60000),
    description: "O'dan 8'e testi bitti.",
    point: 300,
    status: 'completed',
  },
  {
    date: new Date(new Date().getTime() - 45 * 24 * 60 * 60000),
    description: "O'dan 8'e testi bitti.",
    point: 500,
  },
];

const activities2 = [
  {
    date: new Date(),
    description: 'Türkçe - Sözcükte Anlam, Türkçe - Cümlede Anlam',
    point: 300,
    status: 'completed',
  },
  {
    date: new Date(new Date().getTime() - 10 * 60000),
    description: 'Türkçe - Cümlede Anlam',
    point: 300,
    status: 'completed',
  },
  {
    date: new Date(new Date().getTime() - 120 * 60000),
    description: 'Türkçe - Sözcükte Anlam',
    point: 0,
    status: 'canceled',
  },
  {
    date: new Date(new Date().getTime() - 24 * 60 * 60000),
    description: 'Türkçe - Cümlede Anlam',
    point: 300,
    status: 'completed',
  },
  {
    date: new Date(new Date().getTime() - 5 * 24 * 60 * 60000),
    description: 'Türkçe - Sözcükte Anlam',
    point: 300,
    status: 'completed',
  },
  {
    date: new Date(new Date().getTime() - 45 * 24 * 60 * 60000),
    description: 'Türkçe - Cümlede Anlam',
    point: 0,
    status: 'canceled',
  },
];

const lessons = [
  {
    id: 1,
    firstLetter: 'T',
    title: 'Türkçe',
    value: '5',
    actual: 100,
    expexted: 1000,
    awards: 12,
    score: 3600,
    completed: 25,
    units: [
      {
        id: 1,
        title: 'Unite 1',
        completed: 25,
        quizzes: [
          {
            id: 1,
            title: 'Test 1',
            status: 0,
          },
          {
            id: 2,
            title: 'Test 2',
            status: 1,
            right: 90,
            wrong: 10,
            empty: 5,
          },
          {
            id: 3,
            title: 'Test 3',
            status: 2,
            right: 10,
            wrong: 90,
            empty: 5,
          },
        ],
      },
      { id: 2, title: 'Unite 2', completed: 50, quizzes: [] },
      { id: 3, title: 'Unite 3', completed: 75, quizzes: [] },
      { id: 4, title: 'Unite 4', completed: 100, quizzes: [] },
    ],
  },
  {
    id: 2,
    firstLetter: 'M',
    title: 'Matematik',
    value: '5',
    actual: 250,
    expexted: 1000,
    awards: 12,
    score: 3600,
    completed: 50,
    units: [],
  },
  {
    id: 3,
    firstLetter: 'F',
    title: 'Fen',
    value: '9',
    actual: 500,
    expexted: 1000,
    awards: 12,
    score: 3600,
    completed: 75,
    units: [],
  },
  {
    id: 4,
    firstLetter: 'D',
    title: 'Din',
    value: '3',
    actual: 750,
    expexted: 1000,
    awards: 12,
    score: 3600,
    completed: 100,
    units: [],
  },
  {
    id: 5,
    firstLetter: 'İ',
    title: 'İnkilap',
    value: '7',
    actual: 800,
    expexted: 1000,
    awards: 12,
    score: 3600,
    completed: 25,
    units: [],
  },
  {
    id: 6,
    firstLetter: 'E',
    title: 'İngilizce',
    value: '2',
    actual: 950,
    expexted: 1000,
    awards: 12,
    score: 3600,
    completed: 50,
    units: [],
  },
];

const chapters = [
  {
    title: 'Doğal Sayılar',
  },
  {
    title: 'Kesirler',
  },
  {
    title: 'Ondalık Gösterimler ve Yüzdeler',
  },
  {
    title: 'Geometri',
  },
  {
    title: 'Veri İşleme - Uzunluk ve Zaman Ölçme',
  },
  {
    title: 'Alan Ölçme ve Geometrik Cisimler',
  },
  {
    title: 'Matematik Yazılı Provaları',
  },
];

const stores = [
  {
    id: 1,
    name: 'BERKE AKADEMİ KURS MERKEZİ',
    address: 'YENİKÖY MAH. NURİ PAŞA CAD. NO:55/2',
    city: 'İstanbul',
    town: 'Ataşehir',
    map: 'https://goo.gl/maps/BF21TifGe742',
    phone: '02162223344',
    status: 'pending',
  },
  {
    id: 2,
    name: 'ÇAĞRI KİTAP KIRTASİYE',
    address: 'STİNYE CAD. ADA SOK. NO:1',
    city: 'İstanbul',
    town: 'Ataşehir',
    map: 'https://goo.gl/maps/BF21TifGe742',
    phone: '02162223344',
    status: 'completed',
  },
  {
    id: 3,
    name: 'MASAL KIRTASİYE',
    address: 'PETROL İŞ MAH. RAHMANLAR CAD. NO:16/A',
    city: 'İstanbul',
    town: 'Ataşehir',
    map: 'https://goo.gl/maps/BF21TifGe742',
    phone: '02162223344',
    status: 'canceled',
  },
  {
    id: 4,
    name: 'NAR KİTAP KIRTASİYE',
    address: 'MADEN MAH. KİLYOS CAD. NO:155/1-2',
    city: 'İstanbul',
    town: 'Ataşehir',
    map: 'https://goo.gl/maps/BF21TifGe742',
    phone: '02162223344',
    status: 'timeout',
  },
  {
    id: 5,
    name: 'PANDORA KİTABEVİ',
    address: 'MADEN MAH. ŞİFA YOLU ÜSTÜ SOK. NO:3/1',
    city: 'İstanbul',
    town: 'Ataşehir',
    map: 'https://goo.gl/maps/BF21TifGe742',
    phone: '02162223344',
    status: 'canceled',
  },
];

const shoppingCartItems = [
  {
    url: 'https://s3.eu-central-1.amazonaws.com/arsiv.tongucmagaza.com/StokUrunResim/store_kapak_orta/636860853511406402.png',
    title: "8. Sınıf 4'lü LGS Denemeleri",
    quantity: 1,
    price: 23.0,
    total: 23.0,
  },
  {
    url: 'https://s3.eu-central-1.amazonaws.com/arsiv.tongucmagaza.com/StokUrunResim/store_kapak_orta/636860853511406402.png',
    title: "8. Sınıf 4'lü LGS Denemeleri Zoru Bankası",
    quantity: 1,
    price: 23.0,
    total: 23.0,
  },
  {
    url: 'https://s3.eu-central-1.amazonaws.com/arsiv.tongucmagaza.com/StokUrunResim/store_kapak_orta/636860853511406402.png',
    title: "8. Sınıf 4'lü LGS Denemeleri",
    quantity: 1,
    price: 23.0,
    total: 23.0,
  },
  {
    url: 'https://s3.eu-central-1.amazonaws.com/arsiv.tongucmagaza.com/StokUrunResim/store_kapak_orta/636860853511406402.png',
    title: "8. Sınıf 4'lü LGS Denemeleri Zoru Bankası",
    quantity: 1,
    price: 23.0,
    total: 23.0,
  },
];

const orders = [
  {
    id: 503564,
    secure3DSelected: false,
    contractChecked: false,
    orderNumber: '50356416042019',
    customerId: 90496,
    date: '2019-04-16T10:36:34.177',
    fullname: 'Yasin Demir',
    paymentMethod: 0,
    purchaseCount: 0,
    itemTotal: 51.0,
    taxTotal: 0.0,
    shipTotal: 0.0,
    adjustmentTotal: 0.0,
    total: 51.0,
    billAddress: {
      id: 156453,
      firstname: 'Yasin',
      lastname: 'Demir',
      mobile: '0534 453 82 55',
      city: { id: 34, name: 'İstanbul', towns: [] },
      town: { id: 1810, cityId: 0, name: 'Kağıthane' },
      lines:
        'Tonguç Akademi, Yeşilce Mah. Emirşah Sok. No:11 İstanbul / Kağıthane',
    },
    deliveryAddress: {
      id: 156453,
      firstname: 'Yasin',
      lastname: 'Demir',
      mobile: '0534 453 82 55',
      city: { id: 34, name: 'İstanbul', towns: [] },
      town: { id: 1810, cityId: 0, name: 'Kağıthane' },
      lines:
        'Tonguç Akademi, Yeşilce Mah. Emirşah Sok. No:11 İstanbul / Kağıthane',
    },
    lines: [
      {
        id: 1489666,
        productId: 335,
        price: 29.0,
        taxTotal: 0.0,
        total: 29.0,
        quantity: 1,
        product: {
          id: 0,
          name: 'Edebiyatik',
          freeCargo: false,
          fastCargo: false,
          discount: 0.0,
          price: 0.0,
          total: 0.0,
          tax: 0.0,
          taxons: [],
          promotions: [],
          preSale: false,
          reNew: false,
          isNew: false,
          smartContent: false,
          comingSoon: false,
          getable: false,
          addToBasket: false,
        },
      },
      {
        id: 1489667,
        productId: 333,
        price: 22.0,
        taxTotal: 0.0,
        total: 22.0,
        quantity: 1,
        product: {
          id: 0,
          name: 'TYT Matematik Problemler Geometri MPG Seti ',
          freeCargo: false,
          fastCargo: false,
          discount: 0.0,
          price: 0.0,
          total: 0.0,
          tax: 0.0,
          taxons: [],
          promotions: [],
          preSale: false,
          reNew: false,
          isNew: false,
          smartContent: false,
          comingSoon: false,
          getable: false,
          addToBasket: false,
        },
      },
    ],
    deliveries: [
      {
        id: 1,
        deliveryNumber: '',
        date: '2019-04-19T10:36:34.177',
        statusHistory: [
          {
            id: 1,
            title:
              'Yeni sipariş alındı.Ödeme şekli BankTransfer, sipariş takip no: 50356416042019. Storex Siparişi',
            date: '2019-04-16T11:15:59.42',
          },
        ],
      },
    ],
    installment: 1,
    paid: false,
    posId: 0,
  },
  {
    id: 503563,
    secure3DSelected: false,
    contractChecked: false,
    orderNumber: '50356316042019',
    customerId: 90496,
    date: '2019-04-16T09:54:15.53',
    fullname: 'Yasin Demir',
    paymentMethod: 0,
    purchaseCount: 0,
    itemTotal: 47.0,
    taxTotal: 0.0,
    shipTotal: 0.0,
    adjustmentTotal: 0.0,
    total: 47.0,
    billAddress: {
      id: 156453,
      firstname: 'Yasin',
      lastname: 'Demir',
      mobile: '0534 453 82 55',
      city: { id: 34, name: 'İstanbul', towns: [] },
      town: { id: 1810, cityId: 0, name: 'Kağıthane' },
      lines:
        'Tonguç Akademi, Yeşilce Mah. Emirşah Sok. No:11 İstanbul / Kağıthane',
    },
    deliveryAddress: {
      id: 156453,
      firstname: 'Yasin',
      lastname: 'Demir',
      mobile: '0534 453 82 55',
      city: { id: 34, name: 'İstanbul', towns: [] },
      town: { id: 1810, cityId: 0, name: 'Kağıthane' },
      lines:
        'Tonguç Akademi, Yeşilce Mah. Emirşah Sok. No:11 İstanbul / Kağıthane',
    },
    lines: [
      {
        id: 1489664,
        productId: 333,
        price: 22.0,
        taxTotal: 0.0,
        total: 22.0,
        quantity: 1,
        product: {
          id: 0,
          name: 'TYT Matematik Problemler Geometri MPG Seti ',
          freeCargo: false,
          fastCargo: false,
          discount: 0.0,
          price: 0.0,
          total: 0.0,
          tax: 0.0,
          taxons: [],
          promotions: [],
          preSale: false,
          reNew: false,
          isNew: false,
          smartContent: false,
          comingSoon: false,
          getable: false,
          addToBasket: false,
        },
      },
      {
        id: 1489665,
        productId: 316,
        price: 25.0,
        taxTotal: 0.0,
        total: 25.0,
        quantity: 1,
        product: {
          id: 0,
          name: 'Paragrafik',
          freeCargo: false,
          fastCargo: false,
          discount: 0.0,
          price: 0.0,
          total: 0.0,
          tax: 0.0,
          taxons: [],
          promotions: [],
          preSale: false,
          reNew: false,
          isNew: false,
          smartContent: false,
          comingSoon: false,
          getable: false,
          addToBasket: false,
        },
      },
    ],
    deliveries: [
      {
        id: 1,
        deliveryNumber: '',
        date: '2019-04-19T09:54:15.53',
        statusHistory: [
          {
            id: 1,
            title:
              'Yeni sipariş alındı.Ödeme şekli BankTransfer, sipariş takip no: 50356316042019. Storex Siparişi',
            date: '2019-04-16T10:32:10.233',
          },
        ],
      },
    ],
    installment: 1,
    paid: false,
    posId: 0,
  },
  {
    id: 503550,
    secure3DSelected: false,
    contractChecked: false,
    orderNumber: '50355002042019',
    customerId: 90496,
    date: '2019-04-02T12:23:12.757',
    fullname: 'Yasin Demir',
    paymentMethod: 0,
    purchaseCount: 0,
    itemTotal: 19.474576271186441,
    taxTotal: 1.52542372881355938,
    shipTotal: 0.0,
    adjustmentTotal: 0.0,
    total: 21.00000000000000038,
    billAddress: {
      id: 91016,
      firstname: 'Yasin',
      lastname: 'Demir',
      mobile: '0545353535',
      city: { id: 47, name: 'Mardin', towns: [] },
      town: { id: 1526, cityId: 0, name: 'Midyat' },
      lines: 'merkez mahallesi no 4',
    },
    deliveryAddress: {
      id: 91016,
      firstname: 'Yasin',
      lastname: 'Demir',
      mobile: '0545353535',
      city: { id: 47, name: 'Mardin', towns: [] },
      town: { id: 1526, cityId: 0, name: 'Midyat' },
      lines: 'merkez mahallesi no 4',
    },
    lines: [
      {
        id: 1489646,
        productId: 303,
        price: 11.0,
        taxTotal: 0.0,
        total: 11.0,
        quantity: 1,
        product: {
          id: 0,
          name: 'LGS Nutuk Denemeleri',
          freeCargo: false,
          fastCargo: false,
          discount: 0.0,
          price: 0.0,
          total: 0.0,
          tax: 0.0,
          taxons: [],
          promotions: [],
          preSale: false,
          reNew: false,
          isNew: false,
          smartContent: false,
          comingSoon: false,
          getable: false,
          addToBasket: false,
        },
      },
      {
        id: 1489648,
        productId: 9,
        price: 8.474576271186441,
        taxTotal: 1.525424,
        total: 8.474576271186441,
        quantity: 1,
        product: {
          id: 0,
          name: 'Kapıda Teslim Ücreti',
          freeCargo: false,
          fastCargo: false,
          discount: 0.0,
          price: 0.0,
          total: 0.0,
          tax: 0.0,
          taxons: [],
          promotions: [],
          preSale: false,
          reNew: false,
          isNew: false,
          smartContent: false,
          comingSoon: false,
          getable: false,
          addToBasket: false,
        },
      },
    ],
    deliveries: [
      {
        id: 1,
        deliveryNumber: '',
        date: '2019-04-05T12:23:12.757',
        statusHistory: [
          {
            id: 1,
            title:
              'Yeni sipariş alındı.Ödeme şekli DoorPayment, sipariş takip no: 50355002042019. Storex Siparişi',
            date: '2019-04-02T12:23:25.487',
          },
        ],
      },
    ],
    installment: 1,
    paid: false,
    posId: 0,
  },
  {
    id: 503549,
    secure3DSelected: false,
    contractChecked: false,
    orderNumber: '50354902042019',
    customerId: 90496,
    date: '2019-04-02T12:19:47.223',
    fullname: 'Yasin Demir',
    paymentMethod: 0,
    purchaseCount: 0,
    itemTotal: 19.474576271186441,
    taxTotal: 1.52542372881355938,
    shipTotal: 0.0,
    adjustmentTotal: 0.0,
    total: 21.00000000000000038,
    billAddress: {
      id: 91016,
      firstname: 'Yasin',
      lastname: 'Demir',
      mobile: '0545353535',
      city: { id: 47, name: 'Mardin', towns: [] },
      town: { id: 1526, cityId: 0, name: 'Midyat' },
      lines: 'merkez mahallesi no 4',
    },
    deliveryAddress: {
      id: 91016,
      firstname: 'Yasin',
      lastname: 'Demir',
      mobile: '0545353535',
      city: { id: 47, name: 'Mardin', towns: [] },
      town: { id: 1526, cityId: 0, name: 'Midyat' },
      lines: 'merkez mahallesi no 4',
    },
    lines: [
      {
        id: 1489643,
        productId: 303,
        price: 11.0,
        taxTotal: 0.0,
        total: 11.0,
        quantity: 1,
        product: {
          id: 0,
          name: 'LGS Nutuk Denemeleri',
          freeCargo: false,
          fastCargo: false,
          discount: 0.0,
          price: 0.0,
          total: 0.0,
          tax: 0.0,
          taxons: [],
          promotions: [],
          preSale: false,
          reNew: false,
          isNew: false,
          smartContent: false,
          comingSoon: false,
          getable: false,
          addToBasket: false,
        },
      },
      {
        id: 1489645,
        productId: 9,
        price: 8.474576271186441,
        taxTotal: 1.525424,
        total: 8.474576271186441,
        quantity: 1,
        product: {
          id: 0,
          name: 'Kapıda Teslim Ücreti',
          freeCargo: false,
          fastCargo: false,
          discount: 0.0,
          price: 0.0,
          total: 0.0,
          tax: 0.0,
          taxons: [],
          promotions: [],
          preSale: false,
          reNew: false,
          isNew: false,
          smartContent: false,
          comingSoon: false,
          getable: false,
          addToBasket: false,
        },
      },
    ],
    deliveries: [
      {
        id: 1,
        deliveryNumber: '',
        date: '2019-04-05T12:19:47.223',
        statusHistory: [
          {
            id: 1,
            title:
              'Yeni sipariş alındı.Ödeme şekli DoorPayment, sipariş takip no: 50354902042019. Storex Siparişi',
            date: '2019-04-02T12:20:00.327',
          },
        ],
      },
    ],
    installment: 1,
    paid: false,
    posId: 0,
  },
];

const taxonomies = [
  {
    id: 100,
    name: 'Sinavlar',
    code: 'sinavlar',
    taxons: [
      {
        id: 101,
        name: 'YGS',
        taxonomyId: 100,
        slug: 'ygs',
        code: 'ygs',
        taxons: [],
      },
      {
        id: 102,
        name: 'DGS',
        taxonomyId: 100,
        slug: 'dgs',
        code: 'dgs',
        taxons: [],
      },
      {
        id: 103,
        name: 'KPSS',
        taxonomyId: 100,
        slug: 'kpss',
        code: 'kpss',
        taxons: [],
      },
    ],
  },
  {
    id: 73,
    name: 'Markalar',
    code: 'markalar',
    taxons: [
      {
        id: 67,
        name: 'FUNDAmentals',
        taxonomyId: 73,
        slug: 'fundamentals',
        code: 'fundamentals',
        taxons: [],
      },
      {
        id: 64,
        name: 'Gri Koç',
        taxonomyId: 73,
        slug: 'gri-koc',
        code: 'gri-koc',
        taxons: [],
      },
      {
        id: 71,
        name: 'Matematik Okulu',
        taxonomyId: 73,
        slug: 'matematik-okulu',
        code: 'matematik-okulu',
        taxons: [],
      },
      {
        id: 65,
        name: 'Şenol Hoca',
        taxonomyId: 73,
        slug: 'senol-hoca',
        code: 'senol-hoca',
        taxons: [],
      },
      {
        id: 69,
        name: 'Kamp 2019',
        taxonomyId: 73,
        slug: 'kamp-2019',
        code: 'kamp-2019',
        taxons: [],
      },
    ],
  },
  {
    id: 72,
    name: 'Sınıflar',
    code: 'siniflar',
    taxons: [
      {
        id: 53,
        name: '5.Sınıf',
        taxonomyId: 72,
        slug: '5sinif',
        code: '5sinif',
        taxons: [],
      },
      {
        id: 52,
        name: '6.Sınıf',
        taxonomyId: 72,
        slug: '6sinif',
        code: '6sinif',
        taxons: [],
      },
      {
        id: 51,
        name: '7.Sınıf',
        taxonomyId: 72,
        slug: '7sinif',
        code: '7sinif',
        taxons: [],
      },
      {
        id: 50,
        name: '8.Sınıf',
        taxonomyId: 72,
        slug: '8sinif',
        code: '8sinif',
        taxons: [],
      },
      {
        id: 54,
        name: '9.Sınıf',
        taxonomyId: 72,
        slug: '9sinif',
        code: '9sinif',
        taxons: [],
      },
      {
        id: 55,
        name: '10.Sınıf',
        taxonomyId: 72,
        slug: '10sinif',
        code: '10sinif',
        taxons: [],
      },
      {
        id: 56,
        name: '11.Sınıf',
        taxonomyId: 72,
        slug: '11sinif',
        code: '11sinif',
        taxons: [],
      },
      {
        id: 57,
        name: '12.Sınıf',
        taxonomyId: 72,
        slug: '12sinif',
        code: '12sinif',
        taxons: [],
      },
    ],
  },
  {
    id: 75,
    name: 'Ürün Tipi',
    code: 'urun-tipi',
    taxons: [
      {
        id: 92,
        name: 'Soru Bankası',
        taxonomyId: 75,
        slug: 'soru-bankasi',
        code: 'soru-bankasi',
        taxons: [],
      },
      {
        id: 93,
        name: 'Konu Anlatımlı',
        taxonomyId: 75,
        slug: 'konu-anlatimli',
        code: 'konu-anlatimli',
        taxons: [],
      },
      {
        id: 94,
        name: 'Deneme',
        taxonomyId: 75,
        slug: 'deneme',
        code: 'deneme',
        taxons: [],
      },
      {
        id: 95,
        name: 'Fasikül',
        taxonomyId: 75,
        slug: 'fasikul',
        code: 'fasikul',
        taxons: [],
      },
      {
        id: 66,
        name: 'Eko Kitap',
        taxonomyId: 75,
        slug: 'eko-kitap',
        code: 'eko-kitap',
        taxons: [],
      },
      {
        id: 97,
        name: 'Ajanda',
        taxonomyId: 75,
        slug: 'ajanda',
        code: 'ajanda',
        taxons: [],
      },
      {
        id: 98,
        name: 'Defter',
        taxonomyId: 75,
        slug: 'defter',
        code: 'defter',
        taxons: [],
      },
      {
        id: 63,
        name: 'Kırtasiye Ürünleri',
        taxonomyId: 75,
        slug: 'kirtasiye-urunleri',
        code: 'kirtasiye-urunleri',
        taxons: [],
      },
      {
        id: 70,
        name: 'Akıl Haritaları ve Akıl Notları',
        taxonomyId: 75,
        slug: 'akil-haritalari-ve-akil-notlari',
        code: 'akil-haritalari-ve-akil-notlari',
        taxons: [],
      },
    ],
  },
  {
    id: 74,
    name: 'Kategoriler',
    code: 'kategoriler',
    taxons: [
      {
        id: 60,
        name: 'Güncel Ürünler',
        taxonomyId: 74,
        slug: 'guncel-urunler',
        code: 'guncel-urunler',
        taxons: [],
      },
      {
        id: 59,
        name: 'Çok Satanlar',
        taxonomyId: 74,
        slug: 'cok-satanlar',
        code: 'cok-satanlar',
        taxons: [],
      },
      {
        id: 61,
        name: 'Fırsatlar',
        taxonomyId: 74,
        slug: 'firsatlar',
        code: 'firsatlar',
        taxons: [],
      },
      {
        id: 99,
        name: 'Setler',
        taxonomyId: 74,
        slug: 'setler',
        code: 'setler',
        taxons: [],
      },
    ],
  },
  {
    id: 76,
    name: 'Dersler',
    code: 'dersler',
    taxons: [
      {
        id: 77,
        name: 'Türkçe',
        taxonomyId: 76,
        slug: 'turkce',
        code: 'turkce',
        taxons: [],
      },
      {
        id: 78,
        name: 'Matematik',
        taxonomyId: 76,
        slug: 'matematik',
        code: 'matematik',
        taxons: [],
      },
      {
        id: 79,
        name: 'Fen',
        taxonomyId: 76,
        slug: 'fen',
        code: 'fen',
        taxons: [],
      },
      {
        id: 80,
        name: 'Sosyal',
        taxonomyId: 76,
        slug: 'sosyal',
        code: 'sosyal',
        taxons: [],
      },
      {
        id: 81,
        name: 'İngilizce',
        taxonomyId: 76,
        slug: 'ngilizce',
        code: 'ngilizce',
        taxons: [],
      },
      {
        id: 82,
        name: 'Din',
        taxonomyId: 76,
        slug: 'din',
        code: 'din',
        taxons: [],
      },
      {
        id: 83,
        name: 'Inkilap',
        taxonomyId: 76,
        slug: 'inkilap',
        code: 'inkilap',
        taxons: [],
      },
      {
        id: 84,
        name: 'Tarih',
        taxonomyId: 76,
        slug: 'tarih',
        code: 'tarih',
        taxons: [],
      },
      {
        id: 85,
        name: 'Edebiyat',
        taxonomyId: 76,
        slug: 'edebiyat',
        code: 'edebiyat',
        taxons: [],
      },
      {
        id: 86,
        name: 'Coğrafya',
        taxonomyId: 76,
        slug: 'cografya',
        code: 'cografya',
        taxons: [],
      },
      {
        id: 87,
        name: 'Fizik',
        taxonomyId: 76,
        slug: 'fizik',
        code: 'fizik',
        taxons: [],
      },
      {
        id: 88,
        name: 'Kimya',
        taxonomyId: 76,
        slug: 'kimya',
        code: 'kimya',
        taxons: [],
      },
      {
        id: 89,
        name: 'Biyoloji',
        taxonomyId: 76,
        slug: 'biyoloji',
        code: 'biyoloji',
        taxons: [],
      },
      {
        id: 90,
        name: 'Problemler',
        taxonomyId: 76,
        slug: 'problemler',
        code: 'problemler',
        taxons: [],
      },
      {
        id: 91,
        name: 'Geometri',
        taxonomyId: 76,
        slug: 'geometri',
        code: 'geometri',
        taxons: [],
      },
    ],
  },
];

const advertorials = [
  {
    id: 1,
    title: 'Lgs Nutuk Denemeleri',
    description:
      "15 Deneme'den oluşan LGS Nutuk Denemeleri seni hem hayata hem de sınava hazırlayacak!",
    image:
      'https://s3.eu-central-1.amazonaws.com/arsiv.tongucmagaza.com/StokUrunResim/urun_kucuk/636822225228358495.png',
    backgroundImage: '',
    backgroundColor: '#0693e3',
    discountText: 'yeni',
    tag: '',
    freeCargo: false,
    fastCargo: true,
    typeId: 1,
    href: '/urun-detay/303',
    target: '_blank',
    isNew: false,
    orderNo: 2,
    logo: 3,
  },
  {
    id: 7,
    title: 'Kırtasiye Ürünleri Satışta!',
    description: '',
    image: '',
    backgroundImage:
      'https://s3.eu-central-1.amazonaws.com/arsiv.tongucmagaza.com/StorexTemp/tonguc-kirtasiye-bg.jpg',
    backgroundColor: '',
    discountText: '',
    tag: '',
    freeCargo: true,
    fastCargo: true,
    typeId: 1,
    href: '/urunler/kirtasiye-urunleri',
    target: '',
    isNew: true,
    orderNo: 3,
  },
  {
    id: 8,
    title: 'Yeni Çıkan Ürünler  jjk',
    description: 'Henüz kırtasiyelere ulaşmış tüm ürünlerimiz burada!',
    image: '',
    backgroundImage: 'https://www.comtec.daikin.co.jp/SC/pics/h1_bg_ms.png',
    backgroundColor: '#fcb900',
    discountText: '',
    tag: '',
    freeCargo: true,
    fastCargo: false,
    typeId: 1,
    href: '/urunler/guncel-urunler',
    target: '',
    isNew: true,
    orderNo: 1,
    logo: 6,
  },
  {
    id: 2,
    title: 'KOTA Konu Taramaları Matematik Kotası',
    description:
      '8. Sınıf Matematik KOTA Soru Bankası ile bu sene matematiğin şampiyonu sen olacaksın!',
    image:
      'https://s3.eu-central-1.amazonaws.com/arsiv.tongucmagaza.com/StokUrunResim/urun_kucuk/636801474203866401.png',
    backgroundImage: '',
    backgroundColor: 'maroon',
    discountText: '',
    tag: 'SERİ KİTAP',
    freeCargo: false,
    fastCargo: true,
    typeId: 1,
    href: '/urun-detay/278',
    target: '',
    isNew: true,
    orderNo: 4,
  },
  {
    typeId: 2,
    href: '',
    freeCargo: false,
    fastCargo: false,
    image:
      'https://s3.eu-central-1.amazonaws.com/arsiv.dogrukaynak.com/mainpage/78779ded-014a-4023-961a-3bac226b4076.ico',
    backgroundImage: '',
    title: 'merhaba dünya',
    description: 'açıklama',
    discountText: 0,
    id: 10,
  },
  {
    id: 9,
    title: 'Yaz Tatili Kitapları',
    description: '',
    image:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW8Q0-lZ_GZSfcKaY4xV8aCzI-Yl9gBdC-udoS-gLUw2Bgi1Fa',
    backgroundImage: '',
    backgroundColor: '',
    discountText: '',
    tag: '',
    freeCargo: false,
    fastCargo: false,
    typeId: 2,
    href: '#',
    target: '',
    isNew: false,
    orderNo: 1,
  },
  {
    id: 3,
    title: 'LGS Seti',
    description: '',
    image:
      'https://s3.eu-central-1.amazonaws.com/arsiv.tongucmagaza.com/StorexTemp/lgs-seti.jpg',
    backgroundImage: '',
    backgroundColor: '',
    discountText: '',
    tag: '',
    freeCargo: false,
    fastCargo: false,
    typeId: 2,
    href: '/urunler/7sinif',
    target: '',
    isNew: false,
    orderNo: 3,
  },
  {
    id: 4,
    title: "0'dan 8'e Hazırlık Paketi",
    description: '',
    image:
      'https://s3.eu-central-1.amazonaws.com/arsiv.tongucmagaza.com/StorexTemp/0dan-8e-hazirlik-seti.jpg',
    backgroundImage: '',
    backgroundColor: '',
    discountText: '',
    tag: '',
    freeCargo: false,
    fastCargo: false,
    typeId: 2,
    href: '/urunler/7sinif',
    target: '',
    isNew: false,
    orderNo: 4,
  },
  {
    id: 5,
    title: 'Nasıl Sipariş Verebilirim',
    description: '',
    image:
      'https://s3.eu-central-1.amazonaws.com/arsiv.tongucmagaza.com/StorexTemp/nasil-siparis-verebilirim.jpg',
    backgroundImage: '',
    backgroundColor: '',
    discountText: '',
    tag: '',
    freeCargo: false,
    fastCargo: false,
    typeId: 2,
    href: '/sikca-sorulan-sorular/populer-sorular',
    target: '',
    isNew: false,
    orderNo: 5,
  },
  {
    id: 6,
    title: "Tonguç 10.Sınıf'ta Yanınızda!",
    description: '',
    image:
      'https://s3.eu-central-1.amazonaws.com/arsiv.tongucmagaza.com/StorexTemp/tonguc-yaninizda.jpg',
    backgroundImage: '',
    backgroundColor: '',
    discountText: '',
    tag: '',
    freeCargo: false,
    fastCargo: false,
    typeId: 2,
    href: 'https://www.tongucakademi.com',
    target: '_blank',
    isNew: false,
    orderNo: 6,
  },
  {
    typeId: 2,
    freeCargo: false,
    fastCargo: false,
    image:
      'https://s3.eu-central-1.amazonaws.com/arsiv.dogrukaynak.com/mainpage/a496c3ed-efba-46d7-b174-2ab132cb6299.jpg',
    backgroundImage: '',
    title: 'Doğada çalışma kampları',
    description: 'açıklama',
    discountText: 0,
    id: 11,
    href: '/urunler',
  },
];

export {
  activities,
  activities2,
  advertorials,
  bigTree,
  chapters,
  experience,
  lessons,
  logos,
  mainLogo,
  mapScreenShot,
  orders,
  previews,
  previewVideos,
  shoppingCartItems,
  stores,
  taxonomies,
  orderSuccess,
  notFound,
  bakim,
  underConstruction,
};
