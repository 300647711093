import actionTypes from '../../../actions/Desk/Scheduler/types';
export const initialState = {
  scheduler: [],
  topics: [],
  id: null,
  pomodoroWarnModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SCHEDULE:
      return { ...state, scheduler: action.payload };
    case actionTypes.GET_TOPICS:
      return { ...state, topics: action.payload };
    case actionTypes.START_SCHEDULE:
      return { ...state, id: action.payload };
    case actionTypes.CANCEL_SCHEDULE:
      return { ...state, data: action.payload };
    case actionTypes.COMPLETE_SCHEDULE:
      return { ...state, data: action.payload };
    case actionTypes.CANCEL_SCHEDULE_POMODORO:
      return {
        ...state,
        data: action.payload,
        id: null,
        startIntervalRequestId: null,
      };
    case actionTypes.START_INTERVAL_REQUESTED:
      return { ...state, startIntervalRequestId: action.payload };
    case actionTypes.UPDATE_POMODORO_DATA:
      return { ...state, pomodoro: action.payload };
    case actionTypes.UPDATE_POMODORO_WARN_MODAL:
      return { ...state, pomodoroWarnModal: action.payload };
    default:
      return state;
  }
};
