import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { TooltipMenu } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns } from '@fortawesome/free-solid-svg-icons';

class EditMenu extends Component {
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
  }

  handleAddClick = (count) => {
    this.buttonRef.current.click();
    this.props.onAddClick(count);
  };

  render() {
    return (
      <TooltipMenu
        placement="bottom"
        trigger="click"
        tooltip={TooltipMenu}
        content={() => (
          <div className="list-group list-group-flush">
            <button
              type="button"
              className="list-group-item list-group-item-action text-capitalize"
              onClick={() => this.handleAddClick(1)}
            >
              1 Satır 1 Kolon Ekle
            </button>
            <button
              type="button"
              className="list-group-item list-group-item-action text-capitalize"
              onClick={() => this.handleAddClick(2)}
            >
              1 Satır 2 Kolon Ekle
            </button>
          </div>
        )}
      >
        <button
          type="button"
          className="btn btn-primary mx-2 text-capitalize"
          ref={this.buttonRef}
        >
          <FontAwesomeIcon className="mr-2" icon={faColumns} /> Satır Ekle
        </button>
      </TooltipMenu>
    );
  }
}

EditMenu.propTypes = {};

export default EditMenu;
