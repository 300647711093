import React, { Component } from 'react';
import classNames from 'classnames';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MenuItem, Breadcrumb } from '../../components';
import * as actions from '../../routes/actions/Account';
import * as studyActions from '../../routes/actions/Study';
import { connect } from 'react-redux';
import { slugify, contractHelper } from '../../helpers';
import queryString from 'query-string';

//const NotFound = () => <h1>"Sayfa Bulunamadı."</h1>;

const Page = ({ contract, user, order }) => {
  let html = contract.content;
  if (user && order) {
    html = contractHelper.fillContractFields(
      html,
      contractHelper.updateFields(contract.contractKeys, { user, order }),
    );
  }

  return (
    contract && (
      <>
        <h3 className="mt-2">{contract.contractName}</h3>
        <div className="card">
          {/* <div className="card-header">
              <h5>Gizlilik ve Çerez Politikası</h5> 
          </div> */}
          <div
            className="card-body fs-14"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </div>
      </>
    )
  );
};

class Index extends Component {
  state = {};

  async componentDidMount() {
    setTimeout(() => {
      if (this.props.contracts.length === 0) {
        this.props.getContracts();
      }
    }, 500);
    const qs = queryString.parse(this.props.location.search);
    if (qs.o && !isNaN(qs.o)) {
      this.props.getOrder(qs.o);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.contracts.length === 0) {
      nextProps.getContracts();
    }

    return null;
  }

  render() {
    const { routes, contracts } = this.props;

    let footerContracts = contracts.filter(
      (c) => c.places && c.places.indexOf('Footer') > -1,
    );

    var path = this.props.location.pathname.split('/');
    var contract = contracts.find(
      (x) =>
        slugify(x.contractName + ' ' + x.contractTypeId) ===
        slugify(path[path.length - 1]),
    );

    const breadcrumbs = [
      {
        title: 'Anasayfa',
        url: '/',
      },
      {
        title: 'Sözleşmeler',
        url: '/sozlesmeler',
      },
      {
        title: contract ? contract.contractName : null,
        active: true,
      },
    ];
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Breadcrumb items={breadcrumbs} />
          </div>
          <div className="col-lg-3 col-12 p-xs-0">
            <h1>Sözleşmeler</h1>
            <div className="list-group account-menu">
              {footerContracts.map((item, i) => (
                <MenuItem
                  listGroupItem
                  className={classNames({
                    active:
                      this.props.location.pathname.indexOf(
                        slugify(item.contractName + ' ' + item.contractTypeId),
                      ) === 0,
                  })}
                  to={`/sozlesmeler/${slugify(
                    item.contractName + ' ' + item.contractTypeId,
                  )}`}
                  icon={'chevronRight'}
                  key={i}
                >
                  {item.contractName}
                </MenuItem>
              ))}
            </div>
          </div>
          <div className="col-lg-9 col-12 p-xs-0">
            <Switch>
              {contract ? (
                <Route
                  component={() => (
                    <Page
                      contract={contract}
                      user={this.props.user}
                      order={this.props.order}
                      key={contract.contractName}
                    />
                  )}
                />
              ) : (
                <>
                  {/* <Redirect exact from="/sozlesmeler" to={currentRoute.path} /> */}
                  {/* <Route component={NotFound} /> */}
                </>
              )}
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}
Index.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  contracts: AccountReducer.contracts,
  user: AccountReducer.user,
  order: AccountReducer.order,
});

const mapDispatchToProps = {
  getContracts: actions.getContracts,
  getOrder: actions.getOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
